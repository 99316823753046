import cn from 'classnames'
import * as React from 'react'
import AsidePanelWrapper from '../../../../components/asidePanelWrapper'
import './style'

interface IProps {
  city: { name: string; code: string }
  allCity: { cityCode: string; cityName: string; py: string; domain: string }[]
  allBrand: { name: string; letter: string }[]
  showCityMore: boolean
  showBrandMore: boolean
  onCityMoreClick: () => void
  onBrandMoreClick: () => void
  onChangeBrand: (b: any) => void
  brandFilter: string
}

const Filter: React.FC<IProps> = props => {
  const citymap: Record<string, any[]> = {}
  props.allCity.forEach(c => {
    if (!citymap[c.py[0]]) {
      citymap[c.py[0]] = []
    }
    citymap[c.py[0]].push(c)
  })
  const cityLetters = Object.keys(citymap).sort()

  const brandmap: Record<string, any[]> = {}
  props.allBrand.forEach(c => {
    if (!brandmap[c.letter]) {
      brandmap[c.letter] = []
    }
    brandmap[c.letter].push(c)
  })
  const brandLetters = Object.keys(brandmap).sort()

  return (
    <AsidePanelWrapper title={`${props.city.name}租车筛选`}>
      <div className="com-short-rent-filter">
        <header>
          <h4>热租城市</h4>
          <button onClick={props.onCityMoreClick}>更多城市</button>

          <div id="all-city-filter" className={cn('filter-popup', { show: props.showCityMore })}>
            <div className="scroller">
              {cityLetters.map(k => {
                return (
                  <div key={k} className="city-group">
                    <h5>{k.toUpperCase()}</h5>
                    <div className="list">
                      {citymap[k].map((d: any) => {
                        return (
                          <div
                            key={d.cityCode}
                            className={cn('item', { active: d.cityCode === props.city.code })}
                          >
                            <a href={`${d.domain}`}>{d.cityName}</a>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </header>

        <div className="list">
          {props.allCity.map((d: any) => {
            return (
              <div
                className={cn('item', { active: props.city.code === d.cityCode })}
                key={d.cityCode}
              >
                <a href={`${d.domain}`}>{d.cityName}</a>
              </div>
            )
          })}
        </div>

        <header>
          <h4>热门品牌</h4>
          <button onClick={props.onBrandMoreClick}>全部品牌</button>

          <div id="all-brand-filter" className={cn('filter-popup', { show: props.showBrandMore })}>
            <div className="scroller">
              {brandLetters.map(k => {
                return (
                  <div key={k} className="city-group">
                    <h5>{k.toUpperCase()}</h5>
                    <div className="list">
                      {brandmap[k].map((d: any) => {
                        return (
                          <div
                            key={d.name}
                            className={cn('item', { active: props.brandFilter === d.name })}
                          >
                            <a onClick={() => props.onChangeBrand(d)}>{d.name}</a>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </header>

        <div className="list">
          <div className={cn('item', { active: props.brandFilter === '' })}>
            <a onClick={() => props.onChangeBrand({ name: '' })}>全部</a>
          </div>
          {props.allBrand.map(b => {
            return (
              <div key={b.name} className={cn('item', { active: props.brandFilter === b.name })}>
                <a onClick={() => props.onChangeBrand(b)}>{b.name}</a>
              </div>
            )
          })}
        </div>
      </div>
    </AsidePanelWrapper>
  )
}

export default Filter

import React from 'react'

function getOffsetTop(element, container) {
  if (!element) {
    return 0
  }

  if (!element.getClientRects().length) {
    return 0
  }

  const rect = element.getBoundingClientRect()

  if (rect.width || rect.height) {
    if (container === window) {
      // eslint-disable-next-line no-param-reassign
      container = element.ownerDocument.documentElement
      return rect.top - container.clientTop
    }
    return rect.top - container.getBoundingClientRect().top
  }

  return rect.top
}

export default class CarDetailEvent extends React.PureComponent {
  anchorLinks = [
    {
      title: '基本信息',
      href: '#carDetailBase',
    },
    {
      title: '车辆描述',
      href: '#carDetailDes',
    },
    {
      title: '可租用时间',
      href: '#carDetailCalendar',
    },
    {
      title: '车辆配置',
      href: '#carDetailConfig',
    },
    {
      title: '用车规则',
      href: '#carDetailRules',
    },
    {
      title: '相似推荐',
      href: '#carDetailRecommend',
    },
  ]

  toggleCalendarPanel = e => {
    this.setState({
      isShowCalendarPanel: !this.state.isShowCalendarPanel,
    })
  }

  getCarDetailQrCodeUrl = () => {
    const base = `${
      process.env.RAZZLE_PACKAGE === 'development' ? '/proxy' : ''
    }/apigateway/carInfoMgmt/public/qrCodePic/getQrCodePic?size=120&url=`

    const webOrigin =
      process.env.RAZZLE_PACKAGE === 'production'
        ? 'https://m.atzuche.com'
        : process.env.RAZZLE_ORIGIN

    return `${base}${encodeURIComponent(
      `${webOrigin}/m/carDetail/${this.state.carNo}?utm_source=pctominipg&utm_medium=qrcode&utm_campaign=minipgdetail`,
    )}`
  }

  getCurrentAnchor = () => {
    const linkSections = []
    this.anchorLinks.forEach(link => {
      const target = document.querySelector(link.href)
      if (target) {
        const top = getOffsetTop(target, window)
        if (top < 155) {
          linkSections.push({
            link,
            top,
          })
        }
      }
    })

    if (linkSections.length) {
      const maxSection = linkSections.reduce((prev, curr) => (curr.top > prev.top ? curr : prev))
      return maxSection.link.href
    }
    return '#carDetailBase'
  }
}

import * as React from 'react'
import './style'

interface IProps {
  title: string
  children: React.ReactNode
  more?: string
  moreClick?: () => void
  moreHref?: string
}

const AsideWrapper: React.FC<IProps> = props => {
  return (
    <div className="com-aside-panel-wrapper">
      <header>
        <h3>{props.title}</h3>
        {props.more && (
          <a className="more" href={props.moreHref} onClick={props.moreClick}>
            {props.more}
          </a>
        )}
      </header>
      <div className="bd">{props.children}</div>
    </div>
  )
}

export default AsideWrapper

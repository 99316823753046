import React from 'react'
import { createPortal, render, unmountComponentAtNode } from 'react-dom'
import './style.scss'
let timer = 0
let div
let close = function() {
  if (div) {
    div.classList.remove('x-toast--show')
    div.classList.add('x-toast--hide')
    clearTimeout(timer)
    setTimeout(remove, 200)
  }
}
const remove = function() {
  if (div?.parentNode) {
    unmountComponentAtNode(div)
    div.parentNode.removeChild(div)
    div = null
  }
}
let Toast = function(text, duration = 2000) {
  remove()
  div = document.createElement('div')
  div.classList.add('x-toast', 'x-toast--show')
  div.id = 'j-x-toast'
  document.body.appendChild(div)
  render(
    createPortal(
      React.createElement(
        'div',
        { className: 'x-toast__inner' },
        React.createElement('p', null, text),
      ),
      div,
    ),
    div,
  )
  clearTimeout(timer)
  timer = setTimeout(function() {
    close()
  }, Math.max(duration, 500))
  return [close, void 0]
}
Toast.hide = close
export default Toast

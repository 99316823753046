import cn from 'classnames'
import * as React from 'react'
import './style'

interface IProps {
  title: string
  content: string
  subMenu: any
  active: string
  activeSubMenu: string
  onSubMenuClick: (key: string) => void
}

const Tpl: React.FC<IProps> = props => {
  const baseKey = props.active.split('/')[0]

  return (
    <div className="com-about-tpl">
      <h3 className="title">{props.title}</h3>
      {props.subMenu.length && (
        <div className="submenu">
          {props.subMenu.map((m: any) => {
            return (
              <button
                onClick={() => props.onSubMenuClick(baseKey + '/' + m.value)}
                className={cn({ active: props.activeSubMenu === m.value })}
                key={m.value}
              >
                {m.label}
              </button>
            )
          })}
        </div>
      )}
      <div className="content" dangerouslySetInnerHTML={{ __html: props.content }} />
    </div>
  )
}

export default Tpl

import cn from 'classnames'
import * as React from 'react'
import AsidePanelWrapper from '../../../../components/asidePanelWrapper'
import './style'

interface IProps {
  city: any
  allCity: { cityCode: string; cityName: string; py: string; domain: string }[]
  showCityMore: boolean
  onCityMoreClick: () => void
}

const AsidePrice: React.FC<IProps> = props => {
  const citymap: Record<string, any[]> = {}
  props.allCity.forEach(c => {
    if (!citymap[c.py[0]]) {
      citymap[c.py[0]] = []
    }
    citymap[c.py[0]].push(c)
  })
  const cityLetters = Object.keys(citymap).sort()

  return (
    <AsidePanelWrapper title="热租城市租车价格" more="更多城市" moreClick={props.onCityMoreClick}>
      <div className="com-price-aside-price">
        <div className="list">
          {props.allCity.map((d: any) => {
            return (
              <div className={cn('item')} key={d.cityCode}>
                <a
                  href={`/price/${d.cityCode}`}
                  className={cn({ active: d.cityCode === props.city.code })}
                >
                  {d.cityName}租车价格
                </a>
              </div>
            )
          })}
        </div>

        <div id="all-city-filter" className={cn('filter-popup', { show: props.showCityMore })}>
          <div className="scroller">
            {cityLetters.map(k => {
              return (
                <div key={k} className="city-group">
                  <h5>{k.toUpperCase()}</h5>
                  <div className="list">
                    {citymap[k].map((d: any) => {
                      return (
                        <div
                          key={d.cityCode}
                          className={cn('item', { active: d.cityCode === props.city.code })}
                        >
                          <a href={`/price/${d.cityCode}`}>{d.cityName}租车价格</a>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </AsidePanelWrapper>
  )
}

export default AsidePrice

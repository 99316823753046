import * as React from 'react'
import { useParams } from 'react-router-dom'
import ClampLines from '../../../components/clamp'
import './style.scss'

interface QAComProps {
  title: string
  content: string
  id: string
  code: string
}

const QACom: React.FC<QAComProps> = ({ title, content, id, code }) => {
  let { type = '1' } = useParams<{ type: string }>()

  if (type.indexOf('-') > -1) {
    type = type.split('-')[0]
  }

  const link = `/rentqa/${code}-${type}-${id}`
  return (
    <div className="rentqa-qa">
      <a href={link} className="rentqa-qa-question">
        {title}
      </a>
      <a href={link} className="rentqa-qa-answer">
        <ClampLines text={content} moreText="" id={id} />
      </a>
    </div>
  )
}

export default QACom

import cn from 'classnames'
import * as React from 'react'
import './style'

interface IProps {
  data: Record<string, any>
  newsList: Record<string, any>[]
  qaList: Record<string, any>[]
  onTabClick: (_: string) => void
  current: string
}

const Menu: React.FC<IProps> = props => {
  const list: { name: string; key: string }[] = []
  const data = props.data || {}

  if (data.brandName) {
    list.push({ name: '参数配置', key: 'detail' })
  }
  if (data.news && data.news.length > 0) {
    list.push({ name: '体验详解', key: 'articles' })
  }
  if (data.comments && data.comments.length > 0) {
    list.push({ name: '用户评价', key: 'comments' })
  }
  if (data.competitors && data.competitors.length > 0) {
    list.push({ name: '同级对比', key: 'competitors' })
  }
  if (props.newsList && props.newsList.length > 0) {
    list.push({ name: '租车资讯', key: 'news' })
  }
  if (props.qaList && props.qaList.length > 0) {
    list.push({ name: '问题解答', key: 'qa' })
  }

  return (
    <div className="com-brand-lib-menu">
      <div className="inner">
        <div className="list">
          {list.map(k => {
            return (
              <span
                className={cn({ active: 'content-' + k.key === props.current })}
                key={k.key}
                onClick={() => props.onTabClick(k.key)}
              >
                {k.name}
              </span>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Menu

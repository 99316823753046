import * as React from 'react'
import {
  fetchBrandLibDetail,
  fetchNewsList,
  getCityQAListByWeight,
  getCompetitorsList,
} from './service'

interface IProps {
  history: History
  match: any
  dispatch: any
  pageData: Record<string, any>
  newsList: any[]
  qaList: any[]
}

interface IState {
  registerVisible: boolean
  registerSuccess: boolean
  currentTab: string
  showImageDetail: boolean
}

export default class Controller extends React.PureComponent<IProps, IState> {
  static async getInitialProps(ctx: any) {
    try {
      const id = ctx?.match?.params?.id || ''
      const sp = id.split('_')
      const resList: any = await Promise.all([
        fetchBrandLibDetail(sp[0] || '', sp[1] || ''),
        fetchNewsList({ cityCode: '310100' }),
        getCityQAListByWeight({ cityCode: '310100' }),
      ])

      const initProps: any = {}

      if (resList[0]) {
        initProps.pageData = resList[0] || {}
        // 获取同级别车辆数据
        if (initProps.pageData.competitors && initProps.pageData.competitors.length > 0) {
          const res: any = await getCompetitorsList(initProps.pageData.competitors)
          initProps.pageData.competitors = res.list || []
        }
      }
      if (resList[1] && resList[1].list) {
        initProps.newsList = resList[1].list
      }
      if (resList[2] && resList[2].qaDetailInfoResVos) {
        initProps.qaList = resList[2].qaDetailInfoResVos
      }

      return initProps
    } catch (err) {
      return {
        pageData: {},
        newsList: [],
        qaList: [],
      }
    }
  }

  state: Readonly<IState> = {
    registerVisible: false,
    registerSuccess: false,
    currentTab: 'content-detail',
    showImageDetail: false,
  }

  scrollTimer: any = null
  bannerSwiper: any

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.onWindowScrollDebounce)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.onWindowScrollDebounce)
    }
  }

  debounce = (func: Function, delay: number) => {
    clearTimeout(this.scrollTimer)
    this.scrollTimer = setTimeout(func, delay)
  }

  onWindowScrollDebounce = () => {
    this.debounce(this.onWindowScroll, 100)
  }

  onWindowScroll = () => {
    if (typeof document !== 'undefined') {
      const idList = [
        'content-detail',
        'content-articles',
        'content-comments',
        'content-competitors',
        'content-news',
        'content-qa',
      ]
      const offset = -200
      for (let i = 0; i < idList.length; i++) {
        const top = this.getEleOffsetTop(idList[i])
        let nextTop = idList[i + 1] ? this.getEleOffsetTop(idList[i + 1]) : 99999
        if (top < window.scrollY - offset && nextTop > window.scrollY - offset) {
          if (this.state.currentTab !== idList[i]) {
            this.setState({ currentTab: idList[i] })
          }
          break
        }
      }
      const top = this.getEleOffsetTop(idList[0])
      if (window.scrollY < top) {
        this.setState({ currentTab: idList[0] })
      }
    }
  }

  onTabClick = (key: string) => {
    const composeKey = 'content-' + key
    if (composeKey === this.state.currentTab) {
      return
    }
    const top = this.getEleOffsetTop(composeKey)
    if (top >= 0) {
      window.scrollTo(0, top - 170)
      this.setState({ currentTab: composeKey })
    }
  }

  getEleOffsetTop = (id: string): number => {
    const ele = document.getElementById(id)
    if (!ele) {
      return -1
    }

    let offsetTop = ele.offsetTop
    let currentElement: any = ele.offsetParent
    while (currentElement) {
      offsetTop += currentElement.offsetTop
      currentElement = currentElement.offsetParent
    }
    return offsetTop
  }
}

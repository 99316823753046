import cn from 'classnames'
import * as React from 'react'
import { useToggle } from 'react-use'
import AtButton from '../../../../ui/button'
import AtRange from '../../../../ui/range'
import AtTabs from '../../../../ui/tabs'
import './style'

interface IProps {
  onChange: (filter: any) => void
  className?: string
}

const Tabs: React.FC<IProps> = props => {
  const [sortType, setSortType] = React.useState(1)
  const [rentRange, setRentRange] = React.useState([0, 500])
  const [rentRangeActive, setRentRangeActive] = useToggle(false)
  const [filterActive, setFilterActive] = useToggle(false)

  const [transmissionType, setTransmissionType] = React.useState<string[]>([])
  const [seatsNum, setSeatsNum] = React.useState<string[]>([])
  const [vehicleDisplacement, setVehicleDisplacement] = React.useState<string[]>([])

  const onSetSortType = (v: number) => {
    setSortType(v)

    props.onChange({
      sortType: v,
      rentRange,
      transmissionType,
      seatsNum,
      vehicleDisplacement,
    })
  }

  const onSetTransmissionType = (v: string) => {
    if (transmissionType.indexOf(v) !== -1) {
      const list = [...transmissionType]
      list.splice(transmissionType.indexOf(v), 1)
      setTransmissionType(list)
    } else {
      setTransmissionType([...transmissionType, v])
    }
  }

  const onSetVehicleDisplacement = (v: string) => {
    if (vehicleDisplacement.indexOf(v) !== -1) {
      const list = [...vehicleDisplacement]
      list.splice(vehicleDisplacement.indexOf(v), 1)
      setVehicleDisplacement(list)
    } else {
      setVehicleDisplacement([...vehicleDisplacement, v])
    }
  }

  const onSetSeatsNum = (v: string) => {
    if (seatsNum.indexOf(v) !== -1) {
      const list = [...seatsNum]
      list.splice(seatsNum.indexOf(v), 1)
      setSeatsNum(list)
    } else {
      setSeatsNum([...seatsNum, v])
    }
  }

  const renderFilterGroup = (group: any[], active: any[], onChange: any) => {
    return (
      <div className="group">
        {group.map(g => {
          if (typeof g === 'string') {
            return (
              <button
                className={cn({ active: active.indexOf(g) !== -1 })}
                key={g}
                onClick={() => onChange(g)}
              >
                {g}
              </button>
            )
          }
          const val = g.value || g.label
          return (
            <button
              className={cn({ active: active.indexOf(val) !== -1 })}
              key={val}
              onClick={() => onChange(val)}
            >
              {g.label}
            </button>
          )
        })}
      </div>
    )
  }

  // 清空筛选
  const onClearFilters = () => {
    setTransmissionType([])
    setSeatsNum([])
    setVehicleDisplacement([])
    setFilterActive(false)

    props.onChange({
      sortType,
      rentRange,
      transmissionType: [],
      seatsNum: [],
      vehicleDisplacement: [],
    })
  }

  // 清空租金范围
  const onClearRentRange = () => {
    setRentRange([0, 500])
    setRentRangeActive(false)

    props.onChange({
      sortType,
      rentRange: [0, 500],
      transmissionType,
      seatsNum,
      vehicleDisplacement,
    })
  }

  // 确认提交
  const onSubmit = () => {
    setFilterActive(false)
    setRentRangeActive(false)

    props.onChange({
      sortType,
      rentRange,
      transmissionType,
      seatsNum,
      vehicleDisplacement,
    })
  }

  return (
    <div className="com-s2b2c-tabs">
      <AtTabs
        items={[
          { label: '综合排序', value: 1 },
          { label: '价格从低到高', value: 2 },
          { label: '价格从高到低', value: 3 },
        ]}
        value={sortType}
        onChange={onSetSortType}
      >
        <div className={cn('extra', 'rent-range', { open: rentRangeActive })}>
          <button
            onClick={() => {
              setRentRangeActive()
              setFilterActive(false)
            }}
          >
            租金范围
          </button>
          <div className={cn('popup', { open: rentRangeActive })}>
            <AtRange
              min={0}
              max={500}
              value={rentRange}
              onChange={setRentRange}
              step={10}
              tipsFormat={v => {
                if (v === 500) {
                  return '不限'
                }
                return v
              }}
              className="range"
              marks={{
                0: '0',
                100: '100',
                200: '200',
                300: '300',
                400: '500',
                500: '不限',
              }}
            />
            <div className="btns">
              <AtButton size="middle" theme="gray" className="clear" onClick={onClearRentRange}>
                清空
              </AtButton>
              <AtButton size="middle" className="submit" onClick={onSubmit}>
                确定
              </AtButton>
            </div>
          </div>
        </div>
        <div className={cn('extra', 'filter', { open: filterActive })}>
          <button
            onClick={() => {
              setFilterActive()
              setRentRangeActive(false)
            }}
          >
            更多
          </button>
          <div className={cn('popup', { open: filterActive })}>
            <div className="list">
              <h3>变速器</h3>
              {renderFilterGroup(['手动挡', '自动挡'], transmissionType, onSetTransmissionType)}
              <h3>座位数</h3>
              {renderFilterGroup(
                [
                  { label: '2座', value: '2' },
                  { label: '4座', value: '4' },
                  { label: '5座', value: '5' },
                  { label: '6座', value: '6' },
                  { label: '7座', value: '7' },
                  { label: '7座以上', value: '8-99' },
                ],
                seatsNum,
                onSetSeatsNum,
              )}
              <h3>排量</h3>
              {renderFilterGroup(
                [
                  { label: '≤ 1.5L', value: '0-1.5' },
                  { label: '1.6 - 2.0L', value: '1.6-2' },
                  { label: '2.1 - 2.5L', value: '2.1-2.5' },
                  { label: '≥ 2.6L', value: '2.6-99' },
                ],
                vehicleDisplacement,
                onSetVehicleDisplacement,
              )}
            </div>
            <div className="btns">
              <AtButton size="middle" theme="gray" className="clear" onClick={onClearFilters}>
                清空
              </AtButton>
              <AtButton size="middle" className="submit" onClick={onSubmit}>
                确定
              </AtButton>
            </div>
          </div>
        </div>
      </AtTabs>
    </div>
  )
}

export default Tabs

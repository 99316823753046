import httpx from '../../utils/httpX'

// 获取车辆类型列表
export const getCarTypeList = async (cityCode: string) => {
  const res: any = await httpx.request({
    method: 'GET',
    url: '/apigateway/longRentServer/public/h5/longRent/app/carTypeList',
    params: {
      cityCode,
    },
  })
  if (res?.carTypeList) {
    res.carTypeList.forEach((i: any) => {
      if (i.carType === '经济型') i.type = 'jingji'
      if (i.carType === '舒适型') i.type = 'shushi'
      if (i.carType === '豪华型') i.type = 'haohua'
      if (i.carType === 'MPV') i.type = 'mpv'
      if (i.carType === 'SUV') i.type = 'suv'
    })
    const list = res.carTypeList.sort((a: any, b: any) => {
      if (Number(a.discountPrice) < Number(b.discountPrice)) {
        return -1
      }
      return 1
    })
    return list
  }
  return []
}

// 表单提交
interface ISubmitFormData {
  name: string
  mobile: string
  rentCarCity: string
  useType: number
  carType: string
}

export const submitForm = async (data: ISubmitFormData) => {
  return httpx.request({
    method: 'POST',
    url: '/apigateway/longRentServer/public/h5/longRent/putLongRentUser',
    data: {
      ...data,
      channelSrc: 'PC',
    },
  })
}

import React from 'react'
import data from './data'
import './style.scss'

export default function HotBrand() {
  return (
    <div className="com-home-hot-brand">
      {data.map(item => (
        <a className="item" key={item.key} href={`/duanzu/${item.code}`} target="_blank">
          <img src={item.url} alt={item.name} />
          <h2>{item.name}</h2>
        </a>
      ))}
    </div>
  )
}

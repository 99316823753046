import * as React from 'react'
import { l2citys, getCityNameByHost } from '../../utils/citys'
import { getCarData } from './server'

interface IProps {
  city: any
  isServer: boolean
  carList: any[]
  packageFilter: any[]
  brandFilter: any[]
  sweptVolumFilter: any[]
  history: any
  match: any
}

interface IState {
  filter: Record<string, any>
  carList: any[]
}

export default class Controller extends React.PureComponent<IProps, IState> {
  static async getInitialProps(ctx: any) {
    const host = ctx.req ? ctx.req.headers['host'] : window.location.host
    const cityName = getCityNameByHost(host)
    const city = l2citys.find((city: any) => city.fullLetter === cityName) || l2citys[0]
    const carData = await getCarData(city.code)

    const packageFilter = (carData.vehicleGroupList || [])
      .filter((i: any) => i.id !== 0)
      .map((i: any) => {
        return { value: i.id, label: i.name }
      })
    packageFilter.unshift({ label: '不限', value: '' })

    // 处理一口价数据
    const yikoujia: number[] = []
    ;(carData.buyoutPriceVehicleList || []).forEach((i: any) => {
      if (i.id) {
        yikoujia.push(i.id)
      }
    })

    const brandValues: any = []
    const brandFilter: any = []
    ;(carData.vehicleList || []).forEach((i: any) => {
      // 找到车辆的所有涉及品牌
      if (brandValues.indexOf(i.brandCode) === -1) {
        brandValues.push(i.brandCode)
        brandFilter.push({ label: i.brandName, value: i.brandCode })
      }
      // 如果车辆有一口价，存下来
      if (yikoujia.indexOf(i.id)) {
        i.yikoujia = true
      }
    })
    brandFilter.unshift({ label: '不限', value: '' })

    return {
      city,
      packageFilter,
      brandFilter,
      carList: carData.vehicleList || [],
    }
  }

  constructor(props: IProps) {
    super(props)

    const s = (props.match?.params?.filter || '').split('_')
    let pkg: number | '' = ''
    let brand: number | '' = ''
    if (s[0]) {
      pkg = parseInt(s[0], 10)
    }
    if (s[1]) {
      brand = parseInt(s[1], 10)
    }

    this.state = {
      filter: { pkg, brand },
      carList: this.calcCarList({ pkg, brand }),
    }
  }

  onFilterBarChange = (pkg: any, brand: any) => {
    const f = `${pkg}_${brand}`
    if (f !== '_') {
      window.history.replaceState(null, '', '/' + this.props.city.key + '/' + f)
    } else {
      window.history.replaceState(null, '', '/' + this.props.city.key)
    }

    const filter: any = { ...this.state.filter, pkg, brand }
    this.setState({ filter, carList: this.calcCarList(filter) })
  }

  onTabChange = (filter: any) => {
    const ft = { ...this.state.filter, ...filter }
    this.setState({ filter: ft, carList: this.calcCarList(ft) })
  }

  calcCarList = (filter: any) => {
    let carList = [...(this.props.carList || [])]

    if (!filter || !Object.keys(filter).length) {
      return carList
    }

    // 套餐
    if (filter.pkg) {
      carList = carList.filter(c => {
        if (filter.pkg === 1000) {
          // 1000为一口价
          return c.yikoujia
        }
        return c.vehicleGroupId === filter.pkg
      })
    }

    // 品牌
    if (filter.brand) {
      carList = carList.filter(c => {
        return c.brandCode === String(filter.brand)
      })
    }

    // 排序
    if (filter.sortType === 2) {
      carList = carList.sort((a: any, b: any) => a.cheapDayPrice - b.cheapDayPrice)
    } else if (filter.sortType === 3) {
      carList = carList.sort((a: any, b: any) => b.cheapDayPrice - a.cheapDayPrice)
    }

    // 删除不符合的租金范围
    if (filter.rentRange && filter.rentRange.length) {
      const min = filter.rentRange[0] || 0
      let max = filter.rentRange[1] || 500
      if (max === 500) {
        max = 999999
      }
      carList = carList.filter(c => {
        return c.cheapDayPrice >= min && c.cheapDayPrice <= max
      })
    }

    // 过滤变速器
    if (filter.transmissionType && filter.transmissionType.length) {
      carList = carList.filter(c => {
        return filter.transmissionType.indexOf(c.transmissionType) !== -1
      })
    }

    // 过滤座位数
    if (filter.seatsNum && filter.seatsNum.length) {
      const range = filter.seatsNum.map((s: any) => {
        const l = s.split('-')
        return [Number(l[0]), Number(l[1] || l[0])]
      })
      carList = carList.filter(c => {
        const num = Number(c.seatsNum)
        for (let r of range) {
          if (num >= r[0] && num <= r[1]) {
            return true
          }
        }
        return false
      })
    }

    // 过滤排量
    if (filter.vehicleDisplacement && filter.vehicleDisplacement.length) {
      const range = filter.vehicleDisplacement.map((s: any) => {
        const l = s.split('-')
        return [Number(l[0]), Number(l[1] || l[0])]
      })
      carList = carList.filter(c => {
        const num = Number(c.vehicleDisplacement)
        for (let r of range) {
          if (num >= r[0] && num <= r[1]) {
            return true
          }
        }
        return false
      })
    }

    return carList
  }
}

/* eslint-disable @typescript-eslint/member-delimiter-style */
import http from '../../utils/httpX'

export interface Citys {
  cityCode: string
}

export interface QAType {
  id: string
  question: string
  answer: string
}

export interface DetailQAType {
  /**
   * 上一篇
   */
  pre: QAType

  /**
   * 当前
   */
  cur: QAType

  /**
   * 下一篇
   */
  post: QAType
}

export interface ListData {
  count: number
  qaDetailInfoResVos: QAType[]
}

// 获取问答城市
export const getCitys = () =>
  http
    .get('/apigateway/pcWebService/public/pc/qa/cities')
    .then(data => (data as any)?.qaTemplateCityListResVos)

// 根据城市获取主题列表
export const getQAListByCity = (params: { cityCode: string; pageSize?: number; pageNo?: number }) =>
  http.get('/apigateway/pcWebService/public/pc/qa/listByCityCode', {
    params: {
      pageNo: 1,
      pageSize: 10,
      ...params,
    },
  })

// 根据分类获取主题列表
export const getQAListByTopic = (params: {
  topicType: string
  pageSize?: number
  pageNo?: number
}) =>
  http.get('/apigateway/pcWebService/public/pc/qa/listByTopicType', {
    params: {
      pageNo: 1,
      pageSize: 10,
      ...params,
    },
  })

// 根据权重获取城市主题列表
export const getCityQAListByWeight = (cityCode: string) =>
  http
    .get('/apigateway/pcWebService/public/pc/qa/listByCityCode/weight', {
      params: {
        cityCode,
      },
    })
    .then(data => (data as any)?.qaDetailInfoResVos)

// 根据权重获取分类主题列表
export const getTopicQAListByWeight = (topicType: string) =>
  http
    .get('/apigateway/pcWebService/public/pc/qa/listByTopicType/weight', {
      params: {
        topicType,
      },
    })
    .then(data => (data as any)?.qaDetailInfoResVos)

// 获取城市问答详情
export const getQADetailByCity = (params: { cityCode: string; curId?: string }) =>
  http.get('/apigateway/pcWebService/public/pc/qa/detailByCityCode', {
    params,
  })

// 获取分类问答详情
export const getQADetailByTopic = (params: { topicType: string; curId?: string }) =>
  http.get('/apigateway/pcWebService/public/pc/qa/detailByTopicType', {
    params,
  })

const citys = [
  {
    code: '310100',
    fullLetter: 'SHANGHAI',
    name: '上海',
    telPrefix: '021',
    abbr: 'sh',
    domain: 'https://shanghai.atzuche.com',
  },
  {
    code: '320100',
    fullLetter: 'NANJING',
    name: '南京',
    telPrefix: '025',
    abbr: 'nj',
    domain: 'https://nanjing.atzuche.com',
  },
  {
    code: '330100',
    fullLetter: 'HANGZHOU',
    name: '杭州',
    telPrefix: '0571',
    abbr: 'hz',
    domain: 'https://hangzhou.atzuche.com',
  },
  {
    code: '440100',
    fullLetter: 'GUANGZHOU',
    name: '广州',
    telPrefix: '020',
    abbr: 'gz',
    domain: 'https://guangzhou.atzuche.com',
  },
  {
    code: '440300',
    fullLetter: 'SHENZHEN',
    name: '深圳',
    telPrefix: '0755',
    abbr: 'sz',
    domain: 'https://shenzhen.atzuche.com',
  },
  {
    code: '110100',
    fullLetter: 'BEIJING',
    name: '北京',
    telPrefix: '010',
    abbr: 'bj',
    domain: 'https://beijing.atzuche.com',
  },
  {
    code: '420100',
    fullLetter: 'WUHAN',
    name: '武汉',
    telPrefix: '027',
    abbr: 'wh',
    domain: 'https://wuhan.atzuche.com',
  },
  {
    code: '510100',
    fullLetter: 'CHENGDU',
    name: '成都',
    telPrefix: '028',
    abbr: 'cd',
    domain: 'https://chengdu.atzuche.com',
  },
  {
    code: '370200',
    fullLetter: 'QINGDAO',
    name: '青岛',
    telPrefix: '0532',
    abbr: 'qd',
    domain: 'https://qingdao.atzuche.com',
  },
]

/**
 * 缩写获取城市, 默认返回上海
 *
 * @param {*} abbr
 * @returns
 */
function abbrGetCity(abbr) {
  const defaultCity = citys[0]
  if (!abbr) {
    return defaultCity
  }

  return citys.find(v => v.abbr === abbr) || defaultCity
}

const l2citys = [
  {
    code: '310100',
    name: '上海',
    key: 'sh310100',
    fullLetter: 'shanghai',
    domain: 'https://shanghai.atzuche.com',
  },
  {
    code: '320100',
    name: '南京',
    key: 'nj320100',
    fullLetter: 'nanjing',
    domain: 'https://nanjing.atzuche.com',
  },
  {
    code: '330100',
    name: '杭州',
    key: 'hz330100',
    fullLetter: 'hangzhou',
    domain: 'https://hangzhou.atzuche.com',
  },
  {
    code: '440100',
    name: '广州',
    key: 'gz440100',
    fullLetter: 'guangzhou',
    domain: 'https://guangzhou.atzuche.com',
  },
  {
    code: '440300',
    name: '深圳',
    key: 'sz440300',
    fullLetter: 'shenzhen',
    domain: 'https://shenzhen.atzuche.com',
  },
  {
    code: '110100',
    name: '北京',
    key: 'bj110100',
    fullLetter: 'beijing',
    domain: 'https://beijing.atzuche.com',
  },
  {
    code: '500100',
    name: '重庆',
    key: 'cq500100',
    fullLetter: 'chongqing',
    domain: 'https://chongqing.atzuche.com',
  },
  {
    code: '460100',
    name: '海口',
    key: 'hk460100',
    fullLetter: 'haikou',
    domain: 'https://haikou.atzuche.com',
  },
  {
    code: '370200',
    name: '青岛',
    key: 'qd370200',
    fullLetter: 'qingdao',
    domain: 'https://qingdao.atzuche.com',
  },
  {
    code: '510100',
    name: '成都',
    key: 'cd510100',
    fullLetter: 'chengdu',
    domain: 'https://chengdu.atzuche.com',
  },
  {
    code: '610100',
    name: '西安',
    key: 'xa610100',
    fullLetter: 'xian',
    domain: 'https://xian.atzuche.com',
  },
  {
    code: '460200',
    name: '三亚',
    key: 'sy460200',
    fullLetter: 'sanya',
    domain: 'https://sanya.atzuche.com',
  },
  {
    code: '230100',
    name: '哈尔滨',
    key: 'heb230100',
    fullLetter: 'haerbing',
    domain: 'https://haerbing.atzuche.com',
  },
  {
    code: '440600',
    name: '佛山',
    key: 'fs440600',
    fullLetter: 'foushan',
    domain: 'https://foushan.atzuche.com',
  },
  {
    code: '150100',
    name: '呼和浩特',
    key: 'hhht150100',
    fullLetter: 'huhehaote',
    domain: 'https://huhehaote.atzuche.com',
  },
  {
    code: '350200',
    name: '厦门',
    key: 'xm350200',
    fullLetter: 'xiamen',
    domain: 'https://xiamen.atzuche.com',
  },
  {
    code: '410100',
    name: '郑州',
    key: 'zz410100',
    fullLetter: 'zhenzhou',
    domain: 'https://zhenzhou.atzuche.com',
  },
  {
    code: '361100',
    name: '上饶',
    key: 'sr361100',
    fullLetter: 'shangrao',
    domain: 'https://shangrao.atzuche.com',
  },
  {
    code: '420100',
    name: '武汉',
    key: 'wuhan420100',
    fullLetter: 'wuhan',
    domain: 'https://wuhan.atzuche.com',
  },
  {
    code: '320200',
    name: '无锡',
    key: 'wx320200',
    fullLetter: 'wuxi',
    domain: 'https://wuxi.atzuche.com',
  },
  {
    code: '370100',
    name: '济南',
    key: 'jn370100',
    fullLetter: 'jinan',
    domain: 'https://jinan.atzuche.com',
  },
  {
    code: '320600',
    name: '南通',
    key: 'nt320600',
    fullLetter: 'nantong',
    domain: 'https://nantong.atzuche.com',
  },
  {
    code: '140100',
    name: '太原',
    key: 'ty140100',
    fullLetter: 'taiyuan',
    domain: 'https://taiyuan.atzuche.com',
  },
  {
    code: '210100',
    name: '沈阳',
    key: 'sy210100',
    fullLetter: 'shenyang',
    domain: 'https://shenyang.atzuche.com',
  },
  {
    code: '220100',
    name: '长春',
    key: 'cc220100',
    fullLetter: 'changchun',
    domain: 'https://changchun.atzuche.com',
  },
  {
    code: '210200',
    name: '大连',
    key: 'dl210200',
    fullLetter: 'dalian',
    domain: 'https://dalian.atzuche.com',
  },
  {
    code: '320500',
    name: '苏州',
    key: 'sz320500',
    fullLetter: 'suzhou',
    domain: 'https://suzhou.atzuche.com',
  },
  {
    code: '410300',
    name: '洛阳',
    key: 'ly410300',
    fullLetter: 'luoyang',
    domain: 'https://luoyang.atzuche.com',
  },
  {
    code: '120100',
    name: '天津',
    key: 'tj120100',
    fullLetter: 'tianjin',
    domain: 'https://tianjin.atzuche.com',
  },
  {
    code: '441900',
    name: '东莞',
    key: 'dg441900',
    fullLetter: 'dongguan',
    domain: 'https://dongguan.atzuche.com',
  },
  {
    code: '350100',
    name: '福州',
    key: 'fz350100',
    fullLetter: 'fuzhou',
    domain: 'https://fuzhou.atzuche.com',
  },
  {
    code: '430100',
    name: '长沙',
    key: 'cs430100',
    fullLetter: 'changsha',
    domain: 'https://changsha.atzuche.com',
  },
  {
    code: '320700',
    name: '连云港',
    key: 'lyg320700',
    fullLetter: 'lianyungang',
    domain: 'https://lianyungang.atzuche.com',
  },
  {
    code: '371000',
    name: '威海',
    key: 'wh371000',
    fullLetter: 'weihai',
    domain: 'https://weihai.atzuche.com',
  },
  {
    code: '450100',
    name: '南宁',
    key: 'nn450100',
    fullLetter: 'nanning',
    domain: 'https://nanning.atzuche.com',
  },
  {
    code: '321200',
    name: '泰州',
    key: 'tz321200',
    fullLetter: 'taizhou',
    domain: 'https://taizhou.atzuche.com',
  },
  {
    code: '321000',
    name: '扬州',
    key: 'yz321000',
    fullLetter: 'yangzhou',
    domain: 'https://yangzhou.atzuche.com',
  },
  {
    code: '320800',
    name: '淮安',
    key: 'ha320800',
    fullLetter: 'huaian',
    domain: 'https://huaian.atzuche.com',
  },
  {
    code: '321300',
    name: '宿迁',
    key: 'sq321300',
    fullLetter: 'suqian',
    domain: 'https://suqian.atzuche.com',
  },
  {
    code: '320300',
    name: '徐州',
    key: 'xz320300',
    fullLetter: 'xuzhou',
    domain: 'https://xuzhou.atzuche.com',
  },
  {
    code: '320900',
    name: '盐城',
    key: 'yc320900',
    fullLetter: 'yancheng',
    domain: 'https://yancheng.atzuche.com',
  },
  {
    code: '330200',
    name: '宁波',
    key: 'nb330200',
    fullLetter: 'ningbo',
    domain: 'https://ningbo.atzuche.com',
  },
  {
    code: '230200',
    name: '齐齐哈尔',
    key: 'qqhe230200',
    fullLetter: 'qiqihaer',
    domain: 'https://qiqihaer.atzuche.com',
  },
  {
    code: '530100',
    name: '昆明',
    key: 'km530100',
    fullLetter: 'kunming',
    domain: 'https://kunming.atzuche.com',
  },
  {
    code: '340100',
    name: '合肥',
    key: 'hf340100',
    fullLetter: 'hefei',
    domain: 'https://hefei.atzuche.com',
  },
  {
    code: '320400',
    name: '常州',
    key: 'cz320400',
    fullLetter: 'changzhou',
    domain: 'https://changzhou.atzuche.com',
  },
  {
    code: '532900',
    name: '大理',
    key: 'dl532900',
    fullLetter: 'dali',
    domain: 'https://dali.atzuche.com',
  },
  {
    code: '530700',
    name: '丽江',
    key: 'lj530700',
    fullLetter: 'lijiang',
    domain: 'https://lijiang.atzuche.com',
  },
  {
    code: '130100',
    name: '石家庄',
    key: 'sjz130100',
    fullLetter: 'shijiazhuang',
    domain: 'https://shijiazhuang.atzuche.com',
  },
  {
    code: '331000',
    name: '台州',
    key: 'tz331000',
    fullLetter: 'taizhou1',
    domain: 'https://taizhou1.atzuche.com',
  },
  {
    code: '330300',
    name: '温州',
    key: 'wz330300',
    fullLetter: 'wenzhou',
    domain: 'https://wenzhou.atzuche.com',
  },
  {
    code: '520100',
    name: '贵阳',
    key: 'gy520100',
    fullLetter: 'guiyang',
    domain: 'https://guiyang.atzuche.com',
  },
  {
    code: '350500',
    name: '泉州',
    key: 'qz350500',
    fullLetter: 'quanzhou',
    domain: 'https://quanzhou.atzuche.com',
  },
  {
    code: '520300',
    name: '遵义',
    key: 'zy520300',
    fullLetter: 'zunyi',
    domain: 'https://zunyi.atzuche.com',
  },
  {
    code: '330600',
    name: '绍兴',
    key: 'sx330600',
    fullLetter: 'shaoxing',
    domain: 'https://shaoxing.atzuche.com',
  },
  {
    code: '440400',
    name: '珠海',
    key: 'zh440400',
    fullLetter: 'zhuhai',
    domain: 'https://zhuhai.atzuche.com',
  },
  {
    code: '370700',
    name: '潍坊',
    key: 'wf370700',
    fullLetter: 'weifang',
    domain: 'https://weifang.atzuche.com',
  },
  {
    code: '371600',
    name: '滨州',
    key: 'bz371600',
    fullLetter: 'binzhou',
    domain: 'https://binzhou.atzuche.com',
  },
  {
    code: '371300',
    name: '临沂',
    key: 'ly371300',
    fullLetter: 'linyi',
    domain: 'https://linyi.atzuche.com',
  },
  {
    code: '350600',
    name: '漳州',
    key: 'zz350600',
    fullLetter: 'zhangzhou',
    domain: 'https://zhangzhou.atzuche.com',
  },
  {
    code: '540100',
    name: '拉萨',
    key: 'ls540100',
    fullLetter: 'lasai',
    domain: 'https://lasai.atzuche.com',
  },
  {
    code: '650100',
    name: '乌鲁木齐',
    key: 'wlmq650100',
    fullLetter: 'wulumuqi',
    domain: 'https://wulumuqi.atzuche.com',
  },
  {
    code: '430200',
    name: '株洲',
    key: 'zz430200',
    fullLetter: 'zhuzhou',
    domain: 'https://zhuzhou.atzuche.com',
  },
  {
    code: '130200',
    name: '唐山',
    key: 'ts130200',
    fullLetter: 'tangshan',
    domain: 'https://tangshan.atzuche.com',
  },
  {
    code: '441300',
    name: '惠州',
    key: 'hz441300',
    fullLetter: 'huizhou',
    domain: 'https://huizhou.atzuche.com',
  },
  {
    code: '370600',
    name: '烟台',
    key: 'yt370600',
    fullLetter: 'yantai',
    domain: 'https://yantai.atzuche.com',
  },
  {
    code: '450300',
    name: '桂林',
    key: 'gl450300',
    fullLetter: 'guilin',
    domain: 'https://guilin.atzuche.com',
  },
  {
    code: '620100',
    name: '兰州',
    key: 'lz620100',
    fullLetter: 'lanzhou',
    domain: 'https://lanzhou.atzuche.com',
  },
  {
    code: '420500',
    name: '宜昌',
    key: 'yc420500',
    fullLetter: 'yichang',
    domain: 'https://yichang.atzuche.com',
  },
  {
    code: '150700',
    name: '呼伦贝尔',
    key: 'hlbe150700',
    fullLetter: 'hulunbeier',
    domain: 'https://hulunbeier.atzuche.com',
  },
  {
    code: '630100',
    name: '西宁',
    key: 'xn630100',
    fullLetter: 'xining',
    domain: 'https://xining.atzuche.com',
  },
  {
    code: '330681',
    name: '诸暨',
    key: 'zj330681',
    fullLetter: 'zhuji',
    domain: 'https://zhuji.atzuche.com',
  },
  {
    code: '469028',
    name: '陵水',
    key: 'ls469028',
    fullLetter: 'lingshui',
    domain: 'https://lingshui.atzuche.com',
  },
  {
    code: '442000',
    name: '中山',
    key: 'zs442000',
    fullLetter: 'zhongshan',
    domain: 'https://zhongshan.atzuche.com',
  },
  {
    code: '341500',
    name: '六安',
    key: 'la341500',
    fullLetter: 'luan',
    domain: 'https://lulan.atzuche.com',
  },
  {
    code: '370800',
    name: '济宁',
    key: 'jn370800',
    fullLetter: 'jining',
    domain: 'https://jining.atzuche.com',
  },
  {
    code: '341100',
    name: '滁州',
    key: 'cz341100',
    fullLetter: 'chuzhou',
    domain: 'https://chuzhou.atzuche.com',
  },
  {
    code: '130800',
    name: '承德',
    key: 'cd130800',
    fullLetter: 'chengde',
    domain: 'https://chengde.atzuche.com',
  },
  {
    code: '371100',
    name: '日照',
    key: 'rz371100',
    fullLetter: 'rizhao',
    domain: 'https://rizhao.atzuche.com',
  },
  {
    code: '210700',
    name: '锦州',
    key: 'jz210700',
    fullLetter: 'jinzhou',
    domain: 'https://jinzhou.atzuche.com',
  },
  {
    code: '350300',
    name: '莆田',
    key: 'pt350300',
    fullLetter: 'putian',
    domain: 'https://putian.atzuche.com',
  },
  {
    code: '150200',
    name: '包头',
    key: 'bt150200',
    fullLetter: 'baotou',
    domain: 'https://baotou.atzuche.com',
  },
  {
    code: '231100',
    name: '黑河',
    key: 'hh231100',
    fullLetter: 'heihe',
    domain: 'https://heihe.atzuche.com',
  },
  {
    code: '652200',
    name: '哈密',
    key: 'hm652200',
    fullLetter: 'hami',
    domain: 'https://hami.atzuche.com',
  },
  {
    code: '341600',
    name: '亳州',
    key: 'hz341600',
    fullLetter: 'haozhou',
    domain: 'https://haozhou.atzuche.com',
  },
  {
    code: '440800',
    name: '湛江',
    key: 'zj440800',
    fullLetter: 'zhanjiang',
    domain: 'https://zhanjiang.atzuche.com',
  },
  {
    code: '652801',
    name: '库尔勒',
    key: 'kel652801',
    fullLetter: 'kuerle',
    domain: 'https://kuerle.atzuche.com',
  },
  {
    code: '654002',
    name: '伊宁',
    key: 'yn654002',
    fullLetter: 'yining',
    domain: 'https://yining.atzuche.com',
  },
  {
    code: '431200',
    name: '怀化',
    key: 'hh431200',
    fullLetter: 'huaihua',
    domain: 'https://huaihua.atzuche.com',
  },
  {
    code: '530400',
    name: '玉溪',
    key: 'yx530400',
    fullLetter: 'yuxi',
    domain: 'https://yuxi.atzuche.com',
  },
  {
    code: '152201',
    name: '乌兰浩特',
    key: 'wlht152201',
    fullLetter: 'wulanhaote',
    domain: 'https://wulanhaote.atzuche.com',
  },
  {
    code: '370500',
    name: '东营',
    key: 'dy370500',
    fullLetter: 'dongying',
    domain: 'https://dongying.atzuche.com',
  },
  {
    code: '331100',
    name: '丽水',
    key: 'ls331100',
    fullLetter: 'lishui',
    domain: 'https://lishui.atzuche.com',
  },
  {
    code: '420601',
    name: '襄阳',
    key: 'xy420601',
    fullLetter: 'xiangyang',
    domain: 'https://xiangyang.atzuche.com',
  },
  {
    code: '450200',
    name: '柳州',
    key: 'lz450200',
    fullLetter: 'liuzhou',
    domain: 'https://liuzhou.atzuche.com',
  },
  {
    code: '130600',
    name: '保定',
    key: 'bd130600',
    fullLetter: 'baoding',
    domain: 'https://baoding.atzuche.com',
  },
  {
    code: '140800',
    name: '运城',
    key: 'yc140800',
    fullLetter: 'yuncheng',
    domain: 'https://yuncheng.atzuche.com',
  },
  {
    code: '140500',
    name: '晋城',
    key: 'jc140500',
    fullLetter: 'jincheng',
    domain: 'https://jincheng.atzuche.com',
  },
  {
    code: '410400',
    name: '平顶山',
    key: 'pds410400',
    fullLetter: 'pingdingshan',
    domain: 'https://pingdingshan.atzuche.com',
  },
  {
    code: '150600',
    name: '鄂尔多斯',
    key: 'eeds150600',
    fullLetter: 'eerduosi',
    domain: 'https://eerduosi.atzuche.com',
  },
  {
    code: '411400',
    name: '商丘',
    key: 'sq411400',
    fullLetter: 'shangqiu',
    domain: 'https://shangqiu.atzuche.com',
  },
  {
    code: '520500',
    name: '毕节',
    key: 'bj520500',
    fullLetter: 'bijie',
    domain: 'https://bijie.atzuche.com',
  },
  {
    code: '469002',
    name: '琼海',
    key: 'qh469002',
    fullLetter: 'qionghai',
    domain: 'https://qionghai.atzuche.com',
  },
  {
    code: '653100',
    name: '喀什',
    key: 'ks653100',
    fullLetter: 'kashi',
    domain: 'https://kashi.atzuche.com',
  },
  {
    code: '130300',
    name: '秦皇岛',
    key: 'qhd130300',
    fullLetter: 'qinhuangdao',
    domain: 'https://qinhuangdao.atzuche.com',
  },
]

const getCityNameByHost = host => {
  const defaultCityName = 'shanghai'

  if (!host) {
    return defaultCityName
  }

  const v = host.split('.')[0]

  return !v || v === 'www' ? defaultCityName : v
}

const getCityType = host => {
  let city = { type: '' }

  const currentCity = citys.findIndex(item => item.domain.split('//')[1] === host)
  const currentL2City = l2citys.findIndex(item => item.domain.split('//')[1] === host)

  if (currentCity > -1) {
    // 开发环境显示城市租车首页
    city.type = 'Short'
  } else if (
    host === process.env.RAZZLE_ORIGIN.split('//')[1] ||
    host === '127.0.0.1:20004' ||
    host === 'localhost:20004' ||
    host === '10.1.3.50:20004'
  ) {
    city.type = 'All'
  } else if (currentL2City > -1) {
    city.type = 'Secondary'
  }

  return city
}

function getCityByCode(code) {
  const defaultCity = citys[0]
  if (!code) {
    return defaultCity
  }

  return citys.find(v => v.code === code) || defaultCity
}

function getAllCityByCode(code) {
  const defaultCity = citys[0]
  if (!code) {
    return defaultCity
  }

  const city = citys.find(v => v.code === code)
  if (city) {
    return city
  }

  return l2citys.find(v => v.code === code) || defaultCity
}

function getCityByFullLetter(letter) {
  const defaultCity = citys[0]
  if (!letter) {
    return defaultCity
  }

  return citys.find(v => v.fullLetter.toLowerCase() === letter) || defaultCity
}

function getL2CityByFullLetter(letter) {
  const defaultCity = l2citys[0]
  if (!letter) {
    return defaultCity
  }

  return l2citys.find(v => v.fullLetter.toLowerCase() === letter.toLowerCase()) || defaultCity
}

function getAllCityByCityName(cityName) {
  const defaultCity = citys[0]
  if (!cityName) {
    return defaultCity
  }

  let city = citys.find(v => v.fullLetter.toLowerCase() === cityName)

  if (city) {
    return city
  }

  city = l2citys.find(v => v.fullLetter.toLowerCase() === cityName) || defaultCity
  return city
}

export {
  citys,
  l2citys,
  abbrGetCity,
  getCityNameByHost,
  getCityType,
  getCityByCode,
  getAllCityByCode,
  getCityByFullLetter,
  getL2CityByFullLetter,
  getAllCityByCityName,
}

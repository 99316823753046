import { fetchAllCity } from 'pages/shortRentV2/service'
import * as React from 'react'
import { fetchBrandLibList } from './service'

interface IProps {
  history: History
  match: any
  dispatch: any
  brandList: any[]
  allCity: any[]
  allLetters: string[]
}

interface IState {
  currentLetter: string
}

export default class Controller extends React.PureComponent<IProps, IState> {
  static async getInitialProps() {
    try {
      const res: any[] = await Promise.all([fetchBrandLibList(), fetchAllCity()])
      const brandList = res[0] && res[0].list ? res[0].list : []

      const letters: string[] = []
      brandList.forEach((b: any) => {
        if (letters.indexOf(b.brandLetter) === -1) letters.push(b.brandLetter)
      })
      letters.sort()

      return {
        brandList,
        allLetters: letters,
        allCity: res[1],
      }
    } catch (err) {
      return {
        brandList: [],
        allLetters: [],
        allCity: [],
      }
    }
  }

  scrollTimer: any = null

  state: IState = { currentLetter: '' }

  constructor(props: IProps) {
    super(props)
    this.state.currentLetter = props.allLetters[0] || ''
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.onWindowScrollDebounce)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.onWindowScrollDebounce)
    }
  }

  debounce = (func: Function, delay: number) => {
    clearTimeout(this.scrollTimer)
    this.scrollTimer = setTimeout(func, delay)
  }

  onWindowScrollDebounce = () => {
    this.debounce(this.onWindowScroll, 100)
  }

  onWindowScroll = () => {
    if (typeof document !== 'undefined') {
      const offset = -100
      const letters = this.props.allLetters
      for (let i = 0; i < letters.length; i++) {
        const top = this.getEleOffsetTop(`brand-group-${letters[i]}`)
        let nextTop = letters[i + 1] ? this.getEleOffsetTop(`brand-group-${letters[i + 1]}`) : 99999
        if (top < window.scrollY - offset && nextTop > window.scrollY - offset) {
          if (this.state.currentLetter !== letters[i]) {
            this.setState({ currentLetter: letters[i] })
          }
          break
        }
      }
      const top = this.getEleOffsetTop(`brand-group-${letters[0]}`)
      if (window.scrollY < top) {
        this.setState({ currentLetter: letters[0] })
      }
    }
  }

  getEleOffsetTop = (id: string): number => {
    const ele = document.getElementById(id)
    if (!ele) {
      return -1
    }

    let offsetTop = ele.offsetTop
    let currentElement: any = ele.offsetParent
    while (currentElement) {
      offsetTop += currentElement.offsetTop
      currentElement = currentElement.offsetParent
    }
    return offsetTop
  }

  onLetterClick = (letter: string) => {
    this.setState({ currentLetter: letter })
    const group = `brand-group-${letter}`
    const top = this.getEleOffsetTop(group)
    if (top >= 0) {
      window.scrollTo(0, top - 100)
    }
  }
}

import Rate from 'components/rate'
import * as React from 'react'
import cdn from 'utils/cdn'
import './style'

interface IProps {
  data: Record<string, any>
  onClick: () => void
}

const Competitors: React.FC<IProps> = props => {
  const data = props.data || {}

  if (!data.competitors || !data.competitors.length) {
    return null
  }

  const renderItem = (data: Record<string, any>) => {
    return (
      <div className="item" key={data.id} onClick={props.onClick}>
        <img className="thumb" src={cdn + data.thumb} />
        <div className="row">
          <h3>{data.title}</h3>
          <div className="price">
            <strong>{data.price}</strong>
            <span>元/天</span>
          </div>
        </div>
        <div className="row">
          <Rate rate={data.rate} />
        </div>
      </div>
    )
  }

  return (
    <div className="com-brand-lib-competitors" id="content-competitors">
      <header>
        <h2>同级对比</h2>
        <a onClick={props.onClick} className="more">
          查看更多
          <span />
        </a>
      </header>

      <div className="list">{data.competitors.map((n: any) => renderItem(n))}</div>
    </div>
  )
}

export default Competitors

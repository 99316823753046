import React, { FC } from 'react'
import './style.scss'

interface IProps {
  simple?: boolean
}

const carOwnerUrl =
  '/sh/carOwner?utm_source=pcczright&utm_medium=guanwang&utm_campaign=guanwang&utm_term=1848e48107254073b8bd235fb60e9078'

const signUpUrl =
  '/signup?utm_source=pcnewright&utm_medium=guanwang&utm_campaign=guanwang&utm_term=11c1cffcd7d56ff10fd76dc3fdd3c2ae'

const RentNavPanel: FC<IProps> = ({ simple = false }) => {
  if (simple) {
    return (
      <div className="com-rent-nav-panel">
        <a className="panel panel-4" href={carOwnerUrl} />
        <a className="panel panel-5" href={signUpUrl} />
      </div>
    )
  }
  return (
    <div className="com-rent-nav-panel">
      <a className="panel panel-1" href="duanzu" />
      <a className="panel panel-2" href={carOwnerUrl} />
      <a className="panel panel-3" href={signUpUrl} />
    </div>
  )
}

export default RentNavPanel

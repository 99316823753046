/* eslint-disable @typescript-eslint/indent */
import React from 'react'
import Toast from '../../components/toast'
import Http from '../../utils/httpX'
import Cookie from 'js-cookie'
import alert from './alert'

const baseurl = process.env.NODE_ENV === 'development' ? process.env.RAZZLE_ORIGIN : ''

class MobileSignupEvent extends React.Component {
  state = {
    phone: '',
    imgCode: '',
    smsCode: '',
    isShowImgCode: false,
    timeDown: 0,
    isSubmitting: false,
    isAgreementChecked: true,
  }

  source = [
    'socommobH',
    'socommobnj',
    'socommobsh',
    'socommobsz',
    'socommobhz',
    'socommobgz',
    'socommobbj',
    'sougoumobbj',
    'sougoumobsh',
    'sougoumobgz',
    'sougoumobsz',
    'sougoumobhz',
    'sougoumobnj',
    'sougoumobHD',
    'sougoumobSTbj',
    'sougoumobSTsh',
    'sougoumobSTgz',
    'sougoumobSTsz',
    'sougoumobSThz',
    'sougoumobSTnj',
  ]

  componentWillUnmount() {
    this.clearTimer()
  }

  clearTimer = () => {
    this.timeDownIntervaler && window.clearInterval(this.timeDownIntervaler)
  }

  verifyPhone = phone => {
    return /^1[3456789]\d{9}$/.test(phone)
  }

  onChange = (type, e) => {
    this.setState({
      [type]: e.target.value,
    })
  }

  setImgCode = url => {
    const { phone } = this.state

    this.imgCodeUrl = this.imgCodeUrl || url

    if (!this.verifyPhone(phone)) {
      Toast('请输入正确的手机号')
    }

    this.imgCodeUrl = this.imgCodeUrl.replace(/mobile=[0-9]{11}/, `mobile=${phone}`)

    this.setState({
      isShowImgCode: true,
      imgCodeUrl: `${baseurl}/appserver${this.imgCodeUrl}&_v=${Math.random() * 99999}`,
    })
  }

  setTimeDown = () => {
    let time = 60
    this.timeDownIntervaler = setInterval(() => {
      this.setState({
        timeDown: time--,
      })

      if (!time) {
        this.setState({
          timeDown: 0,
        })
        window.clearInterval(this.timeDownIntervaler)
      }
    }, 1000)
  }

  getSmsCode = async () => {
    const { phone, imgCode } = this.state
    if (!phone) {
      return Toast('请输入手机号')
    }
    if (!this.verifyPhone(phone)) {
      return Toast('请输入正确的手机号')
    }

    try {
      await Http.request({
        method: 'post',
        url: '/appserver/h5/v31/mem/action/validate',
        data: {
          mobile: phone,
          verifyCode: imgCode || '',
          validCodeType: 'sms',
        },
      })

      Toast('短信已发送，请注意查收')

      this.setTimeDown()
    } catch (e) {
      if (e.code === '200017') {
        Toast('请输入图片验证码')
        this.setImgCode(e.data.verifyCodeUrl)
      } else if (e.code === '300008') {
        Toast(e.msg)
        this.setImgCode(e.data.verifyCodeUrl)
      } else {
        Toast(e.msg)
      }
    }
  }

  submit = async () => {
    const { phone, imgCode, smsCode, isAgreementChecked } = this.state

    if (!phone) {
      return Toast('请输入手机号')
    }
    if (!imgCode) {
      return Toast('请输入图形验证码')
    }
    if (!smsCode) {
      return Toast('请输入验证码')
    }
    if (!this.verifyPhone(phone)) {
      return Toast('请输入正确的手机号')
    }

    if (!isAgreementChecked) {
      return Toast('请同意协议条款')
    }

    try {
      this.setState({
        isSubmitting: true,
      })

      const utmSource = Cookie.get('utm_source')
      let data = {
        mobile: phone,
        imageValidCode: imgCode || '',
        loginType: 'validCode',
        validCode: smsCode,
        utmSource,
        utmMedium: Cookie.get('utm_medium'),
        utmCampaign: Cookie.get('utm_campaign'),
        utmTerm: Cookie.get('utm_term'),
      }
      if (utmSource) {
        data.OS = utmSource
      }

      const res = await Http.request({
        method: 'post',
        url: '/appserver/h5/v53/member/login',
        data,
      })

      if (res.isNew === '0') {
        const content = ~~res.giveNoLimitCouponflag
          ? '该账号已注册过，立即下载APP可获取30元优惠券哦'
          : res.maxDiscount && res.maxDiscount !== '0'
          ? `尊敬的凹凸租车用户<br />您的账户还有${res.maxDiscount}元优惠券未使用<br />快去凹凸租车APP使用吧~`
          : '该账号已注册过'

        return alert({ content, isNew: false })
      }

      window._agl && window._agl.push(['track', ['success', { t: 3 }]])

      return alert({
        content: `已发放到您的账户<br />
        租车最高立减500元！<br />
        马上去使用吧`,
        isNew: true,
      })
    } catch (error) {
      Toast(error.msg)
    } finally {
      this.setState({
        isSubmitting: false,
      })
    }
  }

  fixInputBlurWhiteBox = e => {
    e.persist()
    setTimeout(() => {
      const ele = e.target
      if (ele) {
        ele.scrollIntoViewIfNeeded(false)
      }
    }, 300)
  }

  scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  onAgreementClick = () => {
    this.setState({
      isAgreementChecked: !this.state.isAgreementChecked,
    })
  }
}

export default MobileSignupEvent

import * as React from 'react'
import { Helmet } from 'react-helmet'
import PanelBaoPai from './components/panelBaoPai'
import PanelBaoPaiForm from './components/panelBaoPaiForm'
import PanelCarOwner from './components/panelCarOwner'
import Controller from './controller'
import './style'

export default class View extends Controller {
  renderHead = () => {
    return (
      <Helmet>
        <title>汽车出租_闲置私家车出租,个人车辆共享平台-凹凸租车</title>
        <meta
          name="keywords"
          content="汽车租赁,婚车租赁,汽车租赁公司,汽车出租,婚车出租,汽车租赁平台"
        />
        <meta
          name="description"
          content="凹凸租车是汽车租赁公司,汽车租赁平台提供汽车租赁,婚车租赁,汽车出租,婚车出租服务.包括私家车出租,汽车共享,车牌出租,车辆闲置,汽车闲置等服务."
        />
        <meta
          name="mobile-agent"
          content="format=html5;url=m.atzuche.com/m/about/carOwner?channelld-22"
        />
      </Helmet>
    )
  }

  renderNewsItem = (title: string, id: number) => {
    return (
      <a key={id} href={`/news/${this.props.city.code}-${id}?role=owner`} className="news-item">
        <span className="p">{title}</span>
        <span className="dot" />
        [查看]
      </a>
    )
  }

  render() {
    const { city = {}, newsList = [] } = this.props

    return (
      <div className="page-car-owner">
        {this.renderHead()}
        <div className="main">
          <div className="col-car-owner">
            <h1 className="title">闲置车辆成负担？</h1>
            <p className="desc">上传你的爱车，止损又赚钱</p>
            <div className="download-app">
              <img src={require('./images/app-qrcode.png')} />
              <h2>
                扫码下载APP
                <br />
                注册成为凹凸车主
              </h2>
            </div>
            <PanelCarOwner />
            <div className="news">
              <header>
                <h3>{`${city.name}·租车资讯`}</h3>
                <a href={`/news/${this.props.city?.code}?role=owner`}>更多</a>
              </header>
              {newsList.map((n: any) => {
                return this.renderNewsItem(n.title, n.id)
              })}
            </div>
          </div>
          <div className="col-bao-pai">
            <h1 className="title">车牌空置怎么办?</h1>
            <p className="desc">闲置资金买辆车，帮你赚钱</p>
            <PanelBaoPaiForm />
            <PanelBaoPai />
            <div className="soon">
              <header>
                <h3>敬请期待…</h3>
              </header>
            </div>
          </div>
        </div>

        <div className="shadow" />
      </div>
    )
  }
}

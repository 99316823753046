import './style.scss'
import React from 'react'
import Event from './event'
import AutoFeature from './component/autoFeature'
import CarArea from './component/carArea'
import Tool from '../../hoc/tool'

class ViewHome extends Event {
  renderTenant() {
    const {
      phone,
      imgCode,
      smsCode,
      isShowImgCode,
      timeDown,
      imgCodeUrl,
      isSubmitting,
    } = this.state
    return (
      <section className="invite-content">
        <div className="invite-func-area">
          <p className="invite-func-area-tille">注册即送1288元优惠券</p>
          <div className="invite-content-input">
            <input
              type="text"
              placeholder="请输入您的手机号"
              maxLength="11"
              value={phone}
              onChange={this.onChange.bind(this, 'phone')}
              onBlur={this.fixInputBlurWhiteBox}
            />
          </div>
          {isShowImgCode && (
            <div className="invite-content-input">
              <input
                type="text"
                placeholder="请输入图形验证码"
                maxLength="6"
                value={imgCode}
                onChange={this.onChange.bind(this, 'imgCode')}
                onBlur={this.fixInputBlurWhiteBox}
              />
              <div className="invite-content-input-right">
                <img src={imgCodeUrl} onClick={this.setImgCode} alt="图形验证码" />
              </div>
            </div>
          )}
          <div className="invite-content-input">
            <input
              type="text"
              placeholder="请输入短信验证码"
              maxLength="6"
              value={smsCode}
              onChange={this.onChange.bind(this, 'smsCode')}
              onBlur={this.fixInputBlurWhiteBox}
            />
            {timeDown ? (
              <button
                className="invite-content-input-right invite-content-input-right-disable"
                disabled
              >
                重新获取 ({timeDown}s)
              </button>
            ) : (
              <button
                className="invite-content-input-right"
                disabled={!phone}
                onClick={this.getSmsCode}
              >
                获取短信验证码
              </button>
            )}
          </div>
          <button
            className="invite-func-area-button"
            onClick={this.submit}
            disabled={!phone || !smsCode || isSubmitting || (isShowImgCode && !imgCode)}
          >
            领取优惠券
          </button>
        </div>
        <AutoFeature />
        <CarArea />
        <div className="invite-bottom">
          <img src={require('./imgs/bottomBG.jpg')} className="goodReputation" />
          <div className="bottomBottom" />
        </div>
      </section>
    )
  }

  render() {
    return (
      <div
        className="invite"
        onScroll={this.pageScroll}
        ref={e => {
          this.page = e
        }}
      >
        <div className="invite-detail">
          <div className="invite-detail-banner" />
          <section className="invite-detail-content">{this.renderTenant()}</section>
        </div>
        <div
          className="getTicket"
          ref={e => {
            this.getTicket = e
          }}
          style={{
            display: this.state.isShowFloatCoupon ? 'block' : 'none',
          }}
        >
          <img onClick={this.topHandler} src={require('./imgs/getTicket.jpg')} />
        </div>
      </div>
    )
  }
}

export default Tool(ViewHome)

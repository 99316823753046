/* eslint-disable @typescript-eslint/indent */
import React from 'react'
import Controller from './controller'
import Tool from '../../hoc/tool'
import './style.scss'
import { AppVersion } from 'at-business-components'

class SignupSuccess extends Controller {
  renderTip = () => {
    const { isNew, maxDiscount, giveNoLimitCouponflag } = this.search

    if (!isNew) {
      return <h3>请先注册</h3>
    }

    if (isNew === '0') {
      return (
        <>
          <h3>您的账户已注册</h3>
          <p>
            {giveNoLimitCouponflag === '1'
              ? '凹凸君再送您一张30元租车券'
              : maxDiscount && maxDiscount !== '0'
              ? `当前还有一张价值${maxDiscount}元的租车券未使用`
              : ''}
          </p>
        </>
      )
    }

    return (
      <>
        <h3>1288元租车券已入账</h3>
        <p>开始您的租车之旅</p>
      </>
    )
  }

  renderSuccessBox = () => {
    return (
      <div className="box">
        {this.renderTip()}
        <div className="qr">
          <img src={require('./images/qr-code.png')} />
        </div>
        <small>扫码下载APP</small>
        <button className="goto-homepage" onClick={this.gotoHomepage}>
          回到首页
        </button>

        <div className="privacy">
          <AppVersion />
        </div>
      </div>
    )
  }

  render() {
    return <div className="singnup-success">{this.renderSuccessBox()}</div>
  }
}

export default Tool(SignupSuccess)

import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import './style.scss'

class Footer extends Component<any, any> {
  renderFriendLink() {
    const pathname = this.props.location.pathname
    // if (!/^\/([a-z]{2,})?([0-9]{6})?$/.test(pathname)) {
    //   return null
    // }

    // 友链只在首页显示
    if (pathname && pathname !== '/') {
      return null
    }

    const list = this.props.linkList || []

    if (list.length === 0) {
      return null
    }

    if (!this.props.www) {
      return null
    }

    return (
      <div className="row friend-link">
        <div className="cell title">
          <h6>友情链接</h6>
        </div>
        <div className="cell">
          <div className="links">
            {list.map((item: any) => {
              return (
                <a key={item.url} href={item.url} target="_blank">
                  {item.name}
                </a>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const widthOutNavRoutes = ['/pc/baopai', '/pc/doubleFreeDeposit']

    if (
      this.props.isMobile ||
      widthOutNavRoutes.indexOf(this.props.location.pathname) !== -1 ||
      this.props.location.pathname.indexOf('/lossConfirmLetter') > -1
    ) {
      return null
    }

    // const cityCode = this.props.city?.code || '310100'

    return (
      <div className="footer">
        <div className="main">
          <div className="row about">
            <div className="cell">
              <h6>关于我们</h6>
              <p>
                <Link to="/about/knowUs" rel="nofollow">
                  关于凹凸
                </Link>
              </p>
              <p>
                <Link to="/about/milestone" rel="nofollow">
                  发展历程
                </Link>
              </p>
              <p>
                <Link to="about/mediaReport" rel="nofollow">
                  媒体报道
                </Link>
              </p>
              <p>
                <a href="/brand">品牌库</a>
              </p>
              <p>
                <a href="/price">租车价格表</a>
              </p>
              <p>
                <a href="/city">全国租车</a>
              </p>
            </div>
            <div className="cell">
              <h6>规则条款</h6>
              <p>
                <Link to="/about/rule/platformRule" rel="nofollow">
                  平台规则
                </Link>
              </p>
              <p>
                <Link to="/about/rentCarFlow" rel="nofollow">
                  租车流程
                </Link>
              </p>
              <p>
                <Link to="/about/insuranceClauses" rel="nofollow">
                  服务条款
                </Link>
              </p>
              <p>
                <Link to="/about/ownersResAndIegal" rel="nofollow">
                  车主责任
                </Link>
              </p>
              <p>
                <Link to="/about/privacyPolicy" rel="nofollow">
                  隐私协议
                </Link>
              </p>
            </div>
            <div className="qr">
              <div className="qr-item">
                <h6>支付宝小程序</h6>
                <img src="https://cdn.atzuche.com/pc/qr/alipay.png" />
              </div>
              <div className="qr-item">
                <h6>微信小程序</h6>
                <img src="https://cdn.atzuche.com/pc/qr/wechat.png?v=1" />
              </div>
              <div className="qr-item">
                <h6>微信公众号</h6>
                <img src="https://cdn.atzuche.com/pc/qr/official-account.png" />
              </div>
              <div className="qr-item">
                <h6>APP下载</h6>
                <img src="https://cdn.atzuche.com/pc/qr/app.png" />
              </div>
            </div>
          </div>

          {this.renderFriendLink()}

          <div className="row copyright">
            <div className="cell title">
              <div className="logo" />
            </div>
            <div className="content cell">
              <h6>©2022 上海馨煜信息科技有限公司</h6>
              <p>
                <a
                  href="http://beian.miit.gov.cn/state/outPortal/loginPortal.action;jsessionid=wX6iOYlc0z9b53BVuHqni8-LwkRRBrpZYWUZhpqLjEfkLHY9djOG!375388418"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  沪ICP备17039182号-2&nbsp;&nbsp;沪公网安备 31010402000209号
                </a>
                &nbsp;&nbsp;经营增值电信业务许可：合字B2-20210054&nbsp;&nbsp;标识编号:
                20180329133434646
              </p>
              <p>违法和不良信息举报邮箱：inbound@atzuche.com&nbsp;&nbsp;举报电话：16601791150</p>
              <p>
                您可以通过
                <a
                  href="https://www.piyao.org.cn/"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ borderBottom: 'solid 1px rgba(255, 255, 255, 0.6)' }}
                >
                  中国互联网联合辟谣平台
                </a>
                提供谣言线索、查证网络谣言（
                <a href="https://www.piyao.org.cn/" rel="noopener noreferrer" target="_blank">
                  https://www.piyao.org.cn/
                </a>
                ）
              </p>
            </div>
          </div>

          <div className="row gov">
            <div className="cell left" />
            <div className="cell right">
              <p>
                亲爱的市民朋友，上海警方反诈劝阻电话“962110”系专门针对避免您财产被骗受损而设，请您一旦收到来电，立即接听
              </p>
              <p>扫黑除恶众志成城，平安上海人人参与。</p>
              <p>
                全国扫黑除恶举报电话：010—12389，上海市扫黑除恶举报电话：021—52397077，全国扫黑除恶举报网站：
                <a href="http://www.12389.gov.cn/" rel="nofollow" target="__blank">
                  www.12389.gov.cn
                </a>
              </p>
              <div className="gov-link">
                <a href="https://www.12377.cn/" target="_blank" rel="nofollow">
                  <img src={require('./imgs/5.png')} />
                </a>
                {/* eslint-disable-next-line react/jsx-no-script-url */}
                <a href="javascript:;">
                  <img src={require('./imgs/3.png')} alt="962110" />{' '}
                </a>
                <a href="http://www.shjbzx.cn/" target="_blank" rel="nofollow">
                  <img src={require('./imgs/2.png')} />
                </a>
                <a
                  href="http://218.242.124.22:8081/businessCheck/verifKey.do?serial=31000091310115a39a271a373a32a3648a44001002-SAIC_SHOW_310000-20180329133434646537&signData=MEQCIHseoMUmrLQH5BTKny1quoYjV4P+XqebkLmwXHHWIfmfAiAXvtMp5H5iewPT57p0cKBbUU1/3mYwyp4Sf3zrm19W2g=="
                  target="_blank"
                  rel="nofollow"
                >
                  <img src={require('./imgs/4.png')} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Footer)

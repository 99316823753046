export enum ERentType {
  Day,
  Week,
  Month,
  Enterprise,
}

const data = {
  day: [
    {
      key: 'jjx',
      name: '经济型',
      price: '60',
      unit: '元/天',
      imgUrl: 'https://cdn.atzuche.com/pc/day/jjx.jpg',
      carType: '大众宝来、丰田卡罗拉、别克英朗GT、雪佛兰科沃兹、本田思域',
      desc: '适用日常代步、短途出行、上下班等',
    },
    {
      key: 'ssx',
      name: '舒适型',
      price: '119',
      unit: '元/天',
      imgUrl: 'https://cdn.atzuche.com/pc/day/ssx.jpg',
      carType: '丰田凯美瑞、别克君威、大众迈腾、奥迪A3、日产天籁',
      desc: '适用日常代步、旅行、约会、上下班等',
    },
    {
      key: 'hhx',
      name: '豪华型',
      price: '200',
      unit: '元/天',
      imgUrl: 'https://cdn.atzuche.com/pc/day/hhx.jpg',
      carType: '奔驰C级、宝马3系、奥迪A4L、凯迪拉克CT5、特斯拉Model 3',
      desc: '适用豪车体验、商务接待、旅行等',
    },
    {
      key: 'suv',
      name: 'SUV',
      price: '68',
      unit: '元/天',
      imgUrl: 'https://cdn.atzuche.com/pc/day/suv.jpg',
      carType: 'Model Y、蔚来ES6、理想ONE、丰田汉兰达、大众途观',
      desc: '适用日常出行、郊游、城市通勤等',
    },
    {
      key: 'swx',
      name: '商务型',
      price: '80',
      unit: '元/天',
      imgUrl: 'https://cdn.atzuche.com/pc/day/swx.jpg',
      carType: '别克GL8、广汽传祺GM8、本田奥德赛、奔驰威霆、丰田埃尔法',
      desc: '适用商务接待、全家出行、行政接待等',
    },
    {
      key: 'xzx',
      name: '行政型',
      price: '369',
      unit: '元/天',
      imgUrl: 'https://cdn.atzuche.com/pc/day/xzx.jpg',
      carType: '保时捷Panamera、奔驰S级、奥迪A8、玛莎拉蒂总裁、宾利飞驰',
      desc: '适用商务接待、豪车体验、婚车等',
    },
  ],
  week: [
    {
      key: 'jjx',
      name: '经济型',
      price: '450',
      unit: '元/周',
      imgUrl: 'https://cdn.atzuche.com/pc/week/jjx.jpg',
      carType:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
      desc: '',
    },
    {
      key: 'ssx',
      name: '舒适型',
      price: '450',
      unit: '元/周',
      imgUrl: 'https://cdn.atzuche.com/pc/week/ssx.jpg',
      carType:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
      desc: '',
    },
    {
      key: 'hhx',
      name: '豪华型',
      price: '450',
      unit: '元/周',
      imgUrl: 'https://cdn.atzuche.com/pc/week/hhx.jpg',
      carType:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
      desc: '',
    },
    {
      key: 'suv',
      name: 'SUV',
      price: '450',
      unit: '元/周',
      imgUrl: 'https://cdn.atzuche.com/pc/week/suv.jpg',
      carType:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
      desc: '',
    },
    {
      key: 'swx',
      name: '商务型',
      price: '450',
      unit: '元/周',
      imgUrl: 'https://cdn.atzuche.com/pc/week/swx.jpg',
      carType:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
      desc: '',
    },
    {
      key: 'xzx',
      name: '行政型',
      price: '450',
      unit: '元/周',
      imgUrl: 'https://cdn.atzuche.com/pc/week/xzx.jpg',
      carType:
        'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.',
      desc: '',
    },
  ],
  month: [
    {
      key: 'jjx',
      name: '经济型',
      price: '2460',
      unit: '元/月',
      imgUrl: 'https://cdn.atzuche.com/pc/month/jjx.jpg',
      carType: '丰田卡罗拉、日产轩逸、大众宝来、本田飞度、别克英朗GT',
      desc: '适用日常代步、短途出行、上下班等',
    },
    {
      key: 'ssx',
      name: '舒适型',
      price: '3180',
      unit: '元/月',
      imgUrl: 'https://cdn.atzuche.com/pc/month/ssx.jpg',
      carType: '本田雅阁、丰田凯美瑞、大众帕萨特、奥迪A3、别克君威',
      desc: '适用日常代步、旅行、约会、上下班等',
    },
    {
      key: 'hhx',
      name: '豪华型',
      price: '4020',
      unit: '元/月',
      imgUrl: 'https://cdn.atzuche.com/pc/month/hhx.jpg',
      carType: '特斯拉Model 3、宝马3系、奥迪A4L、奔驰C级、小鹏P7',
      desc: '适用豪车体验、商务接待、旅行等',
    },
    {
      key: 'suv',
      name: 'SUV',
      price: '3480',
      unit: '元/月',
      imgUrl: 'https://cdn.atzuche.com/pc/month/suv.jpg',
      carType: '奥迪Q3、Model Y、大众途观、奔驰GLC 、哈弗H6',
      desc: '适用日常出行、郊游、城市通勤等',
    },
    {
      key: 'swx',
      name: '商务型',
      price: '3270',
      unit: '元/月',
      imgUrl: 'https://cdn.atzuche.com/pc/month/swx.jpg',
      carType: '丰田埃尔法、别克GL8、广汽传祺GM8、奔驰威霆、本田奥德赛',
      desc: '适用商务接待、全家出行、行政接待等',
    },
    {
      key: 'xzx',
      name: '行政型',
      price: '8370',
      unit: '元/月',
      imgUrl: 'https://cdn.atzuche.com/pc/month/xzx.jpg',
      carType: '玛莎拉蒂Ghibli、保时捷Panamera、奥迪A8、宝马7系、捷豹XJL',
      desc: '适用商务接待、豪车体验、婚车等',
    },
  ],
}

export const tabData = [
  {
    name: '日租',
    key: ERentType.Day,
  },
  {
    name: '月租',
    key: ERentType.Month,
  },
  {
    name: '企业租车',
    key: ERentType.Enterprise,
  },
]

export default data

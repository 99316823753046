import cn from 'classnames'
import * as React from 'react'
import './style.scss'

const NavBar = (props: any) => {
  const { carInfo = {} } = props.state
  const { links = [] } = carInfo

  const navs: any = [
    { label: '参数配置', value: 'configuration' },
    { label: '外观/内饰', value: 'photos' },
    links.length > 0 ? { label: '体验', value: 'links' } : false,
    { label: '评价', value: 'comment' },
    { label: '同级对比', value: 'compares' },
    { label: '文章', value: 'article' },
    { label: '问题解答', value: 'qa' },
  ].filter(Boolean)

  return (
    <div className="com-hot-car-nav-bar">
      {navs.map((v: any) => {
        return (
          <button
            key={v.value}
            className={cn({ active: props.active === v.value })}
            onClick={() => props.onClick(v.value)}
          >
            {v.label}
          </button>
        )
      })}
    </div>
  )
}

export default NavBar

import React, { Component } from 'react'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'
import './style.scss'
import positions from 'positions'
import { findDOMNode } from 'react-dom'

class Button extends Component {
  fixToolEleAlign = (tooltipEl, align) => {
    const bounds = tooltipEl.getBoundingClientRect()
    const placementsMap = {
      tc: 'top center',
      bc: 'bottom center',
      cl: 'center right',
      cr: 'center left',
    }

    // 计算宽度是不是超过了版心
    if (bounds.width >= 1024) {
      tooltipEl.style.width = '1024px'
      tooltipEl.style.left = '0'
    }

    // 计算左边的位置是不是超过了版心
    if (parseFloat(tooltipEl.style.left) < 0) {
      tooltipEl.style.left = '0'
    }

    // 计算右边的位置是不是超过了版心
    if (parseFloat(tooltipEl.style.left) + bounds.width >= 1024) {
      tooltipEl.style.right = '0'
      tooltipEl.style.left = null
    }

    // 修复位置太靠上
    tooltipEl.style.top = parseFloat(tooltipEl.style.top) + 5 + 'px'

    // 修复箭头的位置没有居中
    const arrowEl = tooltipEl.querySelector('.rc-tooltip-arrow')
    // eslint-disable-next-line react/no-find-dom-node
    const targetEl = findDOMNode(this)

    const position = positions(
      arrowEl,
      placementsMap[align.points[0]],
      targetEl,
      placementsMap[align.points[1]],
    )

    if (align.points[0] === 'tc' || align.points[0] === 'bc') {
      arrowEl.style.top = ''
      arrowEl.style.left = `${position.left + 5}px`
    } else {
      arrowEl.style.top = `${position.top}px`
      arrowEl.style.left = ''
    }
  }
  render() {
    const { text, active, onClick, toolTips } = this.props
    const cn = active ? 'shortrent-button shortrent-button-active' : 'shortrent-button'
    return toolTips?.() ? (
      <Tooltip
        placement="bottom"
        overlay={toolTips()}
        onPopupAlign={this.fixToolEleAlign}
        getTooltipContainer={() => {
          return document.querySelector('.shortrent-area')
        }}
        mouseEnterDelay={0.3}
      >
        <button onClick={onClick} className={cn}>
          {text}
        </button>
      </Tooltip>
    ) : (
      <button onClick={onClick} className={cn}>
        {text}
      </button>
    )
  }
}

export default Button

import React from 'react'
import Event from './event'
import Split from '$components/split'
import Registered from 'src/components/registered'
import Button from '$components/brandButton'
import classnames from 'classnames'
import CarCard from '$components/carCard'
import { connect } from 'react-redux'
import Pagination from 'rc-pagination'
import { Helmet } from 'react-helmet'
import 'rc-pagination/dist/rc-pagination.min.css'

import './style.scss'
import Loading from 'src/components/loading'
import NoCar from 'src/components/noCar'

class SpecailCar extends Event {
  renderHead = () => {
    const cityName = this.context.city.name || ''
    return (
      <Helmet>
        <title>豪华车租赁_婚车租赁,{cityName}租跑车及婚庆租车平台-凹凸租车</title>
        <meta name="keywords" content="豪华车租赁,婚车租赁,婚庆租车,租跑车" />
        <meta
          name="description"
          content={`凹凸租车个性车频道提供高端豪华车租赁，${cityName}豪车租赁，百款豪车车型满足不同场景的豪车需求，包括超跑租赁体验、高端商务车租赁、高端婚庆用车等。`}
        />
      </Helmet>
    )
  }

  renderHeader = () => {
    return (
      <div className="special-car-header">
        <div className="main special-car-header-wrapper">
          <div className="special-car-header-title">
            <h2>凹凸个性车</h2>
            <h2>提供超过百款的豪车车型</h2>
          </div>
          <Registered />
        </div>
      </div>
    )
  }

  renderCarTypes = () => {
    return (
      <div className="main">
        <Split />
        <h3 className="special-car-title">您想要的豪华车型应有尽有，满足你的不同场景需求</h3>

        <ul className="special-car-types">
          {this.carTypes.map((v, i) => (
            <li key={i} className="special-car-types-item">
              <div className="special-car-types-item-img">
                <img src={v.url} alt={v.type} />
                <span className="special-car-types-item-type">{v.type}</span>
              </div>
              <h4 className="special-car-types-item-title">{v.title}</h4>
              {v.descriptions.map((j, k) => (
                <p key={k}>{j}</p>
              ))}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  renderCarBrands = () => {
    const { brands = {} } = this.props
    const { selectBrand } = this.state
    const allCn = classnames('special-car-brands-all', 'special-car-brands-arrow', {
      'special-car-brands-arrow-expand': this.state.isShowAllBrands,
    })
    return (
      <div className="main">
        <Split />
        <h3 className="special-car-title">支持全国主流城市 各种豪车供您选择</h3>
        <div className="special-car-brands">
          <span className="special-car-brands-title">品牌</span>
          <Button text="不限" active={!selectBrand} onClick={this.onClickBrand.bind(this, 0)} />
          {brands.randomBrandVoList &&
            brands.randomBrandVoList.map((v, i) => {
              return (
                <Button
                  key={i}
                  text={v.text}
                  active={selectBrand === v.id}
                  onClick={this.onClickBrand.bind(this, v.id)}
                />
              )
            })}

          <button className={allCn} onClick={this.onViewAllBrands}>
            {this.state.isShowAllBrands ? '收起' : '全部'}
          </button>

          {this.state.isShowAllBrands && (
            <div className="special-car-brands-wrapper">
              {brands.brandVOList &&
                brands.brandVOList.map((v, i) => {
                  return (
                    <Button
                      key={i}
                      text={v.text}
                      active={selectBrand === v.id}
                      onClick={this.onClickBrand.bind(this, v.id)}
                    />
                  )
                })}
            </div>
          )}
        </div>
      </div>
    )
  }

  renderCars = () => {
    const { cars } = this.props
    const { loading } = this.state

    if (loading) {
      return <Loading />
    }

    return (
      <div className="main">
        <div className="car-list clearfix">
          {cars ? cars.map((v, i) => <CarCard className="card" key={i} data={v} />) : <NoCar />}
        </div>
      </div>
    )
  }

  renderPagination = () => {
    const { page } = this.state
    const { total = 0, cars } = this.props

    if (!cars) {
      return null
    }

    return (
      <div className="main special-car-pagination">
        <Pagination
          className="at-pagination"
          defaultPageSize={20}
          current={page}
          total={total}
          itemRender={(current, type, element) => {
            if (type === 'prev') {
              return <span>&lt; 上一页 </span>
            }
            if (type === 'next') {
              return <span>下一页 &gt;</span>
            }
            return element
          }}
          onChange={current => {
            let params = {
              page: current,
            }

            this.getCars(params)
          }}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="special-car">
        {this.renderHead()}
        {this.renderHeader()}
        {this.renderCarTypes()}
        {this.renderCarBrands()}
        {this.renderCars()}
        {this.renderPagination()}
      </div>
    )
  }
}

export default connect(({ specialCar }) => ({
  ...specialCar,
}))(SpecailCar)

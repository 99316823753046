import 'react-id-swiper/src/styles/scss/swiper.scss'
import './style.scss'
import React from 'react'
import CountUp from 'react-countup'
import Swiper from 'react-id-swiper'
import VisibilitySensor from 'react-visibility-sensor'
import { getBanners, getHotCars } from './api'
import CarTypeListWiew from './components/carTypeListView'
import HotBrand from './components/hotBrand'
import RentCarService from './components/large-title'
import Subjects from './components/subjects'
import HotCity from './components/hotCity'
import NewsList from './components/newsList'
import Tool from '../../hoc/tool'
import CDN from '../../utils/cdn'
import { CityContext } from '../../utils/city-context'
import Event from './event'

class Home extends Event {
  static async getInitialProps({ req, res, match }) {
    const cityCode = match?.params?.cityCode || '310100'
    const hotCars = await getHotCars(cityCode)

    return { hotCars }
  }

  constructor(props) {
    super(props)
    this.state = {
      banners: [],
      isDataActive: false,
    }
  }

  async componentDidMount() {
    const { citys, toggleCity } = this.context
    let { cityCode } = this.params

    let isEffective = false

    if (citys && cityCode) {
      isEffective = citys.find(city => {
        return city.code === cityCode
      })
    }

    if (isEffective) {
      toggleCity(isEffective)
    }

    const banners = await getBanners()
    this.setState({
      banners,
      isDataActive: true,
    })
  }

  renderSwiper() {
    const { banners } = this.state

    if (!banners || banners.length === 0) {
      return null
    }

    const params = {
      loop: true,
      speed: 800,
      effect: 'fade',
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      containerClass: 'box swiper-container',
    }
    return (
      <div className="swiper">
        <Swiper
          {...params}
          ref={node => {
            if (node) this.swiper = node.swiper
          }}
        >
          {banners.map((banner, index) => {
            return (
              <a
                target={banner.link ? '_blank' : void 0}
                href={banner.link || 'javascript:void(0);'}
                key={index}
                className="swiper-item"
                style={{
                  backgroundImage: `url(${CDN + banner.path})`,
                }}
              >
                <span className="slogan" />
              </a>
            )
          })}
        </Swiper>

        <div className="main swiper-controller">
          <button
            className="swiper-button-prev"
            onClick={() => {
              if (this.swiper) this.swiper.slidePrev()
            }}
          />
          <button
            className="swiper-button-next"
            onClick={() => {
              if (this.swiper) this.swiper.slideNext()
            }}
          />
        </div>
      </div>
    )
  }

  renderSubBanner() {
    const banners = [
      {
        coverImg: 'https://cdn.atzuche.com/pc/subbanner/banner9.png',
        link: '/signup',
        name: '1680新人礼包',
      },
      {
        coverImg: 'https://cdn.atzuche.com/pc/subbanner/banner2.jpg',
        link: '/duanzu',
        name: '万款车型立即租',
      },
      {
        coverImg: 'https://cdn.atzuche.com/pc/subbanner/banner3.jpg',
        link: '/carOwner',
        name: '成为车主获收益',
      },
      {
        coverImg: 'https://cdn.atzuche.com/pc/subbanner/banner10.png',
        link: '/signup',
        name: '叠加立减',
      },
    ]
    return (
      <div className="main sub-banners">
        <div className="banners">
          {banners.map((item, index) => (
            <a className="banner" key={index} target="_blank" href={item.link}>
              <img src={item.coverImg} />
            </a>
          ))}
        </div>
      </div>
    )
  }

  renderData() {
    return (
      <VisibilitySensor
        active={this.state.isDataActive}
        delayedCall
        onChange={isVisibility => {
          if (isVisibility) {
            this.setState({
              isDataActive: false,
              isStartData: true,
            })
          }
        }}
      >
        <div className="main basedata">
          <ul className="data">
            <li className="data-item">
              <h3>{this.state.isStartData ? <CountUp end={300} separator="," suffix="" /> : 0}</h3>
              <p>
                业务覆盖近<em>300</em>个城市
              </p>
              <div className="icon">
                <img src={require('./imgs/bg-superiority-1.png')} />
              </div>
            </li>
            <li className="data-item">
              <h3>
                {this.state.isStartData ? <CountUp end={20000000} separator="," suffix="+" /> : 0}
              </h3>
              <p>
                注册用户突破<em>2000</em>万
              </p>
              <div className="icon">
                <img src={require('./imgs/bg-superiority-2.png')} />
              </div>
            </li>
            <li className="data-item">
              <h3>
                {this.state.isStartData ? <CountUp end={10000} separator="," suffix="+" /> : 0}
              </h3>
              <p>
                超全车型逾<em>10000</em>款
              </p>
              <div className="icon">
                <img src={require('./imgs/bg-superiority-3.png')} />
              </div>
            </li>
            <li className="data-item">
              <h3>
                {this.state.isStartData ? <CountUp end={300000} separator="," suffix="+" /> : 0}
              </h3>
              <p>
                注册车辆超过<em>30</em>万辆
              </p>
              <div className="icon">
                <img src={require('./imgs/bg-superiority-4.png')} />
              </div>
            </li>
          </ul>
        </div>
      </VisibilitySensor>
    )
  }

  renderRentCarService() {
    return (
      <div className="main rent-car-service">
        <RentCarService title="租车服务" />
        <CarTypeListWiew />
      </div>
    )
  }

  renderHotBrand() {
    return (
      <div className="main rent-hot-brand">
        <RentCarService title="热租品牌" />
        <HotBrand />
      </div>
    )
  }

  renderRentSubject() {
    const { subjects = {} } = this.props

    if (!subjects || !subjects.list || subjects.list.length < 4) {
      return null
    }

    return (
      <div className="main rent-subject">
        <RentCarService title="租车专题" />
        <Subjects data={subjects.list} />
      </div>
    )
  }

  renderNews() {
    const { newsList } = this.props
    if (!newsList || newsList.length === 0) {
      return null
    }

    return (
      <div className="main news">
        <RentCarService title="凹凸资讯" />
        <NewsList data={newsList} />
      </div>
    )
  }

  renderHotCity() {
    return (
      <div className="main hot-city">
        <RentCarService title="热门城市" />
        <HotCity />
      </div>
    )
  }

  render() {
    return (
      <div className="home">
        {this.renderSwiper()}
        {this.renderSubBanner()}
        {this.renderData()}
        {this.renderRentCarService()}
        {this.renderHotBrand()}
        {this.renderRentSubject()}
        {this.renderNews()}
        {this.renderHotCity()}
      </div>
    )
  }
}

Home.contextType = CityContext

export { Home }
export default Tool(Home)

import Http from '$utils/httpX'

export async function getBrands(city = '310100') {
  try {
    const result = await Http.request({
      url: '/apigateway/carWebSite/public/brands',
      method: 'GET',
      params: {
        city,
      },
    })

    if (!result) {
      return {}
    }

    return { randomBrandVoList: result.randomBrandVoList, brandVOList: result.brandVOList }
  } catch (error) {
    console.error(error)
    return {}
  }
}

export async function getCars(params = {}) {
  try {
    if (!params.page) {
      params.page = 1
    }
    if (!params.limit) {
      params.limit = 20
    }
    const result = await Http.request({
      url: '/apigateway/carWebSite/public/search/car',
      method: 'GET',
      params,
    })

    if (!result) {
      return {}
    }

    return { cars: result.styleCarVOList, total: result.total }
  } catch (error) {
    console.error(error)
    return {}
  }
}

import * as React from 'react'
import Helmet from 'react-helmet'
import AsideCities from './components/asideCities'
import BrandList from './components/brandList'
import TopFilter from './components/topFilter'
import Controller from './controller'
import './style'

export default class View extends Controller {
  renderAside() {
    return (
      <div className="aside">
        <AsideCities allCity={this.props.allCity} />
      </div>
    )
  }

  renderMain() {
    return (
      <div className="mainbody">
        <TopFilter
          list={this.props.brandList || []}
          currentLetter={this.state.currentLetter}
          onClick={this.onLetterClick}
        />

        <BrandList list={this.props.brandList || []} />
      </div>
    )
  }

  renderHeader() {
    return (
      <Helmet>
        <title>汽车品牌_热门品牌车型库_2023最新车型品牌大全-凹凸租车</title>
        <meta
          name="description"
          content="凹凸租车品牌车型库提供热门汽车品牌报价、汽车图片、汽车评测评价、汽车试驾租赁等万款车型品牌内容；最新新款车型图片、参数、报价信息尽在凹凸租车车型库！"
        />
        <meta name="keywords" content="汽车品牌,品牌车型" />
        <meta name="mobile-agent" content="format=html5;url=https://m.atzuche.com/m/index/brand" />
      </Helmet>
    )
  }

  render() {
    return (
      <div className="page-brand-lib">
        {this.renderHeader()}
        <div className="main">
          {this.renderAside()}
          {this.renderMain()}
        </div>
      </div>
    )
  }
}

import * as React from 'react'
import AtFilterBar from '../../../../ui/filterBar'
import './style'
// import cn from 'classnames'

interface IProps {
  pkg: any
  brand: any
  brandFilter: any[]
  packageFilter: any[]
  className?: string
  onChange: (pkg: number | '', brand: number | '') => void
}

const FilterBar: React.FC<IProps> = props => {
  const { brandFilter = [], packageFilter = [] } = props
  const [packageValue, setPackageValue] = React.useState<number | ''>(props.pkg)
  const [brandValue, setBrandValue] = React.useState<number | ''>(props.brand)

  React.useEffect(() => {
    setPackageValue(props.pkg)
    setBrandValue(props.brand)
  }, [props.pkg, props.brand])

  // const filters = () => {
  //   return (
  //     <div className="filters">
  //       {Object.keys(sortMap).map(key => {
  //         const data = sortMap[key]
  //         if (!data) {
  //           return null
  //         }
  //         return (
  //           <div className="group" key={key}>
  //             <span>{key}</span>
  //             {data.map((item: any) => {
  //               return (
  //                 <button
  //                   key={item.id}
  //                   className={cn({ active: item.id === brandValue })}
  //                   onClick={() => {
  //                     setBrandValue(item.id)
  //                     props.onChange(packageValue, item.id)
  //                   }}
  //                 >
  //                   {item.txt}
  //                 </button>
  //               )
  //             })}
  //           </div>
  //         )
  //       })}
  //     </div>
  //   )
  // }

  return (
    <div className="com-s2b2c-filter-bar">
      <AtFilterBar className="filter-bar">
        <AtFilterBar.Row
          title="套餐"
          options={packageFilter}
          value={packageValue}
          onChange={(v: any) => {
            setPackageValue(v)
            props.onChange(v, brandValue)
          }}
        />
        <AtFilterBar.Row
          title="品牌"
          options={brandFilter}
          value={String(brandValue)}
          onChange={(v: any) => {
            setBrandValue(v)
            props.onChange(packageValue, v)
          }}
          // all={filters()}
        />
      </AtFilterBar>
    </div>
  )
}

export default FilterBar

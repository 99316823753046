import { Model } from 'dva'
import { fetchCarList } from './service'

export interface ICityRentPageState {
  shortTermCarList: any[]
  specialCarList: any[]
  longTermCarList: any[]
  businessCenterList: any[]
  newsList: any[]
  city: Record<string, string>

  // state
  shortTermListActiveTab: string
  shortTermListLoading: boolean
  specialListActiveTab: string
  specialListLoading: boolean
  longTermListActiveTab: string
  longTermListLoading: boolean
  filterActive: string
  filterExtraActive: string
}

const initialState: ICityRentPageState = {
  shortTermCarList: [],
  specialCarList: [],
  longTermCarList: [],
  businessCenterList: [],
  newsList: [],
  city: {},

  shortTermListActiveTab: 'excellentCar',
  shortTermListLoading: true,
  specialListActiveTab: 'paoChe',
  specialListLoading: true,
  longTermListActiveTab: 'longRentSpecialPrice',
  longTermListLoading: true,
  filterActive: 'ALL',
  filterExtraActive: '',
}

const model: Model = {
  namespace: 'cityRent',
  state: initialState,
  reducers: {
    set(state, { type, payload }) {
      return { ...state, ...payload }
    },
    _fetchCarList(state, { payload }) {
      return { ...state, ...payload }
    },
  },
  effects: {
    *fetchCarList({ payload }, { call, put }) {
      const countMap: any = {
        shortTermCarList: 8,
        specialCarList: 4,
        longTermCarList: 4,
      }
      const res = yield call(fetchCarList, payload.cityCode, payload.filter, countMap[payload.key])
      yield put({
        type: '_fetchCarList',
        payload: {
          [payload.key]: res.carList,
        },
      })
    },
  },
}

export default model

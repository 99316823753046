import React from 'react'
import { getCityNameByHost, getCityByFullLetter } from '$utils/citys'
import { getCars, getBrands } from './api'
import { CityContext } from '$utils/city-context'
import store from '../../store.js'

class SpecialCarEvent extends React.PureComponent {
  static async getInitialProps({ req, res, match, history }) {
    try {
      const host = req ? req.headers['host'] : window.location.host
      const cityName = getCityNameByHost(host)
      const city = getCityByFullLetter(cityName)
      const { cars, total } = await getCars({
        city: city.code,
      })
      const brands = await getBrands(city.code)

      const payload = { brands, cars, total }

      await store.dispatch({
        type: 'specialCar/set',
        payload,
      })

      return payload
    } catch (error) {
      return {}
    }
  }

  state = {
    isShowAllBrands: false,
    page: 1,
    selectBrand: 0,
    loading: false,
  }

  carTypes = [
    {
      url: require('./imgs/1.jpg'),
      type: '豪华车租赁',
      title: '自驾出行',
      descriptions: ['让你享受不同豪车自驾体验，', '享受来自超跑的自由驾驶乐趣'],
    },
    {
      url: require('./imgs/2.jpg'),
      type: '高端商务车租赁',
      title: '商务接驾',
      descriptions: ['高端豪华车型满足你高端接待需求，', '助您成功更近一步。'],
    },
    {
      url: require('./imgs/3.jpg'),
      type: '高端婚车租赁',
      title: '豪华婚车',
      descriptions: ['提供高端婚庆用车，', '满足您高端奢华婚礼定制需求。'],
    },
  ]

  async componentDidMount() {
    const { toggleCity, city } = this.context

    toggleCity(city)

    this.cityCode = city.code
  }

  componentDidUpdate() {
    const { city = {} } = this.context
    if (city.code !== this.cityCode) {
      this.cityCode = city.code
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectBrand: 0,
        page: 1,
      })
    }
  }

  onViewAllBrands = () => {
    this.setState({
      isShowAllBrands: !this.state.isShowAllBrands,
    })
  }

  getCars = async (params = {}) => {
    try {
      this.setState({
        loading: true,
      })
      params.city = this.cityCode

      if (this.state.selectBrand) {
        params.brand = this.state.selectBrand
      }
      const { cars, total } = await getCars(params)

      await this.props.dispatch({
        type: 'specialCar/set',
        payload: {
          cars,
          total,
        },
      })
      this.setState({
        ...params,
      })
    } catch (error) {
      console.error(error.msg)
    } finally {
      this.setState({
        loading: false,
      })
    }
  }

  onClickBrand = async selectBrand => {
    this.setState(
      {
        selectBrand,
        loading: true,
        isShowAllBrands: false,
      },
      async () => {
        try {
          const { cars, total } = await this.getCars()
          await this.props.dispatch({
            type: 'specialCar/set',
            payload: {
              cars,
              total,
            },
          })
        } catch (error) {
        } finally {
          this.setState({
            loading: false,
          })
        }
      },
    )
  }
}

// eslint-disable-next-line react/static-property-placement
SpecialCarEvent.contextType = CityContext

export default SpecialCarEvent

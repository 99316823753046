import { History } from 'history'
import { PureComponent } from 'react'
import { getCityByFullLetter, getCityNameByHost, getL2CityByFullLetter } from '../../utils/citys'
import { fetchSubjectList } from './../subjectRent/service'
import {
  fetchAllBrand,
  fetchAllCars,
  fetchAllCity,
  fetchNewsList,
  getCityQAListByWeight,
} from './service'

interface IProps {
  history: History
  match: any
  city: any
  newsList: { list: any[] }
  homeSubjectList: any
  qaList: any[]
  carList: any[]
  allBrand: any[]
  allCity: any[]
  isHomePage: boolean
}

interface IState {
  registerVisible: boolean
  registerSuccess: boolean
  currentPage: number
  showCityMore: boolean
  showBrandMore: boolean
  brandFilter: string
}

class Controller extends PureComponent<IProps, IState> {
  static async getInitialProps(ctx: any) {
    const host = ctx.req ? ctx.req.headers['host'] : window.location.host
    const url = ctx.req ? String(ctx.req.url) || '/' : window.location.pathname || '/'

    const cityName = getCityNameByHost(host)
    let city = getL2CityByFullLetter(cityName)
    if (city.fullLetter !== cityName) {
      city = { ...city, ...getCityByFullLetter(cityName) }
    }

    // 全国
    if (url.indexOf('/city') === 0) {
      city = { code: '000000', name: '全国', key: '000000', fullLetter: '', domain: '' }
    }

    try {
      const cityCode = city.code === '000000' ? '310100' : city.code
      const res = await Promise.all([
        fetchNewsList({
          cityCode,
          pageNum: 1,
          pageSize: 10,
        }),
        getCityQAListByWeight(cityCode),
        fetchAllCars(city.code === '000000' ? '' : city.code),
        fetchAllCity(),
        fetchSubjectList(cityCode, 1, 5),
      ])

      const host = ctx.req ? ctx.req.headers['host'] || '' : window.location.host || ''

      let isHomePage = false
      if (
        host.indexOf('www.') !== -1 ||
        host.indexOf('fat1.') !== -1 ||
        host.indexOf('localhost') !== -1
      ) {
        isHomePage = true
      }
      if (url && url.indexOf('/duanzu') !== -1) {
        isHomePage = false
      }
      // 全国页面
      if (city.code === '000000') {
        isHomePage = false
      }

      return {
        newsList: res[0],
        homeSubjectList: res[4],
        qaList: res[1] ?? [],
        city,
        carList: res[2],
        allBrand: fetchAllBrand(res[2], cityCode),
        allCity: res[3],
        isHomePage,
      }
    } catch (err) {
      return {
        newsList: { list: [] },
        homeSubjectList: { list: [] },
        qaList: [],
        err,
        city,
        carList: [],
        allBrand: [],
        allCity: [],
        cityType: 'All',
      }
    }
  }

  state: IState = {
    registerVisible: false,
    currentPage: 1,
    registerSuccess: false,
    showCityMore: false,
    showBrandMore: false,
    brandFilter: '',
  }

  componentDidMount(): void {
    if (typeof document !== 'undefined') {
      document.querySelector('#root')!.addEventListener('click', this.onWrapperClick)
    }
  }

  componentWillUnmount(): void {
    if (typeof document !== 'undefined') {
      document.querySelector('#root')!.removeEventListener('click', this.onWrapperClick)
    }
  }

  onPageChange = (p: number) => {
    this.setState({ currentPage: p })
    if (typeof document !== 'undefined') {
      document.documentElement.scrollTop = 0
    }
  }

  onCarBooking = () => {
    this.setState({ registerVisible: true })
  }

  onRegisterOk = () => {
    this.setState({ registerVisible: false })
  }

  onCityMoreClick = () => {
    this.setState({ showCityMore: true })
  }

  onBrandMoreClick = () => {
    this.setState({ showBrandMore: true })
  }

  onChangeBrand = (b: { name: string; letter: string }) => {
    this.setState({ brandFilter: b.name, currentPage: 1 })
  }

  // 页面外部点击，关闭一些弹框
  onWrapperClick = (evt: any) => {
    if (this.state.showBrandMore) {
      let parent = evt.target
      let infilter = false
      while (parent) {
        if (parent.id && parent.id === 'all-brand-filter') {
          infilter = true
          break
        }
        parent = parent.parentNode
      }
      if (!infilter) {
        this.setState({ showBrandMore: false })
      }
    }
    if (this.state.showCityMore) {
      let parent = evt.target
      let infilter = false
      while (parent) {
        if (parent.id && parent.id === 'all-city-filter') {
          infilter = true
          break
        }
        parent = parent.parentNode
      }
      if (!infilter) {
        this.setState({ showCityMore: false })
      }
    }
  }
}

export default Controller

import cdn from 'utils/cdn'
import httpx from '../../utils/httpX'

// 租客查看车辆详情
interface IFetchCarDetailData {
  carNo: string
  returnCarFlag: string
  getCarFlag: string
  cityCode: string
  getCarLat?: string
  getCarLon?: string
  returnCarLat?: string
  returnCarLon?: string
  startTime?: string
  endTime?: string
}

export const fetchDetail = async (data: IFetchCarDetailData) => {
  const res: any = await httpx.request({
    method: 'get',
    url: '/appserver/h5/v591/longRentCar/detail/renter',
    params: {
      ...data,
      sceneCode: 'U019',
      schema: 'B',
      OS: 'h5',
    },
  })

  const result: any = {
    carNo: data.carNo,
    name: res.carBaseInfo?.brand + ' ' + res.carBaseInfo?.type + ' ' + res.carBaseInfo?.sweptVolum,
    gbType: res.carBaseInfo?.gearBoxType,
    price: res.preferentialLongrentDiscountMonthPrice || res.longrentDiscountMonthPrice,
    coverList: [],
    carRestriction: res.carRestrictionList,
    carProperty: res.basePropertyList,
    carExtendProperty: res.extendPropertyList,
    priceList: [],
    calendar: {},
    goodCar: [],
    samePriceCar: [],
  }

  if (res.images) {
    result.coverList = res.images.map((p: any) => {
      return cdn + p.picPath
    })
  }

  if (res.renterAmtTrendInfo) {
    const max = res.renterAmtTrendInfo[0] ? res.renterAmtTrendInfo[0].renterAmtTrendPrice : 0
    result.priceList = res.renterAmtTrendInfo.map((d: any) => {
      return {
        day: d.renterAmtTrendPeriodText,
        price: d.renterAmtTrendPrice,
        width: max !== 0 ? (d.renterAmtTrendPrice / max) * 100 : 100,
      }
    })
  }

  if (res.carTimeSelector?.haveSrvPriceCalendar) {
    res.carTimeSelector?.haveSrvPriceCalendar.forEach((d: any) => {
      const month = d.day.substr(0, 6)
      if (!result.calendar[month]) {
        result.calendar[month] = []
      }
      result.calendar[month].push({
        day: parseInt(d.day.substr(-2), 10).toString(),
        flag: d.flag,
        isHoliday: d.isHoliday,
        price: d.price,
      })
    })
  }

  // 优车推荐 & 同价位车型推荐
  const listRes: any = await httpx.request({
    method: 'post',
    url: '/appserver/h5/v60/longRentCar/search/car/list',
    data: { cityCode: data.cityCode, sceneCode: 'U004', pageNum: 1, pageSize: 100, searchType: 1 },
  })

  if (listRes.carList) {
    const list = (listRes.carList || [])
      .filter((d: any) => d.carNo !== data.carNo)
      .sort((a: any, b: any) => {
        const pa = parseInt(
          a.preferentialLongrentDiscountMonthPrice || a.longrentDiscountMonthPrice,
          10,
        )
        const pb = parseInt(
          b.preferentialLongrentDiscountMonthPrice || b.longrentDiscountMonthPrice,
          10,
        )
        return pa > pb ? 1 : -1
      })

    // 优车，取最便宜4个
    result.goodCar = [...list]
    if (result.goodCar.length > 4) {
      result.goodCar.length = 4
    }
    result.goodCar = result.goodCar.map((d: any) => {
      return {
        title: `${d.brandInfo || ''} ${d.sweptVolum || ''}`,
        plateNum: d.plateNum,
        carScore: d.carScore,
        coverPic: cdn + d.coverPic,
        carNo: d.carNo,
        gbType: d.gbType === '1' ? '手' : '自',
        price: d.preferentialLongrentDiscountMonthPrice || d.longrentDiscountMonthPrice,
      }
    })

    // 同价位，取比本车贵的最便宜4个
    const list2 = list.filter((d: any) => {
      const price = d.preferentialLongrentDiscountMonthPrice || d.longrentDiscountMonthPrice
      return parseInt(price, 10) > parseInt(result.price, 10)
    })
    result.samePriceCar = [...list2]
    if (result.samePriceCar.length > 4) {
      result.samePriceCar.length = 4
    }
  }

  return result
}

import http from '../../utils/http'
import httpx from '../../utils/httpX'

async function getBanners() {
  try {
    const result = await http.request({
      method: 'get',
      url: '/banner',
    })

    return result?.list ? result.list : null
  } catch (error) {
    return null
  }
}

async function getHotCars(cityCode = '310100') {
  try {
    const result = await httpx.request({
      method: 'GET',
      url: '/AutoConsoleApiV2/mStation/car/typeRank/month',
      params: {
        pageNo: 1,
        pageSize: 10,
        cityCode,
      },
    })

    return result?.list ? result.list : null
  } catch (error) {
    return null
  }
}

// 租车资讯-列表
const fetchHotNewsList = async () => {
  const res = await httpx.request({
    method: 'GET',
    url: '/apigateway/pcWebService/public/rentCarInformation/findPage',
    params: {
      pageSize: 4,
      pageNum: 1,
      cityCode: '310100',
      role: 'default',
    },
  })

  return res
}

export { getBanners, getHotCars, fetchHotNewsList }

import httpX from '../../utils/httpX'

// 城市列表
const getOpenCityList = async () => {
  try {
    const result: any = await httpX.request({
      method: 'get',
      url: '/appserver/h5/v41/city/simple/openList',
    })

    if (result?.cityList) {
      return result.cityList
    }
    return []
  } catch (error) {
    console.error('getOpenCityList', error)
    return []
  }
}

// 车年份数据
const getCarYearList = () => {
  const result: string[] = []
  let year = new Date().getFullYear()

  for (let index = 0; index < 20; index++) {
    result.push(String(year))
    year--
  }
  return result
}

// 车辆归属数据
const getCarPlaceList = () => {
  return ['沪（非沪C）', '粤A', '粤B', '京', '苏A', '浙A', '其他']
}

// 品牌数据
const getCarBrandList = async () => {
  try {
    const result: any = await httpX.request({
      method: 'get',
      url: '/appserver/h5/web/carParam/listBrandMap/0',
    })
    if (result?.brandSortMap) {
      const map = result.brandSortMap
      const list: any = []
      Object.keys(map).forEach(k => {
        map[k].forEach((i: any) => {
          list.push({ id: i.id, name: i.txt, sort: k })
        })
      })
      return list
    }
  } catch (error) {
    return null
  }
}

// 车型数据
const getCarTypeList = async (brandId: string) => {
  const result: any = await httpX.request({
    method: 'GET',
    url: `/appserver/h5/web/carParam/listTypeByBrand/${brandId}`,
  })

  if (result?.typeList) {
    return result.typeList
  }
  return []
}

// 型号数据
const getCarModelList = async (typeId: string) => {
  const result: any = await httpX.request({
    method: 'GET',
    url: `/appserver/h5/web/carParam/queryNumByType/${typeId}`,
  })
  if (result) {
    return result
  }
  return []
}

// 公里数数据
const getKmList = () => {
  return ['0-10万', '10万-20万', '20万-30万', '30万-40万', '40万-50万', '50万-60万', '60万公里以上']
}

// 收益计算
const calcCarIncome = async (modelId: string, year: string) => {
  const result: any = await httpX.request({
    method: 'POST',
    url: '/appserver/h5/v30/income',
    data: {
      cityCode: '310100',
      modelId: modelId,
      year: year,
    },
  })
  if (result) {
    return `${result.dayPrice} ~ ${result.maxDayPrice}`
  }
  return '0'
}

// 提交我有闲置车牌
const submitSecurityCard = async (data: any) => {
  const result = await httpX.request({
    method: 'post',
    url: '/autowebgateway/h5/theSecurityCard/add',
    data: {
      source: 2,
      ...data,
    },
  })
  return result
}

// 提交成为车主
const submitCarOwner = async (data: any) => {
  const result: any = await httpX.request({
    method: 'POST',
    url: '/autowebgateway/h5/carOwner/saveCarOwnerInfo',
    data,
  })
  return result
}

// 发送短信验证码
const sendFastRegSmsCodeToMobile = async (data: any) => {
  const result = await httpX.request({
    method: 'post',
    url: '/autowebgateway/h5/fastReg/sendSmsWithPic',
    data: {
      mobile: data.mobile,
      verifyCode: data.verifyCode,
    },
  })
  return result
}

// 发送短信验证码(成为车主保牌计划)
const sendCarOwnerSmsCodeToMobile = async (data: any) => {
  const result = await httpX.request({
    method: 'post',
    url: '/autowebgateway/h5/carOwner/sendSmsWithPic',
    data: {
      mobile: data.mobile,
      verifyCode: data.verifyCode,
    },
  })
  return result
}

// 租车资讯-列表
interface INewsListParams {
  cityCode: string
  pageNum: number
  pageSize: number
  type?: 'hot' | 'new'
  tagId?: number
}

const fetchNewsList = async (params: INewsListParams) => {
  const res: any = await httpX.request({
    method: 'GET',
    url: '/apigateway/pcWebService/public/rentCarInformation/findPage',
    params: {
      ...params,
      role: 'owner',
    },
  })
  return res
}

export {
  getOpenCityList,
  getCarYearList,
  getCarPlaceList,
  getCarBrandList,
  getCarTypeList,
  getCarModelList,
  getKmList,
  calcCarIncome,
  submitSecurityCard,
  sendFastRegSmsCodeToMobile,
  sendCarOwnerSmsCodeToMobile,
  submitCarOwner,
  fetchNewsList,
}

import * as React from 'react'
import './style'

interface IProps {
  list: any[]
}

const PriceList: React.FC<IProps> = props => {
  const letters = React.useMemo(() => {
    let list = props.list.map(d => d.brandLetter)
    const res: string[] = []
    list = list.sort()
    list.forEach(k => {
      if (res.indexOf(k) === -1) {
        res.push(k)
      }
    })
    return res
  }, [props.list])

  const brandMap = React.useMemo(() => {
    const map: Record<string, any[]> = {}
    props.list.forEach(d => {
      if (!map[d.brandLetter]) {
        map[d.brandLetter] = []
      }
      map[d.brandLetter].push(d)
    })
    return map
  }, [props.list])

  const renderGroup = (key: string) => {
    return (
      <div className="group" key={key} id={`brand-group-${key}`}>
        <h2>{key.toUpperCase()}</h2>
        <div className="list">
          {brandMap[key].map(d => {
            return (
              <div className="item" key={d.brand + d.carModel}>
                <a href={`/brand/${d.brand}_${d.carModel}`}>{d.brandName + ' ' + d.carModelName}</a>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return <div className="com-brand-lib-list">{letters.map(l => renderGroup(l))}</div>
}

export default PriceList

import cn from 'classnames'
import CarCard from 'components/carCard'
import CarQRPanel from 'components/carQRPanel'
import CarRentCalendar from 'components/carRentCalendar'
import RentNavPanel from 'components/rentNavPanel'
import * as React from 'react'
import AtBreadcrumb from 'ui/breadcrumb'
import Controller from './controller'
import './style'

export default class View extends Controller {
  renderBreadcrumb = () => {
    return (
      <AtBreadcrumb
        className="breadcrumb"
        data={[
          { text: this.state.city.name + '长租', link: '/changzu' },
          { text: this.state.name },
        ]}
      />
    )
  }

  renderCover = () => {
    return (
      <div className="cover">
        <button
          className={cn('prev', { disabled: this.state.coverIndex === 0 })}
          onClick={() => this.onCoverClick(-1)}
        />
        <button
          className={cn('next', {
            disabled: this.state.coverIndex === this.state.coverList.length - 1,
          })}
          onClick={() => this.onCoverClick(1)}
        />

        <div className="swiper-images">
          {this.state.coverList.map((p, i) => {
            return (
              <img
                style={{ display: i === this.state.coverIndex ? 'block' : 'none' }}
                key={i}
                src={p}
              />
            )
          })}
        </div>
      </div>
    )
  }

  renderNav = () => {
    return (
      <div className={cn('bd-nav', { sticky: this.state.navSticky })}>
        <div className="list">
          {this.navs.map(d => {
            return (
              <button
                key={d.id}
                onClick={() => this.onNavClick(d.id)}
                className={cn({ active: d.id === this.state.activeNav })}
              >
                {d.title}
              </button>
            )
          })}
        </div>
      </div>
    )
  }

  renderRentAmount = () => {
    return (
      <div className="rent-amount" id="rent-amount">
        <h2 className="sub-title">租金趋势</h2>
        <div className="list">
          {this.state.priceList.map(p => {
            return (
              <div className="item" key={p.day} style={{ width: `${p.width}%` }}>
                <p className="day">{p.day}</p>
                <p className="amount">
                  <strong>{p.price}</strong>元/月均
                </p>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  renderCalendar = () => {
    return (
      <div className="calendar" id="calendar">
        <h2 className="sub-title">可租用时间</h2>
        <CarRentCalendar data={this.state.calendar} />
      </div>
    )
  }

  renderCarProperty = () => {
    return (
      <div className="car-property" id="car-property">
        <h2 className="sub-title">车辆配置</h2>

        <ul className="base">
          {this.state.carProperty.map(p => {
            return (
              <li key={p.title}>
                <p>{p.title}</p>
                <span>{p.content}</span>
              </li>
            )
          })}
        </ul>

        <ul className="others">
          {this.state.carExtendProperty.map(p => {
            return (
              <li key={p.content}>
                <img src={p.imageUrl} />
                <p>{p.content}</p>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  renderRestriction = () => {
    return (
      <div className="car-restriction" id="car-restriction">
        <h2 className="sub-title">用车限制</h2>

        <ul className="base">
          {(this.state.carRestriction || []).map(d => {
            return (
              <li key={d.name}>
                <p>{d.name}</p>
                <span>{d.condition}</span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  renderGoodCar = () => {
    return (
      <div className="good-car" id="good-car">
        <h2 className="sub-title">优车推荐</h2>

        <div className="list">
          {this.state.goodCar.map(c => {
            return (
              <a className="item" key={c.carNo} href={`/changzu/${c.carNo}`}>
                <img src={c.coverPic} />
                <div className="title">
                  <p>{c.title}</p>
                  <span>{c.gbType}</span>
                </div>
                <div className="info">
                  <span>{c.plateNum}</span>
                  <span className="score">{c.carScore}分</span>
                </div>
                <div className="price">
                  <strong>{c.price}</strong>元/月
                </div>
              </a>
            )
          })}
        </div>
      </div>
    )
  }

  renderPlatform = () => {
    return (
      <div className="car-platform" id="car-platform">
        <h2 className="sub-title">平台优势</h2>

        <p className="desc">
          凹凸出行，万款车型共享平台。APP一键下单、车管家送车上门、高额保障用车无忧。自2014年上线，凹凸出行已累计：
        </p>

        <div className="list">
          <div className="item">
            <i className="icon1" />
            <p>10000+</p>
            <span>精选共享车型</span>
          </div>

          <div className="item">
            <i className="icon2" />
            <p>300000+</p>
            <span>精选共享车型</span>
          </div>

          <div className="item">
            <i className="icon3" />
            <p>10000000+</p>
            <span>精选共享车型</span>
          </div>

          <div className="item">
            <i className="icon4" />
            <p>200+</p>
            <span>精选共享车型</span>
          </div>
        </div>
      </div>
    )
  }

  renderRecommendCar = () => {
    return (
      <div className="recommend-car-list">
        <h2 className="sub-title">同价位车型推荐</h2>
        <div className="list clearfix">
          {this.state.samePriceCar.map(d => {
            return <CarCard key={d.carNo} isLongRent={true} className="item" data={d} />
          })}
        </div>
      </div>
    )
  }

  renderAside = () => {
    return (
      <div className="aside">
        <RentNavPanel simple={true} />
        <CarQRPanel
          price={this.state.price}
          priceUnit="月均"
          title={this.state.name}
          gbType={this.state.gbType}
          onCalendarClick={() => this.onNavClick('calendar')}
          qrImage={this.getQrCodeUrl()}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="page-long-rent-car-detail main">
        {this.renderBreadcrumb()}

        <div className="inner clearfix">
          <div className="bd">
            {this.renderCover()}
            {this.renderNav()}
            {this.renderRentAmount()}
            {this.renderCalendar()}
            {this.renderRestriction()}
            {this.renderCarProperty()}
            {this.renderGoodCar()}
            {this.renderPlatform()}
          </div>

          {this.renderAside()}
        </div>

        {this.renderRecommendCar()}
      </div>
    )
  }
}

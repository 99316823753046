import * as React from 'react'
import { Helmet } from 'react-helmet'
import CarCard from '../../components/carCard'
import NewsPanel from '../../components/newsPanel'
import AtBreadcrumb from '../../ui/breadcrumb'
import AtPagination from '../../ui/pagination'
import cdn from '../../utils/cdn'
import Controller from './controller'
import './style'

export default class View extends Controller {
  renderHead = () => {
    const { subjectDetail, city = {} } = this.props
    const title = subjectDetail.title || ''
    return (
      <Helmet>
        <title>{`${title}攻略,${title}特惠及报价信息_凹凸租车`}</title>
        <meta name="keywords" content={title} />
        <meta
          name="description"
          content={`凹凸租车-${title}专题，为您提供${title}、最新${title}多少钱、${title}自驾游报价等信息，还包括${title}热门租车车型、便宜租车车型推荐；为你提供随时随地共享租车体验。`}
        />
        <meta
          name="mobile-agent"
          content={`format=html5;url=https://m.atzuche.com/m/index/subject/${city.code}-${subjectDetail.id}`}
        />
      </Helmet>
    )
  }

  renderBreadcrumb = () => {
    const { city = {}, subjectDetail } = this.props

    return (
      <AtBreadcrumb
        className="breadcrumb"
        data={[
          { text: `${city.name}租车`, link: '/' },
          { text: '租车专题', link: `/subject/${city.code}` },
          { text: subjectDetail.title },
        ]}
      />
    )
  }

  renderMain() {
    const { subjectDetail, businessCenterList, city } = this.props
    const carList = this.state.carList
    const prev = subjectDetail.topInformation
    const next = subjectDetail.downInformation

    return (
      <div className="main">
        <div className="header">
          <div className="headerimg">
            <img src={cdn + subjectDetail.picUrl} />
          </div>
          <h1>{subjectDetail.title}</h1>
          <p>{subjectDetail.introduction}</p>
        </div>

        {businessCenterList.length > 0 && (
          <div className="tabs clearfix" id="tabs">
            {businessCenterList.map((bc, i) => {
              return (
                <div key={i} className="item">
                  <button
                    onMouseOver={this.onTabMouseOver}
                    className={bc.value === this.state.tabValue ? 'active' : ''}
                    onClick={() => this.onTabClick(bc.value, '')}
                  >
                    {bc.label}
                  </button>

                  {bc.extra && (
                    <div className="extra">
                      <i className="arrow" />
                      {bc.extra.map((k, i) => {
                        return (
                          <button
                            key={i}
                            className={
                              bc.value === this.state.tabValue && k.value === this.state.subTabValue
                                ? 'active'
                                : ''
                            }
                            onClick={() => this.onTabClick(bc.value, k.value)}
                          >
                            {k.label}
                          </button>
                        )
                      })}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}

        <div className="car-list-wrapper clearfix">
          {carList.list.map((d: any, i: any) => {
            return <CarCard className="card" data={d} key={i} />
          })}
        </div>

        {carList.total === 0 && <div className="empty">暂无数据</div>}

        {carList.total > 0 && (
          <AtPagination
            className="pagination"
            total={carList.total}
            size={carList.pageSize}
            current={carList.pageNum}
            onChange={this.onPageChange}
          />
        )}

        <div className="pager">
          {prev && (
            <a href={`/subject/${city.code}-${prev.id}`} className="prev">
              上篇<span>{prev.title}</span>
            </a>
          )}
          {next && (
            <a href={`/subject/${city.code}-${next.id}`} className="next">
              <span>{next.title}</span>下篇
            </a>
          )}
        </div>
      </div>
    )
  }

  renderAside() {
    const { newNews, subjectList = {}, city } = this.props
    const { list = [] } = subjectList as any

    return (
      <div className="aside">
        <NewsPanel type="new" news={newNews.list} code={city.code} />
        <div className="subject-panel">
          <h2>相关专题</h2>
          <div className="list">
            {list.map((item: any) => {
              return (
                <a key={item.id} className="item" href={`/subject/${city.code}-${item.id}`}>
                  <img src={cdn + item.picUrl} />
                  <h3>{item.title}</h3>
                </a>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="page-subject-rent-detail">
        {this.renderHead()}
        {this.renderBreadcrumb()}
        <div className="body">
          {this.renderMain()}
          {this.renderAside()}
        </div>
      </div>
    )
  }
}

import * as React from 'react'
import './style.scss'

const Comment: React.FC<any> = props => {
  const { carInfo = {}, brandId } = props.state
  const { comments = [] } = carInfo
  const [showMore, setShowMore] = React.useState(comments.length > 6)

  return (
    <div className="com-hot-car-comment" id="car-comment">
      <h3>看看大家怎么说</h3>

      <a href={`/duanzu/0_${brandId}`} className="rent-car">
        👉🏻 评价不错，试驾一下
      </a>

      <div className="list">
        {comments.map((c: any, i: number) => {
          if (showMore && i > 5) {
            return null
          }

          const stars = [0, 0, 0, 0, 0]
          for (let j = 0; j < c.score; j++) {
            stars[j] = 1
          }
          return (
            <div className="item" key={i}>
              <div className="inner">
                <div className="hd">
                  <img src={c.avatar} className="avatar" />

                  <div className="info">
                    <div className="row">
                      <p className="username">{c.username}</p>
                      <p className="price">{c.price}元/天</p>
                      <time>租车时间 {c.time}</time>
                    </div>
                    <div className="row">
                      <div className="stars">
                        {stars.map((v: number, i: number) => {
                          if (v === 1) {
                            return <sub key={i} />
                          }
                          return <sup key={i} />
                        })}
                      </div>
                    </div>
                  </div>
                </div>

                {c.list.map((l: any, i: number) => {
                  return (
                    <p className="content" key={i}>
                      <strong>{l.tag}：</strong>
                      {l.content}
                    </p>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>

      {showMore && (
        <div className="more">
          <button onClick={() => setShowMore(false)}>查看更多</button>
        </div>
      )}
    </div>
  )
}

export default Comment

import * as React from 'react'
import cdn from 'utils/cdn'
import './style'

interface IProps {
  data: Record<string, any>
  onClick: () => void
}

const Articles: React.FC<IProps> = props => {
  const data = props.data || {}

  if (!data.news || !data.news.length) {
    return null
  }

  const renderItem = (d: any, index: number) => {
    return (
      <a className="item" key={index} href={`/news/310100-default-${d.newsId}`}>
        <div className="cover">
          <img src={cdn + d.cover} />
        </div>
        <p>{d.title}</p>
      </a>
    )
  }

  return (
    <div className="com-brand-lib-articles" id="content-articles">
      <header>
        <h2>大家体验后怎么样</h2>
        <a href="/news/310100-default" className="more">
          查看更多
          <span />
        </a>
      </header>

      <div className="list">{data.news.map((n: any, i: number) => renderItem(n, i))}</div>
    </div>
  )
}

export default Articles

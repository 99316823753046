import { l2citys } from 'utils/citys'
import httpx from '../../utils/httpX'

export interface INewsListParams {
  cityCode: string
  pageNum: number
  pageSize: number
  type?: 'hot' | 'new'
}

// 租车资讯-列表
export const fetchNewsList = async (params: INewsListParams) => {
  const res: any = await httpx.request({
    method: 'GET',
    url: '/apigateway/pcWebService/public/rentCarInformation/findPage',
    params: params,
  })
  return res
}

// 根据权重获取城市主题列表
export const getCityQAListByWeight = (cityCode: string) => {
  return httpx
    .get('/apigateway/pcWebService/public/pc/qa/listByCityCode/weight', {
      params: {
        cityCode,
      },
    })
    .then(data => (data as any)?.qaDetailInfoResVos)
}

// 获取所有城市
export const fetchAllCity = async () => {
  const l2map: Record<string, typeof l2citys[0]> = {}
  l2citys.forEach(c => {
    l2map[c.code] = c
  })

  const map: any = {}
  const list: { cityCode: string; cityName: string; py: string; domain: string }[] = []

  const citylist = await httpx
    .get('/apigateway/pcConsole/public/shortRent/city')
    .then(data => (data as any)?.list || [])

  citylist.forEach((d: any) => {
    if (!map[d.cityCode]) {
      const c = l2map[d.cityCode]
      list.push({ cityCode: d.cityCode, cityName: d.cityName, py: c.fullLetter, domain: c.domain })
    }
    map[d.cityCode] = d.cityName
  })
  return list
}

// 获取所有品牌
export const fetchAllBrand = (carList: any[], cityCode: string) => {
  const map: any = {}
  const list: { name: string; letter: string }[] = []
  carList.forEach(d => {
    if (d.cityCode === cityCode && !map[d.brand]) {
      list.push({ name: d.brand, letter: d.brandLetter })
    }
    map[d.brand] = d.brand
  })
  return list
}

// 获取所有车辆
export const fetchAllCars = (cityCode: string) => {
  return httpx
    .get('/apigateway/pcConsole/public/shortRent/cars?cityCode=' + cityCode)
    .then(data => (data as any)?.list || [])
}

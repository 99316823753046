import React from 'react'
import dayjs from 'dayjs'
import './style.scss'

export default class Calendar extends React.PureComponent {
  state = {
    left: 0,
  }

  formatData = data => {
    const result = {}

    data.forEach(v => {
      const day = dayjs(v.day, 'YYYYMMDD')
      const key = `${day.year()}年${day.month() + 1}月`
      if (!result[key]) {
        result[key] = []
      }
      result[key].push({ ...v, day })
    })

    return result
  }

  render() {
    const { haveSrvPriceCalendar } = this.props.data || {}

    if (!haveSrvPriceCalendar) {
      return null
    }

    const data = this.formatData(haveSrvPriceCalendar)
    return (
      <div className="calendar-com">
        <div
          className={`calendar-com-btn calendar-com-btn-left ${
            this.state.left === 0 ? 'calendar-com-btn-disabled' : ''
          }`}
          onClick={() => {
            if (this.state.left === 0) {
              return
            }
            this.setState({
              left: this.state.left + 340,
            })
          }}
        >
          ←
        </div>
        <div
          className={`calendar-com-btn calendar-com-btn-right ${
            this.state.left === -340 ? 'calendar-com-btn-disabled' : ''
          }`}
          onClick={() => {
            if (this.state.left === -340) {
              return
            }
            this.setState({
              left: this.state.left - 340,
            })
          }}
        >
          →
        </div>
        <div
          className="calendar-com-wrapper clearfix"
          style={{
            transform: `translateX(${this.state.left}px)`,
            WebkitTransform: `translateX(${this.state.left}px)`,
          }}
        >
          {data &&
            Object.keys(data).map((k, i) => {
              const firstWeek = data[k][0].day.day() || 7

              const holdTag =
                firstWeek !== 1
                  ? new Array(firstWeek - 1).fill(0).map((v, j) => <li key={j} />)
                  : null

              return (
                <div key={i} className="calendar-com-wrapper-month">
                  <h3 className="calendar-com-wrapper-month-title">{k}</h3>
                  <ul className="calendar-com-wrapper-month-items">
                    <li className="calendar-com-wrapper-month-items-day">一</li>
                    <li className="calendar-com-wrapper-month-items-day">二</li>
                    <li className="calendar-com-wrapper-month-items-day">三</li>
                    <li className="calendar-com-wrapper-month-items-day">四</li>
                    <li className="calendar-com-wrapper-month-items-day">五</li>
                    <li className="calendar-com-wrapper-month-items-day">六</li>
                    <li className="calendar-com-wrapper-month-items-day">日</li>
                    {holdTag}
                    {data[k].map((v, j) =>
                      v.flag === '0' ? (
                        <li key={j}>
                          <span
                            className={`calendar-com-wrapper-month-items-date calendar-com-wrapper-month-items-date-no ${
                              v.day.isAfter(dayjs())
                                ? 'calendar-com-wrapper-month-items-date-some'
                                : ''
                            }`}
                          >
                            {v.day.date()}
                          </span>
                        </li>
                      ) : (
                        <li key={j}>
                          <span className="calendar-com-wrapper-month-items-date">
                            {v.day.date()}
                            {v.isRestriction === '1' && <i>限</i>}
                          </span>
                          <span className="calendar-com-wrapper-month-items-price">¥{v.price}</span>
                        </li>
                      ),
                    )}
                  </ul>
                </div>
              )
            })}
        </div>
        <div className="calendar-com-footer">
          <span className="calendar-com-footer-all">全天可租</span>
          <span className="calendar-com-footer-some">部分时段可租</span>
        </div>
      </div>
    )
  }
}

import cn from 'classnames'
import React, { useState } from 'react'
import Form from '../../../companyRent/components/form'
import Header from '../../../companyRent/components/header'
import CarType from './carType'
import CarTypeData, { ERentType, tabData } from './data'
import './style.scss'

export default function CarListWiew() {
  const [rentType, setRentType] = useState(ERentType.Day)

  const onChangeRentType = (type: ERentType) => {
    setRentType(type)
  }

  return (
    <div className="com-page-car-list-view">
      <div className="tab">
        {tabData.map(item => (
          <div
            key={item.key}
            className={cn('tab-item', {
              active: item.key === rentType,
            })}
            onClick={() => onChangeRentType(item.key)}
          >
            {item.name}
          </div>
        ))}
      </div>

      <div className="list">
        {rentType === ERentType.Day ? (
          <div className="item day">
            {CarTypeData.day.map(item => (
              <CarType key={item.key} data={item} url="/duanzu" />
            ))}
          </div>
        ) : rentType === ERentType.Enterprise ? (
          <div className="item enterprise">
            <Header hideSlogan />
            <Form />
          </div>
        ) : (
          <div className="item month">
            {CarTypeData.month.map(item => (
              <CarType key={item.key} data={item} url="/changzu" />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

import React from 'react'
import './style.scss'

const Article: React.FC<any> = props => {
  const { news = [] } = props.state

  return (
    <div className="com-hot-car-article" id="car-article">
      <h3>文章</h3>

      <div className="list">
        {news.map((n: any) => {
          return (
            <div className="item" key={n.id}>
              <a href={`/news/${props.code}-${props.role || 'default'}-${n.id}`}>
                <p>{n.title}</p>
                <em />
                <span>[查看]</span>
              </a>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Article

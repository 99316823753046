import * as React from 'react'
import './style.scss'

const Links: React.FC<any> = props => {
  const { carInfo = {}, brandId } = props.state
  const { links = [] } = carInfo

  if (!links.length) {
    return null
  }

  return (
    <div className="com-hot-car-links" id="car-links">
      <h3>大家体验后怎么样</h3>

      <a href={`/duanzu/0_${brandId}`} className="rent-car">
        👉🏻 反馈不错，体验一下
      </a>

      <div className="list">
        {links.map((l: any, index: number) => {
          return (
            <a href={l.url} className="item" key={index}>
              <img src={l.thumb} />
              <p>{l.title}</p>
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default Links

import Rate from 'components/rate'
import RegisterModal from 'components/registerModal'
import RegisterSuccessModal from 'components/registerSuccessModal'
import * as React from 'react'
import Helmet from 'react-helmet'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/src/styles/scss/swiper.scss'
import cdn from 'utils/cdn'
import Articles from './components/articles'
import Comments from './components/comments'
import Competitors from './components/competitors'
import Detail from './components/detail'
import ImageGrid from './components/imageGrid'
import Menu from './components/menu'
import News from './components/news'
import Qa from './components/qa'
import Controller from './controller'
import './style'

export default class View extends Controller {
  renderAside() {
    const data = this.props.pageData || {}
    if (!data.name) {
      return null
    }
    return (
      <div className="aside">
        <div className="info">
          <div className="price-list">
            {data.dailyPrice && (
              <p className="item">
                <strong>{data.dailyPrice}</strong>
                <span>元</span>起/日租
              </p>
            )}
            {data.weeklyPrice && (
              <p className="item">
                <strong>{data.weeklyPrice}</strong>
                <span>元</span>起/周租
              </p>
            )}
            {data.monthlyPrice && (
              <p className="item">
                <strong>{data.monthlyPrice}</strong>
                <span>元</span>起/月租
              </p>
            )}
            {data.longDiscount && (
              <p className="item">
                <strong>{data.longDiscount}</strong>
                <span>折</span>/长租
              </p>
            )}
          </div>
          <button
            className="btn"
            onClick={() => {
              this.setState({ registerVisible: true })
            }}
          >
            领券预定
          </button>
        </div>
      </div>
    )
  }

  renderMain() {
    const data = this.props.pageData || {}
    if (!data.name) {
      return null
    }

    return (
      <div className="mainbody">
        <Menu
          data={this.props.pageData}
          newsList={this.props.newsList}
          qaList={this.props.qaList}
          onTabClick={this.onTabClick}
          current={this.state.currentTab}
        />

        <h1>{data.name}</h1>
        <Rate rate={data.rate} />

        <Detail data={this.props.pageData} />

        <Articles
          data={this.props.pageData}
          onClick={() => {
            this.setState({ registerVisible: true })
          }}
        />

        <Comments
          data={this.props.pageData}
          onClick={() => {
            this.setState({ registerVisible: true })
          }}
        />

        <Competitors
          data={this.props.pageData}
          onClick={() => {
            this.setState({ registerVisible: true })
          }}
        />

        <News newsList={this.props.newsList} />

        <Qa qaList={this.props.qaList} />
      </div>
    )
  }

  renderHeader() {
    const pageData = this.props.pageData || {}
    if (!pageData.name) {
      return
    }
    const { name = '', brand, carModel } = pageData

    return (
      <Helmet>
        <title>{`${name}_2023${name}报价及图片_${name}好不好-凹凸租车`}</title>
        <meta
          name="description"
          content={`凹凸租车品牌车型库提供最新${name}报价、${name}图片、${name}参数配置、${name}试驾评测、${name}用户评价等信息，了解${name}就到凹凸租车车型库！`}
        />
        <meta name="keywords" content={`${name},${name}报价`} />
        <meta
          name="mobile-agent"
          content={`format=html5;url=https://m.atzuche.com/m/index/brand/${brand}_${carModel}`}
        />
      </Helmet>
    )
  }

  render() {
    const imgList: string[] = []
    const data = this.props.pageData || {}
    if (data.coverImg) {
      imgList.push(data.coverImg)
    }
    if (data.carImg1) {
      imgList.push(...data.carImg1)
    }
    if (data.carImg2) {
      imgList.push(...data.carImg2)
    }
    if (data.carImg3) {
      imgList.push(...data.carImg3)
    }
    if (data.carImg4) {
      imgList.push(...data.carImg4)
    }

    return (
      <div className="page-brand-lib-detail">
        {this.renderHeader()}

        {!this.state.registerSuccess && (
          <ImageGrid
            data={this.props.pageData}
            onShowDetail={() => {
              this.setState({ showImageDetail: true })
            }}
          />
        )}

        {!this.state.registerSuccess && (
          <div className="main">
            {this.renderMain()}
            {this.renderAside()}
          </div>
        )}

        <RegisterModal
          visible={this.state.registerVisible}
          onClose={() => {
            this.setState({ registerVisible: false })
          }}
          onRegisterOk={() => {
            this.setState({ registerVisible: false, registerSuccess: true })
          }}
        />

        <RegisterSuccessModal
          visible={this.state.registerSuccess}
          onClose={() => {
            this.setState({ registerSuccess: false })
          }}
        />

        {this.state.showImageDetail && (
          <div className="car-image-swiper">
            <Swiper
              loop={true}
              slidesPerView={1}
              spaceBetween={0}
              containerClass="container"
              shouldSwiperUpdate={true}
              pagination={{
                el: '.swiper-pagination',
                clickable: true,
              }}
              ref={(node: any) => {
                if (node) this.bannerSwiper = node.swiper
              }}
            >
              {imgList.map((d, index) => {
                return (
                  <div className="item" key={index}>
                    <div className="img-content">
                      <img src={cdn + d} />
                    </div>
                  </div>
                )
              })}
            </Swiper>
            <button
              className="close"
              onClick={() => {
                this.setState({ showImageDetail: false })
              }}
            />
            <div className="btns">
              <button
                className="btn-prev"
                onClick={() => {
                  if (this.bannerSwiper) this.bannerSwiper.slidePrev()
                }}
              />
              <button
                className="btn-next"
                onClick={() => {
                  if (this.bannerSwiper) this.bannerSwiper.slideNext()
                }}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

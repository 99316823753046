import * as React from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/src/styles/scss/swiper.scss'
import Title from '../title'
import './style'

interface IProps {
  list: any[]
}

const CarTypes: React.FC<IProps> = props => {
  const ref = React.useRef()

  if (!props.list || !props.list.length) {
    return null
  }

  const onSwiperBtnClick = (evt: any) => {
    if (!ref.current) {
      return
    }
    if (evt.target.name === 'left') {
      ;(ref.current as any).swiper.slidePrev()
    } else {
      ;(ref.current as any).swiper.slideNext()
    }
  }

  return (
    <div className="company-rent-car-types">
      <Title title="企业长租车型" />

      <Swiper
        slidesPerView={4}
        spaceBetween={15}
        shouldSwiperUpdate={true}
        containerClass="list"
        ref={ref}
      >
        {props.list.map(i => {
          return (
            <div className={`item item-${i.type}`} key={i.promotePriceId}>
              <i className="thumb" />
              <h4>{i.carType}</h4>
              <p>
                <strong>{i.discountPrice}</strong>元起/月
              </p>
              <del>{i.marketPrice}元起/月</del>
              <div className="detail">
                <p>车型分类</p>
                <p>{i.brandCarType}</p>
              </div>
            </div>
          )
        })}
      </Swiper>

      <button className="swiper-btn swiper-btn-left" name="left" onClick={onSwiperBtnClick} />
      <button className="swiper-btn swiper-btn-right" name="right" onClick={onSwiperBtnClick} />
    </div>
  )
}

export default CarTypes

import * as React from 'react'
import './style'

interface IProps {
  qaList: any[]
}

const Qa: React.FC<IProps> = props => {
  if (props.qaList.length === 0) {
    return null
  }

  const renderQAItem = (data: any) => {
    return (
      <a className="qa-item" key={data.id} href={`/rentqa/310100-1-${data.id}`}>
        <h3>{data.question}</h3>
        <p>{data.answer}</p>
      </a>
    )
  }

  return (
    <div className="com-brand-lib-qa" id="content-qa">
      <header>
        <h2>租车常见问题</h2>
        <a href="/rentqa/310100-1" className="more">
          查看更多
          <span />
        </a>
      </header>

      <div className="list">{props.qaList.map(renderQAItem)}</div>
    </div>
  )
}

export default Qa

import React from 'react'
import './style-mobile.scss'
import Tool from '../../hoc/tool'

class Downloads extends React.Component {
  iosForword = () => {
    const iosUrl = 'https://itunes.apple.com/cn/app/id870422896'
    setTimeout(function() {
      window.location.href = iosUrl
    }, 25)
    window.location.href = 'Atzuche://www.aotuzuche.com?type=1000'
  }

  androidForword = () => {
    const androidUrl = '//carphoto.aotuzuche.com/appdownload/atzuche_h5.apk'
    setTimeout(function() {
      window.location.href = androidUrl
    }, 100)
    window.location =
      'intent://www.aotuzuche.com?type=1000#Intent;package=com.Autoyol.auto;scheme=Atzuche;launchFlags=3;end;'
  }
  render() {
    return (
      <div className="downloadMobile">
        <div className="content">
          <img
            className="content_1"
            src="http://carphoto.aotuzuche.com/web/images/app_down_4.png"
          />
          <img
            onClick={this.iosForword}
            src="http://carphoto.aotuzuche.com/web/images/app_down_2.png"
          />
          <img
            onClick={this.androidForword}
            src="http://carphoto.aotuzuche.com/web/images/app_down_3.png"
            alt=""
          />
          <img
            className="content_4"
            src="http://carphoto.aotuzuche.com/web/images/app_down_5.png"
            alt=""
          />
        </div>
      </div>
    )
  }
}

export default Tool(Downloads)

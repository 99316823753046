import React from 'react'
import local from '../../../../local'
import './style.scss'

interface IProps {
  data: any
}

export default function NewsList(props: IProps) {
  return (
    <div className="com-home-news-list">
      <div className="list">
        {props.data.map((item: any, index: number) => (
          <a className="news-item" key={index} href={`/news/310100-default-${item.id}/`}>
            <div className="info">
              <h2>{item.title}</h2>
              <p>{local.localNewsData[index].desc}</p>
            </div>
            <div className="cover-img">
              <img src={local.localNewsData[index].cover} />
            </div>
          </a>
        ))}
      </div>

      <a className="more" target="_blank" href="/news/310100-default">
        查看更多资讯
        <span />
      </a>
    </div>
  )
}

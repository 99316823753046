import React from 'react'
import { AppVersion } from 'at-business-components'
import Event from './event'

// 组件
import Split from '../../components/split'
import ViewMore from './components/view-more'
import { Helmet } from 'react-helmet'
import Tool from '../../hoc/tool'
import Registered from 'src/components/registered'
import './style.scss'

class Signup extends Event {
  constructor(props) {
    super(props)
    this.intervaler = 0
    this.state = {
      phoneNum: '',
      imgNum: '',
      imgUrl: '',
      smsNum: '',
      isShowImgInput: false,
      isShowRetry: false,
      retrySecond: 60,
    }
  }

  renderRegister() {
    return <Registered className="signup-register-main" />
  }

  renderGoodPonit() {
    return (
      <ul className="clearfix signup-goodponit">
        <li className="signup-goodponit-item">
          <i className="signup-goodponit_icon signup-goodponit_icon-two" />
          <h3>高额度保障</h3>
          <h3>保障无忧用车</h3>
          <p>全方位保障</p>
          <p>用车更安心</p>
        </li>
        <li className="signup-goodponit-item">
          <i className="signup-goodponit_icon signup-goodponit_icon-three" />
          <h3>免租车押金</h3>
          <h3>更低门槛</h3>
          <p>芝麻信用分达到650以上</p>
          <p>就可以免租车押金</p>
        </li>
        <li className="signup-goodponit-item">
          <i className="signup-goodponit_icon signup-goodponit_icon-four" />
          <h3>手机在线下单</h3>
          <h3>流程一目了然</h3>
          <p>押金支付，用车流程，违章理赔</p>
          <p>APP里全搞定</p>
        </li>
        <li className="signup-goodponit-item">
          <i className="signup-goodponit_icon signup-goodponit_icon-five" />
          <h3>车管家取送车</h3>
          <h3>上门服务</h3>
          <p>车管家带您无忧交接车</p>
        </li>
      </ul>
    )
  }

  renderCarList() {
    return (
      <ul className="clearfix signup-carlist">
        <li className="signup-carlist-item">
          <img src={require('./imgs/car1.jpg')} alt="" />
          <h4>高尔夫 R</h4>
          <span>5.0分</span>
        </li>
        <li className="signup-carlist-item">
          <img src={require('./imgs/car2.jpg')} alt="" />
          <h4>宝马 Z4</h4>
          <span>5.0分</span>
        </li>
        <li className="signup-carlist-item">
          <img src={require('./imgs/car3.jpg')} alt="" />
          <h4>特斯拉 Model S</h4>
          <span>5.0分</span>
        </li>
        <li className="signup-carlist-item">
          <img src={require('./imgs/car4.jpg')} alt="" />
          <h4>福特 野马</h4>
          <span>5.0分</span>
        </li>
        <li className="signup-carlist-item">
          <img src={require('./imgs/car5.jpg')} alt="" />
          <h4>宝马 5系</h4>
          <span>5.0分</span>
        </li>
        <li className="signup-carlist-item">
          <img src={require('./imgs/car6.jpg')} alt="" />
          <h4>奥迪 A7</h4>
          <span>5.0分</span>
        </li>
        <li className="signup-carlist-item">
          <img src={require('./imgs/car7.jpg')} alt="" />
          <h4>雪佛兰 科迈罗</h4>
          <span>5.0分</span>
        </li>
        <li className="signup-carlist-item">
          <img src={require('./imgs/car8.jpg')} alt="" />
          <h4>MINI ONE</h4>
          <span>5.0分</span>
        </li>
        <li className="signup-carlist-item">
          <img src={require('./imgs/car9.jpg')} alt="" />
          <h4>保时捷 911</h4>
          <span>5.0分</span>
        </li>
      </ul>
    )
  }

  renderRentWay() {
    return (
      <ul className="clearfix signup-rentway">
        <li className="signup-rentway-item signup-rentway-item-shortrent">
          <h3>短租</h3>
          <span>8小时起租</span>
          <p>
            适合家庭、朋友出游时使用，或临时工作代步或商务接客用车。别克GL8、丰田SUV、敞篷跑车……各车型随你选。
          </p>
        </li>
        <li className="signup-rentway-item signup-rentway-item-longrent">
          <h3>长租</h3>
          <span>一个月起租</span>
          <p>
            适合商务用车，或个人日常代步用车，费用更划算。车型配有经济型、舒适型、SUV、MPV、豪华型、尊贵型等车型，满足您的多层次用车体验。
          </p>
        </li>
        <li className="signup-rentway-item signup-rentway-item-hourrent">
          <h3>超短租</h3>
          <span>不限时间</span>
          <p>
            适合市内临时用车需求，比如上下班使用。找车方便，停车灵活，费用低廉，随取随还。现为凹凸租车旗下独立APP“凹凸共享车”。
          </p>
        </li>
      </ul>
    )
  }

  renderAppView() {
    return (
      <div className="signup-appview">
        <h2 className="signup-appview-title">凹凸租车</h2>
        <h3 className="signup-appview-des">跟着好奇心出发</h3>
        <p className="signup-appview-sub">短租、长租、时租、自助找车、快捷租车……</p>
        <p className="signup-appview-sub">万款车型，随您选择</p>
        <ViewMore className="signup-appview-button" text="下载APP" />
        <div className="signup-appview-privacy">
          <AppVersion />
        </div>
        <div className="signup-appview-app" />
      </div>
    )
  }

  render() {
    return (
      <div className="signup">
        <Helmet>
          <title>快速注册-凹凸租车</title>
        </Helmet>

        <div className="signup-banner">
          <img className="banner-img" src={require('./imgs/pc-banner-20231026.png')} />
          {this.renderRegister()}
        </div>

        <div className="main">
          {this.renderGoodPonit()}
          <Split />
          <h2 className="signup-sub signup-sub-title">支持全国150个城市 各种车型任您选择</h2>
          <h3 className="signup-sub signup-sub-des">
            上海、北京、广州、深圳、南京、杭州、苏州、成都、重庆…
          </h3>
          {this.renderCarList()}
          <p className="signup-sub signup-sub-active">
            每台车的车主有各自的定价方案，平台还有各种优惠券可供您使用，具体价格可参见APP
          </p>
          <ViewMore text="查看更多车辆" />
          <Split />
          <h2 className="signup-sub signup-sub-title">长租、短租、超短租… 各种租车方式应有尽有</h2>
          <h3 className="signup-sub signup-sub-des">长租更划算、短租更快捷、超短租更随意</h3>
          {this.renderRentWay()}
          <ViewMore text="查看更多租车方式" />
          {this.renderAppView()}
        </div>
      </div>
    )
  }
}

export default Tool(Signup)

import { matchRoutes } from 'react-router-config'
import { isLoadableComponent } from './utils'
// import SubRouters from '../containers/subRouters'

/**
 * This helps us to make sure all the async code is loaded before rendering.
 */
export async function ensureReady(routes, pathname) {
  const matchedRoutes = matchRoutes(routes, pathname || window.location.pathname)

  await Promise.all(
    matchedRoutes.map(matches => {
      const { route } = matches
      if (route.component && isLoadableComponent(route.component) && route.component.load) {
        return route.component.load()
      }
      return void 0
    }),
  )
  let data
  if (typeof window !== void 0 && !!document) {
    // deserialize state from 'serialize-javascript' format
    // eslint-disable-next-line no-eval
    data = eval('(' + document.getElementById('server-app-state').textContent + ')')
  }
  return Promise.resolve(data)
}

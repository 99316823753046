import React, { Component } from 'react'
import './style.scss'

class ViewMore extends Component {
  render() {
    const { text, className } = this.props
    const cn = className ? `view-more ${className}` : 'view-more'
    return (
      <div className={cn}>
        <div className="view-more-box">
          <span className="view-more-box-name">{text}</span>
          <div className="view-more-hover">
            <img src={require('../../../../assets/imgs/qrcode-signup.png')} alt="下载APP" />
            <p>查看更多内容</p>
            <p>
              请下载
              <span>凹凸租车</span>
              APP
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default ViewMore

import { Model } from 'dva'

export interface ICityRentPageState {
  city: Record<string, string>
}

const initialState: ICityRentPageState = {
  city: {},
}

const model: Model = {
  namespace: 'carOwner',
  state: initialState,
  reducers: {
    set(state, { type, payload }) {
      return { ...state, ...payload }
    },
  },
  effects: {},
}

export default model

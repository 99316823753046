import cn from 'classnames'
import React from 'react'
import './style.scss'

interface IProps {
  onClick?: any
  children?: any
  theme?: 'green' | 'blue' | 'gray'
  size?: 'large' | 'middle' | 'small'
  [otherProps: string]: any
}

const AtButton: React.FC<IProps> = props => {
  const { children, theme = 'green', className, size = 'large', ...otherProps } = props

  const cls = cn('at-button', `at-button--${theme}`, `at-button--${size}`, className)

  return (
    <button className={cls} {...otherProps}>
      {children}
    </button>
  )
}

export default AtButton

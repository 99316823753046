export default {
  namespace: 'city',
  state: {
    city: null,
    citys: null,
    allCitys: null,
  },
  reducers: {
    set(state, { payload }) {
      return { ...state, ...payload }
    },
    dec(state, { payload }) {
      return { ...state, ...payload }
    },
  },
  effects: {
    *asyncAdd({ payload }, { call, put }) {
      function sleep(delay = 16) {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, delay)
        })
      }

      yield call(sleep, 2000)

      yield put({
        type: 'add',
        payload,
      })
    },
  },
}

import cns from 'classnames'
import * as React from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import RentNavPanel from '../../components/rentNavPanel'
import AtPagination from '../../ui/pagination'
import { getCityByCode } from '../../utils/citys'
import { Citys, DetailQAType, ListData, QAType } from './api'
import { qaTypes } from './config'
import QACom from './qa'
import './style.scss'

interface RentQAPageProps {
  /**
   * 是否是分类和城市页面
   */
  isType?: boolean
  /**
   * 是否是详情页面
   */
  isDetail?: boolean

  /**
   * 城市列表
   */
  citys?: Citys[]

  /**
   * 最新问答列表
   */
  listData?: ListData

  /**
   * 提问（权重）最多列表
   */
  weightList?: QAType[]

  /**
   * 当前页
   */
  current?: number

  /**
   * 总页数
   */
  total?: number

  /**
   * 分页变化
   */
  onPageNumChange?: (curr: number) => void

  /**
   * 详情
   */
  detail?: DetailQAType
}

interface AllCity {
  code: string
  name: string
  fullLetter: string
}

function chunk(arr: any[]): any[] {
  const result = []
  for (let i = 0, len = arr.length; i < len; i += 2) {
    result.push(arr.slice(i, i + 2))
  }

  return result
}

function overflowStr(str: string) {
  if (str.length <= 10) {
    return str
  }

  return str.substring(0, 10) + '...'
}

/**
 * 租车问答首页，列表和详情页公用
 *
 */
const RentQAPage: React.FC<RentQAPageProps> = ({
  isType,
  isDetail,
  current = 1,
  onPageNumChange,
  citys: initiaCitys,
  listData: initialListData,
  weightList: initialWeightList,
  detail: initialDetail,
}) => {
  const className = cns('main', 'rentqa', {
    'rentqa-type': !!isType,
    'rentqa-detail': !!isDetail,
  })
  let { cityCode, type = '1' } = useParams<{ type: string; cityCode: string }>()
  const city = getCityByCode(cityCode)
  // if (type.indexOf('-') > -1) {
  //   type = type.split('-')[0]
  // }

  // const isCity = type.length === 6
  const { city: cityModel, rentqa: rentqaModel } =
    useSelector<{ city: any; rentqa: any }, { city: { allCitys: AllCity[] }; rentqa: any }>(
      ({ city, rentqa }) => ({
        city,
        rentqa,
      }),
    ) || {}
  const { allCitys = [] } = cityModel || {}
  // const cityName = isCity ? allCitys.find(({ code }) => code === type)?.name ?? '' : ''
  // const cityCode = isCity ? allCitys.find(({ code }) => code === type)?.code ?? '' : '310100'
  const cityName = city.name
  // 组装一下，利用 dva 的能力在切换的时候尽量不抖动
  let { citys, listData, weightList, detail } = rentqaModel || {}
  citys = citys || initiaCitys
  listData = listData || initialListData
  weightList = weightList || initialWeightList
  detail = detail || initialDetail

  const list = listData?.qaDetailInfoResVos
  const total = listData?.count || 1
  const typeHref = `/rentqa/${cityCode}/${type}`

  // 拿到城市数据加上 name 并且按照城市拼音进行排序，如果找不到就不显示
  const combineCitys = React.useMemo(() => {
    return (
      citys &&
      citys
        ?.map((city: any) => allCitys?.find(({ code }) => code === city.cityCode) || null)
        .filter(Boolean)
        .sort((a: any, b: any) => {
          if (!a?.fullLetter || !b?.fullLetter) {
            return 0
          }
          if (a.fullLetter < b.fullLetter) {
            return -1
          }
          if (a.fullLetter > b.fullLetter) {
            return 1
          }
          return 0
        })
    )
  }, [citys])

  const renderLeftList = () => {
    if (isDetail) {
      return <p className="rentqa-detail-left-body-content">{detail?.cur?.answer}</p>
    }

    if (!list?.length) {
      return null
    }

    if (isType) {
      return list.map((value: any, index: number) => {
        return (
          <div className="rentqa-left-body-content-border" key={index}>
            <QACom title={value.question} content={value.answer} id={value.id} code={cityCode} />
          </div>
        )
      })
    }

    return chunk(list).map((value, index) => {
      const [left, right] = value

      return (
        <div className="rentqa-left-body-content-border" key={index}>
          <QACom title={left.question} content={left.answer} id={left.id} code={cityCode} />
          {right && (
            <QACom title={right.question} content={right.answer} id={right.id} code={cityCode} />
          )}
        </div>
      )
    })
  }

  const renderHeader = () => {
    if (isDetail) {
      return (
        <Helmet>
          <meta
            name="mobile-agent"
            content={`format=html5;url=https://m.atzuche.com/m/index/rentqa/${city.code}-${detail?.cur?.id}`}
          />
        </Helmet>
      )
    }
  }

  const renderBreadcrumbs = () => {
    const isHome = !isDetail && !isType
    const typeTitle = `${cityName}${qaTypes.get(type)}租车问题`

    return (
      <div className="rentqa-breadcrumbs">
        {isHome ? '租车问答' : <a href={`/rentqa/${cityCode}-1`}>租车问答</a>}
        {' · '}
        {isDetail ? <a href={typeHref}>{typeTitle}</a> : typeTitle}
        {isDetail ? ` · ${detail?.cur?.question ?? ''}` : ''}
      </div>
    )
  }

  const leftTitle = isDetail
    ? detail?.cur?.question
    : `最新${cityName}${qaTypes.get(type)}租车问题解答`

  return (
    <div className={className}>
      {renderHeader()}
      {renderBreadcrumbs()}
      <div className="rentqa-content">
        <div className="rentqa-left">
          {isType || isDetail ? (
            <h3 className="rentqa-type-left-title">{leftTitle}</h3>
          ) : (
            <ul className="rentqa-left-tabs">
              {Array.from(qaTypes.entries()).map(([key, value]) => {
                return (
                  <li key={key}>
                    <a
                      className={cns({ 'rentqa-left-tabs-active': key === String(type) })}
                      href={`/rentqa/${cityCode}-${key}`}
                    >
                      {value}
                    </a>
                  </li>
                )
              })}
            </ul>
          )}
          <div className="rentqa-left-body">
            {!isType && !isDetail && (
              <div className="rentqa-left-title">
                <h4>24小时问答</h4>
                <a href={typeHref} className="rentqa-left-title-more">
                  查看更多
                </a>
              </div>
            )}
            <div className="rentqa-left-body-content">{renderLeftList()}</div>
            {isType && (
              <div className="rentqa-left-body-pagination">
                <AtPagination
                  size={10}
                  total={Number(total)}
                  current={current}
                  onChange={onPageNumChange}
                />
              </div>
            )}
            {isDetail && (
              <div className="rentqa-detail-left-footer">
                {detail?.pre ? (
                  <a
                    href={`/rentqa/${cityCode}-${type}-${detail?.pre.id}`}
                    className="rentqa-detail-left-footer-left"
                  >
                    <span>上篇</span>
                    <h4>{overflowStr(detail?.pre.question)}</h4>
                  </a>
                ) : (
                  <span />
                )}
                {detail?.post ? (
                  <a
                    href={`/rentqa/${cityCode}-${type}-${detail?.post.id}`}
                    className="rentqa-detail-left-footer-right"
                  >
                    <h4>{overflowStr(detail?.post.question)}</h4>
                    <span>下篇</span>
                  </a>
                ) : (
                  <span />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="rentqa-right">
          <RentNavPanel />
          <div className="rentqa-right-title">
            <h3>
              {cityName}租车
              {isDetail ? '相关问题' : '热门问题'}
            </h3>
            {isDetail && (
              <a href={typeHref} className="rentqa-left-title-more">
                查看更多
              </a>
            )}
          </div>
          <div className="rentqa-right-content">
            {weightList?.map((value: any) => (
              <div className="rentqa-right-content-border" key={value.id}>
                <QACom
                  title={value.question}
                  content={value.answer}
                  id={value.id}
                  code={cityCode}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {!isType && !isDetail && (
        <div className="rentqa-citys">
          <h3 className="rentqa-citys-title">热门城市问答指引</h3>
          {combineCitys && (
            <ul className="rentqa-citys-list">
              {combineCitys?.map((city: any) => {
                if (!city) return null
                const { code, name } = city
                return (
                  <li key={code}>
                    <a href={`/rentqa/${code}-1`}>{name}</a>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  )
}

export default RentQAPage

import * as React from 'react'
import AsidePanelWrapper from '../../../../components/asidePanelWrapper'
import local from '../../../../local'
import './style'

interface IProps {
  city: any
  list: any[]
}

const AsideNews: React.FC<IProps> = props => {
  return (
    <AsidePanelWrapper
      title={`${props.city.code === '000000' ? '' : props.city.name}最新租车资讯`}
      more="更多"
      moreHref={`/news/${props.city.code === '000000' ? '310100' : props.city.code}-default`}
    >
      <div className="com-price-aside-news">
        <div className="list">
          {props.list.map((item: any, index: number) => (
            <a
              className="news-item"
              key={index}
              href={`/news/${props.city.code === '000000' ? '310100' : props.city.code}-default-${
                item.id
              }/`}
            >
              <div className="cover-img">
                <img src={local.localNewsData[index].cover} />
              </div>
              <div className="info">
                <h2>{item.title}</h2>
                <p>{local.localNewsData[index].desc}</p>
              </div>
            </a>
          ))}
        </div>
      </div>
    </AsidePanelWrapper>
  )
}

export default AsideNews

import cn from 'classnames'
import * as React from 'react'
import './style'

interface IProps {
  list: any[]
  currentLetter: string
  onClick: (_: string) => void
}

const TopFilter: React.FC<IProps> = props => {
  const letters = React.useMemo(() => {
    let list = props.list.map(d => d.brandLetter)
    const res: string[] = []
    list = list.sort()
    list.forEach(k => {
      if (res.indexOf(k) === -1) {
        res.push(k)
      }
    })
    return res
  }, [props.list])

  return (
    <div className="com-brand-lib-filter">
      <div className="filter">
        <header>
          <h1>品牌车型库-快捷筛选</h1>
          <div className="router">
            <a href="/">首页</a>
            <span>·</span>
            <a href="/brand">品牌车型库</a>
          </div>
        </header>
        <div className="letter-list">
          {letters.map(k => {
            return (
              <p
                className={cn('item', { active: props.currentLetter === k })}
                key={k}
                onClick={() => props.onClick(k)}
              >
                <span>{k}</span>
              </p>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TopFilter

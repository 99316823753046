import React from 'react'
import './style.scss'
import Tool from '../../hoc/tool'

class Downloads extends React.Component {
  toAppStore = () => {
    window.location.href = 'https://itunes.apple.com/cn/app/id870422896'
  }
  downloadAndroid = () => {
    window.location.href = '//carphoto.aotuzuche.com/appdownload/atzuche_h5.apk'
  }
  render() {
    return (
      <div className="downloadContainer">
        <div className="downloadContent">
          <h2>开启全新租车体验</h2>
          <div className="handleArea clearfix">
            <figure className="downloadBtns">
              <button onClick={this.toAppStore} className="appStore" />
              <button onClick={this.downloadAndroid} className="android" />
              <figcaption>点击下载凹凸租车</figcaption>
            </figure>
            <span className="center">OR</span>
            <figure className="qrCode">
              <img src={require('../../assets/imgs/download.png')} alt="" />
              <figcaption>扫一扫下载凹凸租车</figcaption>
            </figure>
          </div>
        </div>
      </div>
    )
  }
}

export default Tool(Downloads)

import { Model } from 'dva'

export interface ICityRentPageState {
  city: Record<string, string>
}

const initialState: ICityRentPageState = {
  city: { name: 'xxx' },
}

const model: Model = {
  namespace: 'cityNews',
  state: initialState,
  reducers: {
    set(state, { type, payload }) {
      return { ...state, ...payload }
    },
  },
  effects: {},
}

export default model

import dva from './createApp'
import cityModel from './models/city'
import shortRentModel from './models/shortRent'
import specialCarModel from './models/specialCar'
import cityRentModel from './pages/cityRent/model'
import cityNewsModel from './pages/cityNews/model'
import carOwnerModel from './pages/carOwner/model'

// 修复首次服务端渲染重复填充数据

const options = {
  initialState: typeof window === 'object' ? window.__INITIAL_STATE__ || {} : {},
  // 注册的models
  models: [cityModel, shortRentModel, specialCarModel, cityRentModel, cityNewsModel, carOwnerModel],
  // 注册的事件
  onAction: [],
  // 异常处理，所有的异常都会通过这里
  onError(e) {
    console.error('Error', e)
  },
}
const app = dva(options)

const store = app.getStore()

export default store

import React from 'react'
import './style.scss'
import Event from './event'
import { Helmet } from 'react-helmet'
import { AppVersion } from 'at-business-components'

class MobileSignup extends Event {
  render() {
    const {
      phone,
      imgCode,
      smsCode,
      isShowImgCode,
      timeDown,
      imgCodeUrl,
      isAgreementChecked,
    } = this.state
    return (
      <div className="mobile-signup">
        <Helmet>
          <title>快速注册-凹凸租车</title>
        </Helmet>
        <div className="mobile-signup-form">
          <div className="mobile-signup-input">
            <input
              type="tel"
              placeholder="输入手机号码"
              maxLength="11"
              value={phone}
              onChange={this.onChange.bind(this, 'phone')}
              onBlur={this.fixInputBlurWhiteBox}
            />
          </div>
          {isShowImgCode && (
            <div className="mobile-signup-input">
              <input
                type="text"
                placeholder="输入图形验证码"
                maxLength="6"
                value={imgCode}
                onChange={this.onChange.bind(this, 'imgCode')}
                onBlur={this.fixInputBlurWhiteBox}
              />
              <div className="mobile-signup-input-right">
                <img src={imgCodeUrl} onClick={this.setImgCode} alt="图形验证码" />
              </div>
            </div>
          )}
          <div className="mobile-signup-input">
            <input
              type="tel"
              placeholder="输入手机验证码"
              maxLength="6"
              value={smsCode}
              onChange={this.onChange.bind(this, 'smsCode')}
              onBlur={this.fixInputBlurWhiteBox}
            />
            {timeDown ? (
              <button className="mobile-signup-input-right mobile-signup-input-right-back" disabled>
                重新获取 {timeDown}s
              </button>
            ) : (
              <button
                className="mobile-signup-input-right mobile-signup-input-right-back"
                onClick={this.getSmsCode}
              >
                获取验证码
              </button>
            )}
          </div>
          <div className="agreement">
            <span
              className={`radio ${isAgreementChecked ? 'checked' : ''}`}
              onClick={this.onAgreementClick}
            />
            我同意
            <a href="https://m.atzuche.com/node/cms/tpl/memberRule">《凹凸租车会员服务协议》</a>和
            <a href="https://m.atzuche.com/node/cms/tpl/privacyPolicy">《凹凸租车隐私协议》</a>
          </div>
          <button className="mobile-signup-btn" onClick={this.submit}>
            点击领取
          </button>
        </div>

        <div className="mobile-signup-content">
          <img src={require('./imgs/h5-content.png')} />
        </div>

        <div className="mobile-signup-recive" onClick={this.scrollToTop}>
          <img src={require('./imgs/h5-bottom-banner.png')} />
        </div>

        <div className="mobile-signup-footer">
          <AppVersion isMobile={true} />
        </div>
      </div>
    )
  }
}

export default MobileSignup

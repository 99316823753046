import { Component } from 'react'
import Toast from '../../components/toast'
import Http from '../../utils/httpX'
import Cookie from 'js-cookie'

const baseurl = process.env.NODE_ENV === 'development' ? process.env.RAZZLE_ORIGIN : ''

export default class Event extends Component {
  state = {
    phone: '',
    imgCode: '',
    smsCode: '',
    isShowImgCode: false,
    timeDown: 0,
    isSubmitting: false,
    isShowFloatCoupon: false,
  }

  Animation = {
    StartPosition: 0,
    CurrentAnimationTime: 0,
    StartTime: null,
    AnimationFrame: null,
  }

  throttle = (fn, gapTime = 300) => {
    let _lastTime = null

    // 返回新的函数
    return function() {
      const _nowTime = Number(new Date())
      if (_nowTime - _lastTime > gapTime || !_lastTime) {
        fn.apply(this, arguments) // 将this和参数传给原函数
        _lastTime = _nowTime
      }
    }
  }

  pageScroll = this.throttle(e => {
    clearTimeout(this.timer || null)
    if (!this.clientHeight) {
      this.clientHeight = document.body.clientHeight
    }
    const top = e.target.scrollTop || 0
    if (Math.abs(top) >= this.clientHeight) {
      this.setState({
        isShowFloatCoupon: true,
      })
      // this.getTicket.style.display = 'block'
    } else {
      this.setState({
        isShowFloatCoupon: false,
      })
      // this.getTicket.style.display = 'none'
    }
  })

  componentWillUnmount() {
    this.clearTimer()
  }

  clearTimer = () => {
    this.timeDownIntervaler && window.clearInterval(this.timeDownIntervaler)
  }

  verifyPhone = phone => {
    return /^1[3456789]\d{9}$/.test(phone)
  }

  onChange = (type, e) => {
    this.setState({
      [type]: e.target.value,
    })
  }

  setImgCode = url => {
    const { phone } = this.state

    this.imgCodeUrl = this.imgCodeUrl || url

    if (!this.verifyPhone(phone)) {
      Toast('请输入正确的手机号')
    }

    this.imgCodeUrl = this.imgCodeUrl.replace(/mobile=[0-9]{11}/, `mobile=${phone}`)

    this.setState({
      isShowImgCode: true,
      imgCodeUrl: `${baseurl}/appserver${this.imgCodeUrl}&_v=${Math.random() * 99999}`,
    })
  }

  setTimeDown = () => {
    let time = 60
    this.timeDownIntervaler = setInterval(() => {
      this.setState({
        timeDown: time--,
      })

      if (!time) {
        this.setState({
          timeDown: 0,
        })
        window.clearInterval(this.timeDownIntervaler)
      }
    }, 1000)
  }

  getSmsCode = async () => {
    const { phone, imgCode } = this.state
    if (!this.verifyPhone(phone)) {
      return Toast('请输入正确的手机号')
    }

    try {
      await Http.request({
        method: 'post',
        url: '/appserver/h5/v31/mem/action/validate',
        data: {
          mobile: phone,
          verifyCode: imgCode || '',
          validCodeType: 'sms',
        },
      })

      Toast('短信已发送，请注意查收')

      this.setTimeDown()
    } catch (e) {
      if (e.data && e.data.isNew === '0') {
        return Toast('手机号码已经注册过')
      }
      if (e.code === '200017') {
        Toast('请输入图片验证码')
        this.setImgCode(e.data.verifyCodeUrl)
      } else if (e.code === '300008') {
        Toast(e.msg)
        this.setImgCode(e.data.verifyCodeUrl)
      } else {
        Toast(e.msg)
      }
    }
  }

  submit = async () => {
    const { phone, imgCode, smsCode } = this.state
    if (!this.verifyPhone(phone)) {
      return Toast('请输入正确的手机号')
    }

    try {
      this.setState({
        isSubmitting: true,
      })

      let data = {
        mobile: phone,
        imageValidCode: imgCode || '',
        loginType: 'validCode',
        validCode: smsCode,
        utmSource: Cookie.get('utm_source'),
        utmMedium: Cookie.get('utm_medium'),
        utmCampaign: Cookie.get('utm_campaign'),
        utmTerm: Cookie.get('utm_term'),
      }
      if (Cookie.get('utm_source')) {
        data.OS = Cookie.get('utm_source')
      }

      const res = await Http.request({
        method: 'post',
        url: '/appserver/h5/v53/member/login',
        data,
      })

      if (res.isNew === '0') {
        return Toast('手机号码已经注册过')
      }

      window.localStorage.setItem('_app_token_', res.token)

      window.location.href = '/m/index'
    } catch (error) {
      Toast(error.msg)
    } finally {
      this.setState({
        isSubmitting: false,
      })
    }
  }

  StopScrollingFrame = () => {
    window.cancelAnimationFrame(this.Animation.AnimationFrame)
  }

  ScrollingFrame = () => {
    const StopPosition = 0
    const AnimationDuration = 500
    const timestamp = Math.floor(Date.now())
    if (!this.Animation.StartTime) {
      this.Animation.StartTime = timestamp
    }

    this.Animation.CurrentAnimationTime = timestamp - this.Animation.StartTime
    if (this.page.scrollTop <= StopPosition) {
      this.StopScrollingFrame()
    } else {
      let YPos = this.easeOutCubic(
        this.Animation.CurrentAnimationTime,
        this.Animation.StartPosition,
        StopPosition,
        AnimationDuration,
      )
      if (YPos <= StopPosition) {
        YPos = StopPosition
      }
      this.page.scrollTo(0, YPos)
      this.Animation.AnimationFrame = window.requestAnimationFrame(this.ScrollingFrame)
    }
  }

  easeOutCubic = (t, b, _c, d) => {
    let c = _c - b
    // eslint-disable-next-line no-param-reassign
    t = t / d - 1
    return c * (t * t * t + 1) + b
  }

  topHandler = () => {
    if (!this.page) {
      return
    }
    this.StopScrollingFrame()
    this.Animation.StartPosition = this.page.scrollTop
    this.Animation.CurrentAnimationTime = 0
    this.Animation.StartTime = null
    this.Animation.AnimationFrame = window.requestAnimationFrame(this.ScrollingFrame)
  }

  fixInputBlurWhiteBox = e => {
    e.persist()
    setTimeout(() => {
      const ele = e.target
      if (ele) {
        ele.scrollIntoViewIfNeeded(false)
      }
    }, 300)
  }

  onChange = (type, e) => {
    this.setState({
      [type]: e.target.value,
    })
  }
}

import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { l2citys } from '../../utils/citys'
import { fetchDetail } from './service'

type IProps = RouteComponentProps

interface IState {
  coverList: string[]
  tagList: string[]
  price: string
  name: string
  gbType: string
  coverIndex: number
  goodCar: any[]
  samePriceCar: any[]
  city: { code: string; name: string; key: string }
}

export default class Controller extends React.PureComponent<IProps, IState> {
  static async getInitialProps(ctx: any) {
    try {
      const cityInfo = ctx?.match?.params?.cityInfo
      const id = ctx?.match?.params?.id || ''
      const city = l2citys.find((city: any) => city.key === cityInfo) || l2citys[0]
      const res = await fetchDetail({
        cityCode: city.code,
        id: parseInt(id.replace('id-', ''), 10),
      })

      return { ...res, city }
    } catch (err) {
      console.log(err)
    }
    return {}
  }

  constructor(props: any) {
    super(props)

    this.state = {
      price: props.price,
      name: props.name,
      gbType: props.gbType,
      coverList: props.coverList || [],
      tagList: props.tagList || [],
      goodCar: props.goodCar,
      samePriceCar: props.samePriceCar,
      coverIndex: 0,
      city: props.city,
    }
  }

  onCoverClick = (side: number) => {
    this.setState({
      coverIndex: this.state.coverIndex + side,
    })
  }

  getQrCodeUrl = () => {
    const base =
      'https://www.atzuche.com/apigateway/carInfoMgmt/public/qrCodePic/getQrCodePic?size=120&url='

    return `${base}${encodeURIComponent('https://m.atzuche.com/m/index/redirectmini')}`
  }
}

import { citys } from '$utils/citys'
import http from '../utils/httpX'

/**
 * 最新的需求写死城市
 *
 * @returns citys[]
 */
async function getCitys() {
  return citys
  // try {
  //   const result = await http.request({
  //     method: 'get',
  //     url: '/city'
  //   })

  //   return result && result.hotCityList ? result.hotCityList : null
  // } catch (error) {
  //   console.error('getCitys', error)
  //   return null
  // }
}

const getLinks = async () => {
  try {
    const result = await http.request({
      method: 'get',
      url: '/apigateway/pcConsole/public/link',
    })

    return result
  } catch (error) {
    return null
  }
}

export { getCitys, getLinks }

import './style.scss'
import React from 'react'
import cn from 'classnames'
import Event from './event'
import Tool from '../../hoc/tool'
import { CityContext } from '$utils/city-context'

class Nav extends Event {
  render() {
    // 有隐藏导航栏的需求
    if (this.search && this.search.isHiddenNav === '1') {
      return null
    }

    const widthOutNavRoutes = ['/signup', '/pc/doubleFreeDeposit']
    const { isMobile } = this.props

    if (
      widthOutNavRoutes.indexOf(this.props.location.pathname) !== -1 ||
      isMobile ||
      this.props.location.pathname.indexOf('/lossConfirmLetter') > -1
    ) {
      return null
    }

    const baseUrl = process.env.RAZZLE_ORIGIN

    return (
      <div
        className={cn('com-nav', {
          ghost: this.state.ghost,
        })}
      >
        <div className="main">
          <div className="left">
            <a href={baseUrl} className="logo">
              凹凸租车
            </a>
          </div>
          <ul className="right">
            {this.menus.map(menu => {
              const menuProps = {}
              if (menu.nofollow) {
                menuProps.ref = 'nofollow'
              }

              return (
                <li
                  key={menu.key}
                  className={cn('menu', {
                    active:
                      this.props.location.pathname === menu.pathname ||
                      (menu.pathes && menu.pathes.includes(this.props.location.pathname)),
                  })}
                >
                  <a
                    target={menu.children ? void 0 : '_blank'}
                    href={menu.children ? 'javascript:;' : menu.pathname}
                    {...menuProps}
                  >
                    {menu.name}
                    {menu.extra && (
                      <span
                        style={{ background: `url(${menu.extra.bgUrl}?v=1) no-repeat 50% / cover` }}
                        className="extra"
                      />
                    )}
                  </a>
                  {menu.children && (
                    <div className="dropmenu">
                      <div className="submenu">
                        {menu.children.map(item => {
                          const subMenuProps = {}
                          if (item.nofollow) {
                            subMenuProps.ref = 'nofollow'
                          }

                          return (
                            <a
                              className={cn({
                                active: this.props.location.pathname === item.pathname,
                              })}
                              href={item.pathname}
                              key={item.key}
                              target="_blank"
                              {...subMenuProps}
                            >
                              {item.name}
                            </a>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}

Nav.contextType = CityContext

Nav.defaultProps = {
  ghost: false,
}
export default Tool(Nav)

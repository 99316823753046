import useMergedState from 'hooks/useMergedState'
import * as React from 'react'
import { useParams } from 'react-router'
import { Ctx } from 'types'
import RentQAPage from '../page'
import './style.scss'
import {
  getCityQAListByWeight,
  getQAListByCity,
  getQAListByTopic,
  getTopicQAListByWeight,
  ListData,
  QAType,
} from '../api'

interface RentQAProps {
  listData: ListData
  weightList: QAType[]
  total: number
}

const RentQAType: React.FC<RentQAProps> & {
  getInitialProps: (req: Ctx<{ type: string }>) => void
} = ({ listData, weightList, total }) => {
  const [current, setCurrent] = React.useState(1)
  const [mergedList, setMergedList] = useMergedState(listData)
  const { type = '1' } = useParams<{ type: string }>()
  const isCity = type.length === 6

  const onPageNumChange = async (curr: number) => {
    try {
      setCurrent(curr)

      const result: any = await (isCity
        ? getQAListByCity({ cityCode: type, pageNo: curr })
        : getQAListByTopic({ topicType: type, pageNo: curr }))

      setMergedList(result)
    } catch (error) {}
  }

  return (
    <RentQAPage
      key="type"
      isType
      listData={mergedList}
      weightList={weightList}
      current={current}
      onPageNumChange={onPageNumChange}
      total={total}
    />
  )
}

RentQAType.getInitialProps = async ctx => {
  try {
    const { type = '1' } = ctx.match.params
    const isCity = type.length === 6

    const [listData, weightList] = await Promise.all([
      isCity ? getQAListByCity({ cityCode: type }) : getQAListByTopic({ topicType: type }),
      isCity ? getCityQAListByWeight(type) : getTopicQAListByWeight(type),
    ])

    return {
      listData,
      weightList,
    }
  } catch (error) {
    console.error(error)
  }
}

export default RentQAType

import * as React from 'react'
import { Helmet } from 'react-helmet'
import About from './components/about'
import Media from './components/media'
import Menu from './components/menu'
import Tpl from './components/tpl'
import Controller from './controller'
import './style'

export default class View extends Controller {
  render() {
    return (
      <div className="page-about">
        <Helmet>
          <title>关于我们-凹凸租车</title>
        </Helmet>

        <Menu list={this.menus} active={this.state.activeMenu} onClick={this.onMenuClick} />

        {this.state.activeMenu === 'knowUs' && <About />}
        {this.state.activeMenu === 'mediaReport' && <Media data={this.state.mediaList} />}
        {this.state.activeMenu !== 'knowUs' && this.state.activeMenu !== 'mediaReport' && (
          <Tpl
            onSubMenuClick={this.onTplSubMenuClick}
            title={this.state.tplTitle}
            active={this.state.activeMenu}
            activeSubMenu={this.state.activeSubMenu}
            subMenu={this.state.subMenu}
            content={this.state.tplContent}
          />
        )}
      </div>
    )
  }
}

import * as React from 'react'
import CarCard from '../carCard'
import './style'

interface IProps {
  list: any[]
}

const RecommendCar: React.FC<IProps> = props => {
  return (
    <div className="com-recommend-car">
      <h2>相似推荐</h2>
      <div className="list clearfix">
        {props.list.map(car => {
          return <CarCard key={car.carNo} className="card" data={car} />
        })}
      </div>
    </div>
  )
}

export default RecommendCar

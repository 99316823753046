import React, { useState } from 'react'
import { l2citys } from '../../../../utils/citys'
import './style.scss'

export default function HotCity() {
  const [currentCity, setCurrentCity] = useState('')

  const linkCityRent = () => {
    return [
      {
        link: '//shanghai.atzuche.com',
        name: '上海租车',
        url: 'https://cdn.atzuche.com/pc/hotcity/sh.png',
      },
      {
        link: '//nanjing.atzuche.com',
        name: '南京租车',
        url: 'https://cdn.atzuche.com/pc/hotcity/nj.png',
      },
      {
        link: '//hangzhou.atzuche.com',
        name: '杭州租车',
        url: 'https://cdn.atzuche.com/pc/hotcity/hz.png',
      },
      {
        link: '//guangzhou.atzuche.com',
        name: '广州租车',
        url: 'https://cdn.atzuche.com/pc/hotcity/gz.png',
      },
      {
        link: '//shenzhen.atzuche.com',
        name: '深圳租车',
        url: 'https://cdn.atzuche.com/pc/hotcity/sz.png',
      },
      {
        link: '//beijing.atzuche.com',
        name: '北京租车',
        url: 'https://cdn.atzuche.com/pc/hotcity/bj.png',
      },
      {
        link: '//wuhan.atzuche.com',
        name: '武汉租车',
        url: 'https://cdn.atzuche.com/pc/hotcity/wh.png',
      },
      {
        link: '//chengdu.atzuche.com',
        name: '成都租车',
        url: 'https://cdn.atzuche.com/pc/hotcity/cd.png',
      },
      {
        link: '//qingdao.atzuche.com',
        name: '青岛租车',
        url: 'https://cdn.atzuche.com/pc/hotcity/qd.png',
      },
    ]
  }

  const onL2CityFClick = (k: string) => {
    setCurrentCity(k)
  }

  const renderl2City = () => {
    const l2cityMap: any = {}
    l2citys.forEach(c => {
      if (!l2cityMap[c.key[0]]) {
        l2cityMap[c.key[0]] = []
      }
      l2cityMap[c.key[0]].push(c)
    })
    const azList = Object.keys(l2cityMap).sort()
    const current = currentCity || azList[0]

    return (
      <div className="l2-city">
        <div className="az-list">
          {azList.map(i => {
            return (
              <button
                className={current === i ? 'current' : ''}
                key={i}
                onClick={onL2CityFClick.bind(null, i)}
              >
                {i.toUpperCase()}
              </button>
            )
          })}
        </div>
        <div className="list">
          {l2cityMap[current].map((city: any) => {
            return (
              <a key={city.key} href={`${city.domain}`}>
                {city.name}
              </a>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="com-home-hot-city">
      <div className="hot">
        {linkCityRent().map((link: any, index: number) => {
          return (
            <a key={index} href={link.link} target="_blank">
              <img src={link.url} alt={link.name} />
              <h2>{link.name}</h2>
            </a>
          )
        })}
      </div>
      <div className="all">{renderl2City()}</div>
    </div>
  )
}

import React from 'react'
import Event from './event'
import { CityContext } from '$utils/city-context'
import Anchor from 'antd/lib/anchor'
import { getCarDetail } from './api'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/src/styles/scss/swiper.scss'
import CDN from '$utils/cdn'
import CarCard from '$components/carCard'
import Calendar from './components/calendar'
import Affix from 'antd/lib/affix'
import Clamp from '../../components/clamp'
import Loading from '$components/loading'
import { Helmet } from 'react-helmet'
import RentNavPanel from '../../components/rentNavPanel'

import './style.scss'

class CarDetail extends Event {
  static async getInitialProps({ req, res, match, history }) {
    const { carNo } = match.params

    const carDetail = await getCarDetail(carNo)

    return { carDetail }
  }

  clickListener = null
  calendarNode = null

  constructor(props) {
    super(props)
    this.state = {
      carNo: props.match.params.carNo,
      isShowCalendarPanel: false,
    }
  }

  scrollListener = e => {
    if (this.state.isShowCalendarPanel) {
      this.setState({
        isShowCalendarPanel: false,
      })
    }

    const recommendRefTop = this.recommendRef ? this.recommendRef.offsetTop : 0
    const scrollTop = window.pageYOffset

    if (recommendRefTop > 0 && recommendRefTop - scrollTop < 500) {
      this.setState({
        overRecommendDistance: recommendRefTop - 426 - 158,
      })
    } else {
      this.setState({
        overRecommendDistance: 0,
      })
    }
  }

  async componentDidMount() {
    this.removeListener()

    window.addEventListener('scroll', this.scrollListener, false)

    window.addEventListener(
      'click',
      (this.clickListener = e => {
        if (!this.state.isShowCalendarPanel) {
          return
        }

        if (!this.calendarNode) {
          this.calendarNode = document.querySelector('.calendar-com')
        }

        if (
          e.target &&
          this.calendarNode &&
          !e.target.classList.contains('car-slide-panel-info-time-wrapper-btn') &&
          !this.calendarNode.contains(e.target)
        ) {
          this.setState({
            isShowCalendarPanel: false,
          })
        }
      }),
      false,
    )
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match &&
      nextProps.match.params &&
      nextProps.match.params.carNo &&
      nextProps.match.params.carNo !== this.state.carNo
    ) {
      this.setState({
        carNo: nextProps.match.params.carNo,
      })
    }
  }

  removeListener = () => {
    window.removeEventListener('scroll', this.scrollListener, false)
    window.removeEventListener('click', this.clickListener, false)
  }

  componentWillUnmount() {
    this.removeListener()
  }

  renderHead = () => {
    const { carBaseInfo = {} } = this.props.carDetail || {}

    const title = `${carBaseInfo.brand}${carBaseInfo.type} ${carBaseInfo.sweptVolum}`

    return (
      <Helmet>
        <title>{title}租车价格优惠_电话_地址-凹凸租车</title>
        <meta name="keywords" content={title} />
        <meta
          name="description"
          content={`*凹凸租车提供${title}租车服务，包括最新${title}租车价格、电话、地址等信息，让你以优质的车型及实惠的价格享受随时随地的租车服务。`}
        />
      </Helmet>
    )
  }

  renderCarSlidePanel = () => {
    const { images = null, carBaseInfo = {}, dayPrice, preferentialPrice } =
      this.props.carDetail || {}

    const params = {
      shouldSwiperUpdate: true,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'fraction',
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    }

    let style = null
    if (this.state.overRecommendDistance) {
      style = {
        position: 'absolute',
        top: this.state.overRecommendDistance,
        zIndex: 1,
        // transform 会影响定位所以加上
        transform: 'translateZ(0)',
        WebkitTransform: 'translateZ(0)',
        msTransform: 'translateZ(0)',
      }
    }

    return (
      <div className="car-slide-panel">
        <div className="car-detail-banxin car-slide-panel-slide">
          {images && (
            <Swiper {...params}>
              {images.map((v, i) => (
                <img src={CDN + v.picPath} alt="" key={i} className="car-slide-panel-item" />
              ))}
            </Swiper>
          )}
        </div>

        <div className="car-slide-panel-info-wrapper">
          <RentNavPanel simple={true} />
          <Affix offsetTop={150} style={style}>
            <div className="car-slide-panel-info">
              <h2 className="car-slide-panel-info-price">
                <span className="car-slide-panel-info-price-symbol">¥</span>
                {preferentialPrice || dayPrice}
                <span className="car-slide-panel-info-price-day">/天</span>
                <span className="car-slide-panel-info-oldprice">¥{dayPrice}/天</span>
              </h2>
              <h1 className="car-slide-panel-info-title">
                {`${carBaseInfo.brand}${carBaseInfo.type} ${carBaseInfo.sweptVolum}`}
                <span
                  className={
                    carBaseInfo.gearBoxType === '自'
                      ? 'car-slide-panel-info-title-zi'
                      : 'car-slide-panel-info-title-shou'
                  }
                />
              </h1>

              <Anchor affix={false} onClick={e => e.preventDefault()} offsetTop={145}>
                <Anchor.Link
                  href="#carDetailCalendar"
                  title={
                    <div className="car-slide-panel-info-time-wrapper">
                      <div className="car-slide-panel-info-time-wrapper-btn">查看可租用时间</div>
                    </div>
                  }
                />
              </Anchor>
              <div className="car-slide-panel-info-qrcode-btn">扫码下单吧 ↓</div>
              <div className="qrcode-con clearfix">
                <div className="qrcode-con-text zfb-text fl">
                  <div className="channel-img">
                    <img
                      className="channel-img-icon"
                      src="https://auto-static-pro.oss-cn-hangzhou.aliyuncs.com/miniprogram/cardetail-zfb-icon.png"
                    />
                  </div>
                  <h5>支付宝扫码</h5>
                  <p>信用免押</p>
                  <p>先租后付</p>
                </div>
                <div className="car-slide-panel-info-qrcode-wrapper fl">
                  <img src={this.getCarDetailQrCodeUrl()} alt="qrcode" />
                </div>
                <div className="qrcode-con-text weixin-text fl">
                  <div className="channel-img">
                    <img
                      className="channel-img-icon"
                      src="https://auto-static-pro.oss-cn-hangzhou.aliyuncs.com/miniprogram/cardetail-weixin-icon.png"
                    />
                  </div>
                  <h5>微信扫码</h5>
                  <p>神秘礼包</p>
                  <p>微信专享</p>
                </div>
              </div>
            </div>
          </Affix>
        </div>
      </div>
    )
  }

  renderCarInfoHeader = () => {
    return (
      <Anchor
        ref={e => {
          if (e) {
            this.headerAnchorNode = e
          }
        }}
        className="car-detail-banxin car-info-header clearfix"
        offsetTop={85}
        getCurrentAnchor={this.getCurrentAnchor}
        targetOffset={150}
        onClick={e => e.preventDefault()}
      >
        {this.anchorLinks.map(v => (
          <Anchor.Link
            key={v.href}
            className="car-info-header-item"
            href={v.href}
            title={v.title}
          />
        ))}
      </Anchor>
    )
  }

  renderCarInfoBase = () => {
    const {
      carBaseInfo = {},
      succTransCount,
      carTagList = null,
      plateNum,
      carAgeTxt,
      limitMileage,
      rentDaysTxt,
    } = this.props.carDetail || {}

    return (
      <div className="car-detail-banxin car-detail-border-top car-detail-info-base">
        <h1 id="carDetailBase" className="car-detail-info-base-title">
          {`${carBaseInfo.brand}${carBaseInfo.type} ${carBaseInfo.sweptVolum}`}
          <span
            className={
              carBaseInfo.gearBoxType === '自'
                ? 'car-slide-panel-info-title-zi'
                : 'car-slide-panel-info-title-shou'
            }
          />
          <span className="car-detail-info-base-title-count">已租{succTransCount}次</span>
        </h1>
        {carTagList && (
          <div className="car-detail-info-base-tags">
            {carTagList.map((v, i) => (
              <img
                src={v.imageUrl}
                alt="凹凸租车"
                style={{
                  height: Number(v.height) / 2,
                  width: Number(v.width) / 2,
                }}
                key={i}
              />
            ))}
          </div>
        )}
        <div className="car-detail-info-base-infos">
          <span className="car-detail-info-base-infos-num">{plateNum}</span>
          <span className="car-detail-info-base-infos-age">{carAgeTxt}</span>
          <span className="car-detail-info-base-infos-limit">{limitMileage}</span>
          <span className="car-detail-info-base-infos-rent">{rentDaysTxt}</span>
        </div>
      </div>
    )
  }

  renderCarInfoDes = () => {
    const { getCarAddr, distance, acceptOrderRate, ownerFirstName, ownerPortrait, carDesc = '' } =
      this.props.carDetail || {}
    return (
      <div
        id="carDetailDes"
        className="car-detail-banxin car-detail-border-top car-detail-info-des"
      >
        <div className="car-detail-info-des-address clearfix">
          <p>{getCarAddr}</p>
          <span>{distance}</span>
        </div>

        <div className="car-detail-border-top car-detail-info-des-comment">
          <div className="car-detail-info-des-comment-info clearfix">
            <img
              className="car-detail-info-des-comment-info-headphoto"
              src={CDN + ownerPortrait}
              alt={ownerFirstName}
            />
            <div className="car-detail-info-des-comment-info-name">
              <h3>{ownerFirstName}</h3>
              <span>{acceptOrderRate}%接单率</span>
            </div>
          </div>
          <div className="car-detail-info-des-comment-des">
            <Clamp id="text" text={carDesc.replace(/\s/, '')} />
          </div>
        </div>
      </div>
    )
  }

  renderCarCalendar = () => {
    const { carTimeSelector } = this.props.carDetail || {}
    return (
      <div
        id="carDetailCalendar"
        className="car-detail-banxin car-detail-border-top car-detail-info-calendar"
      >
        <h2 className="car-detail-info-title">可租用时间</h2>
        <Calendar data={carTimeSelector} />
      </div>
    )
  }

  renderCarInfoConfig = () => {
    const { basePropertyList, extendPropertyList } = this.props.carDetail || {}
    return (
      <div className="car-detail-banxin car-detail-border-top car-detail-info-config">
        <h2 id="carDetailConfig" className="car-detail-info-title">
          车辆配置
        </h2>

        {basePropertyList && (
          <ul
            className="car-detail-info-config-items clearfix"
            style={{
              paddingTop: 10,
            }}
          >
            {basePropertyList.map((v, i) => (
              <li key={i} className="car-detail-info-config-items-item">
                {v.title}
                <span>{v.content}</span>
              </li>
            ))}
          </ul>
        )}

        {extendPropertyList && (
          <ul className="car-detail-info-config-propertys clearfix">
            {extendPropertyList.map((v, i) => (
              <li key={i} className="car-detail-info-config-propertys-item">
                <img src={v.imageUrl} alt={v.content} />
                <p>{v.content}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }

  renderCarInfoRules = () => {
    const { carRestrictionList, getRevertExp } = this.props.carDetail || {}

    return (
      <div
        id="carDetailRules"
        className="car-detail-banxin car-detail-border-top car-detail-info-rules"
        ref={e => {
          if (e) {
            this.recommendRef = e
          }
        }}
      >
        <h2 className="car-detail-info-title">用车规则</h2>

        {getRevertExp && (
          <div className="car-detail-info-rules-text">
            <Clamp id="rule" text={getRevertExp.replace(/\s/, '')} />
          </div>
        )}

        {carRestrictionList && (
          <ul className="car-detail-info-config-items clearfix">
            {carRestrictionList.map((v, i) => (
              <li key={i} className="car-detail-info-config-items-item">
                {v.name}
                <span>{v.condition}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }

  renderCarInfoRecommend = () => {
    const { similarCarListV2 } = this.props.carDetail || {}

    if (!similarCarListV2) {
      return null
    }

    return (
      <div id="carDetailRecommend" className="car-detail-border-top car-detail-info-recommend">
        <h2 className="car-detail-info-title">相似推荐</h2>
        <div className="recommend-list clearfix">
          {similarCarListV2.slice(0, 4).map((car, index) => (
            <CarCard
              className="card"
              data={{
                ...car,
                brandInfo: `${car.brand} ${car.type}`,
                gbType: car.gearBoxType === '自' ? '2' : '1',
              }}
              key={index}
            />
          ))}
        </div>
      </div>
    )
  }

  renderCarInfoPanel = () => {
    return (
      <>
        {this.renderCarInfoHeader()}
        {this.renderCarInfoBase()}
        {this.renderCarInfoDes()}
        {this.renderCarCalendar()}
        {this.renderCarInfoConfig()}
        {this.renderCarInfoRules()}
        {this.renderCarInfoRecommend()}
      </>
    )
  }

  render() {
    if (!this.props.carDetail) {
      return (
        <Loading
          style={{
            marginTop: 10,
          }}
        />
      )
    }

    const { carBaseInfo = {} } = this.props.carDetail || {}

    return (
      <div className="main car-detail">
        {this.renderHead()}
        <div className="car-detail-bread">
          首页 · {this.context.city.name}
          找车 · 精准找车 · {`${carBaseInfo.brand}${carBaseInfo.type} ${carBaseInfo.sweptVolum}`}
        </div>
        {this.renderCarSlidePanel()}
        {this.renderCarInfoPanel()}
      </div>
    )
  }
}

CarDetail.contextType = CityContext

export default CarDetail

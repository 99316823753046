import cn from 'classnames'
import React from 'react'
import './style.scss'

interface IProps {
  value: string
  onChange: any
  placeholder?: string
  error?: string
  className?: string
  size?: 'middle' | 'large' | 'small'
  extra?: any
  [otherProps: string]: any
}

const AtInput: React.FC<IProps> = props => {
  const [focused, setFocused] = React.useState(false)

  const {
    value,
    onChange,
    placeholder = '请输入',
    className,
    size = 'middle',
    error,
    onFucos,
    onBlur,
    extra,
    ...otherProps
  } = props

  const cls = cn('at-input', className, `at-input--${size}`, {
    'at-input--error': !!error,
    'at-input--focus': focused,
    'at-input--filled': !!value,
  })

  const _onFucos = (evt: any) => {
    setFocused(true)
    if (onFucos) {
      onFucos(evt)
    }
  }

  const _onBlur = (evt: any) => {
    setFocused(false)
    if (onBlur) {
      onBlur(evt)
    }
  }

  return (
    <div className={cls}>
      {size !== 'small' &&
        (error ? (
          <span className="at-input__error">{error}</span>
        ) : (
          <span className="at-input__placeholder">{placeholder}</span>
        ))}
      <input
        className="at-input__input"
        value={value}
        placeholder={size === 'small' ? placeholder : ''}
        onChange={onChange}
        onFocus={_onFucos}
        onBlur={_onBlur}
        {...otherProps}
      />
      {extra ? <div className="at-input__extra">{extra}</div> : null}
    </div>
  )
}

export default AtInput

import RegisterSuccessModal from 'components/registerSuccessModal'
import React from 'react'
import { Helmet } from 'react-helmet'
import AtPagination from 'ui/pagination'
import RegisterModal from '../../components/registerModal'
import Home from '../home'
import CarItem from './components/carItem'
import Filter from './components/filter'
import News from './components/news'
import Qa from './components/qa'
import Recomm from './components/recomm'
import TopCity from './components/topCity'
import Controller from './controller'
import './style.scss'

export default class View extends Controller {
  renderHead = () => {
    const cityCode = this.props.city.code
    const cityName = this.props.city.name
    const fullLetter = this.props.city.fullLetter || '310100'

    // 全国
    if (cityCode === '000000') {
      return (
        <Helmet>
          <title>全国租车_国内汽车租赁_全国租车公司服务/价格/地址-凹凸租车</title>
          <meta name="keywords" content="全国租车" />
          <meta
            name="description"
            content="【凹凸租车】是专业租车公司，提供全国汽车租赁、国内租车电话及2023最新国内租车价格信息等，自驾租车就找凹凸租车，共享租车模式，让你享受随时随地自驾租车体验。"
          />
        </Helmet>
      )
    }

    return (
      <Helmet>
        <title>{`${cityName}租车_${cityName}汽车租赁_${cityName}租车公司服务/价格/地址-凹凸租车`}</title>
        <meta name="keywords" content={`${cityName}租车`} />
        <meta
          name="description"
          content={`【凹凸租车】是专业${cityName}租车公司，提供${cityName}汽车租赁、租车电话及2023最新${cityName}租车价格信息等，${cityName}自驾租车就找凹凸租车，共享租车模式，让你享受随时随地自驾租车体验。`}
        />
        <meta
          name="mobile-agent"
          content={`format=html5;url=https://m.atzuche.com/${fullLetter}`}
        />
      </Helmet>
    )
  }

  render() {
    if (this.props.isHomePage) {
      const newsList = [...(this.props.newsList.list || [])]
      newsList.length = 6
      return (
        <Home
          // @ts-ignore
          newsList={newsList}
          allShortRentCity={this.props.allCity}
          // @ts-ignore
          subjects={this.props.homeSubjectList}
        />
      )
    }

    return (
      <div className="page-short-rent">
        {this.renderHead()}

        {!this.state.registerSuccess && this.props.city.code === '000000' && (
          <TopCity allCity={this.props.allCity} />
        )}

        {!this.state.registerSuccess && (
          <div className="content main">
            <aside className="page-aside">
              {this.props.city.code !== '000000' && (
                <Filter
                  allCity={this.props.allCity}
                  allBrand={this.props.allBrand}
                  city={this.props.city}
                  showCityMore={this.state.showCityMore}
                  showBrandMore={this.state.showBrandMore}
                  onCityMoreClick={this.onCityMoreClick}
                  onBrandMoreClick={this.onBrandMoreClick}
                  onChangeBrand={this.onChangeBrand}
                  brandFilter={this.state.brandFilter}
                />
              )}
              <News list={this.props.newsList.list} city={this.props.city} />
              <Qa list={this.props.qaList} city={this.props.city} />
              <Recomm
                list={this.props.carList}
                city={this.props.city}
                onMoreClick={this.onCarBooking}
              />
            </aside>

            <div className="mainbody">
              <div className="header">
                <h1>{this.props.city.name}租车价格及评价</h1>
                <div className="router">
                  <a href="/">首页</a>
                  <span>·</span>
                  <a href="https://www.atzuche.com/city">全国租车</a>
                  {this.props.city.code !== '000000' && <span>·</span>}
                  {this.props.city.code !== '000000' && <a>{this.props.city.name}租车</a>}
                </div>
              </div>

              <div className="banner">
                <a
                  href="https://www.atzuche.com/signup?utm_source=seocity&utm_medium=seocity&utm_campaign=seocity&utm_term=aa84ec947f0a72b161a8d27598eda21e"
                  target="_blank"
                >
                  <img src={require('./imgs/banner.png')} />
                </a>
              </div>

              <div className="carlist">
                {this.props.carList
                  .filter((b: any) => {
                    if (this.state.brandFilter === '') {
                      return true
                    }
                    return b.brand === this.state.brandFilter
                  })
                  .filter((_, i) => {
                    const start = (this.state.currentPage - 1) * 10
                    const end = this.state.currentPage * 10
                    return start <= i && end > i
                  })
                  .map(d => {
                    return <CarItem key={d.plateNum} data={d} onBooking={this.onCarBooking} />
                  })}
              </div>

              <AtPagination
                total={
                  this.props.carList.filter((b: any) => {
                    if (this.state.brandFilter === '') {
                      return true
                    }
                    return b.brand === this.state.brandFilter
                  }).length
                }
                size={10}
                current={this.state.currentPage}
                onChange={this.onPageChange}
              />
            </div>
          </div>
        )}

        <RegisterModal
          visible={this.state.registerVisible}
          onClose={() => {
            this.setState({ registerVisible: false })
          }}
          onRegisterOk={() => {
            this.setState({ registerVisible: false, registerSuccess: true })
          }}
        />

        <RegisterSuccessModal
          visible={this.state.registerSuccess}
          onClose={() => {
            this.setState({ registerSuccess: false })
          }}
        />
      </div>
    )
  }
}

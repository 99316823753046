import * as React from 'react'
import { Helmet } from 'react-helmet'
import NewsPanel from '../../components/newsPanel'
import RecommendCar from '../../components/recommendCar'
import RentNavPanel from '../../components/rentNavPanel'
import AtBreadcrumb from '../../ui/breadcrumb'
import AtPagination from '../../ui/pagination'
import Controller from './controller'
import './style'

export default class View extends Controller {
  renderHead = () => {
    const tagName = this.props.tagName

    if (tagName) {
      return (
        <Helmet>
          <title>{`${tagName}资讯集合页-凹凸租车`}</title>
          <meta name="keywords" content={`${tagName},凹凸租车`} />
          <meta
            name="description"
            content={`【凹凸租车】提供最新${tagName}资讯,上海自驾租车就找凹凸租车,P2P租车模式,让你享受随时随地租车体验.`}
          />
        </Helmet>
      )
    }

    const city = this.props.city.name
    const cityCode = this.props.city.code || '310100'

    return (
      <Helmet>
        <title>{`${city}租车政策资讯信息_凹凸租车`}</title>
        <meta name="keywords" content={`${city}租车`} />
        <meta
          name="description"
          content={`【凹凸租车】提供最新${city}租车政策、${city}租车资讯及最新${city}租车优惠信息等，${city}自驾租车就找凹凸租车，P2P租车模式，让你享受随时随地租车体验。`}
        />
        <meta
          name="mobile-agent"
          content={`format=html5;url=https://m.atzuche.com/m/index/news/${cityCode}`}
        />
      </Helmet>
    )
  }

  renderBreadcrumb = () => {
    const { city, role } = this.props

    return (
      <AtBreadcrumb
        className="breadcrumb"
        data={[
          { text: `${city.name}租车`, link: '/' },
          { text: `${city.name}租车资讯`, link: `/news/${city.code}-${role || 'default'}` },
        ]}
      />
    )
  }

  renderNews = () => {
    const { city, role } = this.props

    return (
      <div className="news-main">
        <NewsPanel
          className="main"
          title={<h1>{city.name}·租车资讯</h1>}
          news={this.state.mainNews.list}
          role={role}
          code={city.code}
          addonBottom={
            <AtPagination
              className="pagination"
              total={this.state.mainNews.total}
              size={this.state.mainNews.pageSize}
              current={this.state.mainNews.pageNum}
              onChange={this.onPageChange}
            />
          }
        />
        <div className="aside">
          <RentNavPanel />
          <NewsPanel
            code={city.code}
            className="new"
            type="new"
            role={role}
            news={this.props.newNews.list}
          />
          <NewsPanel
            code={city.code}
            className="hot"
            type="hot"
            role={role}
            news={this.props.hotNews.list}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="page-city-news">
        {this.renderHead()}
        {this.renderBreadcrumb()}
        {this.renderNews()}
        <RecommendCar list={this.props.carList} />
      </div>
    )
  }
}

import httpx from '../../utils/httpX'

export const fetchCarInfo = async (brandId: string, typeId: string) => {
  const res = await httpx.request({
    method: 'GET',
    url: '/apigateway/pcConsole/public/hotCarType',
    params: { brandId, typeId },
  })

  return res
}

export const fetchCarPrice = async (brandId: string, typeId: string, cityCode: string) => {
  try {
    const res = await httpx.request({
      method: 'POST',
      url: '/apigateway/pcWebService/public/getCarInfoByBrandId',
      data: { brandId, typeId, cityCode: '310100' },
    })

    return res
  } catch (err) {
    return {}
  }
}

// 租车资讯-列表
export const fetchNewsList = async (cityCode: string) => {
  const res: any = await httpx.request({
    method: 'GET',
    url: '/apigateway/pcWebService/public/rentCarInformation/findPage',
    params: {
      cityCode,
      pageNum: 1,
      pageSize: 6,
    },
  })
  return res
}

export const fetchQA = async (cityCode: string) => {
  const res = httpx.get('/apigateway/pcWebService/public/pc/qa/listByTopicType', {
    params: {
      pageNo: 1,
      pageSize: 10,
      topicType: 1,
      cityCode,
    },
  })

  return res
}

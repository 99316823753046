import * as React from 'react'
import { Helmet } from 'react-helmet'
import CarTypes from './components/carTypes'
import Form from './components/form'
import Header from './components/header'
import Partner from './components/partner'
import Scenes from './components/scenes'
import Solution from './components/solution'
import Controller from './controller'
import './style'

class View extends Controller {
  render() {
    return (
      <div className="page-company-rent">
        <Helmet>
          <title>企业租车_公司商务租车平台_企业用车一站式解决方案-凹凸租车</title>
          <meta
            name="description"
            content="凹凸企业租车频道，提供专业企业租车服务，专业定制企业长租服务，一站式解决企业租车用车难题；万款车型，车管家送车上门，提供专业企业年租车、月租车服务。"
          />
        </Helmet>
        <Header />
        <Form />
        <Scenes />
        <Solution />
        <CarTypes list={this.props.typeList} />
        <Partner />
      </div>
    )
  }
}

export default View

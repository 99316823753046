import * as React from 'react'
import * as ReactDOMServer from 'react-dom/server'
import { Helmet } from 'react-helmet'
import { StaticRouter } from 'react-router-dom'
import { Document as DefaultDoc } from './Document'
import { After } from './After'
import { loadInitialProps } from './loadInitialProps'
import * as utils from './utils'
import * as url from 'url'

const modPageFn = function (Page) {
  return props => <Page {...props} />
}
export async function render(options) {
  const { req, res, routes, assets, document: Document, customRenderer, ...rest } = options
  // routes[0].component = Signup
  const Doc = Document || DefaultDoc
  const context = {}
  const renderPage = async (fn = modPageFn) => {
    // By default, we keep ReactDOMServer synchronous renderToString function
    const defaultRenderer = element => ({
      html: ReactDOMServer.renderToString(element),
    })
    const renderer = customRenderer || defaultRenderer
    const asyncOrSyncRender = renderer(
      <StaticRouter location={req.url} context={context}>
        {fn(After)({ routes, data })}
      </StaticRouter>,
    )

    const renderedContent = utils.isPromise(asyncOrSyncRender)
      ? await asyncOrSyncRender
      : asyncOrSyncRender
    const helmet = Helmet.renderStatic()
    return { helmet, ...renderedContent }
  }
  const { route, match, data } = await loadInitialProps(routes, url.parse(req.url).pathname, {
    req,
    res,
    ...rest,
  })
  const redirectTo = route.redirectTo

  if (!route || !match) {
    return { redirectTo }
  }
  if (match.path === '**') {
    return { redirectTo }
  } else if (match && route.redirectTo && match.path) {
    return { redirectTo }
  }
  const { html, ...docProps } = await Doc.getInitialProps({
    req,
    res,
    assets,
    renderPage,
    data,
    match,
    helmet: Helmet.renderStatic(),
    ...rest,
  })
  const doc = ReactDOMServer.renderToStaticMarkup(<Doc {...docProps} />)
  return {
    html: `<!doctype html>${doc.replace('DO_NOT_DELETE_THIS_YOU_WILL_BREAK_YOUR_APP', html)}`,
    redirectTo,
  }
}

import cn from 'classnames'
import * as React from 'react'
import './style'

interface IProps {
  title: string
  dom?: 'h2' | 'h3' | 'h4'
  desc?: string
  className?: string
}

const Title: React.FC<IProps> = props => {
  const Dom = props.dom || 'h3'

  return (
    <div className={cn('web-title', props.className)}>
      <Dom className="web-title__text">{props.title}</Dom>
      {props.desc && <h5 className="web-title__desc">{props.desc}</h5>}
    </div>
  )
}

export default Title

import React from 'react'
import './style.scss'

interface IProps {
  data: { name: string; price: string; unit: string; imgUrl: string; carType: string; desc: string }
  url: string
}

export default function CarType(props: IProps) {
  const { name, price, unit, imgUrl, desc, carType } = props.data

  return (
    <a className="com-home-car-type" target="_blank" href={props.url}>
      <div className="title">
        <div className="name">{name}</div>
        <div className="price">
          {price}
          <sub>{unit}</sub>
        </div>
      </div>
      <div className="cover-img" style={{ background: `url(${imgUrl}) no-repeat 50% / contain` }}>
        <img src={imgUrl} alt={name} />
      </div>

      <div className="desc">
        <p>推荐车型：{carType}</p>
        <p style={{ marginTop: '5px' }}>{desc}</p>
      </div>
    </a>
  )
}

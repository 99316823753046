import * as React from 'react'
import './style'

interface IProps {
  city: any
  list: any[]
  onBookClick: () => void
}

const PriceList: React.FC<IProps> = props => {
  const renderRowItem = (data: any) => {
    return (
      <div className="row-item table">
        <div className="t1">{data.title}</div>
        <div className="t2">{data.displacement}</div>
        <div className="t3">{data.gbType === '2' ? '自动挡' : '手动挡'}</div>
        <div className="t4">{data.plateNum}</div>
        <div className="t5">{data.carAge}</div>
        <div className="t6">{data.rate}分</div>
        <div className="t7">
          <strong>￥{data.price}/天</strong>
          <button onClick={() => props.onBookClick()}>领券预定</button>
        </div>
      </div>
    )
  }

  return (
    <div className="com-price-list">
      <div className="hd table">
        <div className="t1">车型</div>
        <div className="t2">排量</div>
        <div className="t3">自动/手动</div>
        <div className="t4">牌照</div>
        <div className="t5">车龄</div>
        <div className="t6">评分</div>
        <div className="t7">报价</div>
      </div>

      <div className="bd">{props.list.map(data => renderRowItem(data))}</div>
    </div>
  )
}

export default PriceList

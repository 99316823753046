import React from 'react'
import { create } from 'dva-core'
import { Provider } from 'react-redux'

export default function (options) {
  const app = create(options)

  if (!global.registered) {
    options.models.forEach(model => {
      // // 修复首次服务端渲染重复填充数据
      if (!options.initialState[model.namespace]) {
        options.initialState[model.namespace] = null
      }

      app.model(model)
    })
  }
  global.registered = true

  app.start()
  // eslint-disable-next-line no-underscore-dangle
  const store = app._store

  app.start = container => () => <Provider store={store}>{container}</Provider>
  app.getStore = () => store

  return app
}

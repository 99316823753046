import React from 'react'
import './style.scss'
import cn from 'classnames'

export default function AutoFeature(props) {
  return (
    <ul className={cn('autoFeature', props.className)}>
      <li>
        <img src={require('../../imgs/feature_icon1.png')} />
        <strong>最高255万保障</strong>
        <span>全额保障险</span>
      </li>
      <li>
        <img src={require('../../imgs/feature_icon2.png')} />
        <strong>万款车型</strong>
        <span>私家车型选择</span>
      </li>
      <li>
        <img src={require('../../imgs/feature_icon3.png')} />
        <strong>上门送取</strong>
        <span>车管家服务</span>
      </li>
    </ul>
  )
}

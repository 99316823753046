import httpx from '../../utils/httpX'

// 长租车辆列表
export const fetchCarList = async (cityCode: string, pageNum: number) => {
  const res: any = await httpx.request({
    method: 'post',
    url: '/appserver/h5/v60/longRentCar/search/car/list',
    data: { cityCode, sceneCode: 'U004', pageNum, pageSize: 20, searchType: 1 },
  })
  return res || {}
}

import httpx from '../../utils/httpX'

// 租车资讯-列表
export const fetchNewsList = async (params: { cityCode: string }) => {
  const res: any = await httpx.request({
    method: 'GET',
    url: '/apigateway/pcWebService/public/rentCarInformation/findPage',
    params: {
      cityCode: params.cityCode,
      pageNum: 1,
      pageSize: 4,
    },
  })
  return res
}

// 获取所有车辆
export const fetchCarList = (cityCode: string, pageNum: number) => {
  return httpx
    .get('/apigateway/pcConsole/public/shortRent/cars', {
      params: { cityCode, pageNum, pageSize: 20 },
    })
    .then(data => {
      return {
        list: (data as any)?.list || [],
        pageSize: (data as any)?.pageSize || 20,
        pageNum: (data as any)?.pageNum || 1,
        count: (data as any)?.count || 20,
      }
    })
}

import React from 'react'
import Http from '../../utils/http'
import { getAllCity } from './api'
import AtAlert from '../../ui/alert'

class BusinessEvent extends React.Component {
  submiting = false

  componentDidMount() {
    this.onCitysSelectFocus()
  }

  onInputCompanyNameChange = e => {
    const ele = e?.target

    this.setState({
      inputCompanyName: ele.value,
    })
  }

  onInputContactNameChange = e => {
    const ele = e?.target

    this.setState({
      inputContactName: ele.value,
    })
  }

  onInputContactMobileChange = e => {
    const ele = e?.target
    const value = ele.value
    this.setState({
      inputContactMobile: value,
    })
  }

  onCitysSelectFocus = async e => {
    if (this.state.citys) {
      return
    }
    this.setState({
      citysLoading: true,
    })

    try {
      const citys = await getAllCity()

      this.setState({
        citys,
        citysLoading: false,
      })
    } catch (error) {
      this.setState({
        citysLoading: false,
      })
    }
  }

  onCitysSelectChange = value => {
    this.setState({
      selectCityName: value.name,
      selectCityCode: value.code,
    })
  }

  onSelectCarCountChange = value => {
    this.setState({
      selectCarCount: value.name,
    })
  }

  onSubmit = async e => {
    try {
      if (this.submiting) {
        return
      }
      const {
        inputCompanyName,
        inputContactName,
        inputContactMobile,
        selectCityName,
        selectCityCode,
        selectCarCount,
      } = this.state

      if (!inputCompanyName) {
        return AtAlert.error('公司名称不能为空')
      }
      if (!inputContactName) {
        return AtAlert.error('联系人姓名不能为空')
      }
      if (!inputContactMobile) {
        return AtAlert.error('联系电话不能为空')
      }
      const telOrPhoneMatch = /(^([0-9]{3,4}-)?[0-9]{5,10}$)|(^1[34578]\d{9}$)/
      if (!telOrPhoneMatch.test(inputContactMobile)) {
        return this.setState({
          inputMobileVerifyMessage: '请输入正确格式的手机号码',
        })
      }
      if (!selectCityName) {
        return AtAlert.error('所在城市不能为空')
      }
      if (!selectCarCount) {
        return AtAlert.error('可提供的车辆数量不能为空')
      }
      this.submiting = true
      await Http.request({
        method: 'POST',
        url: '/business/join',
        data: {
          companyName: inputCompanyName,
          contactName: inputContactName,
          contactMobile: inputContactMobile,
          cityName: selectCityName,
          cityCode: selectCityCode,
          carCount: selectCarCount,
        },
      })
      AtAlert.success('提交成功')
    } catch (error) {
      error.msg && AtAlert.error(error.msg)
    } finally {
      this.submiting = false
    }
  }
}

export default BusinessEvent

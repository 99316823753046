import httpx from '../../utils/httpX'

export interface INewsListParams {
  cityCode: string
  pageNum: number
  pageSize: number
  type?: 'hot' | 'new'
  tagId?: number
}
// 租车资讯-列表
export const fetchNewsList = async (params: INewsListParams) => {
  const res: any = await httpx.request({
    method: 'GET',
    url: '/apigateway/pcWebService/public/rentCarInformation/findPage',
    params: params,
  })
  return res
}

// 租车资讯-详情
export const fetchCarNewsDetail = async (cityCode: string, id: number, role = void 0) => {
  const res: any = await httpx.request({
    method: 'GET',
    url: '/apigateway/pcWebService/public/rentCarInformation/details',
    params: { cityCode, id, role: role === 'default' ? '' : role },
  })

  return res
}

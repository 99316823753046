import axios from 'axios'

function HttpError(result) {
  const { resMsg, errorMsg, errorCode, resCode, data } = result
  this.msg = resMsg || errorMsg
  this.message = resMsg || errorMsg
  this.name = 'HttpError'
  this.data = data
  this.code = resCode || errorCode
}

HttpError.prototype = new Error()
HttpError.prototype.constructor = HttpError

let baseURL = ''

if (process.env.NODE_ENV === 'development' && process.env.BUILD_TARGET === 'client') {
  baseURL = '/proxy/'
}

if (process.env.NODE_ENV === 'development' && process.env.BUILD_TARGET === 'server') {
  baseURL = `${process.env.RAZZLE_ORIGIN}/`
}

if (process.env.RAZZLE_PACKAGE === 'test') {
  if (process.env.BUILD_TARGET === 'server') {
    baseURL = `${process.env.RAZZLE_ORIGIN}/`
  }
}

if (process.env.RAZZLE_PACKAGE === 'production') {
  if (process.env.BUILD_TARGET === 'server') {
    baseURL = `${process.env.RAZZLE_ORIGIN}/`
  }
}

const http = axios.create({
  baseURL,
  timeout: 60000,
  headers: {
    Accept: 'application/json;version=3.0;compress=false',
    'Content-Type': 'application/json;charset=utf-8',
  },
  data: {},
})

// 拦截器
http.interceptors.request.use(config => {
  if (config.method === 'get') {
    if (typeof config.params !== 'object') {
      config.params = {}
    }

    config.params.requestId = Date.now()
  }

  if (config.data && Object.keys(config.data).length > 0) {
    config.data.requestId = Date.now()
  }

  return config
})

http.interceptors.response.use(
  config => {
    // 响应正常
    if (config.data.resCode === '0' || config.data.resCode === '000000') {
      return config.data.data
    }
    // reject错误处理
    return Promise.reject(new HttpError(config.data))
  },
  error => {
    // reject错误处理
    if (error.response && error.response.data) {
      return Promise.reject(new HttpError(error.response.data))
    }
    return Promise.reject(new HttpError('系统错误'))
  },
)

export default http

import { History } from 'history'
import * as React from 'react'
import store from '../../store'
import AtAlert from '../../ui/alert'
import { getCityByCode } from '../../utils/citys'
import { getCtxInfo } from '../../utils/plat'
import { fetchCarList } from '../cityRent/service'
import { fetchNewsList } from './service'
interface INewsList {
  pageNum: number
  pageSize: number
  total: number
  list: any[]
}

interface IProps {
  history: History
  match: any
  city: any
  role?: string
  tagId?: string
  mainNews: INewsList
  newNews: INewsList
  hotNews: INewsList
  tagName: string
  carList: any[]
}

interface IState {
  mainNews: INewsList
}

export default class Controller extends React.PureComponent<IProps, IState> {
  static async getInitialProps(ctx: any) {
    const { isMobile, baseUrl } = getCtxInfo(ctx)
    const key = ctx?.match?.params?.key
    const cityCode = ctx?.match?.params?.cityCode
    const city = getCityByCode(cityCode)
    let role = ctx?.req?.query?.role || 'default'
    let tagId = ctx?.req?.query?.tagId || ''

    if (key) {
      const sp = key.split('-')
      if (sp.length === 2) {
        role = sp[0]
        tagId = sp[1].replace('tag', '')
      }
    }

    // const city = getCityByFullLetter(cityName)
    if (isMobile) {
      // 移动终端
      ctx?.res?.redirect(baseUrl + '/news/' + city.code)
    }

    try {
      let params: any = {
        cityCode: city.code,
        pageNum: 1,
        pageSize: 20,
        role,
      }

      if (params.role === 'default') {
        delete params.role
      }

      if (tagId) {
        params.tagId = tagId
      }

      const res = await Promise.all([
        fetchNewsList(params),
        fetchNewsList({ ...params, pageSize: 5, type: 'hot' }),
        fetchNewsList({ ...params, pageSize: 5, type: 'new' }),
        fetchCarList(city.code, { seq: 7 }, 1, 4),
      ])

      const carList = res[3] ? res[3].list || [] : []

      await store.dispatch({
        type: 'cityNews/set',
        payload: { city },
      })
      let tagName = ''
      if (res[0] && res[0].list && res[0].list.length) {
        tagName = res[0].list[0].tagName
      }
      return {
        city,
        mainNews: res[0],
        hotNews: res[1],
        newNews: res[2],
        carList,
        role,
        tagId,
        tagName,
      }
    } catch (err) {
      console.log(err)
    }

    return {
      mainNews: { list: [] },
      hotNews: { list: [] },
      newNews: { list: [] },
      tagName: '',
      city,
      carList: [],
    }
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      mainNews: props.mainNews,
    }
  }

  onPageChange = async (current: number) => {
    try {
      const { city, tagId } = this.props
      let params: any = {
        cityCode: city.code,
        pageNum: current,
        pageSize: 20,
      }

      if (tagId) {
        params.tagId = tagId
      }

      const news: any = await fetchNewsList(params)
      this.setState({ mainNews: news })
    } catch (err) {
      AtAlert.error('系统错误')
    }
  }
}

import * as React from 'react'
import './style'

interface IProps {
  price: string
  priceUnit: string
  title: string
  gbType: string
  qrImage: string
  onCalendarClick?: () => void
}

const CarQRPanel: React.FC<IProps> = props => {
  return (
    <div className="com-car-qr-panel">
      <p className="amount">
        ￥<strong>{props.price}</strong>/{props.priceUnit}
      </p>

      <div className="name">
        <h5>{props.title}</h5>
        <span className="gb-type">{props.gbType}</span>
      </div>

      {props.onCalendarClick && (
        <button className="look-calendar" onClick={props.onCalendarClick}>
          查看可用租时间
        </button>
      )}

      <button className="scan-qr">扫码下单吧↓</button>

      <div className="qr">
        <div className="alipay">
          <i />
          <p>支付宝扫码</p>
          <span>信用免押</span>
          <span>先租后付</span>
        </div>

        <div className="img">
          <img src={props.qrImage} />
        </div>

        <div className="wx">
          <i />
          <p>微信宝扫码</p>
          <span>神秘礼包</span>
          <span>微信专享</span>
        </div>
      </div>
    </div>
  )
}

export default CarQRPanel

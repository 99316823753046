import cn from 'classnames'
import * as React from 'react'
import './style'

interface INewsItem {
  title: string
  id: number
  tagName?: string
}

interface IProps {
  news: Array<INewsItem>
  title?: React.ReactNode
  type?: 'new' | 'hot'
  role?: string
  code: string
  addonBottom?: React.ReactNode
  [otherProps: string]: any
}

const NewsPanel: React.FC<IProps> = props => {
  let title = props.title
  if (!title) {
    if (props.type === 'hot') {
      title = <h2 className={props.type}>热点文章</h2>
    } else {
      title = <h2 className={props.type}>24小时文章</h2>
    }
  }
  return (
    <div className={cn('com-news-panel', props.className)}>
      <header>{title}</header>
      <div className="list">
        {props.news.map(n => {
          return (
            <a
              href={`/news/${props.code}-${props.role || 'default'}-${n.id}`}
              className={cn('news-item', { 'news-item-hastag': !!n.tagName })}
              key={n.id}
            >
              <span className="p">{n.title}</span>
              {n.tagName && <span className="label">#{n.tagName}#</span>}
              <span className="dot" />
              [查看]
            </a>
          )
        })}
      </div>
      {props.addonBottom}
    </div>
  )
}

export default NewsPanel

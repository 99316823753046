import cn from 'classnames'
import * as React from 'react'
import cdn from 'utils/cdn'
import './style'

interface IProps {
  data: any
  onBooking: () => void
}

const CarItem: React.FC<IProps> = props => {
  if (!props.data) {
    return null
  }

  return (
    <div className="com-short-rent-caritem">
      <div className="thumb">
        <span>{props.data.carAge}车龄</span>
        <img src={`${cdn}${props.data.thumb}`} alt={props.data.title} />
      </div>
      <div className="data">
        <header>
          <h2>{props.data.title}</h2>
          <div className="price">
            ￥<strong>{props.data.price}</strong>/天
          </div>
        </header>

        <p className="info">
          {props.data.plateNum} | {props.data.gbType === '1' ? '手动挡' : '自动挡'} |{' '}
          {props.data.displacement}
        </p>

        <div className="rate">
          <span className={cn('star', { n: props.data.rate < 1 })} />
          <span className={cn('star', { n: props.data.rate < 2 })} />
          <span className={cn('star', { n: props.data.rate < 3 })} />
          <span className={cn('star', { n: props.data.rate < 4 })} />
          <span className={cn('star', { n: props.data.rate < 5 })} />
          <p>{props.data.rate}分</p>
        </div>

        <div className="tags">
          {props.data.tags.map((t: string) => {
            return (
              <p key={t} className="tag">
                {t}
              </p>
            )
          })}
        </div>

        <div className="msglist">
          {props.data.comments.map((d: string) => {
            return (
              <div className="msg" key={d}>
                {d}
              </div>
            )
          })}
        </div>

        <button className="booking" onClick={props.onBooking}>
          立即预定
        </button>
      </div>
    </div>
  )
}

export default CarItem

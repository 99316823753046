import * as React from 'react'
import { createPortal, render, unmountComponentAtNode } from 'react-dom'
import AtButton from '../button'
import './style.scss'

const AlertComponent: React.FC<any> = props => {
  const { desc, onClose } = props

  return (
    <div className="at-alert__inner">
      <div className="at-alert__body">
        <i />
        <div className="desc" dangerouslySetInnerHTML={{ __html: desc }} />
      </div>
      <div className="at-alert__btns">
        <AtButton size="middle" onClick={onClose}>
          知道了
        </AtButton>
      </div>
    </div>
  )
}

const AtAlert = (text: any, type: string, callback?: () => void) => {
  const div = document.createElement('div')
  div.classList.add('at-alert', 'at-alert--show', `at-alert--${type}`)
  if (typeof text !== 'string' && text.className) {
    div.classList.add(text.className)
  }
  document.body.appendChild(div)

  const close = () => {
    div.classList.remove('at-alert--show')
    div.classList.add('at-alert--hide')
    setTimeout(() => {
      if (div?.parentNode) {
        unmountComponentAtNode(div)
        div.parentNode.removeChild(div)
      }
      if (callback) {
        callback()
      }
    }, 200)
  }

  if (typeof text === 'string') {
    render(createPortal(<AlertComponent desc={text} onClose={close} />, div), div)
  } else {
    render(
      createPortal(<AlertComponent {...text} title={text.title || ''} onClose={close} />, div),
      div,
    )
  }

  const resClose = close as () => void
  return [resClose, void 0]
}

AtAlert.success = (text: any) => {
  AtAlert(text, 'success')
}

AtAlert.error = (text: any) => {
  AtAlert(text, 'error')
}

export default AtAlert

import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { getCityNameByHost, getCityByFullLetter } from 'utils/citys'
import { fetchDetail } from './service'

type IProps = RouteComponentProps

interface ICarItem {
  carNo: string
  coverPic: string
  title: string
  gbType: string
  plateNum: string
  carScore: string
  price: string
}

interface IState {
  carNo: string
  name: string
  gbType: string
  price: string
  coverList: string[]
  carRestriction: { name: string; condition: string }[]
  carProperty: { title: string; content: string }[]
  carExtendProperty: { content: string; imageUrl: string }[]
  priceList: { day: string; price: number; width: number }[]
  calendar: Record<string, { day: string; price: string; isHoliday: string; flag: string }[]>
  goodCar: ICarItem[]
  samePriceCar: any[]
  activeNav: string
  navSticky: boolean
  coverIndex: number
  city: any
}

export default class Controller extends React.PureComponent<IProps, IState> {
  static async getInitialProps(ctx: any) {
    try {
      const host = ctx.req ? ctx.req.headers['host'] : window.location.host
      const cityName = getCityNameByHost(host)
      const city = getCityByFullLetter(cityName)

      const res = await fetchDetail({
        cityCode: city.code,
        carNo: ctx?.match?.params?.carNo,
        getCarFlag: '1',
        returnCarFlag: '1',
      })
      return { ...res, city }
    } catch (err) {
      console.log(err)
    }
    return {}
  }

  state: IState = {
    carNo: '',
    name: '',
    gbType: '',
    price: '',
    coverList: [],
    carRestriction: [],
    carProperty: [],
    carExtendProperty: [],
    priceList: [],
    calendar: {},
    goodCar: [],
    samePriceCar: [],
    activeNav: '',
    navSticky: false,
    coverIndex: 0,
    city: { abbr: 'sh' },
  }

  // 菜单
  navs = [
    { id: 'rent-amount', title: '租金趋势', top: 0 },
    { id: 'calendar', title: '可租用时间', top: 0 },
    { id: 'car-restriction', title: '用车限制', top: 0 },
    { id: 'car-property', title: '车辆配置', top: 0 },
    { id: 'good-car', title: '优车推荐', top: 0 },
    { id: 'car-platform', title: '平台优势', top: 0 },
  ]

  constructor(props: any) {
    super(props)

    this.state = {
      carNo: props.carNo,
      name: props.name,
      gbType: props.gbType,
      price: props.price,
      coverList: props.coverList,
      carRestriction: props.carRestriction,
      carProperty: props.carProperty,
      carExtendProperty: props.carExtendProperty,
      priceList: props.priceList,
      calendar: props.calendar,
      goodCar: props.goodCar || [],
      samePriceCar: props.samePriceCar || [],
      activeNav: '',
      navSticky: false,
      coverIndex: 0,
      city: props.city,
    }
  }

  componentDidMount() {
    this.onBodyScroll()
    document.addEventListener('scroll', this.onBodyScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onBodyScroll)
  }

  onBodyScroll = () => {
    const top = window.pageYOffset
    let activeNav = this.navs[0].id

    this.navs.forEach((n, i) => {
      const dom = document.getElementById(n.id)
      if (!dom) {
        return
      }
      n.top = dom.offsetTop - 200
      if (top > n.top) {
        if (!this.navs[i + 1]) {
          activeNav = n.id
        } else if (top < this.navs[i + 1].top) {
          activeNav = n.id
        }
      }
    })

    this.setState({ activeNav, navSticky: top > this.navs[0].top })
  }

  onCoverClick = (side: number) => {
    this.setState({
      coverIndex: this.state.coverIndex + side,
    })
  }

  onNavClick = (id: string) => {
    const dom = document.getElementById(id)
    if (!dom) {
      return
    }
    window.scrollTo({
      top: dom.offsetTop - 150,
      behavior: 'smooth',
    })
  }

  getQrCodeUrl = () => {
    const base =
      'https://www.atzuche.com/apigateway/carInfoMgmt/public/qrCodePic/getQrCodePic?size=120&url='

    return `${base}${encodeURIComponent('https://m.atzuche.com/m/index/redirectmini')}`
  }
}

// 标题取接口数据，描述和图片取本地数据
const localNewsData = [
  {
    desc:
      '随着租车行业的快速发展，越来越多的企业开始投资这个朝阳行业，租车公司的数量迅速上升，这就会导致一个问题，那就是租车公司哪家好？这么多的租车公司，如何选择呢？',
    cover: 'https://cdn.atzuche.com/pc/news/news-1.jpg',
  },
  {
    desc:
      '租车是人们出行相对自由轻松的一种出行方式之一，尤其是疫情情况下，租车的私密安全性，是人们出远门或者周边游的首选方式；那么租车公司哪家好？哪家靠谱正规？',
    cover: 'https://cdn.atzuche.com/pc/news/news-2.jpg',
  },
  {
    desc:
      '坐公交累、打车贵，遇到路线不熟时，找不准景点定位，经常会到站下车后，一家人一脸蒙圈，那该如何解决这个问题呢?然后就想到了租车出门，然后选择那个租车平台便宜靠谱呢？',
    cover: 'https://cdn.atzuche.com/pc/news/news-3.jpg',
  },
  {
    desc:
      '作为一个租车5年的老司机，国内各类租车APP都使用过；整理和分析了国内几个相对靠谱的租车平台的资料，从实力背景、流程便捷度、租车价格、保障及售后等多方面实测对比。',
    cover: 'https://cdn.atzuche.com/pc/news/news-4.jpg',
  },
  {
    desc:
      '作为一个每个月要租车的人，租车平台哪个好？这个问题我来回答在合适不过。早年间还没有租车平台，租车都是街边小店或者百度上找。租10次起码5次不让你省心。',
    cover: 'https://cdn.atzuche.com/pc/news/news-5.jpg',
  },
  {
    desc:
      '衣食住行一直是人们最基础需求保障，随着近几年疫情管控的常态化，更加快捷、安全的出行方式显得更加重要，租车以其便捷安全性的优点，成为越来越多人选择的一种出行方式。',
    cover: 'https://cdn.atzuche.com/pc/news/news-6.jpg',
  },
]

export default {
  localNewsData,
}

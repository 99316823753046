import * as React from 'react'
import { Citys, getCitys, getQAListByTopic, getTopicQAListByWeight, ListData, QAType } from './api'
import RentQAPage from './page'
import { Ctx } from '$types'

interface RentQAProps {
  citys: Citys[]
  listData: ListData
  weightList: QAType[]
}

const RentQA: React.FC<RentQAProps> & {
  getInitialProps: (req: Ctx<{ type: string }>) => void
} = ({ citys, listData, weightList }) => {
  return <RentQAPage key="home" citys={citys} listData={listData} weightList={weightList} />
}

RentQA.getInitialProps = async ctx => {
  try {
    const { type = '1' } = ctx.match.params
    const [citys, listData, weightList] = await Promise.all([
      getCitys(),
      getQAListByTopic({ topicType: type }),
      getTopicQAListByWeight(type),
    ])
    return { citys, listData, weightList }
  } catch (error) {
    console.error(error)
  }
}

export default RentQA

import React from 'react'
import Nav from '../components/nav'
import { renderRoutes } from 'react-router-config'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import Footer from '../components/footer'
import checkMobile from '../utils/checkMobile'

import { CityContext } from '../utils/city-context'
import Tool from '../hoc/tool'
import { citys, getCityNameByHost, getAllCityByCityName } from '$utils/citys'
import canUseDom from '../utils/canUseDom'
import { getLinks } from './api'

class App extends React.Component {
  static async getInitialProps({ req, res, match }) {
    const host = req ? req.headers['host'] : window.location.host
    const cityName = getCityNameByHost(host)
    const city = getAllCityByCityName(cityName)
    let isMobile = false
    if (req?.headers?.['user-agent']) {
      isMobile = checkMobile(req.headers['user-agent'])
    } else if (canUseDom() && navigator?.userAgent) {
      isMobile = checkMobile(navigator.userAgent)
    }

    const isServer = !!req
    let linkList = []

    const links = await getLinks()
    if (links?.list) {
      linkList = links.list
    }

    return { isMobile, isServer, linkList, city, host: host || '' }
  }

  constructor(props) {
    super(props)
    this.state = {
      citys,
      city: this.props.data.city || {},
      toggleCity: this.toggleCity,
    }
  }

  toggleCity = city => {
    this.setState({
      city: city,
    })
  }

  render() {
    const isMobile = this.props.data && this.props.data.isMobile
    const linkList = this.props.data && this.props.data.linkList
    const city = this.state.city || {}
    const { route = {}, data = {} } = this.props
    const { routes = [] } = route
    return (
      <CityContext.Provider value={this.state}>
        <Helmet>
          <title>凹凸租车 - 万款车型、共享租赁、专业自驾租车平台</title>
          <meta
            name="keywords"
            content="自驾租车,租车平台,短期租车,租车价格,豪车租赁,旅游租车,婚车租赁"
          />
          <meta
            name="description"
            content="凹凸租车提供全国自驾租车服务，包括短期租车、长期租车、各类豪车租赁、个人租车等汽车租赁服务；2022租车价格表、车型等信息，满足不同场景租车需求，旅游租车、婚车租赁、商务租车接待等；共享汽车模式，让你享受随时随地的共享租车体验"
          />
          <meta name="mobile-agent" content="format=html5;url=https://m.atzuche.com/" />
        </Helmet>
        <div className="page">
          <Nav city={city} isMobile={isMobile} www={(data.host || '').includes('www.')} />
          {renderRoutes(routes, { ...data })}
          <Footer
            city={city}
            www={(data.host || '').includes('www.')}
            linkList={linkList}
            isMobile={isMobile}
          />
        </div>
      </CityContext.Provider>
    )
  }
}

export { App }
export default connect(({ city }) => ({
  ...city,
}))(Tool(App))

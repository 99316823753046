import React from 'react'
import './style.scss'
import { Helmet } from 'react-helmet'
import Split from '$components/split'

// 组件
import AtSelect from '../../ui/select'
import Event from './event'
import Tool from '../../hoc/tool'

class Owner extends Event {
  constructor(props) {
    super(props)
    this.state = {
      citys: null,
      citysLoading: false,
      inputCompanyName: '',
      inputContactName: '',
      inputContactMobile: '',
      selectCityName: void 0,
      selectCityCode: void 0,
      selectCarCount: void 0,
      inputMobileVerifyMessage: '',
    }
  }

  render() {
    const {
      citys,
      inputCompanyName,
      inputContactName,
      inputContactMobile,
      selectCityName,
      selectCarCount,
      inputMobileVerifyMessage,
    } = this.state

    const carCounts = [
      { id: 1, name: '0-10' },
      { id: 2, name: '10-50' },
      { id: 3, name: '50-100' },
      { id: 4, name: '100-200' },
      { id: 5, name: '200以上' },
    ]

    return (
      <div className="business">
        <Helmet>
          <title>汽车入驻_汽车车源入驻及商家车辆入驻服务平台-凹凸租车</title>
          <meta name="keywords" content="商家入驻" />
          <meta
            name="description"
            content="凹凸商家入驻平台招募全国各地区渠道合作商，让出“行”更快捷！"
          />
        </Helmet>
        <div className="business_banner box">
          <div className="business_inner">
            <div className="business_inner_title">
              <h2>招募全国各地区渠道合作商</h2>
              <h2>让出“行”更快捷</h2>
            </div>
          </div>
        </div>
        <div className="business_center main clearfix">
          <div className="business_center_items clearfix">
            <Split />
            <h2>贵公司是否有意向入驻呢</h2>
            <h5>请留下联系方式，我们会尽快联系您，提供专人服务</h5>
            <ul className="subData">
              <li
                className="business_center_items-item"
                onFocus={e => {
                  const ele = e.currentTarget
                  ele.classList.add(
                    'business_center_items-item-focused',
                    'business_center_items-item-border',
                  )
                }}
                onBlur={e => {
                  const ele = e.currentTarget
                  if (!inputCompanyName) {
                    ele.classList.remove('business_center_items-item-focused')
                  }
                  ele.classList.remove('business_center_items-item-border')
                }}
              >
                <span className="business_center_items-item-placehold">公司名称</span>
                <input value={inputCompanyName} onChange={this.onInputCompanyNameChange} />
              </li>
              <li
                className="business_center_items-item"
                onFocus={e => {
                  const ele = e.currentTarget
                  ele.classList.add(
                    'business_center_items-item-focused',
                    'business_center_items-item-border',
                  )
                }}
                onBlur={e => {
                  const ele = e.currentTarget
                  if (!inputContactName) {
                    ele.classList.remove('business_center_items-item-focused')
                  }
                  ele.classList.remove('business_center_items-item-border')
                }}
              >
                <span className="business_center_items-item-placehold">联系人姓名</span>
                <input value={inputContactName} onChange={this.onInputContactNameChange} />
              </li>
              <li
                className="business_center_items-item"
                onFocus={e => {
                  const ele = e.currentTarget
                  ele.classList.add(
                    'business_center_items-item-focused',
                    'business_center_items-item-border',
                  )
                }}
                onBlur={e => {
                  const ele = e.currentTarget
                  if (!inputContactMobile) {
                    ele.classList.remove('business_center_items-item-focused')
                  }
                  ele.classList.remove('business_center_items-item-border')
                }}
              >
                {inputMobileVerifyMessage ? (
                  <span className="business_center_items-item-placehold business_center_items-item-message">
                    {inputMobileVerifyMessage}
                  </span>
                ) : (
                  <span className="business_center_items-item-placehold">联系电话</span>
                )}
                <input value={inputContactMobile} onChange={this.onInputContactMobileChange} />
              </li>

              <li className="select">
                <AtSelect
                  placeholder="所在城市"
                  size="middle"
                  value={selectCityName}
                  onChange={this.onCitysSelectChange}
                >
                  {citys?.map((item, index) => {
                    return (
                      <AtSelect.Option key={index} value={item}>
                        {item.name}
                      </AtSelect.Option>
                    )
                  })}
                </AtSelect>
              </li>

              <li className="select">
                <AtSelect
                  placeholder="可提供的车辆数量"
                  size="middle"
                  value={selectCarCount}
                  onChange={this.onSelectCarCountChange}
                >
                  {carCounts?.map((item, index) => {
                    return (
                      <AtSelect.Option key={index} value={item}>
                        {item.name}
                      </AtSelect.Option>
                    )
                  })}
                </AtSelect>
              </li>
            </ul>

            <a className="submit" onClick={this.onSubmit}>
              提交意向
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Tool(Owner)

import cn from 'classnames'
import * as React from 'react'
import { Link } from 'react-router-dom'
import './style'

interface IItem {
  label: string
  value: any
}

interface IProps {
  title?: string
  value: any
  onChange: (d: any) => void
  items: IItem[]
  more?: string
  moreText?: string
  [otherProps: string]: any
}

const AtTabs: React.FC<IProps> = props => {
  return (
    <div className={cn('at-tabs', props.className)}>
      {props.title && <h2>{props.title}</h2>}
      <div className="items">
        {props.items.map(item => {
          return (
            <button
              className={cn('item', {
                active: typeof props.value !== 'undefined' && item.value === props.value,
              })}
              onClick={props.onChange.bind(null, item.value)}
              key={item.value}
            >
              {item.label}
            </button>
          )
        })}
      </div>
      {props.children}
      {props.more && (
        <Link className="more" rel="nofollow" to={props.more}>
          {props.moreText || '更多'}
        </Link>
      )}
    </div>
  )
}

export default AtTabs

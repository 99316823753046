import React from 'react'
import './style.scss'

interface IProps {
  title: string
}

export default function LargeTitle(props: IProps) {
  return (
    <div className="com-home-large-title">
      <h2>{props.title}</h2>
    </div>
  )
}

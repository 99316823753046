import cn from 'classnames'
import React from 'react'
import './style'

interface IProps {
  data: any
  cityKey: string
  [otherProps: string]: any
}

const CarCard: React.FC<IProps> = props => {
  const { data = {}, className } = props

  const title = `${data.vehicleName} ${data.engineAndDisplacement} ${data.transmissionType}`

  const tagTypes: number[] = []
  const tags: any[] = []
  if (data.storeList) {
    data.storeList.forEach((s: any) => {
      if (s.storeLabelList) {
        s.storeLabelList.forEach((t: any) => {
          if (tagTypes.indexOf(t.type) === -1) {
            tagTypes.push(t.type)
            tags.push({ label: t.name, type: t.type })
          }
        })
      }
    })
  }

  return (
    <a href={`/taocan/id-${data.id}`} className={cn('s2b2c-car-card', className)}>
      <div className="thumbnail">
        <img src={data.vehiclePictureUrl} alt={title} />
      </div>
      <div className="content">
        <h3 className="title">{title}</h3>
        <p className="tags">
          {tags.map((t: any, i: number) => (
            <span key={i}>{t.label}</span>
          ))}
        </p>
        <p className="price">
          <span>{data.cheapDayPrice}</span>
          元/天
        </p>
      </div>
    </a>
  )
}

export default CarCard

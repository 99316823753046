import * as React from 'react'
import AtAlert from '../../../../ui/alert'
import AtButton from '../../../../ui/button'
import AtInput from '../../../../ui/input'
import AtSelect from '../../../../ui/select'
import { submitForm } from '../../service'
import Title from '../title'
import './style'

const Form: React.FC = () => {
  const [companyName, setCompanyName] = React.useState('')
  const [name, setName] = React.useState('')
  const [mobile, setMobile] = React.useState('')
  const [carType, setCarType] = React.useState('')
  const [city, setCity] = React.useState('')

  const onInputChange = (evt: any) => {
    const name = evt.target.name
    const val = evt.target.value
    if (name === 'companyName') {
      setCompanyName(val)
    } else if (name === 'name') {
      setName(val)
    } else if (name === 'mobile') {
      setMobile(val)
    } else if (name === 'city') {
      setCity(val)
    }
  }

  const onCarTypeChange = (val: any) => {
    setCarType(val)
  }

  const onSubmit = async () => {
    if (!name) {
      return AtAlert.error('请输入姓名')
    }
    if (!mobile) {
      return AtAlert.error('请输入手机号')
    }
    if (!/1[0-9]{10}/.test(mobile)) {
      return AtAlert.error('请输入正确的手机号')
    }
    if (!city) {
      return AtAlert.error('请输入用车城市')
    }
    if (!carType) {
      return AtAlert.error('请选择车辆类型')
    }

    const data = {
      name,
      mobile,
      rentCarCity: city,
      useType: 2, // 企业用车
      carType,
    }

    try {
      await submitForm(data)
      AtAlert.success('提交成功')
      setCompanyName('')
      setName('')
      setMobile('')
      setCity('')
      setCarType('')
    } catch (err) {
      AtAlert.error(err.message || '系统错误')
    }
  }

  return (
    <div className="company-rent-form">
      <Title
        title="您想租什么类型的车辆呢"
        desc="请留下联系方式，我们会尽快联系您，提供专人管家服务"
      />

      <div style={{ display: 'none' }}>
        <AtInput
          value={companyName}
          placeholder="企业名称（选填）"
          name="companyName"
          onChange={onInputChange}
          className="input"
        />
      </div>

      <AtInput
        value={name}
        placeholder="姓名"
        name="name"
        onChange={onInputChange}
        className="input"
      />

      <AtInput
        value={mobile}
        placeholder="手机号码"
        name="mobile"
        onChange={onInputChange}
        className="input"
      />

      <AtInput
        value={city}
        placeholder="用车城市"
        name="city"
        onChange={onInputChange}
        className="input"
      />

      <AtSelect
        className="car-type"
        placeholder="请选择车辆类型"
        value={carType}
        onChange={onCarTypeChange}
      >
        <AtSelect.Option value="经济型" />
        <AtSelect.Option value="舒适型" />
        <AtSelect.Option value="SUV" />
        <AtSelect.Option value="MPV" />
        <AtSelect.Option value="豪华型" />
      </AtSelect>

      <AtButton className="submit" onClick={onSubmit}>
        提交申请
      </AtButton>
    </div>
  )
}

export default Form

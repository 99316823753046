import cn from 'classnames'
import CarQRPanel from 'components/carQRPanel'
import RentNavPanel from 'components/rentNavPanel'
import * as React from 'react'
import AtBreadcrumb from 'ui/breadcrumb'
import Controller from './controller'
import './style'

export default class View extends Controller {
  renderBreadcrumb = () => {
    return (
      <AtBreadcrumb
        className="breadcrumb"
        data={[
          { text: '二线城市套餐' },
          { text: this.state.city.name + '租车', link: '/' + this.state.city.key },
          { text: this.state.name },
        ]}
      />
    )
  }

  renderCover = () => {
    return (
      <div className="cover">
        {this.state.coverList.length > 1 && (
          <button
            className={cn('prev', { disabled: this.state.coverIndex === 0 })}
            onClick={() => this.onCoverClick(-1)}
          />
        )}
        {this.state.coverList.length > 1 && (
          <button
            className={cn('next', {
              disabled: this.state.coverIndex === this.state.coverList.length - 1,
            })}
            onClick={() => this.onCoverClick(1)}
          />
        )}

        <div className="swiper-images">
          {this.state.coverList.map((p, i) => {
            return (
              <img
                style={{ display: i === this.state.coverIndex ? 'block' : 'none' }}
                key={i}
                src={p}
              />
            )
          })}
        </div>
      </div>
    )
  }

  renderTagList = () => {
    if (!this.state.tagList.length) {
      return null
    }

    return (
      <div className="tag-list">
        <h2 className="sub-title">特色标签</h2>

        <div className="list">
          {this.state.tagList.map(t => {
            return <p key={t}>{t}</p>
          })}
        </div>
      </div>
    )
  }

  renderGoodCar = () => {
    const space: any = []
    if (this.state.goodCar.length < 4) {
      for (let i = this.state.goodCar.length; i < 4; i++) {
        space.push(i)
      }
    }

    return (
      <div className="good-car">
        <h2 className="sub-title">优车推荐</h2>

        <div className="list">
          {this.state.goodCar.map(c => {
            return (
              <a
                className="item"
                key={c.id}
                href={`/${this.state.city.key || 'sh310100'}/id-${c.id}`}
              >
                <img src={c.cover} />
                <div className="title">
                  <p>{c.title}</p>
                  <span>{c.gbType}</span>
                </div>
                <div className="price">
                  <strong>{c.price}</strong>元/天
                </div>
              </a>
            )
          })}

          {space.map((i: any) => (
            <div className="item" key={i} />
          ))}
        </div>
      </div>
    )
  }

  renderPlatform = () => {
    return (
      <div className="car-platform">
        <h2 className="sub-title">平台优势</h2>

        <p className="desc">
          凹凸出行，万款车型共享平台。APP一键下单、车管家送车上门、高额保障用车无忧。自2014年上线，凹凸出行已累计：
        </p>

        <div className="list">
          <div className="item">
            <i className="icon1" />
            <p>10000+</p>
            <span>精选共享车型</span>
          </div>

          <div className="item">
            <i className="icon2" />
            <p>300000+</p>
            <span>精选共享车型</span>
          </div>

          <div className="item">
            <i className="icon3" />
            <p>10000000+</p>
            <span>精选共享车型</span>
          </div>

          <div className="item">
            <i className="icon4" />
            <p>200+</p>
            <span>精选共享车型</span>
          </div>
        </div>
      </div>
    )
  }

  renderSamePriceCar = () => {
    const space: any = []
    if (this.state.samePriceCar.length < 4) {
      for (let i = this.state.samePriceCar.length; i < 4; i++) {
        space.push(i)
      }
    }

    return (
      <div className="same-price-car">
        <h2 className="sub-title">同价位车型推荐</h2>

        <div className="list">
          {this.state.samePriceCar.map(c => {
            return (
              <a
                className="item"
                key={c.id}
                href={`/${this.state.city.key || 'sh310100'}/id-${c.id}`}
              >
                <img src={c.cover} />
                <div className="title">
                  <p>{c.title}</p>
                  <span>{c.gbType}</span>
                </div>
                <div className="price">
                  <strong>{c.price}</strong>元/天
                </div>
              </a>
            )
          })}

          {space.map((i: any) => (
            <div className="item" key={i} />
          ))}
        </div>
      </div>
    )
  }

  renderAside = () => {
    return (
      <div className="aside">
        <RentNavPanel simple={true} />

        <CarQRPanel
          price={this.state.price}
          priceUnit="天"
          title={this.state.name}
          gbType={this.state.gbType}
          qrImage={this.getQrCodeUrl()}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="page-s2b2c-detail">
        {this.renderBreadcrumb()}

        <div className="inner clearfix">
          <div className="bd">
            {this.renderCover()}
            {this.renderTagList()}
            {this.renderGoodCar()}
            {this.renderPlatform()}
          </div>

          {this.renderAside()}
        </div>

        {this.renderSamePriceCar()}
      </div>
    )
  }
}

import httpx from '../../utils/httpX'

// 品牌数据
async function getBrandData(cityCode = '310100') {
  const res: any = await httpx.request({
    method: 'get',
    url: `/appserver/h5/web/carParam/listBrandMap/${cityCode}`,
  })

  return res || {}
}

// 车列表
async function getCarData(cityCode = '310100') {
  try {
    const res: any = await httpx.request({
      method: 'post',
      url: '/apigateway/openApiApplets/public/pc/getVehicleList',
      data: {
        cityId: cityCode,
      },
    })

    return res || {}
  } catch (err) {
    return {}
  }
}

export { getBrandData, getCarData }

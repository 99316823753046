import * as React from 'react'
import cdn from '../../../../utils/cdn'
import './style'

interface IProps {
  data: any[]
}

const logoList = [
  {
    link: 'http://www.ftchinese.com/story/001056950?full=y',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_55.jpg',
    alt: '凹凸租车-FT.com',
  },
  {
    link: 'http://www.autohome.com.cn/news/201406/811489.html?',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_27.jpg',
    alt: '凹凸租车-汽车之家',
  },
  {
    link: 'http://auto.163.com/14/0606/16/9U2OO7G900084IJ0.html',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_28.jpg',
    alt: '凹凸租车-网易汽车',
  },
  {
    link: 'http://shanghai.auto.sohu.com/20140606/n400520647.shtml',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_29.jpg',
    alt: '凹凸租车-搜狐汽车',
  },
  {
    link: 'http://www.pcauto.com.cn/qcbj/453/4533691.html',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_30.jpg',
    alt: '凹凸租车-太平洋汽车网',
  },
  {
    link: 'http://info.autofan.com.cn/info/2014-06-06/26/10855284.xhtml',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_31.jpg',
    alt: '凹凸租车-AutoFan.com.cn',
  },
  {
    link: 'http://www.cnautonews.com/jxs/hsc/201406/t20140606_310087.htm',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_32.jpg',
    alt: '凹凸租车-中国汽车报网',
  },
  {
    link: 'http://www.howbuy.com/news/2014-06-06/2382513.html#userconsent#',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_26.jpg',
    alt: '凹凸租车-HOWbuy',
  },
  {
    link: 'http://auto.cnfol.com/cheshidongtai/20140605/18051613.shtml',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_33.jpg',
    alt: '凹凸租车-中金在线',
  },
  {
    link: 'http://auto.gasgoo.com/News/2014/06/05055210521060299945884.shtml',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_34.jpg',
    alt: '凹凸租车-盖世汽车资讯',
  },
  {
    link: 'http://auto.21cn.com/zixun/qiye/a/2014/0605/17/27390860_1.shtml',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_56.jpg',
    alt: '凹凸租车-21CN汽车',
  },
  {
    link: 'http://www.ellechina.com/life/news/20140611-177013-pn-2.shtml',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_57.jpg',
    alt: '凹凸租车-ELLE',
  },
  {
    link: 'http://sh.edushi.com/bang/info/113-118-n889129.html',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_58.jpg',
    alt: '凹凸租车-E都市',
  },
  {
    link: 'http://car.kdslife.com/news/detail_10013476.html',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_59.jpg',
    alt: '凹凸租车-宽带山汽车',
  },
  {
    link: 'http://www.yoka.com/brand/life/2014/0616930584.shtml',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_60.jpg',
    alt: '凹凸租车-YOKA.COM',
  },
  {
    link: 'http://info.xcar.com.cn/201406/news_1601742_1.html',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_61.jpg',
    alt: '凹凸租车-爱卡汽车',
  },
  {
    link: 'http://auto.enorth.com.cn/system/2014/06/08/011932532.shtml',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_62.jpg',
    alt: '凹凸租车-北方网',
  },
  {
    link: 'http://finance.takungpao.com/q/2014/0606/2520266.html',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_63.jpg',
    alt: '凹凸租车-大公网',
  },
  {
    link: 'http://finance.eastmoney.com/news/1348,20140606390722639.html',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_64.jpg',
    alt: '凹凸租车-东方财富网',
  },
  {
    link: 'http://www.dongfang8.com/aotu/news/437246.htm',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_65.jpg',
    alt: '凹凸租车-东方视窗',
  },
  {
    link: 'http://auto.eastday.com/auto/2012auto/node37530/node37531/userobject1ai257361.html',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_66.jpg',
    alt: '凹凸租车-东方网',
  },
  {
    link: 'http://news.ifeng.com/a/20140702/40988792_0.shtml',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_67.jpg',
    alt: '凹凸租车-凤凰资讯',
  },
  {
    link: 'http://finance.gucheng.com/201406/2737984.shtml',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_68.jpg',
    alt: '凹凸租车-股城网',
  },
  {
    link: 'http://www.haibao.com/article/1672059.htm',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_69.jpg',
    alt: '凹凸租车-HAIBAO.COM',
  },
  {
    link: 'http://auto.jstv.com/news/20140606/story_671797.html',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_70.jpg',
    alt: '凹凸租车-江苏网络电视台',
  },
  {
    link: 'http://news.xgo.com.cn/89/897919.html',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_71.jpg',
    alt: '凹凸租车-汽车点评',
  },
  {
    link: 'http://zuche.qc188.com/news/201407/5167.html',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_72.jpg',
    alt: '凹凸租车-汽车江湖',
  },
  {
    link: 'http://www.szkweekly.com/List.asp?ID=52272',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_73.jpg',
    alt: '凹凸租车-商乙网',
  },
  {
    link: 'http://auto.online.sh.cn/content/2014-06/09/content_6914942.htm',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_74.jpg',
    alt: '凹凸租车-上海热线',
  },
  {
    link: 'http://www.pclady.com.cn/station/116/1161598.html',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_75.jpg',
    alt: '凹凸租车-太平洋时尚网',
  },
  {
    link: 'http://news.10jqka.com.cn/20140606/c565937594.shtml',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_76.jpg',
    alt: '凹凸租车-同花顺',
  },
  {
    link: 'http://xmwb.xinmin.cn/html/2014-07/02/content_46_4.htm',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_77.jpg',
    alt: '凹凸租车-新民晚报',
  },
  {
    link: 'http://newspaper.jfdaily.com/xwcb/html/2014-06/11/content_40726.htm',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_78.jpg',
    alt: '凹凸租车-新闻晨报',
  },
  {
    link: 'http://auto.yzdsb.com.cn/news/20140606/story_671797.html',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_79.jpg',
    alt: '凹凸租车-燕赵都输',
  },
  {
    link: 'http://finance.chinanews.com/auto/2014/06-11/6266695.shtml',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_81.jpg',
    alt: '凹凸租车-中国新闻网',
  },
  {
    link: 'http://www.chinairn.com/news/20140606/173035511.shtml',
    path: 'http://carphoto.aotuzuche.com/web/images/about_page/index_82.jpg',
    alt: '凹凸租车-中研网',
  },
]

const Media: React.FC<IProps> = props => {
  return (
    <div className="com-about-media">
      <h3 className="title">视频媒体报道</h3>
      <div className="panel">
        <div className="media-list clearfix">
          {props.data.map((m: any, i: number) => {
            return (
              <a href={m.link} target="_blank" className="item" key={i}>
                <img src={cdn + m.path} />
                <h5>{m.title}</h5>
              </a>
            )
          })}
        </div>
      </div>

      <h3 className="title">网络媒体</h3>
      <div className="panel">
        <div className="web-media clearfix">
          {logoList.map((m: any, i: number) => {
            return (
              <a href={m.link} target="_blank" className="item" key={i}>
                <img src={m.path} alt={m.alt} title={m.alt} />
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Media

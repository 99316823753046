const brandData = [
  {
    name: '奔驰',
    url: 'https://cdn.atzuche.com/pc/hotbrand/bc.jpg',
    key: 'bc',
    code: '_0_44',
  },
  {
    name: '宝马',
    url: 'https://cdn.atzuche.com/pc/hotbrand/bm.jpg',
    key: 'bm',
    code: '_0_49',
  },
  {
    name: '奥迪',
    url: 'https://cdn.atzuche.com/pc/hotbrand/ad.jpg',
    key: 'ad',
    code: '_0_46',
  },
  {
    name: '别克',
    url: 'https://cdn.atzuche.com/pc/hotbrand/bk.jpg',
    key: 'bk',
    code: '_0_27',
  },
  {
    name: '大众',
    url: 'https://cdn.atzuche.com/pc/hotbrand/dz.jpg',
    key: 'dz',
    code: '_0_40',
  },
  {
    name: '本田',
    url: 'https://cdn.atzuche.com/pc/hotbrand/bt.jpg',
    key: 'bt',
    code: '_0_57',
  },
  {
    name: '日产',
    url: 'https://cdn.atzuche.com/pc/hotbrand/rc.jpg',
    key: 'rc',
    code: '_0_3762',
  },
  {
    name: '福特',
    url: 'https://cdn.atzuche.com/pc/hotbrand/ft.jpg',
    key: 'ft',
    code: '_0_71',
  },
  {
    name: '特斯拉',
    url: 'https://cdn.atzuche.com/pc/hotbrand/tsl.jpg',
    key: 'tsl',
    code: '_0_5099',
  },
  {
    name: '凯迪拉克',
    url: 'https://cdn.atzuche.com/pc/hotbrand/kdlk.jpg',
    key: 'kdlk',
    code: '_0_26',
  },
  {
    name: '雷克萨斯',
    url: 'https://cdn.atzuche.com/pc/hotbrand/lkss.jpg',
    key: 'lkss',
    code: '_0_99',
  },
  {
    name: '保时捷',
    url: 'https://cdn.atzuche.com/pc/hotbrand/bsj.jpg',
    key: 'bsj',
    code: '_0_20',
  },
]

export default brandData

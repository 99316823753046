import React from 'react'
import Http from '$utils/http'
import qs from 'qs'
import Cookie from 'js-cookie'
import AtAlert from '../../ui/alert'

const baseurl = process.env.NODE_ENV === 'development' ? process.env.RAZZLE_ORIGIN : ''

class RegisteredEvent extends React.PureComponent {
  state = {
    phoneNum: '',
    imgNum: '',
    imgUrl: '',
    smsNum: '',
    isShowImgInput: false,
    isShowRetry: false,
    retrySecond: 60,
    agreedLicense: true,
  }

  intervaler = 0

  componentWillUnmount() {
    this.clear()
  }

  inputOnChange = (type, e) => {
    const ele = e?.target

    this.setState({
      [type]: ele.value,
    })
  }

  clear = () => {
    this.intervaler && window.clearInterval(this.intervaler)
    this.setState({
      retrySecond: 60,
      isShowRetry: false,
    })
  }

  retryTimer = () => {
    this.intervaler = setInterval(() => {
      if (this.state.retrySecond === 0) {
        this.clear()
      } else {
        this.setState({
          retrySecond: this.state.retrySecond - 1,
        })
      }
    }, 1000)
  }

  getImgUrl = verifyCodeUrl => {
    if (verifyCodeUrl) {
      this.verifyCodeUrl = verifyCodeUrl
    }

    this.setState({
      imgUrl: `${baseurl}/appserver${this.verifyCodeUrl}&_v=${Date.now()}`,
    })
  }

  getSmsCode = async e => {
    try {
      const { phoneNum, imgNum } = this.state

      if (!/^1[3456789]\d{9}$/.test(phoneNum)) {
        return AtAlert.error('请输入有效的手机号')
      }
      await Http.request({
        method: 'post',
        url: '/register/validate',
        data: {
          mobile: phoneNum,
          verifyCode: imgNum,
          validCodeType: 'sms',
        },
      })

      this.setState({
        isShowRetry: true,
      })

      this.retryTimer()
    } catch (error) {
      switch (error.code) {
        case '200017':
          this.setState({
            isShowImgInput: true,
          })
          this.getImgUrl(error.data.verifyCodeUrl)
          break
        case '300008':
          this.getImgUrl(error.data.verifyCodeUrl)
          AtAlert.error(error.msg)
          break
        default:
          AtAlert.error(error.msg || '系统错误')
          break
      }
    }
  }

  onRegisterHandler = async () => {
    try {
      if (!this.state.agreedLicense) {
        return AtAlert.error(
          '<span style="font-size:14px">请先阅读并同意《凹凸租车会员服务协议》和《凹凸租车隐私协议》</span>',
        )
      }

      const { phoneNum, smsNum } = this.state

      if (!/^1[3456789]\d{9}$/.test(phoneNum)) {
        return AtAlert.error('请输入有效的手机号')
      }

      if (!smsNum) {
        return AtAlert.error('请输入有效的验证码')
      }
      const source = {
        utmSource: Cookie.get('utm_source'),
        utmMedium: Cookie.get('utm_medium'),
        utmCampaign: Cookie.get('utm_campaign'),
        utmTerm: Cookie.get('utm_term'),
      }

      const search = qs.parse(window.location.search.replace('?', ''))

      const data = {
        ...source,
        validCode: smsNum,
        mobile: phoneNum,
        loginType: 'validCode',
        outerSource: 'mStation',
        inviteCode: '',
        OS: Cookie.get('utm_source') ? Cookie.get('utm_source') : 'PC',
      }

      if (search.bd_vid) {
        data.extendData = JSON.stringify({
          baiduVidData: { bdVid: search.bd_vid, source: 'pc' },
        })
      }

      const res = await Http.request({
        method: 'post',
        url: '/register',
        data,
      })

      const { isNew, maxDiscount, giveNoLimitCouponflag } = res

      try {
        // 设置 gio 埋点
        if (res.isNew === '1') {
          window.gio('track', 'registerSuccess')
          window._agl && window._agl.push(['track', ['success', { t: 3 }]])

          // 如果是头条的渠道，发送统计事件
          const TouTiao = 'ToutiaoSSiosALL'
          if (source.utmSource === TouTiao) {
            window.meteor && window.meteor.track('form', { convert_id: '1645548637456396' })
          }
        }
        window.gio('setUserId', res.baseInfo.memNo)

        // 百度埋点
        window._agl && window._agl.push(['track', ['success', { t: 3 }]])
      } catch (error) {
        console.error(error)
      }

      const parmas = {
        isNew,
        maxDiscount,
        giveNoLimitCouponflag,
      }

      this.props.history.push(`/signup/signupSuccess?${qs.stringify(parmas)}`)

      // 注册成功后重置状态
      this.setState({
        phoneNum: '',
        smsNum: '',
        imgNum: '',
        isShowImgInput: false,
      })
      this.clear()
    } catch (error) {
      console.error('onRegisterHandler:error', error)

      error.msg && error.msg !== 'null' && AtAlert.error(error.msg)
    }
  }

  changeAgreedLicense = () => {
    this.setState({ agreedLicense: !this.state.agreedLicense })
  }
}
export default RegisteredEvent

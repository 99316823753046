import * as React from 'react'
import { Helmet } from 'react-helmet'
import Controller from './controller'
import './style'

export default class View extends Controller {
  render() {
    const info = this.props.info || {}
    return (
      <div className="page-lcl">
        <Helmet>
          <title>凹凸出行机动车辆损失确认书</title>
        </Helmet>

        <h1>
          <img src={require('./imgs/title.png')} />
        </h1>

        <table className="table-content">
          <tr>
            <th>租客</th>
            <td>{info.userName}</td>

            <th>报案编号</th>
            <td>{info.insuranceNo}</td>

            <th>车牌号</th>
            <td>{info.carNo}</td>
          </tr>

          <tr>
            <th>厂牌型号</th>
            <td>{info.model}</td>

            <th>车辆识别号（车架号）</th>
            <td>{info.carCode}</td>

            <th>出险时间</th>
            <td>{info.insTime}</td>
          </tr>

          <tr>
            <th>估损时间</th>
            <td>{info.checkTime}</td>

            <th>估损地点</th>
            <td colSpan={4}>{info.address}</td>
          </tr>

          <tr>
            <th>更换配件名称</th>
            <th>数量</th>
            <th>金额</th>
            <th>修复项目</th>
            <th colSpan={2}>金额</th>
          </tr>

          {(info.repairList || []).map((v: any, i: number) => {
            return (
              <tr key={i}>
                <td>{v.replaceItem}</td>
                <td>{v.count}</td>
                <td>{v.money1}</td>
                <td>{v.repairItem}</td>
                <td colSpan={2}>{v.money2}</td>
              </tr>
            )
          })}

          {!(info.repairList || []).length && (
            <tr>
              <td>-</td>
              <td>0</td>
              <td>0.00</td>
              <td>-</td>
              <td colSpan={2}>0.00</td>
            </tr>
          )}

          <tr>
            <th>更换配件费合计</th>
            <td colSpan={2}>{info.ReplceCostAmount}</td>

            <th>修复项目费合计</th>
            <td colSpan={2}>{info.repairCostAmounr}</td>
          </tr>

          <tr>
            <th>4S店标准</th>
            <td colSpan={2}>{info.radio1}</td>

            <th>二类修理厂标准</th>
            <td colSpan={2}>{info.radio2}</td>
          </tr>

          <tr>
            <th>总损失金额合计</th>
            <td colSpan={5}>{info.money}</td>
          </tr>

          <tr>
            <th colSpan={6}>
              <h2>费用划分</h2>
            </th>
          </tr>

          <tr>
            <th>平台承担维修费</th>
            <td>{info.ptRepairCost}</td>

            <th>平台承担交通费</th>
            <td>{info.ptTrafficCost}</td>

            <th>平台承担总费用</th>
            <td>{info.ptCostAmount}</td>
          </tr>

          <tr>
            <th>租客承担维修费</th>
            <td>{info.zkRepairCost}</td>

            <th>租客承担交通费</th>
            <td>{info.zkTrafficCost}</td>

            <th>租客承担总费用</th>
            <td>{parseInt(info.zkCostAmount || '0', 10)}</td>
          </tr>

          <tr>
            <td colSpan={6}>
              <br />
              <h3>特别告知</h3>
              <div>
                <p>
                  一、本定损单中更换配件的计费金额标准，是基于
                  <strong>4S店标准 / 二类修理厂标准</strong>的费用标准。
                </p>
                <p>
                  二、如果车辆损失不属于保险责任或属于条款约定的责任免除范围，以上损失确认仅作参考，不作为赔偿承诺依据。
                </p>
                <p>三、办理赔付时需提供以下类型的修理费发票: （√）</p>
                <p>（1）:4S店开具修理费发票（ ）;（2）:综合修理厂开具修理费发票（ ）</p>
                <p>
                  四、车辆拆解后，凡属保险事故范围内的待查部分或遗漏部分，如需追加修理项目及费用，需经及时联系凹凸平台，否则保险公司拒绝赔偿追加部分，将由被保险人自行承担。
                </p>
                <p>
                  五、车辆进厂修理时。车辆方如提出扩大修理范围或更换不属于本次事故所造成的机件损坏，保险公司、凹凸平台不承担赔偿责任。
                </p>
                <p>
                  六、（1）由车主指定修理厂维修﹑因修理质量所发生的直接或间接损失以及由此而引发的所有经济纠纷不属于保险公司、凹凸平台赔偿范围。
                </p>
                <p>
                  （2）由平台协议修理厂维修，因修理质量所发生的直接或间接损失以及由此而引发的所有经济纠纷由平台协议修理厂负责赔偿。
                </p>
              </div>
            </td>
          </tr>

          <tr>
            <th colSpan={2}>租客签字</th>
            <th colSpan={2}>车主签字</th>
            <th colSpan={2}>查勘员签字</th>
          </tr>

          <tr>
            <td colSpan={2}>{info.userName}</td>
            <td colSpan={2}>{info.ownerName}</td>
            <td colSpan={2}>{info.insUser}</td>
          </tr>
        </table>
      </div>
    )
  }
}

import httpx from '../../utils/httpX'

async function getAllCity() {
  try {
    const result = await httpx.request({
      method: 'get',
      url: '/appserver/h5/v41/city/simple/openList',
    })

    return result?.cityList ? result.cityList : null
  } catch (error) {
    console.error('getAllCity', error)
    return null
  }
}

export { getAllCity }

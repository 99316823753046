import httpx from '../../utils/httpX'

export interface INewsListParams {
  cityCode: string
  pageNum: number
  pageSize: number
  type?: 'hot' | 'new'
  tagId?: number
}

// 租车资讯-列表
export const fetchNewsList = async (params: INewsListParams) => {
  const res: any = await httpx.request({
    method: 'GET',
    url: '/apigateway/pcWebService/public/rentCarInformation/findPage',
    params: params,
  })
  return res
}

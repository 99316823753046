import * as React from 'react'
import cdn from '../../../../utils/cdn'
import './style'

interface IProps {
  data: Record<string, any>
  onShowDetail: () => void
}

const ImageGrid: React.FC<IProps> = props => {
  const imgList: string[] = []
  const data = props.data || {}
  if (data.coverImg) {
    imgList.push(data.coverImg)
  }
  if (data.carImg1) {
    imgList.push(...data.carImg1)
  }
  if (data.carImg2) {
    imgList.push(...data.carImg2)
  }
  if (data.carImg3) {
    imgList.push(...data.carImg3)
  }
  if (data.carImg4) {
    imgList.push(...data.carImg4)
  }

  return (
    <div className="com-brand-lib-image-grid">
      <div className="grid">
        <button onClick={props.onShowDetail} className="show-more">
          更多外观/内饰照片
        </button>

        <div className="l">{imgList[0] && <img src={cdn + imgList[0]} />}</div>
        <div className="r">
          {imgList[1] && <img src={cdn + imgList[1]} />}
          {imgList[2] && <img src={cdn + imgList[2]} />}
          {imgList[3] && <img src={cdn + imgList[3]} />}
          {imgList[4] && <img src={cdn + imgList[4]} />}
        </div>
      </div>
    </div>
  )
}

export default ImageGrid

import httpx from '../../utils/httpX'

interface IFetchCarDetailData {
  id: number
  cityCode: string
}

export const fetchDetail = async (data: IFetchCarDetailData) => {
  try {
    const res: any = await httpx.request({
      method: 'post',
      url: '/apigateway/openApiApplets/public/pc/getVehicleList',
      data: {
        cityId: data.cityCode,
      },
    })

    const list = (res.vehicleList || []).sort((a: any, b: any) => {
      return a.cheapDayPrice > b.cheapDayPrice ? 1 : -1
    })

    const find = list.filter((d: any) => d.id === data.id)
    if (find && find[0]) {
      const car = find[0]

      const result: any = {
        name: `${car.vehicleName || ''} ${car.engineAndDisplacement || ''}`,
        coverList: car.vehiclePictureUrl ? [car.vehiclePictureUrl] : [],
        gbType: car.transmissionType.indexOf('自动') === 0 ? '自' : '手',
        price: car.cheapDayPrice,
        tagList: [],
        goodCar: [],
        samePriceCar: [],
      }

      if (car.storeList) {
        car.storeList.forEach((d: any) => {
          if (d.storeLabelList) {
            d.storeLabelList.forEach((s: any) => {
              if (result.tagList.indexOf(s.name) === -1) {
                result.tagList.push(s.name)
              }
            })
          }
        })
      }

      list.forEach((d: any) => {
        const fmt = {
          id: d.id,
          cover: d.vehiclePictureUrl,
          price: d.cheapDayPrice,
          gbType: d.transmissionType.indexOf('自动') === 0 ? '自' : '手',
          title: `${d.vehicleName || ''} ${d.engineAndDisplacement || ''}`,
        }

        if (result.goodCar.length < 4 && d.id !== data.id) {
          result.goodCar.push(fmt)
        }

        if (result.samePriceCar.length < 4 && d.id !== data.id && d.cheapDayPrice > result.price) {
          result.samePriceCar.push(fmt)
        }
      })

      return result
    }

    return {}
  } catch (err) {
    return {}
  }
}

import http from '../../utils/httpX'

// 获取媒体数据
export const getMediaList = async () => {
  try {
    const result: any = await http.request({
      method: 'GET',
      url: '/apigateway/pcConsole/public/media',
    })
    if (result?.list) {
      return result.list
    }
    return []
  } catch (err) {
    return []
  }
}

// 获取关于里的内容
export const getAboutTpl = async (key: string) => {
  const result = {
    tplTitle: '',
    tplContent: '',
  }

  try {
    const res: any = await http.request({
      method: 'GET',
      url: `/apigateway/cmsServer/public/article/${key}`,
    })

    if (!res) {
      return result
    }

    result.tplTitle = res.title
    result.tplContent = res.htmlContent

    return result
  } catch (err) {
    return result
  }
}

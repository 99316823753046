import httpx from '../../utils/httpX'

async function getCarDetail(carNo) {
  if (!carNo) {
    return null
  }
  try {
    const data = await httpx.request({
      method: 'GET',
      url: '/appserver/h5/v591/car/detail/renter',
      params: {
        sceneCode: 'U003',
        OS: 'pc',
        schema: 'B',
        carNo,
      },
    })

    return data
  } catch (error) {
    return null
  }
}

export { getCarDetail }

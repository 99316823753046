import * as React from 'react'
import { Helmet } from 'react-helmet'
import NewsPanel from '../../components/newsPanel'
import RecommendCar from '../../components/recommendCar'
import RentNavPanel from '../../components/rentNavPanel'
import AtBreadcrumb from '../../ui/breadcrumb'
import PanelCarOwnerForm from '../carOwner/components/panelCarOwnerForm'
import Controller from './controller'
import './style'

export default class View extends Controller {
  renderHead = () => {
    const { newsDetail = {}, city = {} } = this.props
    const cityCode = city.code || '310100'

    return (
      <Helmet>
        <title>{newsDetail.title}_凹凸租车</title>
        <meta name="keywords" content={newsDetail.keyWord} />
        <meta name="description" content={newsDetail.describe} />
        <meta
          name="mobile-agent"
          content={`format=html5;url=https://m.atzuche.com/m/index/news/${cityCode}-default-${newsDetail.id}`}
        />
      </Helmet>
    )
  }

  renderBreadcrumb = () => {
    const { city, role = 'default' } = this.props

    return (
      <AtBreadcrumb
        className="breadcrumb"
        data={[
          { text: `${city.name}租车`, link: '/' },
          { text: `${city.name}租车资讯`, link: `/news/${role}` },
        ]}
      />
    )
  }

  renderNews = () => {
    let { newsDetail = {}, role = 'default', city } = this.props
    const { topInformation: prev, downInformation: next } = newsDetail

    if (!role) {
      role = 'default'
    }

    return (
      <div className="news-main">
        <div className="main">
          <h1>{newsDetail.title}</h1>
          {newsDetail.updateTimeStr && <time>{newsDetail.updateTimeStr}</time>}
          {newsDetail.tagList && newsDetail.tagList.length > 0 && (
            <div className="tag-list">
              {newsDetail.tagList.map((item: any) => {
                return (
                  <a href={`/news/${city.code}-${role}-tag${item.id}`} key={item.id}>
                    {item.tagName}
                  </a>
                )
              })}
            </div>
          )}

          <div className="content" dangerouslySetInnerHTML={{ __html: newsDetail.content }} />
          <div className="pager">
            {prev && (
              <a href={`/news/${city.code}-${role}-${prev.id}`} className="prev">
                上篇<span>{prev.title}</span>
              </a>
            )}
            {next && (
              <a href={`/news/${city.code}-${role}-${next.id}`} className="next">
                <span>{next.title}</span>下篇
              </a>
            )}
          </div>
        </div>
        <div className="aside">
          <RentNavPanel />
          <NewsPanel role={role} code={city.code} type="new" news={this.props.newNews.list} />
          <NewsPanel role={role} code={city.code} type="hot" news={this.props.hotNews.list} />
          <PanelCarOwnerForm type="news" />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="page-city-news-detail">
        {this.renderHead()}
        {this.renderBreadcrumb()}
        {this.renderNews()}
        <RecommendCar list={this.props.carList} />
      </div>
    )
  }
}

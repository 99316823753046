import * as React from 'react'
import './style'

interface IProps {
  [otherProps: string]: any
}

const Header: React.FC<IProps> = props => {
  return (
    <div className="company-rent-header">
      {!props.hideSlogan && (
        <div className="slogan">
          <h1>企业租车解决方案</h1>
        </div>
      )}
      <ul>
        <li>
          <h2>管家服务</h2>
          <p>
            全国3000+车管家
            <br />
            行政级服务标准
          </p>
        </li>
        <li>
          <h2>牌照资源</h2>
          <p>
            限牌城市
            <br />
            丰富的牌照储备
          </p>
        </li>
        <li>
          <h2>优质车源</h2>
          <p>
            绿色共享优势
            <br />
            价格低于市场10%以上
          </p>
        </li>
        <li>
          <h2>定制方案</h2>
          <p>
            时租/日租/月租/年租
            <br />
            用车场景全覆盖
          </p>
        </li>
        <li>
          <h2>高效管理</h2>
          <p>
            “企业云”后台
            <br />
            精准调度，集中管理
          </p>
        </li>
      </ul>
    </div>
  )
}

export default Header

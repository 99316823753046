import React from 'react'
import cdn from '../../../../utils/cdn'
import './style.scss'

interface IProps {
  data: any[]
}

export default function Subjects(props: IProps) {
  const code = '310100'
  return (
    <div className="com-home-subjects">
      <div className="subjects">
        {props.data.slice(0, 4).map(item => (
          <a
            className="subject-item"
            key={item.id}
            href={`/subject/${code}-${item.id}`}
            target="_blank"
          >
            <div
              className="cover"
              style={{ background: `url(${cdn + item.picUrl}) no-repeat 50% / cover` }}
            />
            <div className="title">{item.title}</div>
            <div className="desc">{item.introduction}</div>
          </a>
        ))}
      </div>

      <a className="more" target="_blank" href={`/subject/${code}`}>
        查看更多专题
        <span />
      </a>
    </div>
  )
}

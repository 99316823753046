import * as React from 'react'
import NoCar from '../../../../components/noCar'
import CarCard from '../carCard'
import './style'

interface IProps {
  data: any[]
  cityKey: string
  className?: string
}

const CarList: React.FC<IProps> = props => {
  const list = props.data || []

  if (!list.length) {
    return (
      <div className="com-s2b2c-car-list">
        <NoCar />
      </div>
    )
  }

  return (
    <div className="com-s2b2c-car-list">
      {list.map(item => {
        return <CarCard key={item.id} className="item" cityKey={props.cityKey} data={item} />
      })}
    </div>
  )
}

export default CarList

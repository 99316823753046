import * as React from 'react'
import { Helmet } from 'react-helmet'
import AtBreadcrumb from '../../ui/breadcrumb'
import CarList from './components/carList'
import FilterBar from './components/filterBar'
import Tabs from './components/tabs'
import Controller from './controller'
import './style'

export default class View extends Controller {
  render() {
    const { city = {} } = this.props
    const breadcrumb = [{ text: '二线城市套餐' }, { text: (city.name || '') + '租车' }]

    return (
      <div className="page-s2b2c">
        <Helmet>
          <title>{`${city.name || ''}租车自驾价格信息 – 租赁平台 – 凹凸租车网`}</title>
          <meta name="keywords" content={`${city.name || ''}租车`} />
          <meta
            name="description"
            content={`【凹凸租车】是专业${city.name}租车公司，提供${city.name}汽车租赁、租车电话及最新${city.name}租车价格信息等，${city.name}自驾租车就找凹凸租车，P2P租车模式，让你享受随时随地租车体验。`}
          />
        </Helmet>

        <div className="main">
          <AtBreadcrumb className="breadcrumb" data={breadcrumb} />

          <FilterBar
            pkg={this.state.filter.pkg}
            brand={this.state.filter.brand}
            brandFilter={this.props.brandFilter}
            packageFilter={this.props.packageFilter}
            onChange={this.onFilterBarChange}
          />

          <Tabs onChange={this.onTabChange} />

          <CarList cityKey={city.key} data={this.state.carList} />
        </div>
      </div>
    )
  }
}

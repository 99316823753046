import { matchRoutes } from 'react-router-config'
import { isAsyncComponent, flattenObject } from './utils'
export async function loadInitialProps(routes, pathname, ctx) {
  const promises = []
  const matchedRoutes = matchRoutes(routes, pathname)
  if (!matchedRoutes.length) return { data: [] }
  matchedRoutes.forEach(matched => {
    const { match, route } = matched
    if (route.component && isAsyncComponent(route.component)) {
      const component = route.component
      promises.push(
        component.load
          ? component.load().then(() => component.getInitialProps({ match, ...ctx }))
          : component.getInitialProps({ match, ...ctx }),
      )
    }
  })
  const { route, match } = matchedRoutes[matchedRoutes.length - 1]
  const allData = await Promise.all(promises)
  return {
    match,
    route: route,
    data: { data: flattenObject(allData) },
    // data: (await Promise.all(promises))[matchedRoutes.length - 1]
  }
}

import cn from 'classnames'
import qs from 'qs'
import * as React from 'react'
import AtAlert from '../../../../ui/alert'
import AtButton from '../../../../ui/button'
import AtInput from '../../../../ui/input'
import AtSelect from '../../../../ui/select'
import { getCarPlaceList, sendFastRegSmsCodeToMobile, submitSecurityCard } from '../../service'
import './style'

interface IProps {
  className?: string
}

interface IState {
  isOpen: boolean
  randomCode: string
  timeout: number

  carPlaceList: string[]
  mobileValue: string
  imgCodeValue: string
  smsCodeValue: string
  carPlaceValue: string[]
}

class PanelBaoPaiForm extends React.PureComponent<IProps, IState> {
  state = {
    isOpen: false,
    randomCode: '',
    timeout: 0,

    carPlaceList: [],
    mobileValue: '',
    imgCodeValue: '',
    smsCodeValue: '',
    carPlaceValue: [],
  }

  async componentDidMount() {
    const [carPlaceList] = await Promise.all([getCarPlaceList()])
    this.setState({ carPlaceList })
  }

  onToggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  onInputChange = (evt: any) => {
    if (!evt.target.name) {
      return
    }
    this.setState({ [evt.target.name]: evt.target.value } as any)
  }

  onCarPlaceChange = (val: any) => {
    this.setState({ carPlaceValue: val })
  }

  getImgCodeUrl = () => {
    const randomCode = this.state.randomCode || String(Date.now().valueOf())
    if (!this.state.randomCode) {
      this.setState({ randomCode })
    }
    let origin = ''
    if (process.env.BUILD_TARGET !== 'server' && process.env.NODE_ENV !== 'development') {
      origin = location.origin
    }
    if (process.env.NODE_ENV === 'development') {
      origin = process.env.RAZZLE_ORIGIN as string
    }
    return `${origin}/autowebgateway/h5/imgCode/verifyCode/?random=${randomCode}`
  }

  refreshImgCode = () => {
    const randomCode = String(Date.now().valueOf())
    this.setState({ randomCode })
  }

  runSmsCodeTimeout = (t: number) => {
    if (t < 0) {
      return
    }
    this.setState({
      timeout: t,
    })
    setTimeout(this.runSmsCodeTimeout, 1000, t - 1)
  }

  onGetSmsCode = async () => {
    if (!this.state.mobileValue) {
      return AtAlert.error('请输入手机号')
    }
    if (!/1[0-9]{10}/.test(this.state.mobileValue)) {
      return AtAlert.error('请输入正确的手机号')
    }
    if (!this.state.imgCodeValue) {
      return AtAlert.error('请输入图形验证码')
    }
    try {
      await sendFastRegSmsCodeToMobile({
        mobile: this.state.mobileValue,
        verifyCode: this.state.imgCodeValue,
      })
      AtAlert.success('发送成功！')
      this.runSmsCodeTimeout(60)
    } catch (err) {
      if (err.code === '50004') {
        this.setState({ smsCodeValue: '', randomCode: String(Date.now().valueOf()) })
        return AtAlert.error(err.message)
      }
      AtAlert.error(err.message || '系统错误')
    }
  }

  onSubmitClick = async () => {
    if (!this.state.mobileValue) {
      return AtAlert.error('请输入手机号')
    }
    if (!/1[0-9]{10}/.test(this.state.mobileValue)) {
      return AtAlert.error('请输入正确的手机号')
    }
    if (!this.state.imgCodeValue) {
      return AtAlert.error('请输入图形验证码')
    }
    if (!this.state.smsCodeValue) {
      return AtAlert.error('请输入短信验证码')
    }
    if (!this.state.carPlaceValue.length) {
      return AtAlert.error('请选择车辆归属地')
    }
    try {
      const search = qs.parse(window.location.search.replace(/^\?/, ''))
      await submitSecurityCard({
        mobile: this.state.mobileValue,
        validCode: this.state.smsCodeValue,
        carLocation: this.state.carPlaceValue.join(','),
        utmSource: search.utmSource || '',
        utmMedium: search.utmMedium || '',
        utmCampaign: search.utmCampaign || '',
        utmTerm: search.utmTerm || '',
      })
      this.setState({
        carPlaceValue: [],
        mobileValue: '',
        imgCodeValue: '',
        smsCodeValue: '',
        timeout: 0,
        randomCode: String(Date.now().valueOf()),
      })
      AtAlert.success('信息提交成功')
    } catch (err) {
      if (err.code === '900117') {
        this.setState({
          randomCode: String(Date.now().valueOf()),
        })
        AtAlert.error('你已经报名成功啦<br />无需再次报名')
        return
      }
      AtAlert.error(err.message || '系统错误')
    }
  }

  render() {
    return (
      <div className="com-panel-bao-pai-form">
        <button
          className={cn('join-button', { 'is-open': this.state.isOpen })}
          onClick={this.onToggleOpen}
        >
          {this.state.isOpen ? '收起' : '申请保牌计划'}
          <i />
        </button>
        <div className={cn('form', { 'is-open': this.state.isOpen })}>
          <div className="row">
            <AtInput
              placeholder="请输入手机号"
              size="middle"
              name="mobileValue"
              maxLength="11"
              value={this.state.mobileValue}
              onChange={this.onInputChange}
            />
          </div>
          <div className="row">
            <AtInput
              placeholder="请输入图形验证码"
              size="middle"
              name="imgCodeValue"
              maxLength="6"
              value={this.state.imgCodeValue}
              onChange={this.onInputChange}
              extra={
                <img
                  onClick={this.refreshImgCode}
                  className="img-code"
                  src={this.getImgCodeUrl()}
                />
              }
            />
          </div>
          <div className="row">
            <AtInput
              placeholder="请输入短信验证码"
              size="middle"
              name="smsCodeValue"
              maxLength="6"
              value={this.state.smsCodeValue}
              onChange={this.onInputChange}
              extra={
                this.state.timeout > 0 ? (
                  <span className="timeout">{this.state.timeout}S</span>
                ) : (
                  <button className="get-sms-code" onClick={this.onGetSmsCode}>
                    获取验证码
                  </button>
                )
              }
            />
          </div>
          <div className="row">
            <AtSelect
              placeholder="车辆归属地"
              value={this.state.carPlaceValue}
              onChange={this.onCarPlaceChange}
              multiple={true}
            >
              {this.state.carPlaceList.map(val => {
                return (
                  <AtSelect.Option key={val} value={val}>
                    {val}
                  </AtSelect.Option>
                )
              })}
            </AtSelect>
          </div>
          <div className="row submit">
            <AtButton theme="blue" onClick={this.onSubmitClick}>
              提交
            </AtButton>
          </div>
        </div>
      </div>
    )
  }
}

export default PanelBaoPaiForm

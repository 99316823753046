import * as React from 'react'
import AtAlert from '../../ui/alert'
import { getCityByCode } from '../../utils/citys'
import { fetchNewsList } from '../cityRent/service'
import { fetchSubjectList } from './service'

interface INewsList {
  pageNum: number
  pageSize: number
  total: number
  list: any[]
}

interface ISubjectList {
  pageNum: number
  pageSize: number
  total: number
  list: any[]
}

interface IProps {
  history: History
  match: any
  dispatch: any
  city: any
  newNews: INewsList
  subjectList: any
  hotCars: any[]
  isServer: boolean
}

interface IState {
  subjectList: ISubjectList
}

export default class Controller extends React.PureComponent<IProps, IState> {
  static async getInitialProps(ctx: any) {
    const city = getCityByCode(ctx?.match?.params?.cityCode)

    try {
      const [newNews, subjectList] = await Promise.all([
        fetchNewsList(city.code, 1, 5, 'new'),
        fetchSubjectList(city.code),
        // getHotCars(city.code),
      ])

      return {
        city,
        newNews,
        subjectList,
        // hotCars,
      }
    } catch (err) {
      console.log(err)
    }

    return {
      newNews: { list: [] },
      city,
      subjectList: { list: [], pageNum: 0, pageSize: 0, total: 0 },
      hotCars: [],
    }
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      subjectList: props.subjectList || {},
    }
  }

  onPageChange = async (current: number) => {
    try {
      const { city } = this.props
      const list: any = await fetchSubjectList(city.code, current)
      this.setState({ subjectList: list })
      document.documentElement.scrollTop = 0
    } catch (err) {
      AtAlert.error('系统错误')
    }
  }
}

import * as React from 'react'
import Title from '../title'
import './style'

interface IProps {
  [otherProps: string]: any
}

const Solution: React.FC<IProps> = () => {
  return (
    <div className="company-rent-solution">
      <Title title="帮助业务解决租车痛点" />
      <div className="list clearfix">
        <div className="item">
          <i>01</i>
          <h4>降低固定资产投入</h4>
          <p>有效弱化/避免车辆购置的重资模式，解放资金</p>
        </div>
        <div className="item">
          <i>02</i>
          <h4>降低车队管理成本</h4>
          <p>由专业、高效的外部车队管理代替高成本的企业自营车队</p>
        </div>
        <div className="item">
          <i>03</i>
          <h4>控制企业出行成本</h4>
          <p>专业的“企业云”共享后台，合理规划&控制员工出行成本</p>
        </div>
        <div className="item">
          <i>04</i>
          <h4>制定化解决方案</h4>
          <p>按照企业用车需求，制定专属产品方案</p>
        </div>
        <div className="item">
          <i>05</i>
          <h4>提高出行用车体验</h4>
          <p>优质的车源、专业管家服务及完善的管理体系提高出行体验</p>
        </div>
        <div className="item">
          <i>06</i>
          <h4>优化出行效率</h4>
          <p>全程跟进用车过程的即时需求，快速响应，高效处理</p>
        </div>
      </div>
    </div>
  )
}

export default Solution

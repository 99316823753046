import * as React from 'react'
import './style.scss'

const Compares: React.FC<any> = props => {
  const { carInfo = {} } = props.state
  const { compares = [] } = carInfo

  return (
    <div className="com-hot-car-compares" id="car-compares">
      <h3>同级对比</h3>

      <div className="list">
        {compares.map((c: any, i: number) => {
          return (
            <div className="item" key={i}>
              <div className="hd">
                <img src={c.thumb} className="cover" />
                <p className="score">{c.score}分</p>
              </div>

              <div className="info">
                <h3>{c.name}</h3>
                <p className="price">
                  <strong>{c.price}</strong>元/天
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Compares

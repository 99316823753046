import cn from 'classnames'
import * as React from 'react'
import cdn from 'utils/cdn'
import AsidePanelWrapper from '../../../../components/asidePanelWrapper'
import './style'

interface IProps {
  city: { name: string; code: string }
  list: any[]
  onMoreClick: () => void
}

const Recomm: React.FC<IProps> = props => {
  const carItem = (d: any) => {
    return (
      <div className="item">
        <div className="thumb">
          <span>{d.carAge}车龄</span>
          <img src={`${cdn}${d.thumb}`} alt={d.title} />
        </div>
        <div className="r">
          <h3>{d.title}</h3>
          <span className="tags">
            {d.plateNum} | {d.gbType === '1' ? '手动挡' : '自动挡'} | {d.displacement}
          </span>

          <div className="rate">
            <span className={cn('star', { n: d.rate < 1 })} />
            <span className={cn('star', { n: d.rate < 2 })} />
            <span className={cn('star', { n: d.rate < 3 })} />
            <span className={cn('star', { n: d.rate < 4 })} />
            <span className={cn('star', { n: d.rate < 5 })} />
            <p>{d.rate}分</p>
          </div>

          <div className="price">
            <strong>{d.price}</strong>
            <span>元/天</span>
          </div>
        </div>
      </div>
    )
  }

  const list = [...props.list]
    .sort((a: any, b: any) => {
      return a.rate > b.rate ? -1 : 1
    })
    .filter((_, i) => i < 4)

  return (
    <AsidePanelWrapper
      title={`${props.city.name}租车推荐`}
      more="更多"
      moreClick={props.onMoreClick}
    >
      <div className="com-short-rent-recomm">{list.map(d => carItem(d))}</div>
    </AsidePanelWrapper>
  )
}

export default Recomm

import httpx from '../../utils/httpX'

export const fetchSubjectDetail = async (cityCode: string, id: string) => {
  const res: any = await httpx.request({
    method: 'GET',
    url: '/apigateway/pcWebService/public/rentCarSpecial/details',
    params: {
      cityCode,
      id,
    },
  })

  return res
}

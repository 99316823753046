export default {
  namespace: 'shortRent',
  state: {
    brands: null,
    hotBrands: null,
    carData: null,
    filterInfo: [],
    filterCondition: {
      seq: 4,
    },
  },
  reducers: {
    set(state, { payload }) {
      return { ...state, ...payload }
    },
    dec(state, { payload }) {
      return { ...state, ...payload }
    },
  },
  effects: {
    *asyncAdd({ payload }, { call, put }) {
      function sleep(delay = 16) {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, delay)
        })
      }

      yield call(sleep, 2000)

      yield put({
        type: 'add',
        payload,
      })
    },
  },
}

import React, { Component } from 'react'
import './style.scss'

class Split extends Component {
  render() {
    const { className } = this.props

    const cn = className ? `split ${className}` : 'split'
    return <div className={cn} />
  }
}

export default Split

import * as React from 'react'
import HotCars from '../../components/hotCars'
import NewsPanel from '../../components/newsPanel'
import AtBreadcrumb from '../../ui/breadcrumb'
import AtPagination from '../../ui/pagination'
import cdn from '../../utils/cdn'
import Controller from './controller'
import './style'

export default class View extends Controller {
  renderBreadcrumb = () => {
    const { city = {} } = this.props

    return (
      <AtBreadcrumb
        className="breadcrumb"
        data={[
          { text: `${city.name}租车`, link: '/' },
          { text: '租车专题', link: `/subject/${city.code}` },
        ]}
      />
    )
  }

  renderList = () => {
    const { city } = this.props
    const { subjectList } = this.state

    return (
      <div className="subject-main">
        <div className="main">
          <h2>{city.name}·租车专题</h2>
          <div className="list">
            {subjectList.list.map((data: any) => this.renderSubjectItem(data))}
          </div>
          <AtPagination
            className="pagination"
            total={subjectList.total}
            size={subjectList.pageSize}
            current={subjectList.pageNum}
            onChange={this.onPageChange}
          />
        </div>
        <div className="aside">
          <NewsPanel type="new" code={city.code} news={this.props.newNews.list} />
        </div>
      </div>
    )
  }

  renderSubjectItem = (data: any) => {
    const code = this.props.city.code

    return (
      <a className="subject-item" key={data.id} href={`/subject/${code}-${data.id}`}>
        <div className="header">
          <img src={cdn + data.picUrl} />
          <h3>{data.title}</h3>
        </div>
        <div className="text">
          <p>{data.introduction}</p>
        </div>
      </a>
    )
  }

  render() {
    return (
      <div className="page-subject-rent">
        {this.renderBreadcrumb()}
        {this.renderList()}
        <h2 className="hot-cars-title">推荐车型</h2>
        <HotCars list={this.props.hotCars} city={this.props.city} />
      </div>
    )
  }
}

import cn from 'classnames'
import * as React from 'react'
import AsidePanelWrapper from '../../../../components/asidePanelWrapper'
import './style'

interface IProps {
  list: any[]
  city: { name: string; code: string }
  role?: string
}

const News: React.FC<IProps> = props => {
  const newsItem = (n: any) => {
    const code = props.city.code === '000000' ? '310100' : props.city.code
    return (
      <a
        href={`/news/${code}-${props.role || 'default'}-${n.id}`}
        className={cn('news-item', { 'news-item-hastag': !!n.tagName })}
        key={n.id}
      >
        <span className="p">{n.title}</span>
        {n.tagName && <span className="label">#{n.tagName}#</span>}
        <span className="dot" />
        [查看]
      </a>
    )
  }

  return (
    <AsidePanelWrapper
      title={`${props.city.name}租车资讯`}
      more="更多"
      moreHref={`/news/${props.city.code}-${props.role || 'default'}`}
    >
      <div className="com-short-rent-news">{props.list.map(d => newsItem(d))}</div>
    </AsidePanelWrapper>
  )
}

export default News

import httpx from '../../utils/httpX'

// 获取品牌库列表数据
export const fetchBrandLibDetail = (brand: string, carModel: string) => {
  return httpx.get('/apigateway/pcConsole/public/brandLib/detail', { params: { brand, carModel } })
}

// 租车资讯-列表
export const fetchNewsList = async (params: { cityCode: string }) => {
  const res: any = await httpx.request({
    method: 'GET',
    url: '/apigateway/pcWebService/public/rentCarInformation/findPage',
    params: {
      cityCode: params.cityCode,
      pageNum: 1,
      pageSize: 6,
    },
  })
  return res
}

// 根据权重获取城市主题列表
export const getCityQAListByWeight = (params: { cityCode: string }) => {
  return httpx.get('/apigateway/pcWebService/public/pc/qa/listByCityCode/weight', {
    params,
  })
}

// 获取同级别车辆
export const getCompetitorsList = (ids: string[]) => {
  return httpx.get('/apigateway/pcConsole/public/shortRent/ids', {
    params: { ids: ids.join(',') },
  })
}

export default {
  namespace: 'specialCar',
  state: {
    brands: null,
    cars: null,
    total: 0,
  },
  reducers: {
    set(state, { payload }) {
      return { ...state, ...payload }
    },
  },
}

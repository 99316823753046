import * as React from 'react'
import { Ctx } from 'types'
import RentQAPage from '../page'
import './style.scss'
import {
  DetailQAType,
  getQADetailByCity,
  getQADetailByTopic,
  getQAListByCity,
  getQAListByTopic,
  QAType,
} from '../api'

interface RentQADetailProps {
  weightList: QAType[]
  detail: DetailQAType
}

const RentQADetail: React.FC<RentQADetailProps> & {
  // eslint-disable-next-line @typescript-eslint/member-delimiter-style
  getInitialProps: (req: Ctx<{ type: string; id: string }>) => void
} = ({ weightList, detail }) => {
  return <RentQAPage key="detail" isDetail weightList={weightList} detail={detail} />
}

RentQADetail.getInitialProps = async ctx => {
  try {
    const { type, id } = ctx.match.params

    const isCity = type.length === 6

    const [listData, detail] = await Promise.all([
      isCity
        ? getQAListByCity({ cityCode: type, pageSize: 5 })
        : getQAListByTopic({ topicType: type, pageSize: 5 }),
      isCity
        ? getQADetailByCity({ cityCode: type, curId: id })
        : getQADetailByTopic({ curId: id, topicType: type }),
    ])

    return {
      weightList: (listData as any)?.qaDetailInfoResVos || [],

      detail,
    }
  } catch (error) {
    console.error(error)
  }
}

export default RentQADetail

import * as React from 'react'
import './style.scss'

const Configuration: React.FC<any> = props => {
  const { carInfo = {} } = props.state

  return (
    <div className="com-hot-car-configuration" id="car-configuration">
      <h3>参数配置</h3>

      <div className="list">
        {(carInfo.configurations || []).map((c: any) => {
          return (
            <div className="item" key={c.label}>
              <p>{c.label}</p>
              <span>{c.value}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Configuration

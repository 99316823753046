import * as React from 'react'
import { getCityByFullLetter, getCityNameByHost } from '../../utils/citys'
import { fetchNewsList } from '../cityRent/service'
import { fetchSubjectList } from '../subjectRent/service'
import { fetchBusinessCenterList, fetchCarList } from './../cityRent/service'
import { fetchSubjectDetail } from './service'

interface INewsList {
  pageNum: number
  pageSize: number
  total: number
  list: any[]
}

interface ICarList {
  pageNum: number
  pageSize: number
  total: number
  list: any[]
}

interface ISubjectList {
  pageNum: number
  pageSize: number
  total: number
  list: any[]
}

interface IDetailTopDown {
  id: string
  title: string
  picUrl: string
  introduction: string
}

interface IDetail {
  id: string
  picUrl: string
  title: string
  introduction: string
  topInformation?: IDetailTopDown
  downInformation?: IDetailTopDown
  rentCarSpecialRuleReqList?: any
}

interface IBusinessCenter {
  label: string
  value: string
  extra?: Pick<IBusinessCenter, 'label' | 'value'>[]
}

interface IProps {
  history: History
  match: any
  dispatch: any
  city: any
  newNews: INewsList
  carList: ICarList
  subjectDetail: IDetail
  subjectList: ISubjectList
  businessCenterList: IBusinessCenter[]
  carBrandFilter: any
  isServer: boolean
}

interface IState {
  tabValue: string
  subTabValue: string
  carList: ICarList
}

export default class Controller extends React.PureComponent<IProps, IState> {
  static async getInitialProps(ctx: any) {
    const host = ctx.req ? ctx.req.headers['host'] : window.location.host
    const cityName = getCityNameByHost(host)
    const city = getCityByFullLetter(cityName)

    const id = ctx?.match?.params?.id
    let carList = { list: [], pageNum: 1, pageSize: 20, total: 0 }

    try {
      const [newNews, subjectDetail, subjectList, businessCenterList] = await Promise.all([
        fetchNewsList(city.code, 1, 5, 'new'),
        fetchSubjectDetail(city.code, id),
        fetchSubjectList(city.code, 1, 4, id),
        fetchBusinessCenterList(city.code),
      ])
      businessCenterList.unshift({ label: '不限', value: '' })
      subjectDetail.id = id

      const reqList = subjectDetail.rentCarSpecialRuleReqList || []
      const brands: any[] = []
      let findAll = false
      reqList.forEach((r: any) => {
        if (!r.brandId) {
          findAll = true
        }
      })
      if (!findAll) {
        reqList.forEach((r: any) => {
          const brand = { id: r.brandId, typeIds: [] }
          if (r.typeIds) {
            brand.typeIds = r.typeIds.split(',')
          }
          brands.push(brand)
        })
      }
      const carList = await fetchCarList(city.code, { brands }, 1, 12)

      return {
        city,
        newNews,
        subjectDetail,
        carList,
        subjectList,
        carBrandFilter: brands,
        businessCenterList,
      }
    } catch (err) {
      console.log(err)
    }

    return {
      city,
      newNews: { list: [] },
      subjectDetail: {},
      carList,
      carBrandFilter: [],
      subjectList: { list: [] },
      businessCenterList: [],
    }
  }

  constructor(props: IProps) {
    super(props)

    this.state = {
      tabValue: '',
      subTabValue: '',
      carList: props.carList,
    }
  }

  onTabMouseOver = (evt: any) => {
    const target = evt.target
    if (!target) {
      return
    }

    try {
      const parent = target.parentElement
      const rect = parent.getBoundingClientRect()
      const parentRect = parent.parentElement.getBoundingClientRect()
      const extra = parent.querySelector('.extra')
      extra.style.left = parentRect.left - rect.left - 10 + 'px'
      const arrow = extra.querySelector('.arrow')
      arrow.style.left = rect.left - parentRect.left + rect.width / 2 + 5 + 'px'
    } catch (err) {}
  }

  onTabClick = async (value: string, subValue: string) => {
    if (value === this.state.tabValue && subValue === this.state.subTabValue) {
      return
    }
    let areaCode = value
    let businessId = subValue

    if (areaCode === 'HOT') {
      areaCode = subValue.split('_')[0]
      businessId = subValue.split('_')[1]
    }

    const filter: any = {
      brands: this.props.carBrandFilter,
    }

    if (areaCode) {
      filter.regionVo = { areaCode }
      if (businessId) {
        filter.regionVo.businessId = businessId
      }
    }

    try {
      const { city } = this.props
      const carList = await fetchCarList(city.code, filter, 1, 12)
      this.setState({ carList, tabValue: areaCode, subTabValue: businessId })
    } catch (err) {
      console.error(err)
    }
  }

  onPageChange = async (page: number) => {
    try {
      const { city } = this.props
      const filter: any = { brands: this.props.carBrandFilter }
      if (this.state.tabValue) {
        filter.regionVo = { areaCode: this.state.tabValue }
        if (this.state.subTabValue) {
          filter.regionVo.businessId = this.state.subTabValue
        }
      }

      const carList = await fetchCarList(city.code, filter, page, 12)
      this.setState({ carList })
      document.documentElement.scrollTop = 0
    } catch (err) {
      console.error(err)
    }
  }
}

import * as React from 'react'
import { getAboutTpl, getMediaList } from './service'

interface IState {
  activeMenu: string
  activeSubMenu: string
  mediaList: any[]
  tplTitle: string
  tplContent: string
  subMenu: any[]
}

export default class Controller extends React.PureComponent<any, IState> {
  static async getInitialProps(ctx: any) {
    const defaultMenu = ctx?.match?.params?.key
    const props: any = { defaultMenu, mediaList: [], tplTitle: '', tplContent: '' }

    if (defaultMenu === 'mediaReport') {
      const list = await getMediaList()
      props.mediaList = list
    } else if (defaultMenu && defaultMenu !== 'knowUs') {
      const key = defaultMenu.indexOf('/') !== -1 ? defaultMenu.split('/')[1] : defaultMenu
      const res = await getAboutTpl(key)
      props.tplTitle = res.tplTitle
      props.tplContent = res.tplContent
    }

    return props
  }

  menus = [
    { label: '关于我们', value: 'knowUs' },
    { label: '发展历程', value: 'milestone' },
    { label: '媒体报道', value: 'mediaReport' },
    {
      label: '平台规则',
      value: 'rule/platformRule',
      subs: [
        { label: '凹凸短租-租客端', value: 'PlatFormRulesForRenters' },
        { label: '凹凸短租-车主端', value: 'PlatFormRulesForOwners' },
        { label: '凹凸长租', value: 'longrentlicense' },
      ],
    },
    { label: '租车流程', value: 'rentCarFlow' },
    { label: '服务条款', value: 'insuranceClauses' },
    { label: '车主责任/法律解读', value: 'ownersResAndIegal' },
    { label: '服务协议', value: 'vipServiceWeb' },
    { label: '隐私协议', value: 'privacyPolicy' },
  ]

  constructor(props: any) {
    super(props)

    const activeMenu = this.props.defaultMenu || 'knowUs'
    const cur = this.menus.filter(m => m.value === activeMenu)
    const subMenu: any = cur[0] ? cur[0].subs || [] : []
    const activeSubMenu = subMenu[0] ? subMenu[0].value : ''

    this.state = {
      activeMenu,
      mediaList: this.props.mediaList || [],
      tplTitle: this.props.tplTitle || '',
      tplContent: this.props.tplContent || '',
      subMenu,
      activeSubMenu,
    }
  }

  onMenuClick = async (key: string) => {
    this.setState({ activeMenu: key })
    if (key === 'mediaReport' && !this.state.mediaList.length) {
      const list = await getMediaList()
      this.setState({ mediaList: list })
    } else if (key !== 'mediaReport' && key !== 'knowUs') {
      const k = key.indexOf('/') !== -1 ? key.split('/')[1] : key
      const res = await getAboutTpl(k)
      const cur = this.menus.filter(m => m.value === key)
      const subMenu: any = cur[0] ? cur[0].subs || [] : []
      const activeSubMenu = subMenu[0] ? subMenu[0].value : ''

      this.setState({
        tplTitle: res.tplTitle,
        tplContent: res.tplContent,
        subMenu,
        activeSubMenu,
      })
    }
    window.history.replaceState(null, '', `/about/${key}`)
  }

  onTplSubMenuClick = async (key: string) => {
    try {
      const res = await getAboutTpl(key.split('/')[1])
      this.setState({
        activeSubMenu: key.split('/')[1],
        tplTitle: res.tplTitle,
        tplContent: res.tplContent,
      })
    } catch (err) {}
  }
}

import httpx from '../../utils/httpX'

export const fetchSubjectList = async (
  cityCode: string,
  pageNum = 1,
  pageSize = 10,
  id?: string, // 过滤id
) => {
  const res: any = await httpx.request({
    method: 'GET',
    url: '/apigateway/pcWebService/public/rentCarSpecial/findPage',
    params: {
      cityCode,
      pageNum,
      pageSize,
      id,
    },
  })

  return res
}

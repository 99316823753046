import cn from 'classnames'
import Cookie from 'js-cookie'
import qs from 'qs'
import * as React from 'react'
import AtAlert from 'ui/alert'
import Http from '../../utils/http'
import './style'

const baseurl = process.env.NODE_ENV === 'development' ? process.env.RAZZLE_ORIGIN : ''
let countdownTimer: any = null

interface IProps {
  visible: boolean
  onClose: () => void
  onRegisterOk: () => void
}

const RegisterModal: React.FC<IProps> = props => {
  const [mobile, setMobile] = React.useState('')
  const [imgCode, setImgCode] = React.useState('')
  const [smsCode, setSmsCode] = React.useState('')
  const [isShowImgInput, setIsShowImgInput] = React.useState(false)
  const [isShowRetry, setIsShowRetry] = React.useState(false)
  const [retrySecond, setRetrySecond] = React.useState(60)
  const [agreeLicense, setAgreeLicense] = React.useState(true)
  const [verifyCodeUrl, setVerifyCodeUrl] = React.useState('')
  const [imageUrl, setImageUrl] = React.useState('')

  const isMobile = (str: string) => /^1[3-9]\d{9}$/.test(str)

  const onMobileChange = (evt: any) => {
    setMobile(evt.target.value.trim().substr(0, 18))
  }

  const onImgCodeChange = (evt: any) => {
    setImgCode(evt.target.value.trim().substr(0, 6))
  }

  const onSmsCodeChange = (evt: any) => {
    setSmsCode(evt.target.value.trim().substr(0, 6))
  }

  const toggleAgreeLicense = () => {
    setAgreeLicense(!agreeLicense)
  }

  const clearCountdown = () => {
    clearTimeout(countdownTimer)
    setRetrySecond(60)
    setIsShowRetry(false)
  }

  const retryCountdown = (t: number) => {
    clearTimeout(countdownTimer)
    countdownTimer = setTimeout(() => {
      if (t === 0) {
        clearCountdown()
      } else {
        setRetrySecond(t)
        retryCountdown(t - 1)
      }
    }, 1000)
  }

  const getImgUrl = (v: string) => {
    if (v) {
      setVerifyCodeUrl(v)
    }
    setImageUrl(`${baseurl}/appserver${verifyCodeUrl || v}&_v=${Date.now()}`)
  }

  const getSmsCode = async () => {
    try {
      if (!isMobile(mobile)) {
        return AtAlert.error('请输入有效的手机号')
      }
      await Http.request({
        method: 'post',
        url: '/register/validate',
        data: {
          mobile,
          verifyCode: imgCode,
          validCodeType: 'sms',
        },
      })
      setIsShowRetry(true)
      retryCountdown(60)
    } catch (error) {
      console.error(error)
      switch (error.code) {
        case '200017':
          setIsShowImgInput(true)
          getImgUrl(error.data.verifyCodeUrl)
          break
        case '300008':
          getImgUrl(error.data.verifyCodeUrl)
          AtAlert.error(error.msg)
          break
        default:
          AtAlert.error(error.msg || '系统错误')
          break
      }
    }
  }

  const onSubmit = async () => {
    if (!mobile) {
      AtAlert.error('手机号不能为空')
    } else if (!isMobile(mobile)) {
      AtAlert.error('请输入正确的手机号')
    } else if (isShowImgInput && !imgCode) {
      AtAlert.error('请输入图形验证码')
    } else if (!smsCode) {
      AtAlert.error('请输入短信验证码')
    } else if (!agreeLicense) {
      AtAlert.error(
        '<span style="font-size:14px">请先阅读并同意《凹凸租车会员服务协议》和《凹凸租车隐私协议》</span>',
      )
    } else {
      try {
        const source = {
          utmSource: Cookie.get('utm_source'),
          utmMedium: Cookie.get('utm_medium'),
          utmCampaign: Cookie.get('utm_campaign'),
          utmTerm: Cookie.get('utm_term'),
        }

        const search = qs.parse(window.location.search.replace('?', ''))

        const data: any = {
          ...source,
          validCode: smsCode,
          mobile,
          loginType: 'validCode',
          outerSource: 'mStation',
          inviteCode: '',
          OS: Cookie.get('utm_source') ? Cookie.get('utm_source') : 'PC',
        }

        if (search.bd_vid) {
          data.extendData = JSON.stringify({
            baiduVidData: { bdVid: search.bd_vid, source: 'pc' },
          })
        }

        await Http.request({
          method: 'post',
          url: '/register',
          data,
        })

        // const { isNew, maxDiscount, giveNoLimitCouponflag } = res

        // 百度埋点
        ;(window as any)._agl && (window as any)._agl.push(['track', ['success', { t: 3 }]])

        props.onRegisterOk()
      } catch (error) {
        console.error('onRegisterHandler:error', error)
        error.msg && error.msg !== 'null' && AtAlert.error(error.msg)
      }
    }
  }

  return (
    <div className={cn('com-short-rent-register', { show: props.visible })}>
      <div className="content">
        <button className="close" onClick={props.onClose} />

        <div className="qr">
          <div className="title">欢迎体验凹凸租车小程序</div>
          <div className="codes">
            <div className="code">
              <img src="https://cdn.atzuche.com/pc/qr/rr-alipay.png" />
              <p>支付宝小程序</p>
            </div>
            <div className="code">
              <img src="https://cdn.atzuche.com/pc/qr/rr-wx.png" />
              <p>微信小程序</p>
            </div>
          </div>
        </div>

        <div className="form">
          <h1>登录</h1>
          <h2>
            新人注册即可获得<strong>1680元大礼包</strong>
          </h2>
          <div className="input">
            <input
              className="ipt"
              placeholder="请输入手机号码"
              value={mobile}
              maxLength={11}
              onChange={onMobileChange}
            />
          </div>

          {isShowImgInput && (
            <div className="input">
              <input
                className="ipt"
                placeholder="请输入图形验证码"
                value={imgCode}
                onChange={onImgCodeChange}
              />
              <img className="imgcode" src={imageUrl} onClick={() => getImgUrl('')} />
            </div>
          )}

          <div className="input">
            <input
              className="ipt"
              placeholder="请输入短信验证码"
              value={smsCode}
              onChange={onSmsCodeChange}
            />

            {!isShowRetry && (
              <button onClick={getSmsCode} className="getcode">
                获取验证码
              </button>
            )}
            {isShowRetry && <span className="reget">重新获取 {retrySecond}s</span>}
          </div>

          <div className="license">
            <i className={cn('radio', { checked: agreeLicense })} onClick={toggleAgreeLicense} />
            <p>
              <span onClick={toggleAgreeLicense}>我同意</span>
              <a href="/about/vipServiceWeb" target="_blank">
                《凹凸租车会员服务协议》
              </a>
              和
              <a href="/about/privacyPolicy" target="_blank">
                《凹凸租车隐私协议》
              </a>
            </p>
          </div>

          <button className="register-btn" onClick={onSubmit}>
            注册领取
          </button>

          <small className="carowner-tips">
            让您的闲置车辆开始挣钱，成为<a href="/carOwner">凹凸车主</a>
          </small>
        </div>
      </div>
    </div>
  )
}

export default RegisterModal

import cn from 'classnames'
import * as React from 'react'
import AsidePanelWrapper from '../../../../components/asidePanelWrapper'
import './style'

interface IProps {
  allCity: { cityCode: string; cityName: string; py: string; domain: string }[]
}

const AsideCities: React.FC<IProps> = props => {
  return (
    <AsidePanelWrapper title="热门租车城市">
      <div className="com-price-aside-city">
        <div className="list">
          {props.allCity.map((d: any) => {
            return (
              <div className={cn('item')} key={d.cityCode}>
                <a href={d.domain}>{d.cityName}</a>
              </div>
            )
          })}
        </div>
      </div>
    </AsidePanelWrapper>
  )
}

export default AsideCities

import React from 'react'
import './style.scss'
import Event from './event'

class DoubleFreeDeposit extends Event {
  render() {
    return (
      <div className="double-free-deposit">
        <div className="main box">
          <div className="main content">
            <div className="content-header">
              <h1>凹凸租车双免押金了！！</h1>
              <h2>免车辆押金+免违章押金</h2>
              <p>芝麻信用用满650分，</p>
              <p>支付租车押金和违章押金时，</p>
              <p>有机会可享受最高6000元的减免额度。</p>
              <button onClick={this.onClickJump}>点击体验</button>
            </div>
            <div className="content-step">
              <button onClick={this.onClickJump}>点击体验</button>
            </div>
            <div className="content-tips">
              <h3>温馨提示</h3>
              <p>
                芝麻信用650分及以上且通过芝麻信用综合评估，即可享受免收车辆押金和违章押金的双免服务；
              </p>
              <p>开通双免的用户，车辆押金和违章押金可分别享受最高3000元减免；</p>
              <p>享受双免服务的用户，取车时携带本人有效身份证及驾驶证即可；</p>
              <p>车辆指导价在150万以上的车辆无法享受支付宝双免服务。</p>
            </div>
            <div className="content-tips">
              <h3>注意事项</h3>
              <p>若用户支付宝内没绑卡，不会减免； </p>
              <p>若用户支付时，支付宝-芝麻信用内有待守约订单(即未支付)，不会减免；</p>
              <p>
                订单结算后，会自动从用户的支付宝账户内扣除租车费用(用户可在支付宝-我的-芝麻信用-信用守护tab，看到一个待付款项)；
              </p>
              <p>若因余额不足扣款失败，则每隔4小时扣款一次。</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DoubleFreeDeposit

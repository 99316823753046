import * as React from 'react'
import AsidePanelWrapper from '../../../../components/asidePanelWrapper'
import './style'

interface IProps {
  list: any[]
  city: { name: string; code: string }
}

const Qa: React.FC<IProps> = props => {
  const qaItem = (data: any) => {
    return (
      <div className="item">
        <h3>{data.question}</h3>
        <p>{data.answer}</p>
      </div>
    )
  }
  const code = props.city.code === '000000' ? '310100' : props.city.code

  return (
    <AsidePanelWrapper title="租车常见问题" more="更多" moreHref={`/rentqa/${code}-1`}>
      <div className="com-short-rent-qa">{props.list.map(d => qaItem(d))}</div>
    </AsidePanelWrapper>
  )
}

export default Qa

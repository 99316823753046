import * as React from 'react'
import './style'

interface IProps {
  allCity: { cityCode: string; cityName: string; py: string; domain: string }[]
}

const TopCity: React.FC<IProps> = props => {
  const citymap: Record<string, any[]> = {}
  props.allCity.forEach(c => {
    if (!citymap[c.py[0]]) {
      citymap[c.py[0]] = []
    }
    citymap[c.py[0]].push(c)
  })
  const cityLetters = Object.keys(citymap).sort()

  return (
    <div className="com-short-rent-top-city main">
      {cityLetters.map(k => {
        return (
          <div className="city-group" key={k}>
            <h5>{k.toUpperCase()}</h5>
            <div className="list">
              {citymap[k].map((d: any) => {
                return (
                  <div key={d.cityCode} className="item">
                    <a href={`${d.domain}`}>{d.cityName}</a>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TopCity

import * as React from 'react'
import './style'

interface IProps {
  className?: string
}

const PanelCarOwner: React.FC<IProps> = () => {
  return (
    <div className="com-panel-car-owner">
      <h2>成为车主保障</h2>
      <div className="sections">
        <div className="section s1">
          <h3>提供全方位保障</h3>
          <p>
            在租车期间，您与租客一样
            <br />
            可享有我们高额度全方位保障
          </p>
        </div>
        <div className="section s2">
          <h3>严格的认证制度</h3>
          <p>
            在交易前累计扣分不超过11分
            <br />
            车主对租客具有完备的评价体系，
            <br />
            且时时更新
          </p>
        </div>
        <div className="section s3">
          <h3>全方位定位系统，租车全程尽在掌控</h3>
          <p>
            为所有车辆安装凹凸租车智能车联网全方位终端
            <br />
            精准定位车辆，随时掌控车辆信息
            <br />
            行程全掌握，爱车共享无忧
          </p>
        </div>
      </div>
      <h2>操作流程</h2>
      <div className="steps">
        <div className="step s1">
          <i>1</i>
          <p>
            上传资料
            <br />
            车主身份证、行驶证、交强险保单
          </p>
        </div>
        <div className="step s2">
          <i>2</i>
          <p>设置租金价格、可租时间</p>
        </div>
        <div className="step s3">
          <i>3</i>
          <p>足不出户，即可接单</p>
        </div>
      </div>
    </div>
  )
}

export default PanelCarOwner

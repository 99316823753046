import React from 'react'
import './style.scss'

export default function NoCar(props) {
  const { children } = props
  return (
    <div className="main at-cars-empty">
      <div className="at-cars-empty-content">
        <p>很抱歉！凹凸君没有给您找到合适的车辆</p>
        {children}
      </div>
    </div>
  )
}

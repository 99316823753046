import cn from 'classnames'
import React from 'react'
import cdn from '../../utils/cdn'
import './style.scss'

export interface ICarCardData {
  carNo: string
  useQR?: boolean // 使用二维码，即没有车详情，划入时显示二维码
  title: string
  plateNum: string
  distance: string
  score: string
  price: string
}

interface IProps {
  data: any
  isLongRent?: boolean
  cityAbbr?: string
  [otherProps: string]: any
}

const CarCard: React.FC<IProps> = props => {
  const { data = {}, className } = props

  let title = data.brandInfo || `${data.brandTxt || ''} ${data.typeTxt || ''}` || ''
  title += ' '
  title += data.sweptVolum || data.cylinderCapacityTxt || ''
  title += ' '
  title += data.gbType === '1' ? '手动挡' : '自动挡'

  const link = props.isLongRent ? `/changzu/${data.carNo}` : `/duanzu/${data.carNo}`

  return (
    <div className={cn('com-car-card', className)}>
      <a href={link} className="thumbnail">
        <img src={cdn + data.coverPic} alt={title} />
      </a>
      <div className="content">
        <h3 className="title">
          <a href={link}>{title}</a>
        </h3>
        <p className="desc">
          {data.plateNum} <span>{data.carScore || data.score}分</span>
        </p>
        {props.isLongRent ? (
          <p className="price">
            <span>
              {data.preferentialLongrentDiscountMonthPrice || data.longrentDiscountMonthPrice}
            </span>
            元/月均
          </p>
        ) : (
          <p className="price">
            <span>{data.dayPrice || data.guidePurchasePrice}</span>
            元/天
          </p>
        )}
      </div>
    </div>
  )
}

export default CarCard

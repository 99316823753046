import cn from 'classnames'
import RcPagination from 'rc-pagination'
import 'rc-pagination/dist/rc-pagination.min.css'
import * as React from 'react'
import './style'

interface IProps {
  total: number
  current: number
  onChange?: (cur: number, size: number) => void
  size?: number
  className?: string
}

const AtPagination: React.FC<IProps> = props => {
  return (
    <div className={cn('at-pagination', props.className)}>
      <RcPagination
        defaultPageSize={props.size || 10}
        total={props.total}
        current={props.current}
        itemRender={(_, type, element) => {
          if (type === 'prev') {
            return <span>&lt; 上一页 </span>
          }
          if (type === 'next') {
            return <span>下一页 &gt;</span>
          }
          return element
        }}
        onChange={props.onChange}
      />
    </div>
  )
}

export default AtPagination

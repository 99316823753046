import React from 'react'
import App from '../app'
import AboutUs from '../pages/aboutUs'
import BrandLib from '../pages/brandLib'
import BrandLibDetail from '../pages/brandLibDetail'
import Business from '../pages/business'
import CarDetail from '../pages/carDetail'
import CarOwner from '../pages/carOwner'
import CityNews from '../pages/cityNews'
import CityNewsDetail from '../pages/cityNewsDetail'
import CompanyRent from '../pages/companyRent'
import DoubleFreeDeposit from '../pages/doubleFreeDeposit'
import Downloads from '../pages/downloads'
import DownloadsMobile from '../pages/downloads/downLoadMobile'
import HotCar from '../pages/hotCar'
import InviteGift from '../pages/inviteGift'
import LongRent from '../pages/longRent'
import LongRentCarDetail from '../pages/longRentCarDetail'
import LossConfirmLetter from '../pages/lossConfirmLetter'
import MobileSignup from '../pages/mobileSignup'
import Price from '../pages/price'
import RentQA from '../pages/rentqa'
import RentQADetail from '../pages/rentqa/detail'
import RentQAType from '../pages/rentqa/type'
import S2b2c from '../pages/s2b2c'
import S2b2cCarDetail from '../pages/s2b2cCarDetail'
import ShortRent from '../pages/shortRentV2'
import Signup from '../pages/signup'
import SignupSuccess from '../pages/signupSuccess'
import SpecailCar from '../pages/specialCar'
import SubjectRent from '../pages/subjectRent'
import SubjectRentDetail from '../pages/subjectRentDetail'

function Download(props: any) {
  const isMobile = props.isMobile
  return isMobile ? <DownloadsMobile /> : <Downloads />
}

function SignUp(props: any) {
  const isMobile = props.isMobile
  return isMobile ? <MobileSignup /> : <Signup />
}

export default [
  {
    path: '/',
    component: App,
    routes: [
      {
        path: '/',
        component: ShortRent,
        exact: true,
      },
      {
        path: '/duanzu/:carNo([0-9]*)',
        exact: true,
        component: CarDetail,
      },
      {
        path: '/duanzu/:filter?',
        exact: true,
        component: ShortRent,
      },
      {
        path: '/changzu',
        exact: true,
        component: LongRent,
      },
      {
        path: '/changzu/:carNo',
        exact: true,
        component: LongRentCarDetail,
      },
      {
        path: '/business',
        exact: true,
        component: Business,
      },
      {
        path: '/companyRent',
        exact: true,
        component: CompanyRent,
      },
      {
        path: '/about/:key*',
        component: AboutUs,
      },
      {
        path: '/signup',
        exact: true,
        component: SignUp,
      },
      {
        path: '/price',
        exact: true,
        component: Price,
      },
      {
        path: '/price/:cityCode',
        exact: true,
        component: Price,
      },
      {
        path: '/signup/inviteLike',
        exact: true,
        component: InviteGift,
      },
      {
        path: '/brand',
        exact: true,
        component: BrandLib,
      },
      {
        path: '/brand/:id',
        exact: true,
        component: BrandLibDetail,
      },
      {
        path: '/signup/signupSuccess',
        exact: true,
        component: SignupSuccess,
      },
      {
        path: '/downloads/shortUrl',
        exact: true,
        component: Download,
      },
      {
        path: '/pc/doubleFreeDeposit',
        exact: true,
        component: DoubleFreeDeposit,
      },
      {
        path: '/city',
        exact: true,
        component: ShortRent,
      },
      {
        path: '/carOwner',
        exact: true,
        component: CarOwner,
      },
      {
        path: '/haoche',
        exact: true,
        component: SpecailCar,
      },
      {
        path: '/subject/:cityCode([0-9]{6})',
        exact: true,
        component: SubjectRent,
      },
      {
        path: '/subject/:cityCode([0-9]{6})-:id',
        exact: true,
        component: SubjectRentDetail,
      },
      {
        path: '/hotCar/:key',
        exact: true,
        component: HotCar,
      },
      {
        path: '/news/:cityCode([0-9]{6})-:key(default|rent|owner)?',
        exact: true,
        component: CityNews,
      },
      {
        path: '/news/:cityCode([0-9]{6})-:key(default-tag[0-9]+|rent-tag[0-9]+|owner-tag[0-9]+)',
        exact: true,
        component: CityNews,
      },
      {
        path: '/news/:cityCode([0-9]{6})-:id(default-[0-9]+|rent-[0-9]+|owner-[0-9]+|[0-9]+)',
        exact: true,
        component: CityNewsDetail,
      },
      {
        path: '/rentqa/:cityCode([0-9]{6})-:type(1|2|3|4|)?',
        exact: true,
        component: RentQA,
      },
      {
        path: '/rentqa/:cityCode([0-9]{6})/:type(1|2|3|4|[0-9]{6})',
        exact: true,
        component: RentQAType,
      },
      {
        path: '/rentqa/:cityCode([0-9]{6})-:type-:id',
        exact: true,
        component: RentQADetail,
      },
      {
        path: '/taocan/:id(id-[0-9]+)',
        exact: true,
        component: S2b2cCarDetail,
      },
      {
        path: '/taocan/:filter*/',
        exact: true,
        component: S2b2c,
      },
      {
        path: '/lossConfirmLetter/:insuranceNo/:carNo',
        exact: true,
        component: LossConfirmLetter,
      },
      {
        path: '*',
        redirectTo: '/',
      },
    ],
  },
]

interface ctxInfo {
  isMobile: boolean
  baseUrl: string
}

export const getCtxInfo = (ctx: any): ctxInfo => {
  const { req } = ctx
  const u = req?.headers['user-agent']
  const { host } = req?.headers
  const isMobile = !!u.match(/AppleWebKit.*Mobile.*/)
  let baseUrl = 'https://m.atzuche.com/m/index/'
  if (!(host.indexOf('www.atzuche.com') > -1)) {
    baseUrl = 'https://' + host + '/m/index/'
  }
  return {
    isMobile,
    baseUrl,
  }
}

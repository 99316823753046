import * as React from 'react'
import AtAlert from 'ui/alert'
import { getCityNameByHost, getCityByFullLetter, abbrGetCity } from 'utils/citys'
import { fetchCarList } from './service'

interface IState {
  carList: { carNo: string }[]
  city: any
  loading: boolean
  totalPage: number
  currentPage: number
}

export default class Controller extends React.PureComponent<any, IState> {
  static async getInitialProps(ctx: any) {
    try {
      const host = ctx.req ? ctx.req.headers['host'] : window.location.host
      const cityName = getCityNameByHost(host)
      const city = getCityByFullLetter(cityName)

      const res = await fetchCarList(city.code || '310100', 1)
      return { carList: res.carList || [], city, totalPage: parseInt(res.totalPage || '1', 10) }
    } catch (err) {
      console.log('fetchCarList', err)
    }
    return { carList: [] }
  }

  state: IState = { carList: [], totalPage: 1, currentPage: 1, loading: false, city: {} }

  constructor(props: any) {
    super(props)

    this.state = {
      carList: props.carList,
      city: props.city || {},
      loading: false,
      totalPage: props.totalPage || 1,
      currentPage: 1,
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    try {
      const { cityName } = this.props.match.params
      const city = abbrGetCity(cityName)

      this.setState({ loading: true, city })
      const res = await fetchCarList(city.code || '310100', 1)
      this.setState({
        carList: res.carList || [],
        totalPage: parseInt(res.totalPage || '1', 10),
      })
    } catch (err) {
      AtAlert.error(err.message)
    } finally {
      this.setState({ loading: false })
    }
  }

  onChangePage = async (page: number) => {
    try {
      this.setState({ loading: true })
      const res = await fetchCarList(this.state.city.code || '310100', page)
      this.setState({
        carList: res.carList || [],
        totalPage: parseInt(res.totalPage || '1', 10),
        currentPage: page,
        loading: false,
      })
    } catch (err) {
    } finally {
      this.setState({ loading: false })
    }
  }
}

import Rate from 'components/rate'
import * as React from 'react'
import cdn from 'utils/cdn'
import './style'

interface IProps {
  data: Record<string, any>
  onClick: () => void
}

const Comments: React.FC<IProps> = props => {
  const data = props.data || {}

  if (!data.comments || !data.comments.length) {
    return null
  }

  const renderItem = (data: Record<string, any>) => {
    return (
      <div onClick={props.onClick} className="item" key={data.avatar}>
        <div className="hd">
          <img src={cdn + data.avatar} className="avatar" />
          <div className="r">
            <div className="t">
              <h6>{data.name}</h6>
              <strong>{data.price}元/天</strong>
              <time>租车时间 {data.rentTime}</time>
            </div>
            <div className="b">
              <Rate rate={data.rate} showText={false} type={2} />
              {data.tags && (
                <div className="tags">
                  {data.tags
                    .split(',')
                    .filter((t: string) => t.trim() !== '')
                    .map((t: string, index: number) =>
                      index < 2 ? <strong key={t}>{t}</strong> : null,
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bd">
          <strong>外观：</strong>
          <p>{data.comment}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="com-brand-lib-comments" id="content-comments">
      <header>
        <h2>看看大家怎么说</h2>
        <a onClick={props.onClick} className="more">
          查看更多
          <span />
        </a>
      </header>

      <div className="list">{data.comments.map((data: any) => renderItem(data))}</div>
    </div>
  )
}

export default Comments

import * as React from 'react'
import Title from '../title'
import './style'

interface IProps {
  [otherProps: string]: any
}

const Scenes: React.FC<IProps> = props => {
  return (
    <div className="company-rent-scenes">
      <Title title="覆盖企业用车主要场景" />

      <div className="list">
        <div className="item">
          <h4>企业短租</h4>
          <p>万款车型 APP自主下单</p>
        </div>
        <div className="item">
          <h4>企业长租</h4>
          <p>
            30天起特惠套餐
            <br />
            专人服务 定向寻找目标车型
          </p>
        </div>
        <div className="item">
          <h4>商务自驾</h4>
          <p>特色商务车型套餐</p>
        </div>
        <div className="item">
          <h4>会务会议</h4>
          <p>可选代驾模式</p>
        </div>
      </div>
    </div>
  )
}

export default Scenes

import * as React from 'react'
import Title from '../title'
import './style'

interface IProps {
  [otherProps: string]: any
}

const Partner: React.FC<IProps> = props => {
  return (
    <div className="company-rent-partner">
      <Title title="合作企业" />
      <div className="list">
        <img src={require('./images/logo-1.gif')} />
        <img src={require('./images/logo-2.gif')} />
        <img src={require('./images/logo-3.gif')} />
        <img src={require('./images/logo-4.gif')} />
        <img src={require('./images/logo-5.gif')} />
        <img src={require('./images/logo-6.gif')} />
        <img src={require('./images/logo-7.gif')} />
        <img src={require('./images/logo-8.gif')} />
        <img src={require('./images/logo-9.gif')} />
        <img src={require('./images/logo-10.gif')} />
        <img src={require('./images/logo-11.gif')} />
        <img src={require('./images/logo-12.gif')} />
      </div>
    </div>
  )
}

export default Partner

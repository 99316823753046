import * as React from 'react'
import { getCarLossInfo } from './service'
interface IState {
  activeMenu: string
  activeSubMenu: string
  mediaList: any[]
  tplTitle: string
  tplContent: string
  subMenu: any[]
}

export default class Controller extends React.PureComponent<any, IState> {
  static async getInitialProps(ctx: any) {
    const params = ctx?.match?.params
    const { insuranceNo = '', carNo = '' } = params || {}
    let info = {}
    if (insuranceNo && carNo) {
      info = await getCarLossInfo({ insuranceNo, carNo: encodeURIComponent(carNo) })
    }

    return { info }
  }
}

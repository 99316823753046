import cn from 'classnames'
import { Range } from 'rc-slider'
import 'rc-slider/assets/index.css'
import * as React from 'react'
import './style'

interface IProps {
  min: number
  max: number
  marks: any
  value: number[]
  tipsFormat?: (v: number) => any
  onChange: (v: number[]) => void
  [otherProps: string]: any
}

const AtRange: React.FC<IProps> = props => {
  const { className, onChange, min, max, value, tipsFormat = v => v, ...otherProps } = props
  const [minVal, setMinVal] = React.useState(value[0])
  const [maxVal, setMaxVal] = React.useState(value[1])

  React.useEffect(() => {
    setMinVal(min)
    setMaxVal(max)
  }, [min, max])

  React.useEffect(() => {
    setMinVal(props.value[0])
    setMaxVal(props.value[1])
  }, [props.value])

  return (
    <div className={cn('at-range', className)}>
      <div className="at-range-tips">
        <p className="tips" style={{ left: ((minVal - min) / (max - min)) * 100 + '%' }}>
          {tipsFormat(minVal)}
        </p>
        <p className="tips" style={{ left: ((maxVal - min) / (max - min)) * 100 + '%' }}>
          {tipsFormat(maxVal)}
        </p>
      </div>
      <div className="at-range-inner">
        <Range
          dots={false}
          min={min}
          max={max}
          value={value}
          onChange={v => {
            if (onChange) {
              setMinVal(v[0])
              setMaxVal(v[1])
              onChange(v)
            }
          }}
          {...otherProps}
        />
      </div>
    </div>
  )
}

export default AtRange

import * as React from 'react'
import './style'

interface IProps {
  data: Record<string, any>
}

const Detail: React.FC<IProps> = props => {
  const list: { key: string; value: string }[] = []
  const data = props.data || {}
  if (data.brand) {
    list.push({ key: '品牌', value: data.brandName })
  }
  if (data.carModel) {
    list.push({ key: '车型', value: data.carModelName })
  }
  if (data.level) {
    list.push({ key: '车辆级别', value: data.level })
  }
  if (data.salePrice) {
    list.push({ key: '指导价', value: data.salePrice + '万元' })
  }
  if (data.engineType) {
    list.push({ key: '能源类型', value: data.engineType })
  }
  if (data.emissionsStandard) {
    list.push({ key: '排放标准', value: data.emissionsStandard })
  }
  if (data.launchDate) {
    list.push({ key: '上市时间', value: data.launchDate })
  }
  if (data.maxPower) {
    list.push({ key: '最大功率(kW)', value: data.maxPower })
  }
  if (data.maxTorque) {
    list.push({ key: '最大扭矩(N·m)', value: data.maxTorque })
  }
  if (data.engineDisplacement) {
    list.push({ key: '发动机', value: data.engineDisplacement })
  }
  if (data.transmission) {
    list.push({ key: '变速箱', value: data.transmission })
  }
  if (data.size) {
    list.push({ key: '长*宽*高(mm)', value: data.size })
  }
  if (data.bodyType) {
    list.push({ key: '车身结构', value: data.bodyType })
  }
  if (data.maxSpeed) {
    list.push({ key: '最高车速(km/h)', value: data.maxSpeed })
  }
  if (data.fuelEfficiency) {
    list.push({ key: '综合油耗(L/100km)', value: data.fuelEfficiency })
  }
  if (data.drivingRange) {
    list.push({ key: '续航里程', value: data.drivingRange + '公里' })
  }

  if (!list.length) {
    return null
  }

  if (list.length % 2 === 1) {
    list.push({ key: '', value: '' })
  }

  return (
    <div className="com-brand-lib-detail" id="content-detail">
      <h2>参数配置</h2>

      <div className="table">
        {list.map(row => {
          return (
            <div className="row" key={row.key}>
              <p>{row.key}</p>
              <p>{row.value}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Detail

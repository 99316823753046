import { fetchAllCity } from 'pages/shortRentV2/service'
import qs from 'qs'
import * as React from 'react'
import { getAllCityByCode } from 'utils/citys'
import { fetchCarList, fetchNewsList } from './service'

interface IPriceList {
  pageNum: number
  pageSize: number
  total: number
  list: any[]
}

interface IProps {
  history: History
  match: any
  dispatch: any
  city: any
  newsList: { list: any[] }
  allCity: any[]
  carList: any[]
  carListSize: number
  carListNum: number
  carListCount: number
}

interface IState {
  priceList: IPriceList
  showCityMore: boolean
  registerVisible: boolean
  registerSuccess: boolean
}

export default class Controller extends React.PureComponent<IProps, IState> {
  static async getInitialProps(ctx: any) {
    const url = ctx.req ? String(ctx.req.url) || '/' : window.location.pathname || '/'
    const cityCode = ctx?.match?.params?.cityCode || ''
    let city = getAllCityByCode(cityCode)

    // 全国
    if (!cityCode || !city) {
      city = { code: '000000', name: '全国', key: '000000', fullLetter: '', domain: '' } as any
    }
    const search = qs.parse(url.split('?')[1] || '')

    try {
      const cityCode = !city || city.code === '000000' ? '310100' : city.code
      const res = await Promise.all([
        fetchAllCity(),
        fetchNewsList({ cityCode }),
        fetchCarList(
          !city || city.code === '000000' ? '' : city.code,
          parseInt(search.page || '1', 10),
        ),
      ])

      return {
        city,
        allCity: res[0],
        newsList: res[1],
        carList: res[2].list,
        carListSize: res[2].pageSize,
        carListNum: res[2].pageNum,
        carListCount: res[2].count,
      }
    } catch (err) {
      return {
        city,
        allCity: [],
        newsList: { list: [] },
        carList: [],
      }
    }
  }

  state: Readonly<IState> = {
    priceList: { pageNum: 0, pageSize: 20, total: 0, list: [] },
    showCityMore: false,
    registerVisible: false,
    registerSuccess: false,
  }

  componentDidMount(): void {
    if (typeof document !== 'undefined') {
      document.querySelector('#root')!.addEventListener('click', this.onWrapperClick)
    }
  }

  componentWillUnmount(): void {
    if (typeof document !== 'undefined') {
      document.querySelector('#root')!.removeEventListener('click', this.onWrapperClick)
    }
  }

  onCityMoreClick = () => {
    this.setState({ showCityMore: true })
  }

  // 页面外部点击，关闭一些弹框
  onWrapperClick = (evt: any) => {
    if (this.state.showCityMore) {
      let parent = evt.target
      let infilter = false
      while (parent) {
        if (parent.id && parent.id === 'all-city-filter') {
          infilter = true
          break
        }
        parent = parent.parentNode
      }
      if (!infilter) {
        this.setState({ showCityMore: false })
      }
    }
  }
}

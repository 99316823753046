import React from 'react'
import { Helmet } from 'react-helmet'
import Article from './components/article'
import CarBaseInfo from './components/carBaseInfo'
import Comment from './components/comment'
import Compares from './components/compares'
import Configuration from './components/configuration'
import Links from './components/links'
import NavBar from './components/navBar'
import Photos from './components/photos'
import Qa from './components/qa'
import Controller from './controller'
import './style'

export default class View extends Controller {
  renderHead = () => {
    const { carInfo = {} } = this.state as any

    return (
      <Helmet>
        <title>{carInfo.title}</title>
        <meta name="keywords" content={carInfo.keywords} />
        <meta name="description" content={carInfo.description} />
      </Helmet>
    )
  }

  render() {
    return (
      <div className="main page-hot-car">
        {this.renderHead()}

        <CarBaseInfo state={this.state} />

        <NavBar state={this.state} active={this.state.activeNav} onClick={this.onNavClick} />

        <Configuration state={this.state} />

        <Photos state={this.state} />

        <Links state={this.state} />

        <Comment state={this.state} />

        <Compares state={this.state} />

        <Article state={this.state} />

        <Qa state={this.state} />
      </div>
    )
  }
}

import cn from 'classnames'
import * as React from 'react'
import './style'

interface IProps {
  placeholder?: string
  name?: string
  value?: any
  onChange?: (val: any, name?: string) => void
  size?: 'middle'
  className?: string
  children?: any
  multiple?: boolean
}

interface IOptopnProps {
  value: any
  label?: any
}

const AtSelectOption: React.FC<IOptopnProps> = props => {
  const onClick = (props as any).__onClick
  return (
    <div
      className={cn('at-select__option', { active: (props as any).active })}
      onClick={() => {
        if (onClick) {
          onClick(props.value)
        }
      }}
    >
      {props.label || props.children || props.value}
    </div>
  )
}

const AtSelect: React.FC<IProps> & { Option: React.FC<IOptopnProps> } = props => {
  const { placeholder, name, value, onChange, className, children, multiple } = props
  const ref: any = React.useRef()
  const [isOpen, setIsOpen] = React.useState(false)

  const onBodyClick = (evt: any) => {
    if (!ref.current) {
      return
    }
    let target = evt.target
    while (target?.parentNode) {
      if (target.nodeName === 'BODY') {
        setIsOpen(false)
        return
      }
      if (target === ref.current) {
        return
      }
      target = target.parentNode
    }
  }

  React.useEffect(() => {
    if (isOpen) {
      document.body.addEventListener('click', onBodyClick)
    } else {
      document.body.removeEventListener('click', onBodyClick)
    }
    return () => {
      document.body.removeEventListener('click', onBodyClick)
    }
  }, [isOpen])

  const options: any[] = []
  const valueMap: any = {}

  React.Children.map(children, (child, i) => {
    if (child.type === AtSelectOption && child.props && child.props.value) {
      valueMap[child.props.value] = child.props.children || child.props.label || child.props.value
      const isActive = multiple
        ? Array.isArray(value)
          ? value.indexOf(child.props.value) !== -1
          : value === child.props.value
        : value === child.props.value

      options.push(
        React.cloneElement(child, {
          key: i,
          active: isActive,
          __onClick: (val: any) => {
            if (onChange) {
              if (multiple) {
                const curValue = Array.isArray(value) ? [...value] : [value]
                const index = curValue.indexOf(val)
                if (index === -1) {
                  curValue.push(val)
                } else {
                  curValue.splice(index, 1)
                }
                onChange(curValue, name)
              } else {
                onChange(val, name)
              }
            }
            !multiple && setIsOpen(false)
          },
        }),
      )
    }
  })

  const onValueClick = () => {
    setIsOpen(!isOpen)
  }

  const onMultiRemoveButtonClick = (e: any, val: any) => {
    e.preventDefault && e.preventDefault()
    e.stopPropagation && e.stopPropagation()
    const curValue = Array.isArray(value) ? [...value] : [value]
    const index = curValue.indexOf(val)
    if (index !== -1) {
      curValue.splice(index, 1)
      if (onChange) {
        onChange(curValue, name)
      }
    }
  }

  const renderValue = () => {
    if (!multiple) {
      if (typeof value === 'undefined') {
        return null
      }
      return (
        <div className="val">
          {typeof valueMap[value] !== 'undefined' ? valueMap[value] : value}
        </div>
      )
    }
    const values = Array.isArray(value) ? value : [value]
    if (!values.length) {
      return null
    }
    return (
      <div className="val">
        {values.map(val => {
          if (typeof valueMap[val] === 'undefined') {
            return null
          }
          return (
            <button key={val}>
              <p>{valueMap[val]}</p>
              <i onClick={e => onMultiRemoveButtonClick(e, val)} />
            </button>
          )
        })}
      </div>
    )
  }

  return (
    <div
      className={cn(
        'at-select',
        {
          'at-select--is-open': isOpen,
          'at-select--is-filled': Array.isArray(value) ? value.length > 0 : !!value,
          'at-select--is-multi': multiple,
        },
        className,
      )}
      ref={ref}
    >
      <div className="at-select__value" onClick={onValueClick}>
        <span>{placeholder}</span>
        {renderValue()}
      </div>
      {!options.length && <div className="at-select__options is-empty">暂无数据</div>}
      {options.length > 0 && <div className="at-select__options">{options}</div>}
    </div>
  )
}

AtSelect.Option = AtSelectOption

export default AtSelect

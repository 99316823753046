import * as React from 'react'
import './style'

interface IProps {
  className?: string
}

const PanelBaoPai: React.FC<IProps> = () => {
  return (
    <div className="com-panel-bao-pai">
      <h2>参加保牌保障</h2>
      <div className="sections">
        <div className="section s1">
          <h3>车辆代采购</h3>
          <p>
            只需投入资金和车牌
            <br />
            凹凸全权负责车辆的采购及上牌
          </p>
        </div>
        <div className="section s2">
          <h3>车辆全权托管</h3>
          <p>
            无论车辆出租情况如何
            <br />
            每月承诺固定租金收益
          </p>
        </div>
        <div className="section s3">
          <h3>车辆托底回购</h3>
          <p>
            车辆到期后，无论车况优劣
            <br />
            均以合同价格回购车辆
          </p>
        </div>
      </div>
      <h2>操作流程</h2>
      <div className="steps">
        <div className="step s1">
          <i>1</i>
          <p>
            保牌用户投入相应资金
            <br />
            购买指定车辆签车辆托管合同
          </p>
        </div>
        <div className="step s2">
          <i>2</i>
          <p>
            凹凸运营车辆
            <br />
            定期支付租金给保牌用户
          </p>
        </div>
        <div className="step s3">
          <i>3</i>
          <p>
            合同到期后
            <br />
            保牌用户获得车辆卖出收益
            <br />
            牌照退还用户
          </p>
        </div>
      </div>
    </div>
  )
}

export default PanelBaoPai

import { History } from 'history'
import * as React from 'react'
import { getCityByCode } from '../../utils/citys'
import { getCtxInfo } from '../../utils/plat'
import { fetchCarList } from '../cityRent/service'
import { fetchCarNewsDetail, fetchNewsList } from './service'

interface INewsList {
  pageNum: number
  pageSize: number
  total: number
  list: any[]
}

interface IProps {
  history: History
  match: any
  city: any
  role?: string
  newsDetail: any
  newNews: INewsList
  hotNews: INewsList
  carList: any[]
}

export default class Controller extends React.PureComponent<IProps> {
  static async getInitialProps(ctx: any) {
    const { isMobile, baseUrl } = getCtxInfo(ctx)
    let id = ctx?.match?.params?.id || ''
    let cityCode = ctx?.match?.params?.cityCode || '310100'
    let role = ctx?.req?.query?.role || 'default'
    const city = getCityByCode(cityCode)

    if (!/^[0-9]+$/.test(id)) {
      const sp = id.split('-')
      id = sp[1] || ''
      role = sp[0] || 'default'
    }

    if (isMobile) {
      // 移动终端
      ctx?.res?.redirect(baseUrl + '/news/' + city.code + id)
    }

    try {
      let params: any = {
        cityCode: city.code,
        pageNum: 1,
        pageSize: 5,
        role,
      }

      const res = await Promise.all([
        fetchCarNewsDetail(city.code, id, params.role),
        fetchNewsList({ ...params, type: 'hot' }),
        fetchNewsList({ ...params, type: 'new' }),
        fetchCarList(city.code, { seq: 7 }, 1, 4),
      ])

      const carList = res[3] ? res[3].list || [] : []

      return {
        city,
        newsDetail: res[0],
        hotNews: res[1],
        newNews: res[2],
        carList,
        role,
      }
    } catch (err) {}

    return {
      newsDetail: {},
      hotNews: { list: [] },
      newNews: { list: [] },
      carList: [],
      city,
    }
  }
}

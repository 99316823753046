import cn from 'classnames'
import * as React from 'react'
import './style'

// 面包屑

interface IBr {
  text: string
  link?: string
}

interface IProps {
  data: IBr[] | IBr
  className?: string
}

const AtBreadcrumb: React.FC<IProps> = props => {
  // TODO: 首页需要把cityCode带上
  const data = Array.isArray(props.data) ? props.data : [props.data]
  return (
    <div className={cn('at-breadcrumb', props.className)}>
      <a href="/">首页</a>
      {data.map((d, i) => {
        return (
          <React.Fragment key={i}>
            <span>·</span>
            {d.link ? <a href={d.link}>{d.text}</a> : <p>{d.text}</p>}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default AtBreadcrumb

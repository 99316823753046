import * as React from 'react'
import './style.scss'

const Photos: React.FC<any> = props => {
  const { carInfo = {} } = props.state
  const { inside = [], outside = [] } = carInfo.photos || {}

  return (
    <div className="com-hot-car-photos" id="car-photos">
      <h3>外观/内饰</h3>

      {outside.length && (
        <div className="list">
          {outside.map((o: any, i: number) => {
            if (Array.isArray(o)) {
              return (
                <div className="grid" key={i}>
                  {o.map((p: any) => {
                    return <img src={p} key={p} />
                  })}
                </div>
              )
            }
            return <img src={o} key={i} />
          })}

          <sup>外观</sup>
        </div>
      )}

      {inside.length && (
        <div className="list">
          {inside.map((o: any, i: number) => {
            if (Array.isArray(o)) {
              return (
                <div className="grid" key={i}>
                  {o.map((p: any) => {
                    return <img src={p} key={p} />
                  })}
                </div>
              )
            }
            return <img src={o} key={i} />
          })}

          <sup className="right">内饰</sup>
        </div>
      )}
    </div>
  )
}

export default Photos

import cn from 'classnames'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap_white.css'
import * as React from 'react'
import { useToggle } from 'react-use'
import './style'
const positions = require('positions')

interface IToolTipBtnProps {
  parentRef: any
  labelWidth: number
  parentValue: any
  value: any
  onChange?: (val: any, extraVal: any) => void
  className?: string
  options: Array<{
    label: string
    value: any
  }>
}

const ToolTipBtn: React.FC<IToolTipBtnProps> = props => {
  const { labelWidth = 100 } = props
  const btnRef: any = React.createRef()

  const onPopupAlign = (tooltipEl: any, align: any) => {
    const bounds = tooltipEl.getBoundingClientRect()
    const placementsMap: any = {
      tc: 'top center',
      bc: 'bottom center',
      cl: 'center right',
      cr: 'center left',
    }

    // 一般情况下内容不换行不会超过70，若超过即视为换行了，设置最大宽度
    if (bounds.height > 70 || bounds.width >= 1024 - labelWidth - 40) {
      tooltipEl.style.width = 'auto'
      tooltipEl.style.right = '20px'
      tooltipEl.style.left = `${labelWidth + 20}px`
    }

    // 计算左边的位置是不是超过了版心
    if (parseFloat(tooltipEl.style.left) < labelWidth + 20) {
      tooltipEl.style.left = `${labelWidth + 20}px`
    }

    // 修复位置太靠上
    tooltipEl.style.top = parseFloat(tooltipEl.style.top) + 5 + 'px'

    // 修复箭头的位置没有居中
    const arrowEl = tooltipEl.querySelector('.rc-tooltip-arrow')
    const position = positions(
      arrowEl,
      placementsMap[align.points[0]],
      btnRef.current,
      placementsMap[align.points[1]],
    )

    if (align.points[0] === 'tc' || align.points[0] === 'bc') {
      arrowEl.style.top = ''
      arrowEl.style.left = `${position.left + 5}px`
    } else {
      arrowEl.style.top = `${position.top}px`
      arrowEl.style.left = ''
    }
  }

  const renderOverlay = () => {
    return (
      <div className="at-filter-bar-overlay clearfix">
        {props.options.map(opt => {
          return (
            <button
              key={opt.value}
              className={cn({ active: props.value === opt.value })}
              onClick={props.onChange && props.onChange.bind(null, props.parentValue, opt.value)}
            >
              {opt.label}
            </button>
          )
        })}
      </div>
    )
  }

  return (
    <Tooltip
      placement="bottom"
      getTooltipContainer={() => props.parentRef.current}
      mouseEnterDelay={0.3}
      overlay={renderOverlay()}
      onPopupAlign={onPopupAlign}
    >
      <button ref={btnRef} className={props.className}>
        {props.children}
      </button>
    </Tooltip>
  )
}

interface IFilterBarRowOption {
  label: string
  value: any
  extra?: Pick<IFilterBarRowOption, 'label' | 'value'>[]
}

interface IFilterBarRowProps {
  titleDom?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  title: React.ReactNode
  options: IFilterBarRowOption[]
  more?: IFilterBarRowOption[]
  all?: any
  value?: any
  extraValue?: any
  onChange?: (val: any, extraVal: any) => void
  [otherProps: string]: any
}

const AtFilterBarRow: React.FC<IFilterBarRowProps> = props => {
  const listRef = React.createRef()
  const extraRef = React.createRef()
  const [showMore, toggleShowMore] = useToggle(false)
  const [showAll, toggleShowAll] = useToggle(false)

  const labelWidth = props.title ? 100 : 0
  const Title = props.titleDom || 'h3'

  return (
    <div className={cn('at-filter-bar__row', props.className)}>
      <Title className="title">{props.title}</Title>
      <div
        className={cn('list', 'clearfix', {
          'has-more-btn': !!props.more,
          'has-all-btn': !!props.all,
        })}
        ref={listRef as any}
      >
        {props.options.map(opt => {
          if (opt.extra && opt.extra.length) {
            return (
              <ToolTipBtn
                key={opt.value}
                parentRef={listRef}
                labelWidth={labelWidth}
                parentValue={opt.value}
                value={opt.value === props.value ? props.extraValue : void 0}
                onChange={props.onChange}
                options={opt.extra}
                className={cn({ active: opt.value === props.value })}
              >
                {opt.label}
              </ToolTipBtn>
            )
          }
          return (
            <button
              className={cn({ active: opt.value === props.value })}
              onClick={props.onChange && props.onChange.bind(null, opt.value, void 0)}
              key={opt.value}
            >
              {opt.label}
            </button>
          )
        })}
        {props.more && (
          <button className="more-btn" onClick={toggleShowMore}>
            全部
          </button>
        )}
        {props.all && (
          <button className={cn('all-btn', showAll ? 'open' : '')} onClick={toggleShowAll}>
            {showAll ? '收起' : '全部'}
          </button>
        )}
      </div>
      {props.more && (
        <div className={cn('more', 'clearfix', { visible: showMore })} ref={extraRef as any}>
          {props.more.map(opt => {
            if (opt.extra && opt.extra.length) {
              return (
                <ToolTipBtn
                  key={opt.value}
                  parentRef={extraRef}
                  labelWidth={labelWidth}
                  parentValue={opt.value}
                  value={opt.value === props.value ? props.extraValue : void 0}
                  onChange={props.onChange}
                  options={opt.extra}
                  className={cn({ active: opt.value === props.value })}
                >
                  {opt.label}
                </ToolTipBtn>
              )
            }
            return (
              <button
                className={cn({ active: opt.value === props.value })}
                onClick={props.onChange && props.onChange.bind(null, opt.value, void 0)}
                key={opt.value}
              >
                {opt.label}
              </button>
            )
          })}
        </div>
      )}
      {props.all && <div className={cn('at-filter-bar-all', { open: showAll })}>{props.all}</div>}
    </div>
  )
}

const AtFilterBar: React.FC<any> & { Row: React.FC<IFilterBarRowProps> } = props => {
  return <div className={cn('at-filter-bar', props.className)}>{props.children}</div>
}

AtFilterBar.Row = AtFilterBarRow
export default AtFilterBar

import cn from 'classnames'
import * as React from 'react'
import './style'

interface IProps {
  rate: number
  showText?: boolean
  className?: string
  type?: 1 | 2
}

const Rate: React.FC<IProps> = props => {
  return (
    <div className={cn('com-rate', { type2: props.type === 2 }, props.className)}>
      <span className={cn('star', { n: props.rate < 1 })} />
      <span className={cn('star', { n: props.rate < 2 })} />
      <span className={cn('star', { n: props.rate < 3 })} />
      <span className={cn('star', { n: props.rate < 4 })} />
      <span className={cn('star', { n: props.rate < 5 })} />
      {props.showText !== false && <p>{props.rate}分</p>}
    </div>
  )
}

export default Rate

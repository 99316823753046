import * as React from 'react'
import CarCard from '../../components/carCard'
import Loading from '../../components/loading'
import AtBreadcrumb from '../../ui/breadcrumb'
import AtPagination from '../../ui/pagination'
import Controller from './controller'
import './style'

export default class View extends Controller {
  renderCarList() {
    if (this.state.loading) {
      return null
    }

    return (
      <div className="car-list clearfix">
        {(this.state.carList || []).map(c => {
          return <CarCard className="card" isLongRent={true} key={c.carNo} data={c} />
        })}
      </div>
    )
  }

  renderPagination() {
    if (this.state.loading) {
      return null
    }

    return (
      <div className="shortrent-pagination">
        <AtPagination
          size={20}
          total={this.state.totalPage * 20}
          current={this.state.currentPage}
          onChange={current => {
            this.onChangePage(current)
          }}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="page-long-rent main">
        <AtBreadcrumb className="breadcrumb" data={{ text: this.state.city.name + '长租' }} />
        {this.state.loading && <Loading />}
        {this.renderCarList()}
        {this.renderPagination()}
      </div>
    )
  }
}

import cn from 'classnames'
import qs from 'qs'
import * as React from 'react'
import AtAlert from '../../../../ui/alert'
import AtButton from '../../../../ui/button'
import AtInput from '../../../../ui/input'
import AtSelect from '../../../../ui/select'
import './style'
import {
  getCarBrandList,
  getCarPlaceList,
  getCarYearList,
  getOpenCityList,
  getCarTypeList,
  getCarModelList,
  getKmList,
  calcCarIncome,
  sendCarOwnerSmsCodeToMobile,
  submitCarOwner,
} from '../../service'

interface IProps {
  type?: string
  className?: string
}

interface ICity {
  code: string
  name: string
}

interface ISelectValue {
  value: string
  label: string
}

interface IState {
  isOpen: boolean
  timeout: number
  randomCode: string
  carPlaceList: string[]
  openCityList: ICity[]
  carYearList: string[]
  carBrandList: any[]
  carTypeList: any[]
  carModelList: any[]
  kmList: string[]

  carCityValue: ISelectValue
  carPlaceValue: string[]
  carYearValue: ISelectValue
  carBrandValue: ISelectValue
  carTypeValue: ISelectValue
  carModelValue: ISelectValue
  kmValue: ISelectValue

  incomeValue: string
  mobileValue: string
  imgCodeValue: string
  smsCodeValue: string
}

class PanelCarOwnerForm extends React.PureComponent<IProps, IState> {
  state = {
    isOpen: false,
    randomCode: '',
    timeout: 0,

    carPlaceList: [],
    openCityList: [],
    carYearList: [],
    carBrandList: [],
    carTypeList: [],
    carModelList: [],
    kmList: [],

    carCityValue: { value: '', label: '' },
    carPlaceValue: [],
    carYearValue: { value: '', label: '' },
    carBrandValue: { value: '', label: '' },
    carTypeValue: { value: '', label: '' },
    carModelValue: { value: '', label: '' },
    kmValue: { value: '', label: '' },

    incomeValue: '0',
    mobileValue: '',
    imgCodeValue: '',
    smsCodeValue: '',
  }

  async componentDidMount() {
    try {
      const [
        openCityList = [],
        carPlaceList = [],
        carBrandList = [],
        carYearList = [],
        kmList,
      ] = await Promise.all([
        getOpenCityList(),
        getCarPlaceList(),
        getCarBrandList(),
        getCarYearList(),
        getKmList(),
      ])
      this.setState({ openCityList, carPlaceList, carBrandList, carYearList, kmList })
    } catch (err) {
      console.log(err)
    }
  }

  onToggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  onCarCityChange = (val: any) => {
    const res: any = this.state.openCityList.find((v: any) => v.code === val)
    if (res) {
      this.setState({ carCityValue: { value: res.code, label: res.name } })
    }
  }

  onCarYearChange = (val: any) => {
    this.setState({ carYearValue: { value: val, label: val } }, this.calcCarIncome)
  }

  onKmChange = (val: any) => {
    this.setState({ kmValue: { value: val, label: val } })
  }

  onCarPlaceChange = (val: any) => {
    this.setState({ carPlaceValue: val })
  }

  onCarBrandChange = async (val: any) => {
    const res: any = this.state.carBrandList.find((v: any) => v.id === val)
    if (res) {
      if (this.state.carBrandValue.value === val) {
        return
      }
      this.setState({
        carBrandValue: { value: res.id, label: res.name },
        carTypeValue: { value: '', label: '' },
        carModelValue: { value: '', label: '' },
      })
      try {
        const type = await getCarTypeList(val)
        this.setState({ carTypeList: type })
      } catch (err) {}
    }
  }

  onCarTypeChange = async (val: any) => {
    const res: any = this.state.carTypeList.find((v: any) => v.id === val)
    if (res) {
      if (this.state.carTypeValue.value === val) {
        return
      }
      this.setState({
        carTypeValue: { value: res.id, label: res.txt },
        carModelValue: { value: '', label: '' },
      })
      try {
        const model = await getCarModelList(val)
        this.setState({ carModelList: model })
      } catch (err) {}
    }
  }

  onCarModelChange = (val: any) => {
    const res: any = this.state.carModelList.find((v: any) => v.id === val)
    if (res) {
      this.setState({ carModelValue: { value: res.id, label: res.txt } }, this.calcCarIncome)
    }
  }

  onInputChange = (evt: any) => {
    if (!evt.target.name) {
      return
    }
    this.setState({ [evt.target.name]: evt.target.value } as any)
  }

  getImgCodeUrl = () => {
    const randomCode = this.state.randomCode || String(Date.now().valueOf())
    if (!this.state.randomCode) {
      this.setState({ randomCode })
    }
    let origin = ''
    if (process.env.BUILD_TARGET !== 'server' && process.env.NODE_ENV !== 'development') {
      origin = location.origin
    }
    if (process.env.NODE_ENV === 'development') {
      origin = process.env.RAZZLE_ORIGIN as string
    }
    return `${origin}/autowebgateway/h5/carOwner/imgCode/verifyCode/?random=${randomCode}`
  }

  refreshImgCode = () => {
    const randomCode = String(Date.now().valueOf())
    this.setState({ randomCode })
  }

  calcCarIncome = async () => {
    if (this.state.carModelValue.value && this.state.carYearValue.value) {
      try {
        const res = await calcCarIncome(
          this.state.carModelValue.value,
          this.state.carYearValue.value,
        )
        this.setState({ incomeValue: res })
      } catch (err) {}
    }
  }

  runSmsCodeTimeout = (t: number) => {
    if (t < 0) {
      return
    }
    this.setState({
      timeout: t,
    })
    setTimeout(this.runSmsCodeTimeout, 1000, t - 1)
  }

  onGetSmsCode = async () => {
    if (!this.state.mobileValue) {
      return AtAlert.error('请输入手机号')
    }
    if (!/1[0-9]{10}/.test(this.state.mobileValue)) {
      return AtAlert.error('请输入正确的手机号')
    }
    if (!this.state.imgCodeValue) {
      return AtAlert.error('请输入图形验证码')
    }
    try {
      await sendCarOwnerSmsCodeToMobile({
        mobile: this.state.mobileValue,
        verifyCode: this.state.imgCodeValue,
      })
      AtAlert.success('发送成功！')
      this.runSmsCodeTimeout(60)
    } catch (err) {
      if (err.code === '50004') {
        this.setState({ smsCodeValue: '', randomCode: String(Date.now().valueOf()) })
        return AtAlert.error(err.message)
      }
      AtAlert.error(err.message || '系统错误')
    }
  }

  onSubmitClick = async () => {
    if (!this.state.carCityValue.value) {
      return AtAlert.error('请选择车辆所在地')
    }
    if (!this.state.carPlaceValue.length) {
      return AtAlert.error('请选择车辆归属地')
    }
    if (!this.state.carBrandValue.value) {
      return AtAlert.error('请选择品牌')
    }
    if (!this.state.carTypeValue.value) {
      return AtAlert.error('请选择车型')
    }
    if (!this.state.carTypeValue.value) {
      return AtAlert.error('请选择型号')
    }
    if (!this.state.carYearValue.value) {
      return AtAlert.error('请选择出厂年份')
    }
    if (!this.state.kmValue.value) {
      return AtAlert.error('请选择公里数')
    }
    if (!this.state.mobileValue) {
      return AtAlert.error('请输入手机号')
    }
    if (!/1[0-9]{10}/.test(this.state.mobileValue)) {
      return AtAlert.error('请输入正确的手机号')
    }
    if (!this.state.imgCodeValue) {
      return AtAlert.error('请输入图形验证码')
    }
    if (!this.state.smsCodeValue) {
      return AtAlert.error('请输入短信验证码')
    }
    try {
      const search = qs.parse(window.location.search.replace(/^\?/, ''))
      await submitCarOwner({
        city: this.state.carCityValue.value,
        carLocation: this.state.carPlaceValue.join(','),
        brand: String(this.state.carBrandValue.value),
        brandTxt: this.state.carBrandValue.label,
        carType: String(this.state.carTypeValue.value),
        carTypeTxt: this.state.carTypeValue.label,
        carModel: String(this.state.carModelValue.value),
        carModelTxt: this.state.carModelValue.label,
        year: this.state.carYearValue.value,
        mileage: this.state.kmValue.value,
        mobile: this.state.mobileValue,
        validCode: this.state.smsCodeValue,
        utmSource: search.utmSource || '',
        utmMedium: search.utmMedium || '',
        utmCampaign: search.utmCampaign || '',
        utmTerm: search.utmTerm || '',
      })
      this.setState({
        carCityValue: { value: '', label: '' },
        carPlaceValue: [],
        carYearValue: { value: '', label: '' },
        carBrandValue: { value: '', label: '' },
        carTypeValue: { value: '', label: '' },
        carModelValue: { value: '', label: '' },
        kmValue: { value: '', label: '' },
        incomeValue: '0',
        mobileValue: '',
        imgCodeValue: '',
        smsCodeValue: '',
        timeout: 0,
        randomCode: String(Date.now().valueOf()),
      })
      AtAlert.success('信息提交成功')
    } catch (err) {
      AtAlert.error(err.message || '系统错误')
    }
  }

  render() {
    return (
      <div className={cn('com-panel-car-owner-form', { 'in-news': this.props.type === 'news' })}>
        <button
          className={cn('join-button', { 'is-open': this.state.isOpen })}
          onClick={this.onToggleOpen}
        >
          {this.state.isOpen ? '收起' : '注册成为车主'}
          <i />
        </button>
        <div className={cn('form', { 'is-open': this.state.isOpen })}>
          <div className="row">
            <AtSelect
              placeholder="车辆所在地"
              size="middle"
              value={this.state.carCityValue.label}
              onChange={this.onCarCityChange}
            >
              {this.state.openCityList.map((val: ICity) => {
                return (
                  <AtSelect.Option key={val.code} value={val.code}>
                    {val.name}
                  </AtSelect.Option>
                )
              })}
            </AtSelect>
            <AtSelect
              placeholder="车辆归属地"
              value={this.state.carPlaceValue}
              onChange={this.onCarPlaceChange}
              multiple={true}
            >
              {this.state.carPlaceList.map(val => {
                return (
                  <AtSelect.Option key={val} value={val}>
                    {val}
                  </AtSelect.Option>
                )
              })}
            </AtSelect>
          </div>
          <div className="row">
            <AtSelect
              placeholder="请选择品牌"
              size="middle"
              value={this.state.carBrandValue.label}
              onChange={this.onCarBrandChange}
            >
              {this.state.carBrandList?.map((val: any) => {
                return (
                  <AtSelect.Option key={val.id} value={val.id}>
                    {`${val.sort} ${val.name}`}
                  </AtSelect.Option>
                )
              })}
            </AtSelect>
            <AtSelect
              placeholder="请选择车型"
              size="middle"
              value={this.state.carTypeValue.label}
              onChange={this.onCarTypeChange}
            >
              {this.state.carTypeList.map((val: any) => {
                return (
                  <AtSelect.Option key={val.id} value={val.id}>
                    {val.txt}
                  </AtSelect.Option>
                )
              })}
            </AtSelect>
          </div>
          <div className="row">
            <AtSelect
              placeholder="请选择型号"
              size="middle"
              value={this.state.carModelValue.label}
              onChange={this.onCarModelChange}
            >
              {this.state.carModelList.map((val: any) => {
                return (
                  <AtSelect.Option key={val.id} value={val.id}>
                    {val.txt}
                  </AtSelect.Option>
                )
              })}
            </AtSelect>
          </div>
          <div className="row">
            <AtSelect
              placeholder="请选择出厂年份"
              size="middle"
              value={this.state.carYearValue.label}
              onChange={this.onCarYearChange}
            >
              {this.state.carYearList.map(val => {
                return (
                  <AtSelect.Option key={val} value={val}>
                    {val}
                  </AtSelect.Option>
                )
              })}
            </AtSelect>
            <AtSelect
              placeholder="请选择公里数"
              size="middle"
              value={this.state.kmValue.label}
              onChange={this.onKmChange}
            >
              {this.state.kmList.map(val => {
                return (
                  <AtSelect.Option key={val} value={val}>
                    {val}
                  </AtSelect.Option>
                )
              })}
            </AtSelect>
          </div>
          <div className="row">
            <AtInput
              placeholder="请输入手机号"
              size="middle"
              name="mobileValue"
              maxLength="11"
              value={this.state.mobileValue}
              onChange={this.onInputChange}
            />
          </div>
          <div className="row">
            <AtInput
              placeholder="请输入图形验证码"
              size="middle"
              name="imgCodeValue"
              value={this.state.imgCodeValue}
              onChange={this.onInputChange}
              extra={
                <img
                  onClick={this.refreshImgCode}
                  className="img-code"
                  src={this.getImgCodeUrl()}
                />
              }
            />
          </div>
          <div className="row">
            <AtInput
              placeholder="请输入短信验证码"
              size="middle"
              name="smsCodeValue"
              value={this.state.smsCodeValue}
              onChange={this.onInputChange}
              extra={
                this.state.timeout > 0 ? (
                  <span className="timeout">{this.state.timeout}S</span>
                ) : (
                  <button className="get-sms-code" onClick={this.onGetSmsCode}>
                    获取验证码
                  </button>
                )
              }
            />
          </div>
          <div className="row total">
            <p>
              预计收益
              <span>
                <strong>{this.state.incomeValue}</strong>元/天
              </span>
            </p>
            <small>在未获知车辆详细配置前，预计收益仅供参考</small>
          </div>
          <div className="row submit">
            <AtButton onClick={this.onSubmitClick}>提交</AtButton>
          </div>
        </div>
      </div>
    )
  }
}

export default PanelCarOwnerForm

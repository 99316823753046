import * as React from 'react'
import './style.scss'

const Qa: React.FC<any> = props => {
  const { qa = [] } = props.state

  return (
    <div className="com-hot-car-qa" id="car-qa">
      <h3>问题解答</h3>

      <div className="list">
        {qa.map((q: any) => {
          return (
            <div className="item" key={q.id}>
              <a href={`/rentqa/310100-1-${q.id}`}>
                <div className="q">
                  <sup>Q</sup>
                  <h3>{q.question}</h3>
                </div>
                <div className="a">
                  <sub>A</sub>
                  <p>{q.answer}</p>
                </div>
              </a>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Qa

import * as React from 'react'
import { getCityNameByHost, getCityByFullLetter } from '../../utils/citys'
import { getCarTypeList } from './service'

interface IProps {
  history: History
  match: any
  dispatch: any
  typeList: any[]
}

export default class Controller extends React.PureComponent<IProps> {
  static async getInitialProps(ctx: any) {
    const host = ctx.req ? ctx.req.headers['host'] : window.location.host
    const cityName = getCityNameByHost(host)
    const city = getCityByFullLetter(cityName)

    try {
      const typeList = await getCarTypeList(city.code)
      return {
        typeList,
      }
    } catch (err) {
      return {
        typeList: [],
      }
    }
  }
}

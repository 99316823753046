import * as React from 'react'
import Swiper from 'react-id-swiper'
import 'react-id-swiper/src/styles/scss/swiper.scss'
import { Link } from 'react-router-dom'
import CDN from '../../utils/cdn'
import './style'

interface IProps {
  city: any
  list: any[]
}

const HotCars: React.FC<IProps> = props => {
  const ref = React.useRef()
  const { list } = props
  if (!list || !list.length) {
    return null
  }

  const params = {
    loop: false,
    slidesPerView: 4,
    spaceBetween: 15,
    containerClass: 'container',
  }

  return (
    <div className="com-hot-cars">
      <Swiper {...params} shouldSwiperUpdate ref={ref}>
        {list.map((car, index) => {
          return (
            <div className="item" key={index}>
              <Link
                className="img"
                style={{
                  backgroundImage: `url(${`${CDN}${car.coverPic}`})`,
                }}
                to={`/duanzu/${car.type}_${car.brand}`}
              >
                {index <= 2 && <span className="mark">TOP{index + 1}</span>}
              </Link>
              <h3 className="name">
                {car.brandTxt} {car.typeTxt}
              </h3>
              <p className="price">
                <span>{car.minPrice}</span>元/天
              </p>
            </div>
          )
        })}
      </Swiper>
      <button
        className="swiper-button swiper-button-next"
        onClick={() => {
          if (ref.current) {
            ;(ref.current as any).swiper.slideNext()
          }
        }}
      />
      <button
        className="swiper-button swiper-button-prev"
        onClick={() => {
          if (ref.current) {
            ;(ref.current as any).swiper.slidePrev()
          }
        }}
      />
    </div>
  )
}

export default HotCars

import cn from 'classnames'
import * as React from 'react'
import IconArrow from './arrow'
import './style'

interface IProps {
  current: number
  total: number
  href: (i: number) => string
}

const Pager: React.FC<IProps> = props => {
  const cur = Math.max(Math.min(props.current, props.total), 0)

  const renderPages = () => {
    if (props.total <= 10) {
      const list = []
      for (let i = 0; i < props.total; i++) {
        list.push(i + 1)
      }
      return (
        <div className="pages">
          {list.map(v => (
            <a href={props.href(v)} key={v} className={cn({ active: v === cur })}>
              {v}
            </a>
          ))}
        </div>
      )
    }

    // 计算头部的页码、尾部的页面、以及当前页码左右保留的页码
    let arr = []
    let start = 0
    let end = 0
    let left = 0
    let right = 0
    if (cur < 4) {
      start = 5
      end = 5
    } else if (cur > props.total - 4) {
      start = 5
      end = 5
    } else {
      start = 2
      end = 2
      left = 2
      right = 2
      if (cur < 6) {
        start = 6 - cur + 2
        left = 4 - start
      }
      if (cur > props.total - 6) {
        end = 6 - (props.total - cur) + 1
        right = 4 - end
      }
    }
    for (let i = 0; i < start; i++) {
      arr.push(i + 1)
    }
    if (left === 0 && right === 0) {
      arr.push(-1)
    }
    if (left > 0) {
      if (arr[arr.length - 1] !== cur - left - 1) {
        arr.push(-1)
      }
      for (let i = 0; i < left; i++) {
        arr.push(cur - left + i)
      }
    }
    if (left > 0 && right > 0) {
      arr.push(cur)
    }
    if (right > 0) {
      for (let i = 0; i < right; i++) {
        arr.push(cur + i + 1)
      }
      if (cur + right !== props.total - end) {
        arr.push(-1)
      }
    }
    for (let i = 0; i < end; i++) {
      arr.push(props.total - end + i + 1)
    }

    return (
      <div className="pages">
        {arr.map((v, i) => {
          if (v === -1) {
            return <span key={i}>···</span>
          }
          return (
            <a key={v} href={props.href(v)} className={cn({ active: v === cur })}>
              {v}
            </a>
          )
        })}
      </div>
    )
  }

  return (
    <div className="com-ui-pager">
      {cur === 1 ? (
        <span className="prev">
          <IconArrow />
          上一页
        </span>
      ) : (
        <a href={props.href(cur - 1)} className="prev">
          <IconArrow />
          上一页
        </a>
      )}
      {renderPages()}
      {cur === props.total ? (
        <span className="next">
          下一页
          <IconArrow />
        </span>
      ) : (
        <a href={props.href(cur + 1)} className="next">
          下一页
          <IconArrow />
        </a>
      )}
    </div>
  )
}

export default Pager

import * as React from 'react'
import local from '../../../../local'
import './style'

interface IProps {
  newsList: any[]
}

const News: React.FC<IProps> = props => {
  if (props.newsList.length === 0) {
    return null
  }

  const renderNewsItem = (data: any, index: number) => {
    const extra: any = local.localNewsData[index] || {}
    return (
      <a className="news-item" key={data.id} href={`/news/310100-default-${data.id}`}>
        <div className="info">
          <h3>{data.title}</h3>
          <p>{extra.desc}</p>
        </div>
        {extra.cover && <img className="thumb" src={extra.cover} />}
      </a>
    )
  }

  return (
    <div className="com-brand-lib-news" id="content-news">
      <header>
        <h2>租车资讯</h2>
        <a href="/news/310100-default" className="more">
          查看更多
          <span />
        </a>
      </header>

      <div className="list">{props.newsList.map(renderNewsItem)}</div>
    </div>
  )
}

export default News

import * as React from 'react'

export default function useMergedState<T>(
  defaluValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [innerValue, setInnerValue] = React.useState<T>(defaluValue)

  React.useEffect(() => {
    setInnerValue(defaluValue)
  }, [defaluValue])

  return [innerValue, setInnerValue]
}

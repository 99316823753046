import axios from 'axios'

// 获取关于里的内容
export const getCarLossInfo = async (data: { insuranceNo: string; carNo: string }) => {
  const res: any = await axios.request({
    url: `/thirdpart/processsystem/AotuClaim/getcarlossinfo?data=${JSON.stringify(data)}`,
    method: 'GET',
  })

  if (res.data.rescode === '000000') {
    return res.data.data
  } else {
    return {}
  }
}

// polyfill
import './assets/js/raf'
import 'core-js/es/map'
import 'core-js/es/set'

import React from 'react'
import { hydrate } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ensureReady, After } from './after'
import routes from './router'
import { Provider } from 'react-redux'
import store from './store'
import Raven from 'raven-js'

import './reset.scss'
import './style.scss'

Raven.config(
  process.env.RAZZLE_PACKAGE === 'production'
    ? 'http://10ace59526e94cf796394fe5f124602b@47.96.104.13:9000/19'
    : 'http://7cb254432b044dca90510659efdfb549@47.96.104.13:9000/17',
).install()

ensureReady(routes).then(data => {
  return hydrate(
    <BrowserRouter>
      <Provider store={store}>
        <After data={data} routes={routes} />
      </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
  )
})

if (module.hot) {
  module.hot.accept()
}

import cn from 'classnames'
import * as React from 'react'
import './style'

interface IProps {
  data: Record<string, { day: string; price: string; isHoliday: string; flag: string }[]>
}

const CarRentCalendar: React.FC<IProps> = props => {
  const getMonthList = React.useCallback(() => {
    const keys = Object.keys(props.data)
    return keys.map((v: any) => {
      return { key: v, title: `${v.substr(0, 4)}年${v.substr(-2)}月` }
    })
  }, [props.data])
  const monthList = getMonthList()
  const [index, setIndex] = React.useState(0)

  if (monthList.length < 2) {
    return null
  }

  const renderCalendarColumn = (month: { key: string; title: string }) => {
    return (
      <div className="column">
        <h6>{month.title}</h6>

        <div className="weeks">
          <span>一</span>
          <span>二</span>
          <span>三</span>
          <span>四</span>
          <span>五</span>
          <span>六</span>
          <span>日</span>
        </div>

        <div className="list">
          {props.data[month.key].map(i => {
            return (
              <div
                className={cn('item', {
                  holiday: i.isHoliday === '1',
                  part: i.flag === '2',
                  disabled: i.flag === '0',
                })}
                key={i.day}
              >
                <p className="day">{i.day}</p>
                {i.flag !== '0' && <p className="amount">￥{i.price}</p>}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="com-car-rent-calendar">
      <button
        className={cn('prev', { disabled: index === 0 })}
        onClick={() => {
          setIndex(index - 1)
        }}
      >
        ←
      </button>
      <button
        className={cn('next', { disabled: index === monthList.length - 2 })}
        onClick={() => {
          setIndex(index + 1)
        }}
      >
        →
      </button>

      {renderCalendarColumn(monthList[index])}
      {renderCalendarColumn(monthList[index + 1])}

      <div className="footer">
        <span className="all">全天可租</span>
        <span className="part">部分时段可租</span>
      </div>
    </div>
  )
}

export default CarRentCalendar

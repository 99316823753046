import React from 'react'
import './style.scss'

const carData = [
  {
    title: '个性车',
    des: '个性的人要开个性的车，我就是马路上最闪耀的明星',
    cars: [
      {
        imgUrl: require('../../imgs/car1.jpg'),
        imgText: '福特 野马',
      },
      {
        imgUrl: require('../../imgs/car2.jpg'),
        imgText: '保时捷 Boxter',
      },
    ],
  },
  {
    title: '性价比好车',
    des: '代步出行实惠最重要，最低只要60元/天起',
    cars: [
      {
        imgUrl: require('../../imgs/car3.jpg'),
        imgText: '丰田 卡罗拉',
      },
      {
        imgUrl: require('../../imgs/car4.jpg'),
        imgText: '大众 帕萨特',
      },
    ],
  },
  {
    title: '品质商务车',
    des: '从宝马3系到玛莎拉蒂总裁，你想要的品质座驾我们都有',
    cars: [
      {
        imgUrl: require('../../imgs/car5.jpg'),
        imgText: '玛莎拉蒂 总裁',
      },
      {
        imgUrl: require('../../imgs/car6.jpg'),
        imgText: '宝马 3系',
      },
    ],
  },
]

export default function CarArea(props) {
  return (
    <div className="carArea">
      {carData.map(({ title, des, cars }, i) => (
        <div className="carAreaItem" key={i}>
          <h3>{title}</h3>
          <p>{des}</p>
          <div className="carList" data-growing-container data-growing-title={title}>
            {cars.map(({ imgUrl, imgText }, j) => (
              <figure key={j}>
                <img src={imgUrl} />
                <figcaption>{imgText}</figcaption>
              </figure>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

import React from 'react'
import './style.scss'
import { render, createPortal, unmountComponentAtNode } from 'react-dom'

let timer

class Alert extends React.Component {
  componentWillUnmount() {
    // clear timeout
    timer && window.clearTimeout(timer)
  }

  render() {
    const { content, isNew } = this.props
    return (
      <div className={`alert-content-sign ${isNew ? 'new' : 'old'}`}>
        <p
          className="alert-content-sign-des"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
        <button
          className="alert-content-sign-button"
          onClick={() => {
            window.location.href = '/m/download'
          }}
        >
          下载APP
        </button>
      </div>
    )
  }
}

export default function alert(params) {
  const { className, callback, content, isNew } = params
  const div = document.createElement('div')
  div.classList.add('alert')

  div.addEventListener(
    'click',
    e => {
      if (e.target.classList.contains('alert-show')) {
        close()
      }
    },
    false,
  )

  timer = setTimeout(() => {
    div.classList.add('alert-show')
  }, 200)

  className && div.classList.add(className)
  document.body.appendChild(div)

  function close() {
    div.classList.add('alert-hide')
    timer = setTimeout(() => {
      if (div?.parentNode) {
        div.parentNode.removeChild(div)
      }
      unmountComponentAtNode(div)
      if (callback) {
        callback()
      }
    }, 200)
  }

  render(createPortal(<Alert content={content} isNew={isNew} onClose={close} />, div), div)
}

import cn from 'classnames'
import * as React from 'react'
import './style.scss'

const CarBaseInfo: React.FC<any> = props => {
  const { carInfo = {}, brandId } = props.state
  const [coverIndex, setCoverIndex] = React.useState(0)

  const onImageClick = (offset: number) => {
    let n = coverIndex + offset
    if (n < 0) {
      n = 0
    } else if (n > carInfo.banner.length - 1) {
      n = carInfo.banner.length - 1
    }
    setCoverIndex(n)
  }

  return (
    <div className="com-hot-car-base-info">
      <div className="cover">
        <button
          className={cn('l', { disabled: coverIndex <= 0 })}
          onClick={() => onImageClick(-1)}
        />
        <button
          className={cn('r', { disabled: coverIndex >= carInfo.banner.length - 1 })}
          onClick={() => onImageClick(1)}
        />
        <img src={carInfo.banner[coverIndex]} />
      </div>

      <div className="info">
        <div className="row">
          <h2>{carInfo.name}</h2>
          <span>
            口碑<strong>{carInfo.score}分</strong>
          </span>
        </div>

        <div className="price">
          <p>
            日租<strong>{carInfo.dayAmt}元</strong>起
          </p>

          <p>
            周租<strong>{carInfo.weekAmt}元</strong>起
          </p>

          <p>
            月租<strong>{carInfo.monthAmt}元</strong>起
          </p>

          <p>
            长租折扣<strong>6-8折</strong>
          </p>
        </div>

        <a href={`/duanzu/0_${brandId}`} className="rent-car">
          体验一下
        </a>

        <a
          href="/signup?utm_source=pcdibanner&utm_medium=guanwang&utm_campaign=pc&utm_term=12c4c5e8abda77e1fdc93653d6414187"
          className="register"
        >
          领券下单
        </a>
      </div>
    </div>
  )
}

export default CarBaseInfo

import * as React from 'react'
import { getCityNameByHost, getCityByFullLetter } from 'utils/citys'
import { fetchCarInfo, fetchCarPrice, fetchNewsList, fetchQA } from './service'

interface IProps {
  [key: string]: any
}

interface IState {
  carInfo: {
    banner: string[]
    comments: {
      avatar: string
      list: { tag: string; content: string }[]
      price: number
      score: number
      time: string
      username: string
    }[]
    compares: {
      name: string
      price: number
      score: number
      thumb: string
    }[]
    configurations: { label: string; value: string }[]
    links: { source: string; thumb: string; title: string; url: string }[]
    name: string
    photos: { inside: string[] | string; outside: string[] | string }
    score: number
    dayAmt: string
    weekAmt: string
    monthAmt: string
  }
  news: { id: number; title: string }[]
  qa: { id: string; answer: string; question: string }[]
  city: any
  brandId: string
  typeId: string
  activeNav: string
}

export default class Controller extends React.PureComponent<IProps, IState> {
  static async getInitialProps(ctx: any) {
    try {
      const host = ctx.req.headers['host']
      const cityName = getCityNameByHost(host)
      const key = ctx?.match?.params?.key
      const city = getCityByFullLetter(cityName)
      const brandId = key.split('_')[0]
      const typeId = key.split('_')[1]

      const res: any[] = await Promise.all([
        fetchCarInfo(brandId, typeId),
        fetchCarPrice(brandId, typeId, city.code),
        fetchNewsList(city.code),
        fetchQA(city.code),
      ])

      const data = {
        carInfo: {
          ...res[0],
          ...res[1],
        },
        news: res[2].list || [],
        qa: res[3].qaDetailInfoResVos || [],
        city,
        brandId,
        typeId,
      }
      return data
    } catch (err) {
      console.log(err, 'errrrrrr:::::::')
    }

    return { carInfo: {}, news: [], qa: [], city: {} }
  }

  state = {
    carInfo: {
      banner: [],
      comments: [],
      compares: [],
      configurations: [],
      links: [],
      name: '',
      photos: { inside: [], outside: [] },
      score: 0,
      dayAmt: '',
      weekAmt: '',
      monthAmt: '',
    },
    news: [],
    qa: [],
    brandId: '',
    typeId: '',
    city: {},
    activeNav: 'configuration',
  }

  constructor(props: any) {
    super(props)

    this.state = {
      carInfo: props.carInfo,
      news: props.news,
      qa: props.qa,
      brandId: props.brandId,
      typeId: props.typeId,
      city: props.city,
      activeNav: 'configuration',
    }
  }

  onNavClick = (value: string) => {
    this.setState({ activeNav: value })
    const dom = document.getElementById('car-' + value)
    if (!dom) {
      return
    }
    window.scrollTo({
      top: dom.offsetTop - 100,
      behavior: 'smooth',
    })
    setTimeout(() => {
      this.setState({ activeNav: 'configuration' })
    }, 1000)
  }
}

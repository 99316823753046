import BScroll from 'better-scroll'
import cn from 'classnames'
import * as React from 'react'
import './style'

interface IProps {
  active: string
  onClick: (key: string, subs: any) => void
  className?: string
  list: any
}

let scroll: any = null

const Menu: React.FC<IProps> = props => {
  const ref: any = React.useRef()
  const [leftBtnDisabled, setLeftBtnDisabled] = React.useState(true)
  const [rightBtnDisabled, setRightBtnDisabled] = React.useState(false)

  const onScrollStart = () => {
    setLeftBtnDisabled(false)
    setRightBtnDisabled(false)
  }

  const onScrollEnd = (pos: any) => {
    const isEnd = scroll.wrapperWidth - scroll.scrollerWidth >= pos.x
    if (pos.x === 0) {
      setLeftBtnDisabled(true)
      setRightBtnDisabled(false)
    } else if (isEnd) {
      setLeftBtnDisabled(false)
      setRightBtnDisabled(true)
    }
  }

  React.useEffect(() => {
    if (ref.current) {
      scroll = new BScroll(ref.current, { scrollX: true, scrollY: false, bounce: false })
      setTimeout(() => {
        if (scroll) {
          scroll.refresh()
          scroll.on('scrollStart', onScrollStart)
          scroll.on('scrollEnd', onScrollEnd)
        }
      }, 100)
    }
    return () => {
      if (scroll) {
        scroll.off('scrollStart')
        scroll.off('scrollEnd')
        scroll.destroy()
        scroll = null
      }
    }
  }, [])

  const scrollBtnClick = (evt: any) => {
    if (!scroll) {
      return
    }
    if (evt.target.name === 'left') {
      scroll.scrollTo(0, 0, 200)
      setLeftBtnDisabled(true)
      setRightBtnDisabled(false)
    } else {
      const end = scroll.wrapperWidth - scroll.scrollerWidth
      scroll.scrollTo(end, 0, 200)
      setLeftBtnDisabled(false)
      setRightBtnDisabled(true)
    }
  }

  return (
    <div className="com-about-us-menu">
      <h2>
        凹凸出行
        <br />
        万款车型共享平台
      </h2>
      <nav className="scroll-wrapper">
        <button
          className={cn('btn', 'left', { disabled: leftBtnDisabled })}
          name="left"
          onClick={scrollBtnClick}
        />
        <div className="scroll" ref={ref}>
          <div>
            {props.list.map((m: any) => {
              return (
                <button
                  key={m.value}
                  className={props.active === m.value ? 'active' : ''}
                  onClick={() => {
                    props.onClick(m.value, m.subs)
                  }}
                >
                  {m.label}
                </button>
              )
            })}
          </div>
        </div>
        <button
          className={cn('btn', 'right', { disabled: rightBtnDisabled })}
          name="right"
          onClick={scrollBtnClick}
        />
      </nav>
    </div>
  )
}

export default Menu

import httpx from '../../utils/httpX'

// 获取车辆列表
export const fetchCarList = async (
  cityCode: string,
  filter: Record<string, any>,
  pageNum: number,
  pageSize: number,
) => {
  const filterCondition: any = {
    seq: 4,
  }
  Object.keys(filter).forEach(k => {
    if (k === 'regionVo' || k === 'seq') {
      filterCondition[k] = filter[k]
      return
    }
    if (k === 'brands') {
      if (!filterCondition.brandAndTypeList) {
        filterCondition.brandAndTypeList = []
      }
      const brands = filter[k] || []
      brands.forEach((b: any) => {
        const data: any = { brandId: b.id }
        if (b.typeIds && b.typeIds.length) {
          data.typeList = []
          b.typeIds.forEach((t: string) => {
            data.typeList.push({ typeId: t })
          })
        }
        filterCondition.brandAndTypeList.push(data)
      })
      return
    }
    if (!filterCondition[k]) {
      filterCondition[k] = {}
    }
    filterCondition[k][filter[k]] = '1'
  })
  const res: any = await httpx.request({
    method: 'POST',
    url: '/appserver/h5/v592/search/car/list',
    data: {
      cityCode,
      sceneCode: 'U005',
      pageNum: pageNum || 1,
      pageSize: pageSize || 8,
      searchType: 1,
      filterCondition,
    },
  })
  return {
    list: res.carList || [],
    pageNum: pageNum || 1,
    pageSize: pageSize || 8,
    total: parseInt(res.count, 10),
  }
}

// 获取商圈列表
export const fetchBusinessCenterList = async (cityCode: string, hotList = true) => {
  const res: any = await httpx.request({
    method: 'GET',
    url: '/appserver/h5/v46/filter/config',
    params: {
      cityCode,
      sceneCode: 'U004',
      OS: 'pc',
    },
  })

  const result: any[] = []

  if (res.hotBusinessCenterList && hotList) {
    const opt: any = {
      label: '热门商圈',
      value: 'HOT',
    }
    opt.extra = res.hotBusinessCenterList.map((b: any) => {
      return { label: b.businessName, value: `${b.areaCode}_${b.businessId}` }
    })
    result.push(opt)
  }

  ;(res.normalBusinessCenterList || []).forEach((bc: any) => {
    const opt: any = {
      label: bc.areaName,
      value: bc.areaCode,
    }
    if (bc.businessList && bc.businessList.length) {
      opt.extra = bc.businessList.map((b: any) => {
        return { label: b.businessName, value: b.businessId }
      })
    }
    result.push(opt)
  })

  return result
}

// 租车资讯-列表
export const fetchNewsList = async (
  cityCode: string,
  pageNum = 1,
  pageSize = 10,
  typ?: 'hot' | 'new',
) => {
  const res: any = await httpx.request({
    method: 'GET',
    url: '/apigateway/pcWebService/public/rentCarInformation/findPage',
    params: {
      cityCode,
      pageNum,
      pageSize,
      type: typ,
    },
  })

  return res
}

// 租车banner-列表网页端
export const fetchBanner = async (cityCode: string) => {
  const res: any = await httpx.request({
    method: 'GET',
    url: '/apigateway/pcWebService/public/rentCarBanner/findPage',
    params: {
      cityCode,
    },
  })
  return res || []
}

export async function getHotCars(cityCode = '310100') {
  try {
    const result: any = await httpx.request({
      method: 'GET',
      url: '/AutoConsoleApiV2/mStation/car/typeRank/month',
      params: {
        pageNo: 1,
        pageSize: 10,
        cityCode,
      },
    })

    return result?.list ? result.list : null
  } catch (error) {
    return null
  }
}

export async function getL2CarData(cityCode = '310100') {
  try {
    const res: any = await httpx.request({
      method: 'post',
      url: '/apigateway/openApiApplets/public/pc/getVehicleList',
      data: {
        cityId: cityCode,
      },
    })

    return res || {}
  } catch (err) {
    return {}
  }
}

import * as React from 'react'
import store from '../../store'
import { getCityByFullLetter, getCityNameByHost } from '../../utils/citys'
import { fetchNewsList } from './service'

export default class Controller extends React.PureComponent<any, any> {
  static async getInitialProps(ctx: any) {
    const host = ctx.req ? ctx.req.headers['host'] : window.location.host
    const cityName = getCityNameByHost(host)
    const city = getCityByFullLetter(cityName)

    try {
      let params: any = {
        cityCode: city.code,
        pageNum: 1,
        pageSize: 5,
      }
      const res = await fetchNewsList(params)

      await store.dispatch({
        type: 'carOwner/set',
        payload: { city },
      })
      return {
        city,
        newsList: res.list,
      }
    } catch (err) {
      console.log(err)
    }

    return {
      city,
      newsList: [],
    }
  }
}
